import { DragHandle as Hamburger } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MyUser } from 'interfaces/baseInterfaces';
import { useState, memo, MouseEvent, useEffect } from 'react';
import posthog from 'posthog-js';
import DrawerWrapper from '../DrawerWrapper';
import AppDrawerContent from '../AppNavigation/AppDrawerContent';
import { NavigationTab } from '../DesktopNavigation/utils/navigationTabs';
import UserProfileWidget from '../UserProfileWidget';

export interface MobileNavBarProps {
  user?: MyUser | null;
  tabs: NavigationTab[];
}

const MobileNavigation = memo(function MobileNavBar(props: MobileNavBarProps) {
  const muiTheme = useTheme();
  const { user, tabs } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const userName = `${user?.first_name} ${user?.last_name}`;

  const companyName = user?.company_name;

  const open = Boolean(anchorEl);
  const openDrawer = (event: MouseEvent<HTMLElement>) => {
    posthog.capture('Hamburger menu click');
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onTabSelect = (tab: NavigationTab) => {
    setAnchorEl(null);
  };

  useEffect(() => {
    return () => {
      setAnchorEl(null);
    };
  }, []);

  return (
    <div className="w-full z-2">
      <header className="h-4 bg-black">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
            padding: '0 16px',
          }}
        >
          <IconButton
            onClick={openDrawer}
            size="small"
            className="ml-0"
            aria-controls={open ? 'menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            sx={{ mt: '-2px' }}
          >
            <Hamburger className="text-white" sx={{ width: 35, height: 35 }} />
          </IconButton>

          <UserProfileWidget
            userName={userName}
            companyName={companyName}
            userNameStyle={{ color: muiTheme.palette.common.white, fontSize: 16 }}
            companyNameStyle={{ color: '#9D9D9D', fontSize: 14 }}
            iconStyle={{ color: muiTheme.palette.common.white }}
            avatarStyle={{ width: 30, height: 30 }}
            avatarTextStyle={{ fontSize: 20.4 }}
            variant="small"
          />
        </Box>
        <DrawerWrapper
          onOpen={() => {
            posthog.capture('Primary navigation drawer open');
          }}
          open={open}
          onClose={handleClose}
          drawerClasses="w-20 mt-0 overflow-x-hidden overflow-y-hidden bg-black h-full"
          anchor="left"
        >
          <AppDrawerContent
            isDrawerOpen={true}
            tabs={tabs}
            user={user}
            onTabSelect={onTabSelect}
            isMobile={true}
            closeDrawer={handleClose}
          />
        </DrawerWrapper>
      </header>
    </div>
  );
});

export default MobileNavigation;
