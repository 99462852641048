import { useQuery } from '@tanstack/react-query';
import accountingService from './accounting.service';
import { Pagination } from '../../../common/pagination';

export const useGetPayments = (pagination: Pagination) => {
  return useQuery({
    queryKey: ['/payments', pagination],
    queryFn: () => accountingService.getPayments(pagination),
  });
};
