/**
 * Ensures user has an active deposit account
 *
 * Depends on a unit (credit) account being created and approved.
 *
 * If no deposit account exists, return fallBackPage if provided.
 *
 * * NOTE!!!: fallbackPage is not wrapped by any auth wrapper.
 * This is to ensure flexibility in how other pages want to use it.
 * * Always ensure the necessary auth wrapper is provided
 * around your fallbackPage as you deem fit.
 *
 */

import { ScreenProps } from 'interfaces/baseInterfaces';
import RequiresUnitApplication from 'app/pages/authWrappers/RequiresUnitApplication';
import UnauthorizedPage from 'app/pages/authWrappers/UnauthorizedPage';

interface RequiresDepositAccountProps extends ScreenProps {
  children: JSX.Element;
  dangerouslySetFallbackPage?: JSX.Element;
}

const RequiresDepositAccount = (props: RequiresDepositAccountProps) => {
  const { currentUser, children, dangerouslySetFallbackPage: fallbackPage } = props;

  if (currentUser.is_deposit_customer) {
    return <RequiresUnitApplication {...props}>{children}</RequiresUnitApplication>;
  }

  return (
    fallbackPage ?? (
      <RequiresUnitApplication {...props}>
        <UnauthorizedPage {...props} />
      </RequiresUnitApplication>
    )
  );
};

export default RequiresDepositAccount;
