import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { useState } from 'react';
import CreateTagModal from '../CreateTagModal';
import LoadingButton from '../../../../app/components/LoadingButton';
import { ButtonProps } from '@mui/material/Button';

interface CreateTagButtonProps extends ButtonProps {}

const CreateTagButton = (props: CreateTagButtonProps) => {
  const [isCreateTagModalVisible, setIsCreateTagModalVisible] = useState(false);
  const openCreateTagModal = () => setIsCreateTagModalVisible(true);
  const closeCreateTagModal = () => setIsCreateTagModalVisible(false);

  return (
    <>
      <LoadingButton onClick={openCreateTagModal} {...props} className="flex items-center">
        <AddBoxOutlinedIcon className={props.variant === 'outlined' ? 'text-dark-green' : ''} />
        <span
          className={`ml-[5px] ${props.variant === 'outlined' && 'text-dark-green font-normal'}`}
        >
          Create Tag
        </span>
      </LoadingButton>
      <CreateTagModal isOpen={isCreateTagModalVisible} onClose={closeCreateTagModal} />
    </>
  );
};

export default CreateTagButton;
