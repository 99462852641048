import baseService from './base.service';
import { objectToUrlParams } from '../helpers/base';
import imageCompression from 'browser-image-compression';
import httpClient from './httpClient';
import { CreditCard } from '../interfaces/baseInterfaces';

export interface CreateCompany {
  tax_id: string;
}

export interface CreateAddress {
  line1: string;
  line2?: string;
  city: string;
  country: string;
  state?: string;
  postal_code: string;
}

export interface CreateBilling {
  address: CreateAddress;
}

export interface CreateCardholder {
  billing: CreateBilling;
  name: string;
  type: string;
  email?: string;
  phone_number?: string;
  company?: CreateCompany;
}

export interface CreateCard {
  first_name: string;
  last_name: string;
  unit_verification_code?: string;
}

interface GetCardsResponse {
  cards: CreditCard[];
  cardholder_limit: number;
}

class FinancialService {
  public async createCardholder(payload: CreateCardholder) {
    return await baseService.postJSON('cardholders', payload);
  }

  public async addNewCard(payload: CreateCard) {
    return await baseService.postJSON('cards', payload);
  }

  public async addNewMemberCard(role_id: any, payload: CreateCard) {
    return await baseService.postJSON('cards/member/' + role_id, payload);
  }

  public async getCards(params?: any) {
    const filters = params ? objectToUrlParams(params) : '';
    return await baseService.getJSON(`cards${filters}`);
  }

  public async queryCards(status?: string) {
    return httpClient.get<GetCardsResponse>('/cards', { params: { status } }).then(res => res.data);
  }

  public async getCard(card_id: string) {
    return await baseService.getJSON('cards/' + card_id);
  }

  public async cancelCard(card_id: string, unit_verification_code?: string) {
    return await baseService.postJSON('cards/' + card_id, {
      unit_verification_code: unit_verification_code ?? null,
      status: 'canceled',
    });
  }

  public async changeCardFreezeStatus(
    card_id: string,
    unit_verification_code: string,
    freeze: boolean,
  ) {
    return await baseService.postJSON('cards/' + card_id, {
      unit_verification_code: unit_verification_code,
      status: freeze ? 'frozen' : 'unfrozen',
    });
  }

  public async modifyCardLimits(
    card_id: string,
    max_spent_limit: number | null,
    max_transaction_limit: number | null,
    unit_verification_code?: string | null,
  ) {
    return await baseService.postJSON('cards/' + card_id, {
      max_spent_limit,
      max_transaction_limit,
      unit_verification_code,
    });
  }

  public async checkAuth() {
    return await baseService.getJSON('check-auth');
  }

  public async getTransactions(params?: any) {
    return await httpClient
      .get('/transactions', {
        params,
        paramsSerializer: {
          indexes: true, // no brackets at all
        },
      })
      .then(res => res.data);
  }

  public async getTransactionFilters(params?: any) {
    const filters = objectToUrlParams(params);
    const url = params ? 'transaction-filters' + filters : 'transaction-filters';
    return await baseService.getJSON(url);
  }

  public async makePayment(amount: number) {
    return await baseService.postJSON('payments', { amount });
  }

  public async getDepositInformation() {
    return await baseService.getJSON('deposit/information');
  }

  public async getBankVerficationPDF() {
    return await baseService.getJSON('deposit/verification');
  }

  public async addTransactionMemo(stripe_transaction_id: string, memo: string) {
    return await baseService.postJSON('transaction-memos', {
      stripe_transaction_id,
      memo,
    });
  }

  public async addTransactionRutterAccount(
    platform: string,
    transaction_id: string,
    rutter_account_id: string,
  ) {
    return await baseService.postJSON(`rutter/${platform}/transaction-categories`, {
      transaction_id,
      account_id: Number(rutter_account_id),
    });
  }

  public async getTransactionCashback(transaction_id: string) {
    return await baseService.getJSON('transaction-cashback/' + transaction_id);
  }

  public async getCardPinStatus(card_id: string) {
    return await baseService.getJSON(`/cards/${card_id}/secure-data/pin/status`);
  }

  public async uploadReceipt(file: File, stripe_id: string) {
    const options = {
      maxSizeMB: 1,
    };
    const compressedImage = await imageCompression(file, options);

    const formData: FormData = new FormData();
    formData.append(file.name, compressedImage);

    return await baseService.postFormData(`receipts/${stripe_id}`, formData);
  }

  public async downloadReceipt(stripe_id: string) {
    return await baseService.getJSON(`receipts/${stripe_id}`);
  }

  public async replaceReceipt(file: File, stripe_id: string) {
    const options = {
      maxSizeMB: 1,
    };
    const compressedImage = await imageCompression(file, options);

    const formData: FormData = new FormData();
    formData.append(file.name, compressedImage);

    return await baseService.postFormData(`receipts/${stripe_id}/replace`, formData);
  }
}

const financialService = new FinancialService();

export default financialService;
