import { PayVendorFormData } from 'app/pages/dashboard/vendors/pay/index';

import { DASHBOARD_PATHS } from '../../../../../constants/routing';

export const defaultFormData: PayVendorFormData = {
  details: {},
  amount: {},
  moreDetails: {},
};

export const isDetailsStageComplete = (formData: PayVendorFormData['details']) => {
  const requiredFields: (keyof PayVendorFormData['details'])[] = ['vendorId'];

  for (const field of requiredFields) {
    if (!formData[field]) {
      return false;
    }
  }
  return true;
};

export const isAmountStageComplete = (formData: PayVendorFormData['amount']) => {
  const requiredFields: (keyof PayVendorFormData['amount'])[] = ['amount'];

  for (const field of requiredFields) {
    if (!formData[field]) {
      return false;
    }
  }
  return true;
};

export const isMoreDetailsStageComplete = (formData: PayVendorFormData['moreDetails']) => {
  const requiredFields: (keyof PayVendorFormData['moreDetails'])[] = [
    'internalMemo',
    'externalMemo',
  ];

  for (const field of requiredFields) {
    if (!formData[field]) {
      return false;
    }
  }
  return true;
};

export const getIncompleteStagePath = (formData: PayVendorFormData) => {
  if (!isDetailsStageComplete(formData.details)) {
    return DASHBOARD_PATHS.payVendor;
  }
  if (!isAmountStageComplete(formData.amount)) {
    return DASHBOARD_PATHS.payVendorAmount;
  }
  if (!isMoreDetailsStageComplete(formData.moreDetails)) {
    return DASHBOARD_PATHS.payVendorMoreDetails;
  }

  return DASHBOARD_PATHS.payVendorReview;
};

export const maskAccountNumber = (accountNumber: string, numberOnly?: boolean) => {
  return numberOnly ? `**${accountNumber?.slice(-4)}` : `Account **${accountNumber?.slice(-4)} `;
};
