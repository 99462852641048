import { usePostIntegrationInterest } from '../../companies/data/postIntegrationInterest';
import posthog from 'posthog-js';
import IntegrationConnectionCard from './IntegrationConnectionCard';
import xero_logo from 'app/assets/images/toolbox_logo.png';

interface ToolboxAiConnectionCardProps {}

const ToolboxAiConnectionCard = (props: ToolboxAiConnectionCardProps) => {
  const { mutate: postInterest, isPending, isSuccess } = usePostIntegrationInterest();

  const handleConnection = () => {
    postInterest('toolbox-ai');
    posthog.capture('Clicked Toolbox AI Button');
  };

  return (
    <IntegrationConnectionCard
      title="Toolbox AI"
      subtitle="Coming Soon for Banking Customers"
      onConnect={handleConnection}
      connectLabel="Indicate Interest"
      logo={xero_logo}
      disabled={isSuccess || isPending}
    />
  );
};

export default ToolboxAiConnectionCard;
