import { useMutation } from '@tanstack/react-query';
import rutterService from './rutter.service';
import { queryClient } from '../../../../common/queryClient';
import { RutterPlatform } from '../rutter.types';

export const useCreateRutterAccount = (platform: RutterPlatform) =>
  useMutation({
    mutationFn: rutterService.createRutterAccount,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [`rutter/${platform}/accounts`] }),
  });
