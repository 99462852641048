import React, { useEffect, useReducer, useState } from 'react';
import { Alert, Button, Menu, MenuItem } from '@mui/material';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import MoreVert from '@mui/icons-material/MoreVert';
import { useTheme } from '@mui/material/styles';
import AddressAutoComplete from '../AddressAutoComplete';
import {
  DepositPageActionType,
  depositPageInitialState,
  depositPageReducer,
  Location,
} from 'app/pages/dashboard/deposit/reducer';
import depositService from 'services/deposit.service';
import { standardError } from 'helpers/base';
import CustomDialog from '../CustomDialog';
import { useNavigate } from 'react-router';

import { DASHBOARD_PATHS } from '../../../constants/routing';
import financialService from '../../../services/financial.service';
import posthog from 'posthog-js';
import { formatDollarNumber } from 'helpers/formattings';

interface ActionMenuButtonProps {
  open: boolean;
  anchorEl?: null | HTMLElement;
  handleActionClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleActionClose: () => void;
  handleActionAddsFunds: () => void;
  handleShowAccountDetails: () => void;
  style?: React.CSSProperties;
}

const center = {
  lat: 40.7128,
  lng: -74.006,
};

const ActionMenuButton: React.FC<ActionMenuButtonProps> = ({
  open,
  anchorEl,
  handleActionClick,
  handleActionClose,
  handleActionAddsFunds,
  handleShowAccountDetails,
  style,
}) => {
  const muiTheme = useTheme();

  const navigate = useNavigate();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [selectedATM, setSelectedATM] = useState<Location | null>(null);

  const [mapCenter, setMapCenter] = useState(center);

  const [state, dispatch] = useReducer(depositPageReducer, depositPageInitialState);

  const containerStyle = {
    width: '100%',
    height: '300px',
    marginTop: '16px',
  };

  const handleAddressSelection = async (_: string, __: string, ___: string, zipCode: string) => {
    dispatch({ type: DepositPageActionType.Loading, payload: true });

    await depositService
      .getATMLocations(zipCode)
      .then(res => {
        if (res?.error) throw new Error();
        if (res) {
          dispatch({ type: DepositPageActionType.ATMLocations, payload: res?.locations });
        }
      })
      .catch(err => {
        standardError(err);
      });

    dispatch({ type: DepositPageActionType.Loading, payload: false });
  };

  const handleATMLocatorsClick = () => {
    posthog.capture('Banking Page - Actions - ATM Locator Click');
    setIsDialogOpen(true);
    handleActionClose();
  };

  const handleBankVerification = async () => {
    posthog.capture('Banking Page - Actions - Bank Verification Click');
    handleActionClose();
    const resp = await financialService.getBankVerficationPDF();

    if (resp.error) {
      standardError(resp.error);
      return;
    }

    const data = new Blob([resp], { type: 'application/pdf' });
    const csvURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'Bank Verification.pdf');
    tempLink.click();
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handlePayVendor = () => {
    posthog.capture('Banking Page - Actions - Pay A Vendor Click');
    navigate(DASHBOARD_PATHS.payVendor);
  };

  useEffect(() => {
    if (state.atmLocations.length > 0) {
      setMapCenter({
        lat: state.atmLocations[0].coordinates.latitude,
        lng: state.atmLocations[0].coordinates.longitude,
      });
    }
  }, [state.atmLocations]);

  return (
    <div className="flex-grow-1 pl-half text-right">
      <Button
        id="deposits-actions-button"
        color="secondary"
        variant="outlined"
        data-cy="actionButton"
        className="h-full"
        style={{ paddingLeft: '18px', ...style }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleActionClick}
        endIcon={<MoreVert />}
      >
        Actions
      </Button>
      <Menu
        id="deposits-actions-menu"
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: -8, horizontal: 'right' }}
        onClose={handleActionClose}
        open={open}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '4px',
            border: '1px solid',
            borderColor: '#F7F7F7',
            background: muiTheme.palette.common.white,
            boxShadow: '0px 4px 8px 2px rgba(37, 37, 37, 0.10)',
          },
          '& .MuiMenuItem-root': {
            fontSize: '14px',
            display: 'flex',
            padding: '8.5px 18px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            background:
              'linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%)',
            '&:hover, &.Mui-selected': {
              color: muiTheme.palette.primary.main || '#09442F',
              backgroundColor: '#E7EDEA',
            },
          },
          '& .MuiMenu-list': {
            padding: '3px 0',
          },
        }}
      >
        <MenuItem data-cy="addFunds" onClick={handleActionAddsFunds}>
          Add Funds
        </MenuItem>
        <MenuItem data-cy="payVendor" onClick={handlePayVendor}>
          Pay a Vendor
        </MenuItem>
        <MenuItem data-cy="viewAccDetails" onClick={handleShowAccountDetails}>
          View Account Details
        </MenuItem>
        <MenuItem data-cy="atmLocator" onClick={handleATMLocatorsClick}>
          <div style={{ display: 'flex', alignItems: 'center' }}>ATM Locator</div>
        </MenuItem>
        <MenuItem data-cy="bankVerification" onClick={handleBankVerification}>
          Bank Verification
        </MenuItem>
      </Menu>

      <CustomDialog
        title="ATM Locator"
        subtitle="See the ATMs near you"
        open={isDialogOpen}
        onClose={handleCloseDialog}
      >
        <div
          style={{
            paddingTop: 16,
          }}
        >
          <AddressAutoComplete
            customStyle
            cb={(address, city, state, zipCode) => {
              handleAddressSelection(address, city, state, zipCode);
            }}
          />
        </div>

        <div>
          <Alert icon={false} severity="warning" className="mt-half">
            Withdrawal Limit <span className="font-bold">{formatDollarNumber(500)}/day</span>
            <span className="mx-[10px]">|</span>Deposit Limit{' '}
            <span className="font-bold">{formatDollarNumber(2000)}/day</span>
          </Alert>
        </div>

        <GoogleMap mapContainerStyle={containerStyle} center={mapCenter} zoom={10}>
          {state.atmLocations.map((atm, idx) => (
            <Marker
              key={idx}
              position={{ lat: atm.coordinates.latitude, lng: atm.coordinates.longitude }}
              onClick={() => setSelectedATM(atm)}
            />
          ))}
          {selectedATM && (
            <InfoWindow
              position={{
                lat: selectedATM.coordinates.latitude,
                lng: selectedATM.coordinates.longitude,
              }}
              onCloseClick={() => setSelectedATM(null)}
            >
              <div>
                <h2>{selectedATM.location_name}</h2>
                <p>
                  {selectedATM.address.street}, {selectedATM.address.city},{' '}
                  {selectedATM.address.state} {selectedATM.address.postal_code}
                </p>
                <p>Network: {selectedATM.network}</p>
                {selectedATM.surcharge_free && <p>Surcharge Free</p>}
                {selectedATM.accept_deposits && <p>Accepts Deposits</p>}
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </CustomDialog>
    </div>
  );
};

export default ActionMenuButton;
