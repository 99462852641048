import React from 'react'
import { Box, Button } from '@mui/material'

interface DialogFooterButtonsProps {
  matchesMobile: boolean
  onCancel: () => void
  onRequestCard: () => void
  isRequestDisabled: boolean
  continueText?: string
}

const DialogFooterButtons: React.FC<DialogFooterButtonsProps> = ({
  matchesMobile,
  onCancel,
  onRequestCard,
  isRequestDisabled,
  continueText = 'Continue',
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: 2,
        width: '100%',
      }}
    >
      <Button
        data-cy="cardCancel"
        variant="outlined"
        color="secondary"
        onClick={onCancel}
        sx={{ marginRight: 1, paddingX: 2, width: matchesMobile ? '45%' : 'auto' }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        data-cy= "cardContinue"
        disabled={isRequestDisabled}
        onClick={onRequestCard}
        sx={{
          paddingX: 3,
          width: matchesMobile ? '45%' : 'auto',
        }}
      >
        {continueText}
      </Button>
    </Box>
  )
}

export default DialogFooterButtons
