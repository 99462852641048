import { EntityTag } from '../../types';
import { Tag as Chip } from 'antd';
import { CloseCircleIcon } from '../../../../app/components/Icon/fontawesome';
import { useRemoveTagFromEntity } from '../../data/removeTagFromEntity';
import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { getTagGroupColor } from '../../tags.helpers';
import Show from '../../../../app/components/Show';
import { useUserRole } from '../../../auth/data/userRole';

interface TagChipProps {
  entityTag: EntityTag;
  className?: string;
  color?: string;
  onRemovedFromEntity: () => void;
}

const TagChip = (props: TagChipProps) => {
  const { entityTag, className, onRemovedFromEntity } = props;
  const [isRemoving, setIsRemoving] = useState(false);
  const { isAdmin } = useUserRole();

  const {
    mutate: removeTagFromEntity,
    isPending,
    isSuccess: isRemoveSuccess,
  } = useRemoveTagFromEntity();

  const handleRemoveTagFromEntity = () => {
    if (isRemoving) {
      return;
    }
    removeTagFromEntity(entityTag.tagged_entity_id);
  };

  useEffect(() => {
    if (isRemoveSuccess) {
      onRemovedFromEntity();
    }
    if (isPending) {
      setIsRemoving(true);
    }
  }, [isRemoveSuccess, isPending]);

  const chipColor = getTagGroupColor(entityTag.tag.group_name);

  return (
    <Chip
      className={`mr-1 rounded-full cursor-pointer ${className}`}
      style={{ color: chipColor, borderColor: chipColor }}
    >
      <div className="flex items-center">
        <span>{entityTag.tag.name}</span>
        <Show when={isRemoving}>
          <CircularProgress size={15} className="ml-[5px]" />
        </Show>
        <Show when={!isRemoving && isAdmin}>
          <CloseCircleIcon
            onClick={handleRemoveTagFromEntity}
            className="text-[15px] ml-[5px] text-[12px]"
            color={chipColor}
          />
        </Show>
      </div>
    </Chip>
  );
};

export default TagChip;
