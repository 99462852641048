import { SellOutlined } from '@mui/icons-material';
import { MenuItem, Select } from '@mui/material';
import { Tag } from '../../types';

interface TagPopupSelectorInputFieldProps {
  selectedTags: Tag[];
  onClick: () => void;
  className?: string;
}

const TagPopupSelectorInputField = (props: TagPopupSelectorInputFieldProps) => {
  const { selectedTags, onClick, className } = props;
  const noTagSelected = selectedTags.length === 0;

  return (
    <Select
      labelId="tag-popup-selector"
      id="demo-simple-select-readonly"
      size="small"
      value={['']}
      onClick={onClick}
      className={`w-[215px] pr-[25px] ${className}`}
      inputProps={{
        readOnly: true,
      }}
      renderValue={() => (
        <span data-cy="select-tags" className="flex items-center">
          <SellOutlined />
          <span className="ml-[5px] text-gray-600">
            {noTagSelected ? 'Select tags' : selectedTags.map(tag => tag.name).join(', ')}
          </span>
        </span>
      )}
    >
      <MenuItem value={''}>Select Tags</MenuItem>
      {selectedTags.map(tag => (
        <MenuItem key={tag.id} value={`${tag.id}`}>
          {tag.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default TagPopupSelectorInputField;
