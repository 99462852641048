export const DASHBOARD_PATHS = {
  root: '/dashboard',
  home: '/dashboard/home',
  transactions: '/dashboard/transactions',
  cards: '/dashboard/cards',
  accounting: '/dashboard/accounting',
  team: '/dashboard/team',
  settingsBankConnection: '/dashboard/settings?tab=Bank%20Connections',
  settingsDocumentUpload: '/dashboard/settings?tab=Document%20Upload',
  payVendor: '/dashboard/vendors/pay',
  payVendorAmount: '/dashboard/vendors/pay/amount',
  payVendorMoreDetails: '/dashboard/vendors/pay/more-details',
  payVendorReview: '/dashboard/vendors/pay/review',
  addVendor: '/dashboard/vendors/add',
  vendors: '/dashboard/vendors',
};
