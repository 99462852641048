import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  InputAdornment,
  Popover,
  TextField,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TagCheckbox from '../TagCheckbox';
import CreateTagButton from '../CreateTagButton';
import { Tag } from '../../types';
import { useEffect, useRef, useState } from 'react';
import { useGetTags } from '../../data/getTags';
import { getGroupedTags } from '../../tags.helpers';
import Show from '../../../../app/components/Show';
import TagPopupSelectorInputField from './TagPopupSelectorInputField';

interface TagPopupSelectorProps {
  selectedTags: Tag[];
  onChange: (selectedTags: Tag[], tag: Tag, isChecked: boolean) => void;
  enableCheckboxLoaders?: boolean;
  popupAnchorElement?: HTMLElement;
  title?: string;
  showClearAll?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  className?: string;
}

const TagPopupSelector = (props: TagPopupSelectorProps) => {
  const anchorElRef = useRef<HTMLElement>(null);

  const {
    selectedTags,
    onChange,
    enableCheckboxLoaders = false,
    popupAnchorElement,
    title,
    showClearAll = false,
    isOpen = false,
    onClose,
    className,
  } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(isOpen);

  const { data: tagsData } = useGetTags();
  const [groupedTags, setGroupedTags] = useState<Record<string, Tag[]>>({});

  const handleTagCheckboxChange = (checked: boolean, tag: Tag) => {
    if (checked) {
      const updatedTags = [...selectedTags, tag];
      onChange(updatedTags, tag, checked);
    } else {
      const updatedTags = selectedTags.filter(selectedTag => selectedTag.id !== tag.id);
      onChange(updatedTags, tag, checked);
    }
  };

  const clearSelectedTags = () => {
    onChange([], selectedTags[0], false);
    closePopup();
  };

  const togglePopupOpen = () => {
    setIsPopupOpen(prevState => !prevState);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    onClose && onClose();
  };

  useEffect(() => {
    setIsPopupOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const tags = tagsData?.data ?? [];
    const newTags = tags.filter(tag => tag.name.toLowerCase().includes(searchTerm.toLowerCase()));
    const NewGroupedTags = getGroupedTags(newTags);
    setGroupedTags(NewGroupedTags);
  }, [searchTerm, tagsData]);

  return (
    <>
      <Show when={!popupAnchorElement}>
        <div className="relative">
          <span ref={anchorElRef} className="absolute"></span>
          <TagPopupSelectorInputField
            className={className}
            selectedTags={selectedTags}
            onClick={togglePopupOpen}
          />
        </div>
      </Show>

      <Popover
        open={isPopupOpen}
        onClose={closePopup}
        anchorEl={popupAnchorElement ?? anchorElRef.current}
        onClick={e => e.stopPropagation()}
      >
        <section className="rounded min-w-[300px] max-h-[70vh] overflow-scroll">
          <div className="px-1 py-1 ">
            <p className="flex items-center justify-between">
              <span className="font-bold">{title ?? 'Select Tags'}</span>
              <Show when={showClearAll}>
                <span
                  className="cursor-pointer text-[12px] text-dark-green"
                  onClick={clearSelectedTags}
                >
                  Clear all
                </span>
              </Show>
            </p>
            <TextField
              focused
              inputProps={{
                'data-cy': 'trans-search-input',
              }}
              className="mt-1 p-0"
              size="small"
              variant="outlined"
              type="search"
              value={searchTerm}
              placeholder="Search tags"
              onChange={event => setSearchTerm(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(groupedTags).map(groupName => (
              <div key={groupName}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <p className="uppercase text-[10px] font-bold">{groupName}</p>
                  </AccordionSummary>
                  <AccordionDetails style={{ margin: 0, padding: 0, marginBottom: 10 }}>
                    {groupedTags[groupName].map((tag, index) => (
                      <TagCheckbox
                        key={tag.id}
                        index={index}
                        tag={tag}
                        onChange={handleTagCheckboxChange}
                        enableLoaders={enableCheckboxLoaders}
                        isChecked={!!selectedTags.find(selectedTag => selectedTag.id === tag.id)}
                      />
                    ))}
                  </AccordionDetails>
                </Accordion>
                <Divider />
              </div>
            ))}
          </div>
          <div className="px-1 flex items-center justify-end shadow mt-1 pb-1">
            <CreateTagButton
              data-cy="trans-create-tag"
              variant="outlined"
              className="text-dark-green"
            />
          </div>
        </section>
      </Popover>
    </>
  );
};

export default TagPopupSelector;
