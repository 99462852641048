import { memo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Button, useTheme } from '@mui/material';
import Spinner from 'app/components/Spinner';
import { Helmet } from 'react-helmet';
import { Text } from 'contexts/language';
import { Swiper, SwiperSlide } from 'swiper/react';
import FinancialCard from 'app/components/FinancialCard';
import { MyUser } from 'interfaces/baseInterfaces';
import { formatCentsAsDollars } from 'helpers/formattings';
import posthog from 'posthog-js';
import CreditTransactionTable from './components/CreditTransactionTable';
import { FinancialCardProps, TabContent } from 'interfaces/components';
import FullWidthTabs from 'app/components/Tabs';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './style.scss';
import StyledCustomBanner from 'app/components/CustomBanner';
import {
  useGetAmountOverdue,
  useGetLatestTransactions,
  useGetRewards,
  useGetCreditAccountSummary,
  useGetCreditAccountLimit,
} from './data/getCreditServices';
import { useNavigate } from 'react-router';
import {
  CashStackIcon,
  CloseIcon,
  GiftIcon,
  MoneyCheckDollarIcon,
  PieChartIcon,
  WalletIcon,
} from 'app/components/Icon/fontawesome';

export interface CreditPageProps {
  currentUser: MyUser;
}

const CreditPage = memo(function CreditPage(props: CreditPageProps) {
  const { currentUser } = props;

  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentTab, setCurrentTab] = useState(0);

  const navigate = useNavigate();

  const {
    data: latestTransactionsData,
    isLoading: isLatestTransactionsLoading,
    isError: isLatestTransactionsError,
    refetch: refetchTransactions,
  } = useGetLatestTransactions();

  const {
    data: summaryData,
    isLoading: isSummaryLoading,
    isError: isSummaryError,
    refetch: refetchSummary,
  } = useGetCreditAccountSummary();

  const {
    data: limitData,
    isLoading: isLimitLoading,
    isError: isLimitError,
    refetch: refetchLimit,
  } = useGetCreditAccountLimit();

  const { data: amountOverdueData } = useGetAmountOverdue();

  const { data: rewardsData } = useGetRewards();

  const availableSpend = summaryData?.company_available_spend ?? 0;

  const creditLimit = limitData?.credit_limit ?? 0;

  const companyBalance = summaryData?.company_balance ?? 0;

  const paymentDueDate = summaryData?.due_date ?? '';

  const holdAmount = summaryData?.current_pending_authorizations ?? 0;

  const amountDue = summaryData?.amount_due ?? 0;

  const pendingPayments = summaryData?.pending_payments ?? 0;

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const mobileCards: FinancialCardProps[] = [
    {
      title: 'Spending Power',
      amount: formatCentsAsDollars(availableSpend),
      backgroundColor: 'black',
      titleColor: '#F7F7F7',
      iconBackgroundColor: '#E7EDEA1A',
      icon: <CashStackIcon style={{ color: '#35CF9B', height: 18, width: 18 }} />,
      dataCy: 'availableSpendCard',
      tooltipText:
        'This is how much your company is able to spend on all of its Toolbox charge cards until you either make a payment or receive a limit increase.',
    },
    {
      title: 'Current Balance',
      amount: formatCentsAsDollars(companyBalance),
      backgroundColor: 'black',
      titleColor: '#F7F7F7',
      iconBackgroundColor: '#E7EDEA1A',
      icon: <WalletIcon style={{ color: '#35CF9B', height: 18, width: 18 }} />,
      additionalSectionTitle: 'HOLD AMOUNT',
      additionalSectionAmount: formatCentsAsDollars(holdAmount),
      dataCy: 'currentBalanceCard',
      tooltipText:
        'This is the sum of the outstanding transactions across all of your charge cards. The hold amount means there are pending authorizations on your account that have not yet cleared.',
    },
    {
      title: 'Credit Limit',
      amount: formatCentsAsDollars(creditLimit),
      backgroundColor: 'black',
      titleColor: '#F7F7F7',
      iconBackgroundColor: '#E7EDEA1A',
      icon: <PieChartIcon style={{ color: '#3A6959', height: 16, width: 16 }} />,
      dataCy: 'companyLimitCard',
      tooltipText: 'This is the total credit limit of your company across all charge cards.',
    },
    {
      title: 'Accrued Rewards',
      amount: `${rewardsData?.total_rewards} points`,
      backgroundColor: 'black',
      titleColor: '#F7F7F7',
      iconBackgroundColor: '#E7EDEA1A',
      icon: <GiftIcon style={{ color: '#3A6959', height: 16, width: 16 }} />,
      dataCy: 'accruedRewardsCard',
      tooltipText:
        "These are the total rewards you've earned, excluding the rewards you've already redeemed.",
    },
    {
      title: 'Payment Due',
      amount: formatCentsAsDollars(amountDue),
      subtitle: paymentDueDate,
      backgroundColor: 'black',
      titleColor: '#FFF',
      iconBackgroundColor: '#E7EDEA1A',
      icon: <MoneyCheckDollarIcon style={{ color: '#3A6959', height: 16, width: 16 }} />,
      showPaymentButton: true,
      pendingPaymentsText: !currentUser.is_repayment_method_thread ? (
        <>
          You have <b>{formatCentsAsDollars(pendingPayments)}</b> in pending payments
        </>
      ) : (
        ''
      ),
      subtitleColor: '#F7F7F7',
      dataCy: 'paymentDueCard',
      tooltipText:
        'This is how much will be due on your next payment date. Disclaimer: Any additional purchases you make until the end of your statement period will be added to this Payment Due.',
    },
  ];

  const handleTabChange = (_: any, newValue: any) => {
    setCurrentTab(newValue);
  };

  const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} {...other} data-cy="tabPanel">
        <Box>{children}</Box>
      </div>
    );
  };

  if (isLatestTransactionsLoading || isSummaryLoading || isLimitLoading) {
    return <Spinner />;
  }

  if (isLatestTransactionsError || isSummaryError || isLimitError) {
    return (
      <div className="flex items-center justify-center h-[150px]">
        <p>
          Could not fetch data.{' '}
          <span
            className="underline cursor-pointer"
            onClick={() => {
              if (isLatestTransactionsError) refetchTransactions();
              if (isSummaryError) refetchSummary();
              if (isLimitError) refetchLimit();
            }}
          >
            Retry
          </span>
        </p>
      </div>
    );
  }

  return (
    <div>
      {matchesMobile && (
        <div>
          <div
            className="bg-black mb-half"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'left',
              flexDirection: 'column',
            }}
          >
            <p className="mt-1 ml-1 font-3xl font-bold font-noto-sans text-white">
              <Text tid="Credit" />
            </p>

            <Swiper
              pagination={{
                dynamicBullets: true,
              }}
              style={{
                alignItems: 'stretch',
              }}
              modules={[Pagination]}
            >
              {mobileCards.map((card, index) => (
                <SwiperSlide
                  style={{
                    textAlign: 'left',
                    height: 'auto',
                  }}
                >
                  <FinancialCard
                    key={index}
                    title={card.title}
                    amount={card.amount}
                    backgroundColor={card.backgroundColor}
                    titleColor={card.titleColor}
                    iconBackgroundColor={card.iconBackgroundColor}
                    icon={card.icon}
                    additionalSectionTitle={card.additionalSectionTitle}
                    additionalSectionAmount={card.additionalSectionAmount}
                    subtitle={card.subtitle}
                    showPaymentButton={card.showPaymentButton}
                    pendingPaymentsText={card.pendingPaymentsText}
                    subtitleColor={card.subtitleColor}
                    data-cy={card.dataCy}
                    tooltipText={card.tooltipText}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}

      <Helmet>
        <title>Toolbox - Credit</title>
      </Helmet>

      <Box
        flexDirection={matchesMobile ? 'column' : 'row'}
        padding="32px"
        alignItems="center"
        justifyContent="space-between"
        sx={{ display: matchesMobile ? 'none' : 'flex' }}
      >
        <p className="font-3xl font-bold font-noto-sans">
          <Text tid="Credit" data-cy="creditPageTitle" />
        </p>
      </Box>

      {currentUser?.is_delinquent && (
        <StyledCustomBanner
          backgroundColor="#FFFBFB"
          textColor="#7F0202"
          borderColor=" 0.5px solid rgba(255, 77, 77, 0.20)"
          patternColor="#FF5733"
          matchesMobile={matchesMobile}
          text={`Please resolve your past due balance of ${formatCentsAsDollars(
            amountOverdueData?.data ?? 0,
          )} to avoid losing access to your credit limit of ${formatCentsAsDollars(creditLimit)}.`}
        />
      )}

      <Box
        sx={{
          display: matchesMobile ? 'none' : 'grid',
          gridTemplateColumns: { md: '1fr 1fr 1fr', xs: '1fr' },
          gridTemplateRows: { md: '1fr 1fr', xs: 'auto' },
          columnGap: 4,
          rowGap: 2,
          overflowX: 'visible',
          mt: 1,
          padding: '0 32px',
          width: '100%',
        }}
      >
        <FinancialCard
          title="Spending Power"
          amount={formatCentsAsDollars(availableSpend)}
          backgroundColor="black"
          titleColor="#F7F7F7"
          iconBackgroundColor="#E7EDEA1A"
          icon={<CashStackIcon style={{ color: '#35CF9B', height: 18, width: 18 }} />}
          data-cy="availableSpendCard"
          tooltipText="This is how much your company is able to spend on all of its Toolbox charge cards until you either make a payment or receive a limit increase."
        />

        <FinancialCard
          title="Credit Limit"
          amount={formatCentsAsDollars(creditLimit)}
          icon={<PieChartIcon style={{ color: '#3A6959', height: 16, width: 16 }} />}
          data-cy="companyLimitCard"
          tooltipText="This is the total credit limit of your company across all charge cards."
        />

        <FinancialCard
          title="Current Balance"
          amount={formatCentsAsDollars(companyBalance)}
          backgroundColor="#063021"
          titleColor="#F7F7F7"
          iconBackgroundColor="#E7EDEA1A"
          icon={<WalletIcon style={{ color: '#35CF9B', height: 16, width: 16 }} />}
          additionalSectionTitle="HOLD AMOUNT"
          additionalSectionAmount={formatCentsAsDollars(holdAmount)}
          data-cy="currentBalanceCard"
          tooltipText="This is the sum of the outstanding transactions across all of your charge cards. The hold amount means there are pending authorizations on your account that have not yet cleared."
        />

        <FinancialCard
          title="Accrued Rewards"
          amount={
            <>
              {rewardsData?.total_rewards}{' '}
              <span style={{ fontSize: '0.7em', fontWeight: 'normal', color: '#1D1D1D' }}>
                points
              </span>
            </>
          }
          icon={<GiftIcon style={{ color: '#3A6959', height: 16, width: 16 }} />}
          data-cy="accruedRewardsCard"
          tooltipText="These are the total rewards you've earned, excluding the rewards you've already redeemed."
        />

        <Box
          sx={{
            gridColumn: { md: '3 / 4', xs: '1 / 2' },
            gridRow: { md: '1 / 3', xs: '5 / 6' },
          }}
        >
          <FinancialCard
            title="Payment Due"
            amount={formatCentsAsDollars(amountDue)}
            subtitle={paymentDueDate}
            icon={<MoneyCheckDollarIcon style={{ color: '#3A6959', height: 16, width: 16 }} />}
            showPaymentButton={true}
            pendingPaymentsText={
              !currentUser.is_repayment_method_thread ? (
                <>
                  You have <b>{formatCentsAsDollars(pendingPayments)}</b> in pending payments
                </>
              ) : (
                ''
              )
            }
            data-cy="paymentDueCard"
            tooltipText="This is how much will be due on your next payment date. Disclaimer: Any additional purchases you make until the end of your statement period will be added to this Payment Due."
          />
        </Box>
      </Box>

      {matchesMobile ? (
        <div>
          <FullWidthTabs
            tabContents={
              [
                {
                  label: 'Recent Transactions',
                  content: (
                    <div data-id="cards-box" className="mb-2 my-1 min-h-70vh cards-box">
                      <Box style={{ width: '100%' }}>
                        <TabPanel
                          value={currentTab}
                          index={['Recent Transactions'].indexOf('Recent Transactions')}
                        >
                          <CreditTransactionTable
                            transactions={latestTransactionsData?.data ?? []}
                            loadTransactions={() => false}
                            data-cy="transactionsTable"
                          />
                        </TabPanel>
                      </Box>
                    </div>
                  ),

                  count: latestTransactionsData?.data?.length,
                },
              ] as TabContent[]
            }
            parentState={{ currentTab }}
            data-cy="cardTabs"
            onTabChange={handleTabChange}
          />
        </div>
      ) : (
        <>
          <div
            className="flex-row mt-2"
            style={{
              padding: '32px 32px 0 32px',
            }}
          >
            <div className="flex-grow-1">
              <h2 style={{ fontSize: 20, fontWeight: 'semi-bold' }}>Recent Activity</h2>
            </div>
            <div className="flex-grow-1 text-right">
              <Button
                color="secondary"
                variant="outlined"
                data-cy="viewAllTransactionsButton"
                onClick={() => handleNavigate('/dashboard/transactions')}
              >
                View All Transactions
              </Button>
            </div>
          </div>

          <CreditTransactionTable
            transactions={latestTransactionsData?.data ?? []}
            loadTransactions={refetchTransactions}
            data-cy="transactionsTable"
          />
        </>
      )}
    </div>
  );
});

export default CreditPage;
