import React from 'react';
import { Typography, InputAdornment } from '@mui/material';
import DollarSignIcon from '@mui/icons-material/AttachMoney';
import Input from '../Input';
import Show from '../Show';

interface CardDetailsProps {
  initialMonthlyLimit: number;
  setInitialMonthlyLimit: React.Dispatch<React.SetStateAction<number>>;
  formatCentsAsDollars: (cents: number) => string;
  cardholderLimit: number;
  debitCardDailyLimit?: string;
  selectedAccountType?: string;
}

const CardDetails: React.FC<CardDetailsProps> = ({
  initialMonthlyLimit,
  setInitialMonthlyLimit,
  formatCentsAsDollars,
  cardholderLimit,
  debitCardDailyLimit,
  selectedAccountType,
}) => {
  const handleInputChange = (e: string) => {
    const convertedString = parseFloat(e);
    if (convertedString && convertedString < 0) return;

    setInitialMonthlyLimit(convertedString ? convertedString : 0);
  };
  return (
    <div>
      <Typography
        sx={{
          marginBottom: 1,
          color: '#3A6959',
          fontSize: '14px',
          fontStyle: 'normal',
          lineHeight: '140%',
          fontVariant: 'all-small-caps',
        }}
      >
        Card Details
      </Typography>

      <Typography
        sx={{
          marginBottom: 2,
          color: '#9D9D9D',
          fontSize: '14px',
          fontStyle: 'normal',
          lineHeight: '140%',
          fontVariant: 'all-small-caps',
        }}
      >
        Initial monthly spending limit {formatCentsAsDollars(cardholderLimit)}
      </Typography>

      <div className="mb-1 flex flex-col">
        <Input
          dataCy="monthlySpend"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DollarSignIcon style={{ color: '#3A6959', verticalAlign: 'middle' }} />
              </InputAdornment>
            ),
            style: {
              fontSize: '44px',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
            },
          }}
          type="number"
          placeholder={initialMonthlyLimit.toFixed(2)}
          className="w-full text-left"
          variant="standard"
          onChange={handleInputChange}
          value={initialMonthlyLimit}
        />
        <Show when={selectedAccountType === 'debit'}>
          <p className="mt-[5px] text-[#D98600] text-sm font-normal leading-5 tracking-normal">
            Debit cards have a {debitCardDailyLimit} daily spending limit.
          </p>
        </Show>
      </div>
    </div>
  );
};

export default CardDetails;
