import { styled, Theme, CSSObject, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { MyUser } from '../../../interfaces/baseInterfaces';
import { NavigationTab } from './utils/navigationTabs';
import './style.scss';
import AppDrawerContent from '../AppNavigation/AppDrawerContent';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export interface NavigationMenuProps {
  tabs: NavigationTab[];
  isMobileView?: boolean;
  user?: MyUser | null;

  signOut?(): void;

  goToSettings?(): void;

  onTabSelection?(): void;

  isDrawerOpen: boolean;

  setIsDrawerOpen(isOpen: boolean): void;
}

export default function DesktopNavigation(props: NavigationMenuProps) {
  const { user, isDrawerOpen, setIsDrawerOpen, tabs } = props;

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      className="desktop-navigation"
    >
      <Drawer variant="permanent" open={isDrawerOpen} style={{ border: 'none' }}>
        <DrawerHeader>
          <IconButton onClick={isDrawerOpen ? handleDrawerClose : handleDrawerOpen}>
            {isDrawerOpen ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
          </IconButton>
        </DrawerHeader>
        <AppDrawerContent isDrawerOpen={isDrawerOpen} tabs={tabs} user={user} />
      </Drawer>
    </Box>
  );
}
