import { Menu, MenuItem } from '@mui/material';
import React, { useRef } from 'react';
import { styled } from '@mui/system';
import { MenuProps } from '@mui/material/Menu';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MakePaymentPopup } from '../../../../app/components/MakePaymentPopUp';
import PayWithLineOfCreditModal from '../PayWithLineOfCreditModal';
import posthog from 'posthog-js';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
    '& .MuiMenuItem-root': {
      '&:hover': {
        backgroundColor: '#58595B',
      },
    },
    '& .MuiList-root': {
      backgroundColor: '#31463E',
      color: '#fff',
    },
  },
}));

interface CreditPaymentButtonProps {
  buttonText?: string;
  className?: string;
}

const CreditPaymentButton = (props: CreditPaymentButtonProps) => {
  const { buttonText, className } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const isOpen = Boolean(anchorEl);

  const [isPayWithBankModalOpen, setPayWithBankModalOpen] = React.useState(false);
  const [isPayWithLineOfCreditModalOpen, setPayWithLineOfCreditModalOpen] = React.useState(false);

  const showMenuDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenuDropdown = () => {
    setAnchorEl(null);
  };

  const showPayWithBankModal = () => {
    closeMenuDropdown();
    setPayWithBankModalOpen(true);
    posthog.capture('Make payment popup open from credit button click');
  };

  const showPayWithLineOfCreditModal = () => {
    closeMenuDropdown();
    setPayWithLineOfCreditModalOpen(true);
    posthog.capture('Line of Credit Split Button Click');
  };

  return (
    <div className={className}>
      <Button
        size="small"
        color="primary"
        ref={buttonRef}
        fullWidth
        onClick={showMenuDropdown}
        aria-controls={isOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        className="mb-half md:mb-0"
        data-cy="payNowButton"
        endIcon={<KeyboardArrowDownIcon />}
      >
        <span style={{ width: '80%' }}>{buttonText ?? 'Pay Now'}</span>
      </Button>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenuDropdown}
        slotProps={{
          paper: { style: { width: buttonRef.current?.offsetWidth || 'auto' } },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem data-cy="payWithBankAcc" onClick={showPayWithBankModal}>
          Pay with Bank Account
        </MenuItem>
        <MenuItem data-cy="payWithLineofCred" onClick={showPayWithLineOfCreditModal}>
          Pay with Line of Credit
        </MenuItem>
      </StyledMenu>
      <MakePaymentPopup
        onMadePayment={() => null}
        onClose={() => setPayWithBankModalOpen(false)}
        isOpen={isPayWithBankModalOpen}
      />
      <PayWithLineOfCreditModal
        isOpen={isPayWithLineOfCreditModalOpen}
        onClose={() => setPayWithLineOfCreditModalOpen(false)}
      />
    </div>
  );
};

export default CreditPaymentButton;
