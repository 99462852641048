import httpClient from '../../../services/httpClient';
import { PaginatedData, Pagination } from '../../../common/pagination';
import { EntityTag, Tag, TaggedEntityType, TagGroup } from '../types';
import { logger } from '../../../common/logging';

interface NewTag {
  name: string;
  tag_group_id: number;
}

export interface GetTagsFilters {
  tag_group_id?: number;
}

class TagsService {
  getTagGroups = (pagination: Pagination) =>
    httpClient
      .get<PaginatedData<TagGroup>>('/tag_groups', { params: pagination })
      .then(res => res.data);

  createTag = (tag: NewTag) => httpClient.post<Tag>('/tags', tag).then(res => res.data);

  updateTag = (updatedTag: Pick<Tag, 'id' | 'name' | 'tag_group_id'>) => {
    const { id, ...updates } = updatedTag;
    return httpClient.patch<{ tag: Tag }>(`/tags/${id}`, updates).then(res => res.data);
  };

  deleteTag = (id: number) => httpClient.delete<{ success: boolean }>(`/tags/${id}`);

  getTags = (pagination: Pagination, filters: GetTagsFilters) =>
    httpClient
      .get<PaginatedData<Tag>>('/tags', { params: { ...pagination, ...filters } })
      .then(res => res.data);

  addTagToEntity = (params: { tagId: number; entityId: number; entityType: TaggedEntityType }) => {
    const { tagId: tag_id, entityId: entity_id, entityType: entity_type } = params;
    return httpClient
      .post('/tags/tagged_entities', { tag_id, entity_id, entity_type })
      .then(res => res.data);
  };

  removeTagFromEntity = (taggedEntityId: number) =>
    httpClient.delete(`/tags/tagged_entities/${taggedEntityId}`).then(res => res.data);

  getEntityTags = (entity_id: number, entityType: TaggedEntityType) =>
    httpClient
      .get<{
        entity_tags: EntityTag[];
      }>(`/tags/tagged_entities/${entityType}/${entity_id}`)
      .then(res => res.data);
}

const tagsService = new TagsService();
export default tagsService;
