import CustomDialog from '../../../../app/components/CustomDialog';
import { TextField } from '@mui/material';
import Show from '../../../../app/components/Show';
import LoadingButton from '../../../../app/components/LoadingButton';
import SnackbarNotification from '../../../../app/components/SnackbarNotification';
import { useCreateRutterAccount } from '../../rutter/data/creatRutterAccount';
import { useEffect, useState } from 'react';
import { RutterAccount, RutterAccountType, RutterPlatform } from '../../rutter/rutter.types';
import RutterAccountTypeSelector from '../RutterAccountTypeSelector';

interface CreateRutterAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
  platform: RutterPlatform;
  title?: string;
  allowedAccountTypes?: RutterAccountType[];
  onSuccess?: (account: RutterAccount) => void;
}

const CreateRutterAccountModal = (props: CreateRutterAccountModalProps) => {
  const { isOpen, onClose, platform, title, allowedAccountTypes, onSuccess } = props;

  const [accountName, setAccountName] = useState('');
  const [nominalCode, setNominalCode] = useState('');
  const [selectedAccountType, setSelectedAccountType] = useState<RutterAccountType>();

  const {
    mutate: postAccount,
    isSuccess,
    isPending,
    error,
    isError,
    reset,
    data,
  } = useCreateRutterAccount(platform);

  const createAccount = () => {
    postAccount({
      platform,
      nominalCode: nominalCode,
      accountType: selectedAccountType!,
      name: accountName,
    });
  };

  const closeModal = () => {
    setAccountName('');
    setNominalCode('');
    setSelectedAccountType(undefined);
    onClose();
    reset();
  };

  useEffect(() => {
    if (isSuccess) {
      onSuccess ? onSuccess(data) : null;
    }
  }, [isSuccess]);

  return (
    <>
      <CustomDialog
        title={title ?? 'Create an Account'}
        open={isOpen && !isSuccess}
        onClose={closeModal}
      >
        <TextField
          label="Account Name"
          variant="outlined"
          className="w-full mt-1"
          required
          value={accountName}
          onChange={event => setAccountName(event.target.value)}
        />
        <div className="block md:flex items-center justify-between gap-x-4 mt-2">
          <RutterAccountTypeSelector
            required
            className="grow md:w-1/2"
            allowedAccountTypes={allowedAccountTypes}
            value={selectedAccountType ?? ''}
            onChange={accountType => setSelectedAccountType(accountType)}
          />
          <TextField
            label="Nominal code"
            required
            variant="outlined"
            className="grow md:w-1/2"
            type="number"
            value={nominalCode}
            onChange={event => setNominalCode(event.target.value)}
          />
        </div>

        <Show when={isError}>
          <p className="text-sm font-semibold text-[red] mt-[5px]">{error as unknown as string}</p>
        </Show>
        <div className="flex items-center justify-end mt-1">
          <LoadingButton
            loading={isPending}
            disabled={!accountName || !selectedAccountType || !nominalCode}
            onClick={createAccount}
          >
            Create Account
          </LoadingButton>
        </div>
      </CustomDialog>

      <Show when={isError}>
        <SnackbarNotification type={'error'} message={error as unknown as string} />
      </Show>
      <Show when={isSuccess}>
        <SnackbarNotification
          open={isSuccess}
          type="success"
          message={`${accountName} has been created`}
        />
      </Show>
    </>
  );
};

export default CreateRutterAccountModal;
