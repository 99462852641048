import { Helmet } from 'react-helmet';
import CustomNavBar from '../../../../components/CustomNavBar';
import { Outlet } from 'react-router-dom';
import BankingAccountingTiles from '../../../../../features/accounting/banking/components/BankingAccountingTiles';

interface BankingAccountingPageProps {}

const BankingAccountingPage = (props: BankingAccountingPageProps) => {
  const tabs = [{ title: 'Statements', path: '/dashboard/accounting/banking/statements' }];

  return (
    <>
      <Helmet>
        <title>Toolbox - Banking Accounting</title>
      </Helmet>
      <CustomNavBar tabs={tabs} />
      <section className="xl:flex xl:flex-row-reverse xl:gap-x-4">
        <div className="xl:basis-1/4">
          <BankingAccountingTiles />
        </div>
        <div className="mt-[10px] xl:mt-[5px] grow">
          <Outlet />
        </div>
      </section>
    </>
  );
};

export default BankingAccountingPage;
