import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ADMIN_JOB_TITLES, EMPLOYEE_JOB_TITLES } from '../../../constants/system';
import {
  lengthCheck,
  noSpecialCharacters,
  standardError,
  titleCase,
  validateEmail,
  validateJobTitle,
} from '../../../helpers/base';
import { useState } from 'react';
import posthog from 'posthog-js';
import companyService from '../../../services/companyService';
import { MyUser } from 'interfaces/baseInterfaces';
import { BookIcon, GearWideConnectedFilledIcon, UsersGearIcon } from '../Icon/fontawesome';
import { CompanyRole } from '../../../interfaces/company';
import Input from '../Input';

interface TeamMemberInviteProps {
  currentUser: MyUser;
  onSave: () => void;
}

function getRoleOptions(role: CompanyRole) {
  switch (role) {
    case 'account_owner':
      return ['super_admin', 'admin', 'employee', 'bookkeeper'];
    case 'super_admin':
      return ['admin', 'employee', 'bookkeeper'];
    case 'admin':
      return ['employee', 'bookkeeper'];
    default:
      return [];
  }
}
export function TeamMemberInvite(props: TeamMemberInviteProps) {
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteRole, setInviteRole] = useState('');
  const [inviteTitle, setInviteTitle] = useState('');
  const [roleOptions] = useState(getRoleOptions(props.currentUser.team_role));
  const [titleOptions, setTitleOptions] = useState(ADMIN_JOB_TITLES);
  const [addMemberError, setAddMemberError] = useState('');
  const [isSendingInvite, setIsSendingInvite] = useState(false);
  const { currentUser } = props;

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleAddTeamMember = async (email: string, role: string, title: string) => {
    setIsSendingInvite(true);
    setAddMemberError('');
    posthog.capture('Add team member click - cards page');

    try {
      const res = await companyService.inviteTeamMember({ email, role, title });
      if (res?.error) {
        standardError(res.error);
        setAddMemberError('There was an issue sending this invite');
        return;
      }
      if (res) {
        setInviteEmail('');
        setInviteRole('');
        setInviteTitle('');
        props.onSave();
      }
    } catch (e) {
      standardError(e);
    } finally {
      setIsSendingInvite(false);
    }
  };

  const handleTitleError = (inviteTitle: string) => {
    if (!inviteTitle.length) return '';
    if (!lengthCheck(inviteTitle)) return 'Not enough characters';
    if (!noSpecialCharacters(inviteTitle)) return 'Special characters are not allowed';
    return '';
  };

  if (!!['employee', 'bookkeeper'].find(s => s == currentUser.team_role)) {
    return <></>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        gap: 2,
        width: '100%',
        px: isMobile ? 3 : 0,
      }}
    >
      <Box
        sx={{
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: { md: '70%' },
          width: { xs: '100%', md: 'auto' },
        }}
      >
        <h2 className="font-2xl font-bold mb-1">Capability</h2>
        <p className="text-gray">
          Your team will have the following capabilities if you choose to invite them:
        </p>
        <Accordion square={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="paneladmin-content"
            id="paneladmin-header"
          >
            <div className="items-center flex">
              <span
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                  background: 'var(--feedback-success-light, #E9FBF0)',
                  color: '#007149',
                  padding: '4px',
                }}
              >
                <GearWideConnectedFilledIcon fontSize="small" />
              </span>{' '}
              <span className="ml-half">Super Administrator Capability</span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p>Manage other users (except account owners)</p>
              <p>View accounting page</p>
              <p>View the dashboard page</p>
              <p>Make payments</p>
              <p>Manage tags</p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion square={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="paneladmin-content"
            id="paneladmin-header"
          >
            <div className="items-center flex">
              <span
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                  background: 'var(--feedback-success-light, #E9FBF0)',
                  color: '#007149',
                  padding: '4px',
                }}
              >
                <GearWideConnectedFilledIcon fontSize="small" />
              </span>{' '}
              <span className="ml-half">Administrator Capability</span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p>Manage other users</p>
              <p>View accounting page</p>
              <p>View the dashboard page</p>
              <p>Make payments</p>
              <p>Manage tags</p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion square={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <div className="items-center flex">
              <span
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                  background: 'var(--feedback-success-light, #E9FBF0)',
                  color: '#007149',
                  padding: '4px',
                }}
              >
                <BookIcon fontSize="small" />{' '}
              </span>
              <span className="ml-half">Bookkeeper Capability</span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p>View transactions</p>
              <p>View accounting Page</p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion square={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <div className="flex items-center">
              <span
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                  background: 'var(--feedback-success-light, #E9FBF0)',
                  color: '#007149',
                  padding: '4px',
                }}
              >
                <UsersGearIcon fontSize="small" />
              </span>
              <span className="ml-half">Employee Capability</span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p>Add a receipt to transactions</p>
              <p>View transactions</p>
              <p>Tag transactions</p>
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box
        sx={{
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: { md: '30%' },
          width: { xs: '100%', md: 'auto' },
          maxWidth: { md: '30%' },
          mb: 8,
        }}
      >
        <Box className="py-1 px-1  text-black rounded-md mt-2" style={{ background: '#F7F7F7' }}>
          <p className="font-extrabold  mb-1">Invite Team Member</p>
          <FormControl size="small" className="w-full">
            <Input
              dataCy="inviteEmail"
              className="text-black"
              value={inviteEmail}
              onChange={e => setInviteEmail(e)}
              placeholder="Email"
            />
          </FormControl>

          <FormControl size="small" className="w-full mt-1">
            <Input
              dataCy="inviteTitle"
              className="text-black"
              value={inviteTitle}
              onChange={e => setInviteTitle(e)}
              placeholder="Title"
              disabled={inviteRole == 'bookkeeper'}
              error={addMemberError || handleTitleError(inviteTitle)}
            />
          </FormControl>

          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', marginY: 2 }}>
            <FormControl variant="outlined" size="small" fullWidth sx={{ mr: 1 }}>
              <Select
                inputProps={{
                  'data-cy': 'inviteRole',
                }}
                renderValue={value =>
                  value?.length
                    ? Array.isArray(value)
                      ? value.join(', ')
                      : titleCase(value.split('_').join(' '))
                    : 'Role'
                }
                style={{ height: '100%', width: '100%', color: '#000' }}
                value={inviteRole}
                onChange={(e: SelectChangeEvent) => {
                  const targetValue: string = e.target.value;
                  setTitleOptions(
                    targetValue == 'employee' ? EMPLOYEE_JOB_TITLES : ADMIN_JOB_TITLES,
                  );
                  setInviteRole(e.target.value);

                  if (targetValue == 'bookkeeper') {
                    return setInviteTitle('Other');
                  }
                }}
                displayEmpty
              >
                {roleOptions.map((option: string, i: number) => (
                  <MenuItem data-cy={`inviteRole-${option}`} key={i} value={option}>
                    {titleCase(option.split('_').join(' '))}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              disabled={
                !inviteEmail ||
                !inviteRole ||
                !validateJobTitle(inviteTitle) ||
                !validateEmail(inviteEmail) ||
                isSendingInvite
              }
              data-cy="send"
              onClick={() => handleAddTeamMember(inviteEmail, inviteRole, inviteTitle)}
              sx={{ whiteSpace: 'nowrap' }}
            >
              {isSendingInvite ? 'Sending...' : 'Send'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
