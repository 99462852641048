import PopUp from '../PopUp';

export interface SensitiveCardInfoPopUpProps {
  isOpen: boolean;
  onClose(): void;
  number: string;
  cvc: string;
  exp_month: number;
  exp_year: number;
}

export function SensitiveCardInfoPopUp(props: SensitiveCardInfoPopUpProps) {
  return (
    <PopUp className="py-2 px-2" isOpen={props.isOpen} onClose={props.onClose}>
      <p data-id="card-sensitive-info-number" className="sensitive-info-input">
        Card Number: {props.number}
      </p>
      <p data-id="card-sensitive-info-csv" className="sensitive-info-input">
        CVC: {props.cvc}
      </p>
      <p data-id="card-sensitive-info-exp" className="sensitive-info-input">
        Exp: {props.exp_month}/{props.exp_year}
      </p>
    </PopUp>
  );
}
