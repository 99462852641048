import axios from 'axios';
import { useAuthStore } from 'features/auth/data/authStore';
import currentEnv from 'config/environment';
import { standardError } from 'helpers/base';
import baseService from 'services/base.service';

const httpClient = axios.create({
  baseURL: currentEnv().MAIN_API.slice(0, -1),
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

const handleErrorResponse = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    switch (error.response?.status) {
      case 401: {
        baseService.handle401(error.response as unknown as Response);
        return 'Unauthorized';
      }
      case 400: {
        const errorMsg = error.response?.data?.error;
        return errorMsg ? JSON.stringify(errorMsg) : 'Invalid request';
      }
      case 409: {
        const errorMsg = error.response?.data?.error;
        return errorMsg ? errorMsg : 'Conflict';
      }
      case 500: {
        return 'Something unexpected happened. Our team is checking it out.';
      }
      default: {
        return 'Request failed. Please try again.';
      }
    }
  }
  return 'An error occurred. Our team is checking it out.';
};

httpClient.interceptors.request.use(function (config) {
  const authToken = useAuthStore.getState().authToken;
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  } else {
    config.headers.Authorization = '';
  }
  return config;
});

httpClient.interceptors.response.use(
  fulfilledResponse => fulfilledResponse,
  rejectedResponse => {
    standardError(rejectedResponse);
    return Promise.reject(handleErrorResponse(rejectedResponse));
  },
);

export default httpClient;
