import { useQuery } from '@tanstack/react-query';
import rutterService from './rutter.service';
import { RutterConnectionStatusValue, RutterPlatform } from '../rutter.types';
import { logger } from '../../../../common/logging';

export const useGetRutterConnectionStatus = (platform: RutterPlatform, enabled: boolean) =>
  useQuery({
    queryKey: [`/rutter/${platform}/connection/status`],
    queryFn: () => rutterService.getConnectionStatus(platform),
    enabled,
    select: data => {
      let connectionStatus: RutterConnectionStatusValue | undefined = data?.status;
      if (data?.needs_update) {
        connectionStatus = 'needs update';
      }
      if (data?.is_disabled) {
        connectionStatus = 'disabled';
      }
      if (data?.accounts_mapped === false) {
        connectionStatus = 'accounts not mapped';
      }

      return { ...data, connectionStatus };
    },
    refetchInterval: ({ state: { data } }) => {
      if (
        data?.status == 'ready' &&
        data?.accounts_mapped &&
        !data?.needs_update &&
        !data?.is_disabled
      ) {
        return false;
      }
      return 30 * 1000;
    },
  });
