import Show from '../../../../../app/components/Show';
import { formatCentsAsDollars } from '../../../../../helpers/formattings';
import BankingAccountingTilesLoader from './BankingAccountingTilesLoader';
import { useGetDepositAccount } from '../../../../depositAccount/data/getDepositAccount';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface BankingAccountingTabsProps {}

const BankingAccountingTiles = (props: BankingAccountingTabsProps) => {
  const navigate = useNavigate();
  const { data: depositAccount, isLoading, isSuccess } = useGetDepositAccount();

  return (
    <>
      <Show when={isLoading}>
        <BankingAccountingTilesLoader />
      </Show>
      <Show when={isSuccess}>
        <section className="flex flex-wrap items-center justify-start gap-x-4 gap-y-4">
          <div className="bg-slate-100 w-full lg:w-[300px] text-center py-[12px] pb-[15px] px-[20px] xl:w-full xl:text-center xl:py-[30px] min-h-[120px] rounded-lg">
            <p className="text-capitalize">Available Balance</p>
            <div className="mt-[5px]">
              <p className="text-xl xl:text-2xl xl:font-bold">
                {formatCentsAsDollars(depositAccount?.available ?? 0)}
              </p>
              <span
                onClick={() => navigate('/dashboard/home')}
                className="underline cursor-pointer"
              >
                View account
              </span>
            </div>
          </div>
        </section>
      </Show>
    </>
  );
};

export default BankingAccountingTiles;
