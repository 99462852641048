import Button, { ButtonProps } from '@mui/material/Button';
import LoaderGif from './loader.gif';
import Show from 'app/components/Show';
import './style.scss';

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
  dataCy?: string;
}

const LoadingButton = (props: LoadingButtonProps) => {
  const { loading, disabled, dataCy, variant, ...buttonProps } = props;
  const isLoading = !!loading;

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    !disabled && !isLoading && props.onClick && props.onClick(e);
  };

  switch (variant) {
    case 'outlined':
      return (
        <span
          className={`relative ${props.className} outlined-btn ${disabled && 'opacity-50'}`}
          onClick={handleOnClick}
        >
          {props.children}
          <Show when={isLoading}>
            <div className="absolute inset-0 flex items-center justify-center cursor-progress">
              <img src={LoaderGif} className="h-1 " />
            </div>
          </Show>
        </span>
      );
    default:
      return (
        <Button
          data-cy={dataCy}
          {...buttonProps}
          disabled={isLoading || disabled}
          className={`relative ${props.className}`}
        >
          {props.children}
          <Show when={isLoading}>
            <div className="absolute inset-0 flex items-center justify-center cursor-progress">
              <img src={LoaderGif} className="h-1 " />
            </div>
          </Show>
        </Button>
      );
  }
};

export default LoadingButton;
