import { useQuery } from '@tanstack/react-query';
import accountingService from './accounting.service';
import { Statement } from '../../../interfaces/baseInterfaces';
import dayjs from 'dayjs';

export const useGetCsvStatement = (statement?: Statement) =>
  useQuery({
    queryKey: ['/statement', statement?.statement_period_start, statement?.statement_period_end],
    queryFn: () =>
      accountingService.getCsvStatement({
        range_start: dayjs(statement?.statement_period_start as string).format('YYYY-MM-DD'),
        range_end: dayjs(statement?.statement_period_end as string).format('YYYY-MM-DD'),
      }),
    enabled: !!statement,
  });
