import { useAuthStore } from './authStore';
import { CompanyRole } from '../../../interfaces/company';

export const useUserRole = () => {
  const appUser = useAuthStore(state => state.appUser);

  const isAdmin = [CompanyRole.ACCOUNT_OWNER, CompanyRole.ADMIN, CompanyRole.SUPER_ADMIN].includes(
    appUser?.team_role as CompanyRole,
  );
  const isOwner = appUser?.team_role === CompanyRole.ACCOUNT_OWNER;
  const isSuperAdmin = appUser?.team_role === CompanyRole.SUPER_ADMIN;
  const isOwnerOrSuperAdmin = isOwner || isSuperAdmin;
  const isBookkeeper = appUser?.team_role === CompanyRole.BOOKKEEPER;
  const isEmployee = appUser?.team_role === CompanyRole.EMPLOYEE;
  const isDepositCustomer = Boolean(appUser?.is_deposit_customer);
  const isCreditCustomer = Boolean(appUser?.is_credit_customer);

  return {
    isAdmin,
    isOwner,
    isOwnerOrSuperAdmin,
    isSuperAdmin,
    isBookkeeper,
    isEmployee,
    isDepositCustomer,
    isCreditCustomer,
  };
};
