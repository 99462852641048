import { useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import plaidService from '../../../services/plaid.service';
import Button from '../Button';
import { PlaidLinkError, PlaidLinkOnExitMetadata } from 'react-plaid-link/src/types';
import { standardError } from '../../../helpers/base';

export interface PlaidConnectProps {
  linkToken: string;
  relinkMode: boolean;

  onPlaidError(error: PlaidLinkError): void;

  samedayMicrodepositsVerification?: boolean;

  onAccessTokenSet(): void;

  onFetchingAccessToken(isFetching: boolean): void;

  onStartSettingPublicToken(): void;

  onStoppedSettingPublicToken(): void;

  dataCy: string;
}

export function PlaidConnect(props: PlaidConnectProps) {
  const [loading, set_loading] = useState(false);

  const { open, ready } = usePlaidLink({
    token: props.linkToken,
    onExit: async (error: null | PlaidLinkError, metadata: PlaidLinkOnExitMetadata) => {
      if (error) {
        props.onPlaidError(error);
      }
    },
    onSuccess: async (public_token, metadata) => {
      props.onFetchingAccessToken(true);
      if (!props.relinkMode) {
        set_loading(true);
        props.onStartSettingPublicToken();
        const resp = await plaidService.setPublicToken(
          public_token,
          metadata.accounts[0],
          metadata.institution,
        );
        set_loading(false);
        props.onStoppedSettingPublicToken();

        if (resp.error) {
          standardError(resp.error);
          props.onFetchingAccessToken(false);
          return;
        }
      } else {
        set_loading(true);
        props.onStartSettingPublicToken();
        const resp = await plaidService.relinkedPlaid(public_token, metadata.accounts[0].id);
        set_loading(false);
        props.onStoppedSettingPublicToken();

        if (resp.error) {
          standardError(resp.error);
          props.onFetchingAccessToken(false);
          return;
        }
      }
      props.onFetchingAccessToken(false);
      props.onAccessTokenSet();
    },
  });

  return (
    <div className="flex-row justify-center">
      <Button onClick={() => open()} dataCy={props.dataCy} disabled={!ready || loading}>
        {props.samedayMicrodepositsVerification ? 'Verify Deposit' : 'Connect Bank Account'}
      </Button>
    </div>
  );
}
