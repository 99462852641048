import { Check2CircleIcon, CloseIcon } from '../Icon/fontawesome';
import { Dialog } from '@mui/material';

interface SuccessDialogProps {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  title?: string;
  alertIcon?: React.ReactNode;
}

const SuccessDialog = (props: SuccessDialogProps) => {
  const { open: isOpen, onClose, children, title, alertIcon } = props;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <section className="w-full xl:w-[30rem] p-8 text-center relative">
        <CloseIcon
          style={{
            color: '#09442F',
            cursor: 'pointer',
            height: 22,
            width: 22,
          }}
          className="absolute top-4 right-4"
          onClick={onClose}
        />
        <p>
          {alertIcon ?? (
            <Check2CircleIcon style={{ fontSize: 30, marginBottom: 2, color: '#09442F' }} />
          )}
        </p>
        <p className="font-bold text-lg">{title}</p>
        <div className="mt-1">{children}</div>
      </section>
    </Dialog>
  );
};

export default SuccessDialog;
