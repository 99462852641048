import { EntityTag, Tag, TaggedEntityType } from '../../types';
import { useAddTagToEntity } from '../../data/addTagToEntity';
import { useRemoveTagFromEntity } from '../../data/removeTagFromEntity';
import { useEffect, useState } from 'react';
import TagPopupSelector from '../TagPopupSelector';

interface AddTagToEntityPopupProps {
  isOpen: boolean;
  anchorEl?: HTMLElement;
  onClose: () => void;
  entityType: TaggedEntityType;
  entityId: number;
  entityTags: EntityTag[];
  onUpdate: () => void;
}

const AddTagToEntityPopup = (props: AddTagToEntityPopupProps) => {
  const { isOpen, anchorEl, onClose, entityId, entityType, entityTags, onUpdate } = props;

  const { mutate: addTagToEntity, isSuccess: isAddToEntitySuccess } = useAddTagToEntity();
  const { mutate: removeTagFromEntity, isSuccess: isRemoveTagSuccess } = useRemoveTagFromEntity();

  const handleRemoveTagFromEntity = (tag: Tag) => {
    const tagToRemove = entityTags.find(entityTag => entityTag.tag.id === tag.id);
    if (tagToRemove) {
      removeTagFromEntity(tagToRemove.tagged_entity_id);
    }
  };

  const handleTagCheckboxChange = (checked: boolean, tag: Tag) => {
    if (checked) {
      addTagToEntity({ tagId: tag.id, entityId, entityType });
    } else {
      handleRemoveTagFromEntity(tag);
    }
  };

  useEffect(() => {
    if (isAddToEntitySuccess || isRemoveTagSuccess) {
      onUpdate();
    }
  }, [isAddToEntitySuccess, isRemoveTagSuccess]);

  return (
    <TagPopupSelector
      title="Add Tags"
      popupAnchorElement={anchorEl}
      enableCheckboxLoaders
      isOpen={isOpen}
      onClose={onClose}
      selectedTags={entityTags.map(entityTag => entityTag.tag)}
      onChange={(_, tag, isChecked) => handleTagCheckboxChange(isChecked, tag)}
    />
  );
};

export default AddTagToEntityPopup;
