import { memo, useRef, useState, Dispatch } from 'react';
import {
  CardholderData,
  CreditCard as ICreditCard,
  MyUser,
  ShippingInfo,
} from 'interfaces/baseInterfaces';
import img_overflow from './img_overflow.png';
import posthog from 'posthog-js';
import financialService from 'services/financial.service';
import { Menu, MenuItem, Chip, Button } from '@mui/material';
import CreditCard from '../CreditCard';
import { CardPageAction, CardPageActionType } from 'app/pages/dashboard/cards/reducer';
import { standardError } from 'helpers/base';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import dayjs from 'dayjs';

interface CardListProps {
  cards: any;
  status: string;
  isAdmin: boolean;
  onCreateClick?: (type: string) => void;
  dispatcher: Dispatch<CardPageAction>;
  currentUser: MyUser;
  isLoading: boolean;
}

interface CardWithOptionsProps {
  creditCard: ICreditCard;
  adminView: boolean;
  cardholderData: CardholderData;
  dataCyIndex: number;

  onUnfreezeCardClick(): void;

  onFreezeCardClick(): void;

  onCancelCardClick(): void;

  onViewEditLimitsClick(): void;

  setSelectedCardDetails(): void;

  onViewCardAssignmentClick(): void;

  onSensitiveCardInfoClick(): void;

  onSetDebitCardPINClick(): void;

  onSensitiveUnitCardInfoClick(): void;

  onActivateCard(): void;
}

const EmptyState = () => (
  <div
    style={{
      textAlign: 'center',
      margin: '20px',
      minHeight: '20em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    data-cy="noCardsAvailable"
  >
    <h2>No cards available.</h2>
  </div>
);

function CardWithOptions(props: CardWithOptionsProps) {
  const ref = useRef<any>();
  const [menu_open, set_menu_open] = useState(false);

  const closeMenu = () => {
    set_menu_open(false);
  };

  const shipping = (shippingInfo: ShippingInfo) => {
    if (shippingInfo.status == 'pending') {
      return (
        <p
          data-id="physical-card-shipped-label"
          data-cy="physicalCardShippedLabel"
          className="shipped-label font-noto-sans font-base"
        >
          Card is being prepared for shipping
        </p>
      );
    } else if (shippingInfo.status == 'shipped' && shippingInfo.tracking_url) {
      return (
        <div
          data-id="physical-card-shipped-info"
          data-cy="physicalCardShippedInfo"
          className="flex-row"
        >
          <span
            data-id="physical-card-shipped-info"
            className="shipped-label font-noto-sans font-base"
          >
            Your card has shipped! Track your card by{' '}
          </span>
          <a
            data-id="physical-card-shipped-info"
            className="shipped-label font-noto-sans font-base"
            href={shippingInfo.tracking_url}
          >
            clicking here
          </a>
        </div>
      );
    }

    return <></>;
  };

  const menuItems = [];
  if (props.creditCard.type == 'virtual') {
    menuItems.push(
      <MenuItem
        key="see-card-number-info-selector"
        datatype="see-card-number-info-selector"
        data-cy={`seeCardNumbInfo-${props.dataCyIndex}`}
        onClick={() => {
          props.setSelectedCardDetails();
          props.onSensitiveUnitCardInfoClick();
          set_menu_open(false);
        }}
      >
        See Card Number Info
      </MenuItem>,
    );
  }

  if (props.creditCard.status == 'active') {
    menuItems.push(
      <MenuItem
        key="view-edit-limit-selector"
        datatype="view-edit-limit-selector"
        data-cy={`editLimit-${props.dataCyIndex}`}
        onClick={() => {
          props.onViewEditLimitsClick();
          set_menu_open(false);
        }}
      >
        Edit Limit
      </MenuItem>,
    );

    if (props.creditCard.product === 'debit') {
      menuItems.push(
        <MenuItem
          key="set-debit-card-pin"
          datatype="set-debit-card-pin"
          data-cy={`setDebitCardPin-${props.dataCyIndex}`}
          onClick={() => {
            set_menu_open(false);
            props.onSetDebitCardPINClick();
          }}
        >
          Set PIN
        </MenuItem>,
      );
    }

    if (props.adminView) {
      menuItems.push(
        <MenuItem
          key="view-card-assignment-selector"
          datatype="view-card-assignment-selector"
          data-cy={`viewCardAssignment-${props.dataCyIndex}`}
          onClick={() => {
            props.onViewCardAssignmentClick();
            set_menu_open(false);
          }}
        >
          View Card Assignment
        </MenuItem>,
      );

      menuItems.push(
        <MenuItem
          key="mobile-wallet-instructions"
          data-cy={`mobileWallet-${props.dataCyIndex}`}
          onClick={() => {
            window.open(
              'https://help.trytoolbox.com/en/articles/8780558-does-toolbox-work-with-apple-pay-or-google-pay',
              '_blank',
            );
            set_menu_open(false);
          }}
        >
          Mobile Wallet
        </MenuItem>,
      );
    }

    menuItems.push(
      <MenuItem
        key="cancel-card-selector"
        datatype="cancel-card-selector"
        data-cy={`cancelCard-${props.dataCyIndex}`}
        onClick={() => {
          props.onCancelCardClick();
          set_menu_open(false);
        }}
      >
        Cancel Card
      </MenuItem>,
    );

    menuItems.push(
      <MenuItem
        key="view-freeze-card-selector"
        data-cy={`viewFreezeCard-${props.dataCyIndex}`}
        datatype="view-card-assignment-selector"
        onClick={() => {
          props.onFreezeCardClick();
          set_menu_open(false);
        }}
      >
        Freeze Card
      </MenuItem>,
    );
  }

  if (props.creditCard.status == 'frozen') {
    menuItems.push(
      <MenuItem
        key="view-unfreeze-card-selector"
        datatype="view-card-assignment-selector"
        data-cy={`viewCardAssignment-${props.dataCyIndex}`}
        onClick={() => {
          props.onUnfreezeCardClick();
          set_menu_open(false);
        }}
      >
        Unfreeze Card
      </MenuItem>,
    );
  }

  if (props.cardholderData) {
    menuItems.push(
      <MenuItem
        key="view-billing-address-selector-label"
        datatype="view-card-assignment-selector"
        data-cy={`viewBillingAddress-${props.dataCyIndex}`}
        dense={true}
        disabled={true}
      >
        Billing Address:
      </MenuItem>,
    );
    menuItems.push(
      <MenuItem
        key="view-billing-address-selector"
        datatype="view-card-assignment-selector"
        data-cy={`viewBillingAddress-${props.dataCyIndex}`}
        dense={true}
        disabled={true}
      >
        {props.cardholderData.billing_address_line1} {props.cardholderData.billing_address_line2}{' '}
        {props.cardholderData.billing_address_city} {props.cardholderData.billing_address_state}{' '}
        {props.cardholderData.billing_address_postal_code}
      </MenuItem>,
    );
  }

  menuItems.push(
    <MenuItem
      key="view-card-assignment-selector-label"
      datatype="view-card-assignment-selector"
      data-cy={`viewCardAssignment-${props.dataCyIndex}`}
      dense={true}
      disabled={true}
    >
      Created:
    </MenuItem>,
  );

  menuItems.push(
    <MenuItem
      key="view-created-at-selector"
      datatype="view-card-assignment-selector"
      data-cy={`viewCreatedAt-${props.dataCyIndex}`}
      dense={true}
      disabled={true}
    >
      {dayjs(props.creditCard.created_at).format('MMMM d, YYYY')}
    </MenuItem>,
  );

  return (
    <div
      data-id="individual-card"
      data-cy={`individualCard-${props.dataCyIndex}`}
      className="CardWithOptions-component flex-column"
    >
      <div
        data-id="individual-card-information-box"
        className="top-options flex-row justify-between align-items-center"
      >
        <div>
          {props.creditCard.type === 'virtual' ? (
            <Chip
              label="Virtual"
              variant="outlined"
              sx={{
                borderRadius: '2px',
                borderColor: '#16A34A',
                borderWidth: '1.5px',
                background: 'none',
                color: '#16A34A',
              }}
            />
          ) : (
            <Chip
              label="Physical"
              color="success"
              variant="outlined"
              sx={{
                borderRadius: '2px',
                borderColor: '#0284C7',
                borderWidth: '1.5px',
                background: 'none',
                color: '#0284C7',
              }}
            />
          )}

          {props.creditCard.status == 'active' && (
            <Chip
              label="Active"
              sx={{ borderRadius: '2px', ml: 1, color: '#14532D', background: '#DCFCE7' }}
            />
          )}
          {props.creditCard.product == 'credit' ? (
            <Chip
              label="Charge"
              sx={{ borderRadius: '2px', ml: 1, color: 'white', background: '#4102C7' }}
            />
          ) : (
            <Chip
              label="Debit"
              sx={{ borderRadius: '2px', ml: 1, color: 'white', background: '#3A6959' }}
            />
          )}

          {props.creditCard.status == 'inactive' && (
            <Chip
              label="Pending Activation"
              sx={{ borderRadius: '2px', ml: 1, background: '#FFEDD5', color: '#7C2D12' }}
            />
          )}
          {props.creditCard.status == 'canceled' && (
            <Chip label="Canceled" color="default" sx={{ borderRadius: '2px', ml: 1 }} />
          )}
          {props.creditCard.status == 'frozen' && (
            <Chip
              label="Frozen"
              sx={{
                borderRadius: '2px',
                ml: 1,
                color: '#164E63',
                background: 'rgba(243, 9, 51, 0.38)',
              }}
            />
          )}
        </div>

        {menuItems.length > 0 && (
          <img
            ref={ref}
            data-id="individual-card-options"
            className="img-overflow pointer"
            data-cy={`cardEllipsis-${props.dataCyIndex}`}
            src={img_overflow}
            onClick={() => set_menu_open(true)}
          />
        )}
      </div>
      <Menu
        datatype="card-options-menu"
        data-cy={`cardOptionsMenu-${props.dataCyIndex}`}
        keepMounted
        anchorEl={ref.current}
        open={menu_open}
        onClose={closeMenu}
      >
        {menuItems.map(mi => mi)}
      </Menu>

      <CreditCard creditCard={props.creditCard} data-cy={`creditCard-${props.creditCard.id}`} />

      {props.creditCard.status == 'inactive' && (
        <Button
          className="mt-1 mb-2 "
          onClick={props.onActivateCard}
          style={{
            backgroundColor: 'white',
            color: '#09442F',
            border: '1px solid #09442F',
            fontWeight: 500,
          }}
          variant="outlined"
          data-cy={`activateCardButton-${props.dataCyIndex}`}
        >
          Activate
        </Button>
      )}

      {props.creditCard.shipping_info && shipping(props.creditCard.shipping_info)}
    </div>
  );
}

const ScrollButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 50%;
  z-index: 10;
  box-shadow: 0px 3px 6px #00000029;
  width: 30px;
  height: 30px;
`;

const CardList = memo(function CardList(props: CardListProps) {
  const { cards, isAdmin, dispatcher } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scroll = (direction: 'left' | 'right') => {
    const scrollAmount = 200;
    const container = scrollContainerRef.current;
    if (container) {
      container.scrollLeft += direction === 'left' ? -scrollAmount : scrollAmount;
    }
  };

  const showSensitiveInfo = async (cardId: string) => {
    await financialService
      .getCard(cardId)
      .then(res => {
        if (res?.error) throw new Error();
        if (res) {
          const { sensitive_card_info, exp_month, exp_year } = res;
          dispatcher({
            type: CardPageActionType.SensitiveCardNumber,
            payload: sensitive_card_info?.number,
          });
          dispatcher({
            type: CardPageActionType.SensitiveCardCvc,
            payload: sensitive_card_info?.cvc,
          });
          dispatcher({ type: CardPageActionType.CardExpMonth, payload: exp_month });
          dispatcher({ type: CardPageActionType.CardExpYear, payload: exp_year });
          dispatcher({ type: CardPageActionType.SensitiveCardInfoPopupOpen, payload: true });
        }
      })
      .catch(err => {
        standardError(err);
      });
  };

  if (props.isLoading) {
    return (
      <div
        style={{
          textAlign: 'center',
          margin: '20px',
          minHeight: '20em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h2>Loading...</h2>
      </div>
    );
  }

  if (cards.length === 0) {
    return <EmptyState />;
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      {!isMobile && (
        <ScrollButton style={{ left: '-16px' }}>
          <IconButton onClick={() => scroll('left')}>
            <KeyboardArrowLeft />
          </IconButton>
        </ScrollButton>
      )}

      <div
        ref={scrollContainerRef}
        style={{
          display: 'flex',
          overflowX: isMobile ? 'hidden' : 'auto',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'center' : 'flex-start',
          scrollBehavior: 'smooth',
          whiteSpace: 'nowrap',
          width: '100%',
          position: 'relative',
        }}
      >
        {cards
          .sort((a: ICreditCard, b: ICreditCard) => {
            const x: string = a.first_name.toLowerCase();
            const y: string = b.first_name.toLowerCase();
            return x < y ? -1 : 1;
          })
          .map((c: ICreditCard, i: number) => {
            return (
              <CardWithOptions
                key={i}
                dataCyIndex={i}
                cardholderData={props.currentUser.cardholder_data!}
                setSelectedCardDetails={() => {
                  dispatcher({ type: CardPageActionType.SelectedCardDetails, payload: c });
                }}
                adminView={isAdmin}
                creditCard={c}
                onViewCardAssignmentClick={() => {
                  posthog.capture('Card Assignment Info Click');
                  dispatcher({ type: CardPageActionType.CardAssignmentPopupCard, payload: c });
                }}
                onSensitiveCardInfoClick={() => {
                  posthog.capture('Sensitive Card Info Click');
                  showSensitiveInfo(c.id);
                }}
                onSetDebitCardPINClick={() => {
                  posthog.capture('Set Debit Card PIN');
                  dispatcher({ type: CardPageActionType.DebitCardSetPin, payload: c });
                  dispatcher({ type: CardPageActionType.ConfirmationCodePopupOpen, payload: true });
                }}
                onCancelCardClick={() => {
                  posthog.capture('Cancel Card Click');
                  dispatcher({ type: CardPageActionType.ConfirmPopupOpen, payload: true });
                  dispatcher({ type: CardPageActionType.CardToDelete, payload: c });
                }}
                onViewEditLimitsClick={() => {
                  posthog.capture('View Edit Limits Click');
                  dispatcher({ type: CardPageActionType.CardLimitsCard, payload: c });
                  dispatcher({ type: CardPageActionType.CardLimitsPopupOpen, payload: true });
                }}
                onFreezeCardClick={() => {
                  posthog.capture('Freeze Card Click');
                  dispatcher({ type: CardPageActionType.CardToFreeze, payload: c });
                  dispatcher({ type: CardPageActionType.ConfirmationCodePopupOpen, payload: true });
                }}
                onUnfreezeCardClick={() => {
                  posthog.capture('Unfreeze Card Click');
                  dispatcher({ type: CardPageActionType.CardToUnfreeze, payload: c });
                  dispatcher({ type: CardPageActionType.ConfirmationCodePopupOpen, payload: true });
                }}
                onActivateCard={() => {
                  posthog.capture('Activate Card Click');
                  dispatcher({ type: CardPageActionType.ActivationCard, payload: c });
                  dispatcher({ type: CardPageActionType.ConfirmationCodePopupOpen, payload: true });
                }}
                onSensitiveUnitCardInfoClick={() => {
                  posthog.capture('Sensitive Card Info Click');
                  dispatcher({ type: CardPageActionType.UnitCardSensitiveData, payload: c });
                  dispatcher({ type: CardPageActionType.ConfirmationCodePopupOpen, payload: true });
                }}
              />
            );
          })}
      </div>

      {!isMobile && (
        <ScrollButton style={{ right: '-14px' }}>
          <IconButton onClick={() => scroll('right')}>
            <KeyboardArrowRight />
          </IconButton>
        </ScrollButton>
      )}
    </div>
  );
});

export default CardList;
