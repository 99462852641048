import './styles/global.scss';
import App from './App';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import ENVIRONMENT from './config/current_environment';
import './fonts/NotoSans-Regular.ttf';
import './fonts/NotoSans-Bold.ttf';
import './fonts/NotoSans-ExtraBold.ttf';
import { init } from '@sentry/react';
import { Integrations as SentryIntegrations } from '@sentry/tracing';
import { createRoot } from 'react-dom/client';

init({
  dsn: 'https://390633dc744e440e892bc6a5c7a88dc8@o383125.ingest.sentry.io/5957908',
  integrations: [new SentryIntegrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: ENVIRONMENT,
  release: process.env.RELEASE,
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

serviceWorker.unregister();
export { apiCategoryToLabel } from './helpers.formatting';
