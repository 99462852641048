import { Chip, Paper } from '@mui/material';
import Show from 'app/components/Show';

interface FundingOptionItemProps {
  onClick: () => void;
  icon: React.ReactElement;
  title: string;
  subtitle: string;
  mobileOnly?: boolean;
}

const FundingOptionItem = (props: FundingOptionItemProps) => {
  const { onClick, icon, title, subtitle, mobileOnly } = props;
  return (
    <Paper
      variant="outlined"
      onClick={onClick}
      color="secondary"
      className="flex-row px-half-quarter py-half-quarter border-none pointer sm-flex mb-1"
    >
      <div className="flex items-center py-quarter px-quarter mr-2">{icon}</div>
      <div className="flex-grow-3">
        <p className="text-bold">{title}</p>
        <p>{subtitle}</p>
      </div>
      <Show when={!!mobileOnly}>
        <div className="flex-grow-2 flex items-center justify-end">
          <Chip label="Available on Mobile" color="secondary" className="sm-no-display" />
        </div>
      </Show>
    </Paper>
  );
};

export default FundingOptionItem;
