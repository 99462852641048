import { useQuery } from '@tanstack/react-query';
import { EntityTag, TaggedEntityType } from '../types';
import tagsService from '../services/tags.service';
import { useUserRole } from '../../auth/data/userRole';

export const useGetEntityTags = (
  entity_id: number,
  entityType: TaggedEntityType,
  initialData?: EntityTag[],
) => {
  const { isAdmin } = useUserRole();

  return useQuery({
    queryKey: ['/tags/tagged_entities', entityType, entity_id],
    queryFn: () => tagsService.getEntityTags(entity_id, entityType),
    initialData: () => (initialData ? { entity_tags: initialData } : undefined),
    refetchOnMount: 'always',
    enabled: isAdmin,
  });
};
