import React from 'react';
import { Box, TextField, MenuItem, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface TeamMember {
  id: number;
  name: string;
  email: string;
  first_name: string;
  last_name: string;
  removed: boolean;
  role: string;
}

interface InviteStatus {
  sent: boolean;
  email: string;
}

interface AddTeamMemberProps {
  showAddButton: boolean;
  addButtonRef: React.RefObject<HTMLButtonElement>;
  handleAddTeamMember: () => void;
  teamMembers: TeamMember[];
  inviteStatus: InviteStatus;
  selectedTeamMember: TeamMember | null;
  setShowAddButton: React.Dispatch<React.SetStateAction<boolean>>;
  setInviteStatus: React.Dispatch<React.SetStateAction<InviteStatus>>;
  setSelectedTeamMember: React.Dispatch<React.SetStateAction<TeamMember | null>>;
  handleDropdownOpen: () => void;
  handleDropdownClose: (e: React.SyntheticEvent) => void;
  currentUser: { is_deposit_customer: boolean };
  firstName: string;
  lastName: string;
}

const AddTeamMember: React.FC<AddTeamMemberProps> = ({
  showAddButton,
  addButtonRef,
  handleAddTeamMember,
  teamMembers,
  inviteStatus,
  selectedTeamMember,
  setShowAddButton,
  setInviteStatus,
  setSelectedTeamMember,
  handleDropdownOpen,
  handleDropdownClose,
  currentUser,
  firstName,
  lastName,
}) => {
  return (
    <Box className="w-full mb-2" position="relative">
      <TextField
        value={
          inviteStatus.sent && inviteStatus.email !== ''
            ? inviteStatus.email
            : selectedTeamMember?.name || ''
        }
        onChange={v => {
          const selectedValue = v.target.value;
          const foundMember = teamMembers.find(member => member.name === selectedValue);

          if (foundMember) {
            setShowAddButton(false);
            setInviteStatus({ sent: false, email: '' });
            setSelectedTeamMember(foundMember);
          } else if (inviteStatus.sent && selectedValue === inviteStatus.email) {
            setSelectedTeamMember({
              name: '',
              email: inviteStatus.email,
              first_name: firstName,
              id: 1,
              last_name: lastName,
              removed: false,
              role: 'account_owner',
            });
          }
        }}
        disabled={!currentUser.is_deposit_customer}
        select
        label="Team Member (optional)"
        className="w-full"
        inputProps={{
          'data-cy': 'teamMember',
        }}
        SelectProps={{
          onOpen: () => handleDropdownOpen(),
          onClose: e => {
            e.stopPropagation();
            setTimeout(() => {
              handleDropdownClose(e);
            }, 1000);
          },
        }}
      >
        <div className="shadow pt-[8px] pb-1 flex items-center justify-center">
          <span
            className="flex items-center justify-center font-medium underline cursor-pointer"
            onClick={handleAddTeamMember}
          >
            <AddCircleOutlineIcon fontSize="small" />
            <span className="ml-[5px] text-sm">Add new team member</span>
          </span>
        </div>

        {teamMembers.map((member, index) => (
          <MenuItem data-cy={`team-${member}-${index}`} key={member.id} value={member.name}>
            {member.name}
          </MenuItem>
        ))}
        {inviteStatus.sent && (
          <MenuItem value={inviteStatus.email} style={{ color: '#9D9D9D' }}>
            {`${inviteStatus.email} (invited)`}
          </MenuItem>
        )}
      </TextField>
      {inviteStatus.sent && (
        <Typography
          sx={{
            color: '#007149',
            marginTop: '8px',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '140%',
          }}
        >
          Great! We've sent {inviteStatus.email} an invite to join you on Toolbox.
        </Typography>
      )}
    </Box>
  );
};

export default AddTeamMember;
