import CustomDialog from '../../../../app/components/CustomDialog';
import { Button, InputAdornment, TextField } from '@mui/material';
import TagGroupSelector from '../TagGroupSelector/TagGroupSelector';
import LoadingButton from '../../../../app/components/LoadingButton';
import Show from '../../../../app/components/Show';
import SnackbarNotification from '../../../../app/components/SnackbarNotification';
import SuccessDialog from '../../../../app/components/SuccessDialog';
import { useEffect, useState } from 'react';
import { Tag } from '../../types';
import { useUpdateTag } from '../../data/updateTag';
import { SellOutlined } from '@mui/icons-material';

interface EditTagModalProps {
  tag: Tag;
  isOpen: boolean;
  onClose: () => void;
}

const EditTagModal = (props: EditTagModalProps) => {
  const { isOpen, onClose, tag } = props;

  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

  const [selectedTagGroupId, setSelectedTagGroupId] = useState<number>(tag.tag_group_id);
  const [tagName, setTagName] = useState(tag.name);

  const { mutate: patchTag, isSuccess, isPending, error, isError, data } = useUpdateTag();

  const updatedTag = data?.tag;

  const updateTag = () => {
    patchTag({ id: tag.id, name: tagName?.trim(), tag_group_id: selectedTagGroupId });
  };

  const closeModal = () => {
    onClose();
  };

  const closeSuccessDialog = () => {
    setIsSuccessDialogOpen(false);
    closeModal();
  };

  useEffect(() => {
    if (isSuccess) {
      setIsSuccessDialogOpen(true);
    }
  }, [isSuccess]);

  return (
    <>
      <CustomDialog title="Edit Tag" open={isOpen && !isSuccess} onClose={closeModal}>
        <TextField
          label="Tag Name"
          variant="outlined"
          className="w-full mt-1"
          value={tagName}
          onChange={event => setTagName(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SellOutlined />
              </InputAdornment>
            ),
          }}
          error={isError}
        />
        <TagGroupSelector
          className="mt-1"
          onChange={selectedId => (selectedId ? setSelectedTagGroupId(selectedId) : null)}
          value={`${selectedTagGroupId}`}
          textFieldError={isError}
        />
        <Show when={isError}>
          <p className="text-sm font-semibold text-[red] mt-[5px]">{error as unknown as string}</p>
        </Show>
        <div className="flex items-center justify-end mt-1">
          <Button color="inherit" className="cancel-btn mr-1" onClick={closeModal}>
            Cancel
          </Button>
          <LoadingButton
            loading={isPending}
            disabled={!tagName || !selectedTagGroupId}
            onClick={updateTag}
          >
            Update Tag
          </LoadingButton>
        </div>
      </CustomDialog>

      <Show when={isError}>
        <SnackbarNotification type={'error'} message={error as unknown as string} />
      </Show>
      <Show when={isSuccess}>
        <SuccessDialog
          open={isSuccessDialogOpen}
          onClose={closeSuccessDialog}
          title="Your tag has been updated!"
        >
          <div>
            <p>Your tag {tagName} has been updated!</p>
            <div className="flex items-center justify-between mt-1">
              <Button className="w-full" onClick={closeSuccessDialog}>
                Done
              </Button>
            </div>
          </div>
        </SuccessDialog>
      </Show>
    </>
  );
};

export default EditTagModal;
