import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck as farCircleCheck,
  faCircleLeft,
  faCircleRight,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
  faLightbulb,
  faRectangleList,
  faFolderOpen,
  faMoneyBill1,
  faCreditCard,
  faCircleXmark,
  faCalendar,
  faThumbsUp,
} from '@fortawesome/free-regular-svg-icons';
import {
  faExclamationCircle,
  faXmark,
  faEye,
  faEyeSlash,
  faWallet,
  faPercent,
  faGift,
  faCropSimple,
  faCameraRetro,
  faMoneyBills,
  faDollarSign,
  faRightLeft,
  faBriefcase,
  faMoneyCheckDollar,
  faPieChart,
  faWrench,
  faHandHoldingDollar,
  faGasPump,
  faToolbox,
  faScrewdriverWrench,
  faHelmetSafety,
  faSquareArrowUpRight,
  faBookOpen,
  faGear,
  faUsersGear,
  faListCheck,
  faArrowRightFromBracket,
  faMobileScreenButton,
  faGlobe,
  faTag,
  faTags,
  faTimesCircle,
  faLandmark,
} from '@fortawesome/free-solid-svg-icons';
import { CSSProperties } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IconProps extends Omit<FontAwesomeIconProps, 'icon'> {
  style?: CSSProperties;
  fill?: string;
  className?: string;

  onClick?(): void;

  fontSize?: string;
}

export const Check2CircleIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon
    icon={farCircleCheck}
    style={{ fontSize, marginBottom: 2, marginTop: 2, ...props.style }}
    {...props}
  />
);

export const ExclamationCircleIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faExclamationCircle} style={{ fontSize, ...props.style }} {...props} />
);

export const CloseIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon
    data-cy="closeX"
    icon={faXmark}
    style={{ fontSize, ...props.style }}
    {...props}
  />
);

export const Visibility = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faEye} style={{ fontSize, ...props.style }} {...props} />
);

export const VisibilityOff = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faEyeSlash} style={{ fontSize, ...props.style }} {...props} />
);

export const WalletIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faWallet} style={{ fontSize, ...props.style }} {...props} />
);

export const ArrowLeftIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faCircleLeft} style={{ fontSize, ...props.style }} {...props} />
);

export const ArrowRightIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faCircleRight} style={{ fontSize, ...props.style }} {...props} />
);

export const ArrowDownIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faArrowAltCircleUp} style={{ fontSize, ...props.style }} {...props} />
);

export const ArrowUpRightIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faArrowAltCircleDown} style={{ fontSize, ...props.style }} {...props} />
);

export const ArrowSquareUpRightIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faSquareArrowUpRight} style={{ fontSize, ...props.style }} {...props} />
);

export const ArrowLeftRightIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faRightLeft} style={{ fontSize, ...props.style }} {...props} />
);

export const PercentageIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faPercent} style={{ fontSize, ...props.style }} {...props} />
);

export const GiftIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faGift} style={{ fontSize, ...props.style }} {...props} />
);

export const LightBulb2Icon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faLightbulb} style={{ fontSize, ...props.style }} {...props} />
);

export const TextResizeIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faCropSimple} style={{ fontSize, ...props.style }} {...props} />
);

export const CameraIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faCameraRetro} style={{ fontSize, ...props.style }} {...props} />
);

export const CashStackIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faMoneyBills} style={{ fontSize, ...props.style }} {...props} />
);

export const DollarSignIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faDollarSign} style={{ fontSize, ...props.style }} {...props} />
);

export const CardListIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faRectangleList} style={{ fontSize, ...props.style }} {...props} />
);

export const CreditCard = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faRectangleList} style={{ fontSize, ...props.style }} {...props} />
);

export const CaseIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faBriefcase} style={{ fontSize, ...props.style }} {...props} />
);

export const FolderOpenIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faFolderOpen} style={{ fontSize, ...props.style }} {...props} />
);

export const Money = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faMoneyBill1} style={{ fontSize, ...props.style }} {...props} />
);

export const MoneyCheckDollarIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faMoneyCheckDollar} style={{ fontSize, ...props.style }} {...props} />
);

export const PieChartIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faPieChart} style={{ fontSize, ...props.style }} {...props} />
);

export const SubcontractorIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faWrench} style={{ fontSize, ...props.style }} {...props} />
);

export const PayrollIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faHandHoldingDollar} style={{ fontSize, ...props.style }} {...props} />
);

export const FuelIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faGasPump} style={{ fontSize, ...props.style }} {...props} />
);

export const EquipmentIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faToolbox} style={{ fontSize, ...props.style }} {...props} />
);

export const MaterialsIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faScrewdriverWrench} style={{ fontSize, ...props.style }} {...props} />
);

export const MaintenanceIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faHelmetSafety} style={{ fontSize, ...props.style }} {...props} />
);

export const BookIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faBookOpen} style={{ fontSize, ...props.style }} {...props} />
);

export const GearWideConnectedFilledIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faGear} style={{ fontSize, ...props.style }} {...props} />
);

export const UsersGearIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faUsersGear} style={{ fontSize, ...props.style }} {...props} />
);

export const CheckListIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faListCheck} style={{ fontSize, ...props.style }} {...props} />
);

export const LogoutIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ fontSize, ...props.style }} {...props} />
);

export const CardIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faCreditCard} style={{ fontSize, ...props.style }} {...props} />
);

export const VirtualCardIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faMobileScreenButton} style={{ fontSize, ...props.style }} {...props} />
);

export const GlobeIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faGlobe} style={{ fontSize, ...props.style }} {...props} />
);

export const XCircleIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faCircleXmark} style={{ fontSize, ...props.style }} {...props} />
);

export const CalendarIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faCalendar} style={{ fontSize, ...props.style }} {...props} />
);

export const ThumbsUpIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faThumbsUp} style={{ fontSize, ...props.style }} {...props} />
);

export const TagIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faTag} style={{ fontSize, ...props.style }} {...props} />
);

export const TagsIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faTags} style={{ fontSize, ...props.style }} {...props} />
);

export const CloseCircleIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize, ...props.style }} {...props} />
);

export const LandMarkIcon = ({ fontSize, ...props }: IconProps) => (
  <FontAwesomeIcon icon={faLandmark} style={{ fontSize, ...props.style }} {...props} />
);

export const GenericFontAwesomeIcon = ({
  icon,
  style,
  fill,
  className,
  onClick,
  fontSize,
  ...props
}: IconProps & { icon: IconProp }) => (
  <FontAwesomeIcon
    icon={icon}
    style={{ fontSize, fill, ...style }}
    className={className}
    onClick={onClick}
    {...props}
  />
);
