import PopUp from '../PopUp';
import { PlaidConnect } from '../PlaidConnectButton';
import { useState } from 'react';
import './style.scss';
import { standardError } from '../../../helpers/base';
import { PlaidLinkError } from 'react-plaid-link';

interface PlaidPopUpProps {
  isOpen: boolean;

  onClose(): void;

  linkToken: string;
  relinkMode: boolean;
  samedayMicrodepositsVerification?: boolean;

  onAccessTokenSet(): void;

  onPlaidError(err: PlaidLinkError): void;
}

export function PlaidPopUp(props: PlaidPopUpProps) {
  const [loading, set_loading] = useState(false);

  return (
    <PopUp
      className="PlaidPopUp-component px-2 py-2"
      isOpen={props.isOpen}
      onClose={props.onClose}
      noClose={loading}
    >
      {!loading && (
        <>
          <p className="title font-noto-sans font-bold font-base ">
            {props.samedayMicrodepositsVerification
              ? 'Received micro-deposits? Please verify amounts'
              : 'Connect Bank Account'}
          </p>

          <div className="mt-half">
            <li className="font-noto-sans">- Used to underwrite you</li>
            <li className="mt-half font-noto-sans">- Used for card repayment</li>
          </div>

          <p className="disclaimer font-noto-sans font-italic mb-1 mt-1">
            I authorize Toolbox to electronically debit my selected account and, if necessary,
            electronically credit this account to correct erroneous debits. Toolbox will notify me
            via the email on my user account before such debits/credits occur.
          </p>

          <PlaidConnect
            dataCy="connect"
            relinkMode={props.relinkMode}
            linkToken={props.linkToken}
            samedayMicrodepositsVerification={props.samedayMicrodepositsVerification}
            onAccessTokenSet={props.onAccessTokenSet}
            onFetchingAccessToken={() => null}
            onStartSettingPublicToken={() => set_loading(true)}
            onStoppedSettingPublicToken={() => set_loading(false)}
            onPlaidError={err => {
              props.onPlaidError(err);
            }}
          />
        </>
      )}
      {loading && (
        <>
          <p className="description font-noto-sans font-sm mb-2">
            Please hold while we make sure your account is connected.
          </p>
        </>
      )}
    </PopUp>
  );
}
