import { ScreenProps } from 'interfaces/baseInterfaces';
import { Navigate } from 'react-router';
import { DASHBOARD_PATHS } from '../../../../constants/routing';

interface UnmatchedRoutesProps extends ScreenProps {}

const UnmatchedRoutes = (props: UnmatchedRoutesProps) => {
  const { currentUser } = props;

  if (currentUser) {
    return <Navigate to={DASHBOARD_PATHS.transactions} />;
  }
  return <Navigate to="/sign-in" />;
};

export default UnmatchedRoutes;
