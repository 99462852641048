interface ShowProps {
  when: boolean;
  children: React.ReactNode;
}

const Show = (props: ShowProps) => {
  const { when, children } = props;

  return when ? <>{children}</> : null;
};

export default Show;
