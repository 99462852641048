import Box from '@mui/material/Box';
import List from '@mui/material/List';
import logo_white from '../../../common/assets/logo.svg';
import { Divider } from '@mui/material';
import NavigationItem from '../DesktopNavigation/NavigationItem';
import GiftButton from '../GiftButton';
import UserProfileWidget from '../UserProfileWidget';
import { NavigationTab } from '../DesktopNavigation/utils/navigationTabs';
import { MyUser } from '../../../interfaces/baseInterfaces';
import { useTheme } from '@mui/material/styles';
import Show from '../Show';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { logger } from '../../../common/logging';

interface AppDrawerContentProps {
  isDrawerOpen: boolean;
  isMobile?: boolean;
  tabs: NavigationTab[];
  onTabSelect?: (tab: NavigationTab) => void;
  user?: MyUser | null;
  closeDrawer?: () => void;
}

const AppDrawerContent = (props: AppDrawerContentProps) => {
  const { isDrawerOpen, tabs, user, onTabSelect, isMobile, closeDrawer } = props;

  const muiTheme = useTheme();
  const userName = `${user?.first_name} ${user?.last_name}`;
  const companyName = user?.company_name;

  return (
    <>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <List sx={{ flexGrow: 1 }}>
          {isDrawerOpen && (
            <div className="mb-3 ml-1 flex items-center">
              <Show when={!!isMobile}>
                <KeyboardDoubleArrowLeftIcon
                  className="text-gray-500 mr-1 cursor-pointer"
                  onClick={() => {
                    closeDrawer ? closeDrawer() : null;
                  }}
                />
              </Show>
              <img src={logo_white} alt="Toolbox logo" />
            </div>
          )}
          {!isDrawerOpen && (
            <Divider
              sx={{ backgroundColor: 'gray', maxWidth: 50, marginLeft: 1, marginBottom: 2 }}
            />
          )}
          {tabs.map(navItem => (
            <NavigationItem
              key={navItem.title}
              onItemSelect={onTabSelect}
              navItem={navItem}
              isDrawerOpen={isDrawerOpen}
            />
          ))}
        </List>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          marginBottom: 4,
          padding: '0 21px',
        }}
      >
        <GiftButton />
      </Box>

      <Show when={!isMobile}>
        <UserProfileWidget
        sx={{ paddingBottom: 32 }}
        userName={userName}
        companyName={companyName}
        userNameStyle={{ color: muiTheme.palette.common.white, fontSize: 16 }}
        companyNameStyle={{ color: '#9D9D9D', fontSize: 14 }}
        iconStyle={{ color: muiTheme.palette.common.white }}
        avatarStyle={{ width: 34, height: 34 }}
        avatarTextStyle={{ fontSize: 22.4 }}
      />
      </Show>
    </>
  );
};

export default AppDrawerContent;
