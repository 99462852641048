import IntegrationConnectionCard from '../../../components/IntegrationConnectionCard';
import posthog from 'posthog-js';
import { useRutterLink } from 'react-rutter-link';
import { usePostIntegrationInterest } from '../../../../companies/data/postIntegrationInterest';
import qb_logo from 'app/assets/images/qb.png';
import { useCreateRutterConnection } from '../../../rutter/data/createRutterConnection';
import currentEnv from '../../../../../config/environment';
import { useEffect } from 'react';
import { RutterPlatform } from '../../../rutter/rutter.types';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useUserRole } from '../../../../auth/data/userRole';
import { useNavigate } from 'react-router-dom';
import { useGetRutterConnectionStatus } from '../../../rutter/data/getRutterConnectionStatus';
import { useGetRutterConnection } from '../../../rutter/data/getRutterConnection';

interface QuickbooksConnectionCardProps {}

const QuickbooksConnectionCard = (props: QuickbooksConnectionCardProps) => {
  const navigate = useNavigate();
  const { isAdmin, isBookkeeper } = useUserRole();
  const isUserAllowed = isAdmin || isBookkeeper;
  const isFlagEnabled = useFeatureFlagEnabled('Rutter');
  const isConnectionAllowed = isFlagEnabled;
  const connectionLabel = isConnectionAllowed ? 'Connect' : 'Indicate Interest';

  const { mutate: postInterest } = usePostIntegrationInterest();
  const { mutate: createRutterConnection, isSuccess: isConnectionCreated } =
    useCreateRutterConnection();

  const {
    data: connectionStatusData,
    isLoading: isLoadingConnectionStatus,
    refetch: reFetchConnectionStatus,
  } = useGetRutterConnectionStatus(RutterPlatform.QUICKBOOKS, Boolean(isFlagEnabled));

  const {
    data: connectionData,
    isLoading: isLoadingConnection,
    refetch: refetchConnection,
  } = useGetRutterConnection(RutterPlatform.QUICKBOOKS, Boolean(isFlagEnabled));

  const isLoading = isLoadingConnectionStatus || isLoadingConnection || isFlagEnabled === undefined;
  const isConnected = !isLoading && !!connectionData;
  const connectionStatus = connectionStatusData?.connectionStatus;

  const onRutterAuthSuccess = (publicToken: string) => {
    createRutterConnection(publicToken);
    posthog.capture('Connected Quickbooks Online');
  };

  const { open: openRutterLink } = useRutterLink({
    onSuccess: onRutterAuthSuccess,
    publicKey: currentEnv().RUTTER_PUBLIC_KEY,
  });

  const indicateInterest = () => {
    postInterest('quickbooks');
    posthog.capture('Clicked Quickbooks Online Button');
  };

  const handleConnection = () => {
    if (!isUserAllowed) return;
    if (isConnectionAllowed) {
      openRutterLink();
    } else {
      indicateInterest();
    }
  };

  const handleViewIntegration = () =>
    navigate('/dashboard/accounting/integrations/quickbooks-online');

  useEffect(() => {
    if (isConnectionCreated) {
      void reFetchConnectionStatus();
      void refetchConnection();
    }
  }, [isConnectionCreated]);

  return (
    <IntegrationConnectionCard
      title="Quickbooks Online"
      onConnect={handleConnection}
      connectionStatus={connectionStatus}
      connectLabel={connectionLabel}
      logo={qb_logo}
      disabled={!isUserAllowed}
      isConnected={isConnected}
      isLoading={isLoading}
      isReadyForConnection={isConnectionAllowed}
      onViewIntegration={handleViewIntegration}
    />
  );
};

export default QuickbooksConnectionCard;
