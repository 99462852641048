import ENVIRONMENT from './current_environment';

export type ENVs = 'staging' | 'production' | 'development';

const ENV = {
  development: {
    MAIN_API: 'http://localhost:12345/',
    STRIPE_TEST_KEY: '', //'pk_test_I1FN62YA8NU3fzmjVegLZqBh',
    DEEPLINK_URL_BASE: '', //'https://staging.trytoolbox.com/',
    WEB_BASE: 'http://localhost:3000/web/',
    GOOGLE_API_KEY: 'AIzaSyCZdhnHt7UZZGbLKEWKErQHFzm-9SDakyk',
    VGS_ENVIRONMENT: 'sandbox',
    VGS_VAULT_ID: 'tntazhyknp1',
    RUTTER_PUBLIC_KEY: 'sandbox_pk_3fce57fe-594e-46f2-83e5-ddd5cf7c089f',
  },
  dev_prod: {
    MAIN_API: 'http://localhost:5000/',
    STRIPE_TEST_KEY: '', //'pk_test_I1FN62YA8NU3fzmjVegLZqBh',
    DEEPLINK_URL_BASE: '', //'https://staging.trytoolbox.com/',
    WEB_BASE: 'http://localhost:3000/web/',
    GOOGLE_API_KEY: 'AIzaSyCZdhnHt7UZZGbLKEWKErQHFzm-9SDakyk',
    VGS_ENVIRONMENT: 'live',
    VGS_VAULT_ID: 'tnt8w6nrmbu',
    RUTTER_PUBLIC_KEY: '5c3d422f-e257-4085-99ec-8ca7ffdd7e25',
  },
  staging: {
    MAIN_API: 'https://staging.trytoolbox.com/',
    STRIPE_TEST_KEY: '', //'pk_test_I1FN62YA8NU3fzmjVegLZqBh',
    DEEPLINK_URL_BASE: '', //'https://staging.trytoolbox.com/',
    WEB_BASE: 'https://staging-app.trytoolbox.com/web/',
    GOOGLE_API_KEY: 'AIzaSyAZ_lOUZiDOsYjQLo3IOtBrOZJk6hR5bb0',
    VGS_ENVIRONMENT: 'sandbox',
    VGS_VAULT_ID: 'tntazhyknp1',
    RUTTER_PUBLIC_KEY: '5c3d422f-e257-4085-99ec-8ca7ffdd7e25',
  },
  production: {
    MAIN_API: 'https://api.trytoolbox.com/',
    STRIPE_TEST_KEY: '', //'pk_live_C93UrPGCvBLrobJIWEgRw0XG',
    DEEPLINK_URL_BASE: '', //'https://app.trytoolbox.com/',
    WEB_BASE: 'https://app.trytoolbox.com/web/',
    GOOGLE_API_KEY: 'AIzaSyAbOKhnDE0-zUvyZI8Wgm1Ynx2jPZj7ae0',
    VGS_ENVIRONMENT: 'live',
    VGS_VAULT_ID: 'tnt8w6nrmbu',
    RUTTER_PUBLIC_KEY: '5c3d422f-e257-4085-99ec-8ca7ffdd7e25',
  },
};

export const ENV_IS_DEV = ENVIRONMENT === 'development';
export const ENV_IS_STAGING = ENVIRONMENT === 'staging';
export const ENV_IS_PRODUCTION = ENVIRONMENT === 'production';

const currentEnv = () => ENV[ENVIRONMENT];

export default currentEnv;
