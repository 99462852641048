import CustomNavBar from '../../../../components/CustomNavBar';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CreditAccountingTiles from '../../../../../features/accounting/credit/components/CreditAccountingTiles';
import { useGetCreditHistoricalStatements } from '../../../../../features/accounting/data/getCreditHistoricalStatements';

interface CreditAccountingPageProps {}

const CreditAccountingPage = (props: CreditAccountingPageProps) => {
  const tabs = [
    { title: 'Payments', path: '/dashboard/accounting/credit/payments' },
    {
      title: 'Statements',
      path: '/dashboard/accounting/credit/statements',
    },
  ];

  const { data, isLoading, isSuccess } = useGetCreditHistoricalStatements({ page: 1, per_page: 5 });
  const historicalStatements = data?.data ?? [];

  // Only show historical statements tab if there are statements
  if (historicalStatements.length > 0) {
    tabs.push({
      title: 'Historical Statements',
      path: '/dashboard/accounting/credit/historical-statements',
    });
  }

  return (
    <>
      <Helmet>
        <title>Toolbox - Credit Accounting</title>
      </Helmet>
      <CustomNavBar tabs={tabs} />
      <section className="xl:flex xl:flex-row-reverse xl:gap-x-4">
        <div className="xl:basis-1/4">
          <CreditAccountingTiles />
        </div>
        <div className="mt-[10px] xl:mt-[5px] grow">
          <Outlet />
        </div>
      </section>
    </>
  );
};

export default CreditAccountingPage;
