import React from 'react';
import { styled } from '@mui/material/styles';
import './styles.scss';

interface StyledCustomBannerProps {
  backgroundColor?: string;
  textColor?: string;
  text?: string;
  borderColor?: string;
  matchesMobile?: boolean;
  patternColor?: string;
}

const CustomBanner = styled('div')<StyledCustomBannerProps>(
  ({ theme, backgroundColor, borderColor, matchesMobile, patternColor }) => ({
    backgroundColor: backgroundColor || theme.palette.primary.main,
    width: matchesMobile ? '90%' : '96%',
    height: '108px',
    display: 'flex',
    borderRadius: '6px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundImage: `url('data:image/svg+xml;utf8,<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"><defs><pattern id="slantedLines" patternUnits="userSpaceOnUse" width="20" height="24" patternTransform="rotate(-40)"><line x1="12" y1="-24" x2="12" y2="36" stroke="${encodeURIComponent(
      patternColor || '#000',
    )}" stroke-width="0.5" stroke-miterlimit="10" stroke-opacity="0.3" /></pattern><pattern id="oppositeSlantedLines" patternUnits="userSpaceOnUse" width="12" height="24" patternTransform="rotate(40)"><line x1="0" y1="-24" x2="0" y2="40" stroke="${encodeURIComponent(
      patternColor || '#000',
    )}" stroke-width="0.5" stroke-miterlimit="10" stroke-opacity="0.3" /></pattern></defs><rect x="62%" width="30%" height="100%" fill="url(%23slantedLines)" /><rect x="92%" width="10%" height="100%" fill="url(%23oppositeSlantedLines)" /></svg>')`,
    paddingLeft: matchesMobile ? '18px' : '42px',
    paddingRight: matchesMobile ? '21px' : 0,
    border: borderColor || 'none',
  }),
);

const StyledCustomBanner: React.FC<StyledCustomBannerProps> = ({
  backgroundColor,
  textColor = '#fff',
  text,
  borderColor,
  matchesMobile,
  patternColor,
}) => {
  const marginClass = matchesMobile ? 'my-1 md:mb-4' : 'mb-6';

  return (
    <div className={`flex justify-center ${marginClass}`}>
      <CustomBanner
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        patternColor={patternColor}
        matchesMobile={matchesMobile}
      >
        <h1 style={{ color: textColor, textAlign: 'left', fontSize: 16 }}>{text}</h1>
      </CustomBanner>
    </div>
  );
};

export default StyledCustomBanner;
