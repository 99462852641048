import { CSSProperties } from 'react';
import { SYSTEM_COLORS } from '../../../constants/system';
import './style.scss';
import Icon, { SystemIcons } from '../Icon';

export type ButtonBackground =
  | 'MainPrimary'
  | 'MainSecondary'
  | 'MainPrimary'
  | 'MainSuccess'
  | 'MainError'
  | 'MainWarning'
  | 'MainInfo'
  | 'MainLight'
  | 'MainWhite'
  | 'VeryLightBlue'
  | 'BodyLight'
  | 'HoverPrimary'
  | 'BabyBlue';

interface ButtonProps {
  qaLabel?: string;
  children?: any;
  onClick?: any;
  className?: string;
  background?: ButtonBackground;
  style?: CSSProperties;
  disabled?: boolean;
  loading?: boolean;
  icon?: SystemIcons;
  dataId?: string;
  dataCy?:string;
  type?: 'submit' | 'reset' | 'button' | undefined;
}

export default function Button(props: ButtonProps) {
  const {
    qaLabel = '',
    children = undefined,
    onClick = undefined,
    className = '',
    background = 'MainPrimary',
    style = '',
    disabled = false,
    loading = false,
    icon = undefined,
    dataId = undefined,
    type,
    dataCy
  } = props;
  const btnStyle = { ...style, backgroundColor: SYSTEM_COLORS[`${background}`] };

  return (
    <button
      id={qaLabel ? qaLabel : undefined}
      data-cy={dataCy}
      className={`Button-component font-noto-sans font-bold text-white font-sm min-h-2 rounded-lg text-uppercase border-none px-1 ${className}`}
      style={btnStyle}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      disabled={disabled || loading}
      data-id={dataId}
      type={type}
    >
      {loading ? (
        <div className="flex-row justify-center">
          <Loader />
        </div>
      ) : (
        <>
          {icon && <Icon icon={icon} style={children ? { marginRight: 6 } : undefined} />}{' '}
          {children}
        </>
      )}
    </button>
  );
}

const Loader = () => {
  return (
    <div className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
