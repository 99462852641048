import Show from 'app/components/Show';
import { Helmet } from 'react-helmet';

interface PageLayoutProps {
  children: React.ReactNode;
  title?: string;
  navBar?: React.ReactElement;
  className?: string;
  titleClassName?: string;
  contentClassName?: string;
}

const PageLayout = (props: PageLayoutProps) => {
  const { children, titleClassName, contentClassName, title, navBar, className } = props;
  const hasTitle = !!title;
  const hasNavBar = !!navBar;

  return (
    <div className={`pb-16 ${className}`}>
      <Helmet>
        <title>Toolbox {title ? `- ${title}` : ''}</title>
      </Helmet>
      <section className="px-4 pt-8 lg:pt-14 lg:px-8">
        <Show when={hasTitle}>
          <p className={`text-3xl lg:text-5xl font-bold ${titleClassName}`}>{title}</p>
        </Show>
      </section>
      <Show when={hasNavBar}>
        <section className="mt-8 w-full px-4 lg:px-8">{navBar}</section>
      </Show>
      <section className={`px-4 lg:px-8 ${contentClassName}`}>{children}</section>
    </div>
  );
};

export default PageLayout;
