import { GearWideConnectedFilledIcon, LogoutIcon } from 'app/components/Icon/fontawesome';

export const popupnavigationConfig = [
  {
    title: 'Settings',
    logEvent: 'Settings drawer link clicked',
    linkTo: '/dashboard/settings',
    icon: <GearWideConnectedFilledIcon color={'#3A6959'} />,
    selected: false,
    callback: '',
  },
  {
    title: 'Logout',
    logEvent: 'Logout drawer link clicked',
    linkTo: '/sign-in',
    icon: <LogoutIcon color={'#3A6959'} />,
    selected: false,
    callback: 'SignOut',
  },
];
