import httpClient from '../../../../services/httpClient';
import {
  RutterAccount,
  RutterAccountMapping,
  RutterAccountType,
  RutterCategory,
  RutterConnection,
  RutterConnectionStatus,
  RutterPlatform,
} from '../rutter.types';

interface GetMappedAccountsResponse {
  account_mappings: RutterAccountMapping[];
}

interface GetRutterCategoriesResponse {
  categories: RutterCategory[];
  next_cursor: string;
}

interface CreateAccountMappingParams {
  platform: RutterPlatform;
  rutterAccountId: number;
}

interface CreateRutterAccountParams {
  platform: RutterPlatform;
  accountType: RutterAccountType;
  nominalCode: string;
  name: string;
}

interface IRutterService {
  createConnection: (publicToken: string) => Promise<{ is_ready: boolean }>;
  getConnection: (platform: RutterPlatform) => Promise<RutterConnection>;
  getConnectionStatus: (platform: RutterPlatform) => Promise<RutterConnectionStatus>;
  getMappedAccounts: (platform: RutterPlatform) => Promise<GetMappedAccountsResponse>;
  getAccounts: (platform: RutterPlatform) => Promise<{ accounts: RutterAccount[] }>;
  getCategories: (platform: RutterPlatform) => Promise<GetRutterCategoriesResponse>;
  createDepositAccountMapping: (
    params: CreateAccountMappingParams,
  ) => Promise<RutterAccountMapping>;
  createExpenseAccountMapping: (
    params: CreateAccountMappingParams,
  ) => Promise<RutterAccountMapping>;
  createPaymentAccountMapping: (
    params: CreateAccountMappingParams,
  ) => Promise<RutterAccountMapping>;
}

class RutterService implements IRutterService {
  createConnection = (publicToken: string) =>
    httpClient
      .post<{ is_ready: boolean }>('/rutter/connections', { public_token: publicToken })
      .then(res => res.data);

  getConnection = (platform: RutterPlatform) =>
    httpClient.get<RutterConnection>(`/rutter/connections/${platform}`).then(res => res.data);

  getConnectionStatus = (platform: RutterPlatform) =>
    httpClient
      .get<RutterConnectionStatus>(`/rutter/connections/${platform}/status`, {
        timeout: 180 * 1000,
      })
      .then(res => res.data);

  getMappedAccounts = (platform: RutterPlatform) =>
    httpClient
      .get<GetMappedAccountsResponse>(`/rutter/${platform}/account-mapping`)
      .then(res => res.data);

  createDepositAccountMapping = ({ platform, rutterAccountId }: CreateAccountMappingParams) =>
    httpClient
      .post(`/rutter/${platform}/account-mapping/deposit`, { rutter_account_id: rutterAccountId })
      .then(res => res.data);

  createExpenseAccountMapping = ({ platform, rutterAccountId }: CreateAccountMappingParams) =>
    httpClient
      .post(`/rutter/${platform}/account-mapping/credit-expense`, {
        rutter_account_id: rutterAccountId,
      })
      .then(res => res.data);

  createPaymentAccountMapping = ({ platform, rutterAccountId }: CreateAccountMappingParams) =>
    httpClient
      .post(`/rutter/${platform}/account-mapping/credit-payment`, {
        rutter_account_id: rutterAccountId,
      })
      .then(res => res.data);

  getAccounts = (platform: RutterPlatform) =>
    httpClient
      .get<{ accounts: RutterAccount[] }>(`/rutter/${platform}/accounts`)
      .then(res => res.data);

  getCategories = (platform: RutterPlatform) =>
    httpClient
      .get<GetRutterCategoriesResponse>(`/rutter/${platform}/categories`)
      .then(res => res.data);

  createRutterAccount = ({ name, accountType, platform, nominalCode }: CreateRutterAccountParams) =>
    httpClient
      .post<RutterAccount>(`/rutter/${platform}/accounts`, {
        name,
        account_type: accountType,
        nominal_code: nominalCode,
      })
      .then(res => res.data);
}

const rutterService = new RutterService();
export default rutterService;
