import { Outlet } from 'react-router-dom';
import AccountingPageNavbar from '../../../../features/accounting/components/AccountingPageNavbar';
import PageLayout from '../../../components/PageLayout';

interface AccountingPagesLayoutProps {}

const AccountingPagesLayout = (props: AccountingPagesLayoutProps) => {
  return (
    <PageLayout title="Accounting" navBar={<AccountingPageNavbar />}>
      <Outlet />
    </PageLayout>
  );
};

export default AccountingPagesLayout;
