import { CSSProperties, ReactNode } from 'react';
import './style.scss';
import { fixClass } from '../../../helpers/base';

interface CardProps {
  children: ReactNode[] | ReactNode;
  className?: string;
  style?: CSSProperties;
  onClick?(): void;
  dataId?: string;
}

export default function Card(props: CardProps) {
  return (
    <section
      datatype={props.dataId}
      style={props.style}
      onClick={props.onClick}
      className={`relative bg-white rounded-sm Card-component${fixClass(props.className)}${fixClass(
        !!props.onClick && 'clickable pointer',
      )}`}
    >
      {props.children}
    </section>
  );
}
