import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

interface Dataset {
  data: number[];
  fill: boolean;
  backgroundColor: any;
  borderColor: string;
  borderWidth: number;
  cubicInterpolationMode: 'monotone';
  tension: number;
}

interface LineChartData {
  labels: string[];
  datasets: Dataset[];
}

interface LineChartProps {
  data?: LineChartData;
  options?: Record<string, any>;
  title: string;
  subtitle?: string;
}

const LineChart: React.FC<LineChartProps> = ({
  data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
    datasets: [
      {
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: true,
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderColor: '#36A0FB',
        borderWidth: 1,
        cubicInterpolationMode: 'monotone',
        tension: 0.4,
      },
    ],
  },
  options = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0,
      },
    },
    layout: {
      padding: {
        top: 67,
        left: 16,
      },
    },
    scales: {
      x: {
        ticks: {
          padding: 10,
        },
        grid: {
          drawOnChartArea: false,
          drawBorder: false,
          drawTicks: false,
        },
        display: true,
      },
      y: {
        ticks: {
          display: false,
          padding: 10,
        },
        grid: {
          drawOnChartArea: false,
          drawBorder: false,
          drawTicks: false,
        },
        display: true,
      },
    },
    plugins: {
      Filler,
      legend: false,
      tooltip: {
        enabled: false,
      },
      title: {
        display: false,
      },
    },
  },
  ...props
}) => {
  const { title, subtitle } = props;
  const chartRef = useRef<ChartJS<'line', number[], string>>(null);
  const resizeObserver = useRef<ResizeObserver | null>(null);

  const createGradient = useCallback((canvas: HTMLCanvasElement, chartHeight: number) => {
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const gradient = ctx.createLinearGradient(0, 0, 0, chartHeight);
    gradient.addColorStop(0, '#E6F3FF');
    gradient.addColorStop(1, 'rgba(230, 243, 255, 0)');
    return gradient;
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current;
      const canvas = chart.canvas;
      const gradient = createGradient(canvas, canvas.clientHeight);
      if (gradient) {
        chart.data.datasets[0].backgroundColor = gradient;
        chart.update();
      }

      if (!resizeObserver.current) {
        resizeObserver.current = new ResizeObserver(entries => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          if (chartRef.current) {
            const currentCanvas = entries[0].target as HTMLCanvasElement;
            const newGradient = createGradient(currentCanvas, currentCanvas.clientHeight);
            if (newGradient) {
              chart.data.datasets[0].backgroundColor = newGradient;
              chart.update();
            }
          }
        });
      }

      resizeObserver.current.observe(canvas);
    }

    return () => {
      if (resizeObserver.current && chartRef.current) {
        resizeObserver.current.unobserve(chartRef.current.canvas);
      }
    };
  }, [createGradient]);

  return (
    <div
      style={{
        position: 'relative',
        height: '185px',
        flex: '1 0 0',
        borderRadius: '2px',
        border: '1px solid var(--greyscale-200, #EEE)',
        background: 'var(--greyscale-000, #FFF)',
        padding: '0.25rem 0.25rem 12px 0.25rem',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '16px',
          left: '16px',
        }}
      >
        <div
          style={{
            color: '#1D1D1D',
            fontSize: '16px',
            fontWeight: 'bold',
            lineHeight: '140%',
          }}
        >
          {title}
        </div>
        <div
          className="capitalize"
          style={{
            color: 'var(--greyscale-400, #9D9D9D)',
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '140%',
            marginTop: 2,
          }}
        >
          {subtitle}
        </div>
      </div>
      <Line ref={chartRef} data={data} options={options} />
    </div>
  );
};

export default LineChart;
