import Button from '../Button';
import { useEffect, useState } from 'react';
import './styles.scss';
import Input from '../Input';
import financialService from '../../../services/financial.service';
import posthog from 'posthog-js';
import { postedPaymentDate } from '../../../helpers/base';
import { formatCentsAsDollars, formatMonthDay } from '../../../helpers/formattings';
import { getLangText, Text } from '../../../contexts/language';
import { queryClient } from '../../../common/queryClient';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { CloseIcon } from '../Icon/fontawesome';
import { useGetCreditAccountSummary } from 'features/credit/data/getCreditServices';

interface MakePaymentPopupProps {
  onMadePayment(): void;
  onClose(): void;
  isOpen: boolean;
}

export function MakePaymentPopup(props: MakePaymentPopupProps) {
  const [payment_amount, set_payment_amount] = useState<null | number>(null);
  const [made_payment, set_made_payment] = useState(false);
  const [ready_to_confirm, set_ready_to_confirm] = useState(false);
  const [total_balance_due, set_total_balance_due] = useState(0);
  const [amount_due, set_amount_due] = useState(0);
  const [loading, set_loading] = useState(false);
  const [payment_error_message, set_payment_error_message] = useState('');

  const {
    data: creditAccountSummaryData,
    isLoading: isCreditAccountSummaryLoading,
    isError: isCreditAccountSummaryError,
    refetch: refetchCreditAccountSummary,
  } = useGetCreditAccountSummary();

  const makePayment = async () => {
    set_loading(true);
    set_payment_error_message('');

    posthog.capture('Made Payment V2', {
      'Payment Amount': payment_amount,
    });

    const resp = await financialService.makePayment(payment_amount! * 100);

    if (resp.error) {
      set_loading(false);
      set_payment_error_message(resp.error);
      return;
    }

    props.onMadePayment();

    // Clear cache for queried payments data
    queryClient.invalidateQueries({ queryKey: ['/payments'] });

    set_made_payment(true);
    refetchCreditAccountSummary();
    set_loading(false);
  };

  const handleClose = () => {
    set_payment_amount(0);
    set_made_payment(false);
    set_ready_to_confirm(false);

    props.onClose();
  };

  const handleLearnMore = () => {
    posthog.capture('Payment Modal - Learn More Button Click');
    window.open('https://usemotion.com/meet/michael-colgan/checking-accounts?d=15', '_blank');
  };

  useEffect(() => {
    set_total_balance_due(
      (creditAccountSummaryData?.company_balance ?? 0) / 100 -
        (creditAccountSummaryData?.current_pending_authorizations ?? 0) / 100,
    );
    set_amount_due((creditAccountSummaryData?.amount_due ?? 0) / 100);
  }, [creditAccountSummaryData]);

  return (
    <Dialog
      open={props.isOpen}
      fullWidth={true}
      onClose={handleClose}
      style={{ padding: 30, minWidth: 250 }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <p data-id="payment-popup-title" className="title font-2xl font-bold font-noto-sans p-4">
        <Text tid="MakePayment" />
      </p>
      <DialogContent>
        {!ready_to_confirm && !made_payment && (
          <>
            <div data-id="custom-payment-box" className="flex-row items-center justify-end">
              <Input
                fullWidth
                placeholder={getLangText('Amount')}
                dataCy="customPaymentValue"
                dataId="custom-payment-value"
                onChange={e => {
                  try {
                    const parsedAmount = parseFloat(e);
                    set_payment_amount(parsedAmount);
                  } catch (e) {
                    alert('e=' + e);
                  }
                }}
                value={payment_amount + ''}
                type={'number'}
              />
            </div>

            <div className="flex-row justify-between items-center mt-1 font-bold text-primary mb-2">
              <span
                className="pointer"
                onClick={() => {
                  posthog.capture('Quick Payment Button - Current Amount Due', {
                    Amount: amount_due,
                  });
                  set_payment_amount(amount_due);
                }}
              >
                <Text tid="PaymentDue" />
              </span>
              <span
                onClick={() => {
                  posthog.capture('Quick Payment Button - Total Balance Due', {
                    Amount: total_balance_due,
                  });
                  set_payment_amount(total_balance_due > 0 ? total_balance_due : 0);
                }}
                className="pointer ml-1"
              >
                <Text tid="CurrentBalance" />
              </span>
            </div>
          </>
        )}

        {ready_to_confirm && !made_payment && (
          <div className="flex-column items-center w-full">
            <span className="font-bold">Payment Amount:</span>
            <span>{formatCentsAsDollars(payment_amount! * 100)}</span>
            {creditAccountSummaryData?.is_paying_with_thread ? (
              <p className="mt-1">
                Note: Your payments post instantly because you're paying with a Toolbox deposit
                account
              </p>
            ) : (
              <>
                <span className="mt-1 font-bold">Estimated Post Date:</span>
                <span>{formatMonthDay(postedPaymentDate().toDate())}</span>
                <p className="mt-1">
                  Note: You could be getting instant payments with a Toolbox deposit account.{' '}
                </p>
                <p>
                  <a onClick={handleLearnMore} className="underline pointer">
                    Learn More
                  </a>
                </p>
              </>
            )}
            {payment_error_message && (
              <span className="red-text mt-1">{payment_error_message}</span>
            )}
          </div>
        )}

        {made_payment && (
          <span>Thank you! We will notify you once your payment has been processed.</span>
        )}

        {!made_payment && (
          <div className="flex-row justify-end" style={{ marginTop: 12 }}>
            <Button
              dataCy="confirmMakePaymentButton"
              dataId="confirm-make-payment-button"
              disabled={made_payment || !payment_amount || loading}
              className="btn-save mt-1"
              onClick={() => {
                if (ready_to_confirm) {
                  makePayment();
                } else {
                  set_ready_to_confirm(true);
                }
              }}
            >
              {ready_to_confirm ? getLangText('Confirm') : getLangText('Submit')}
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
