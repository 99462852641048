import baseService from './base.service';
import { formatEmail } from '../helpers/formattings';
import { CompanyOwner } from '../interfaces/baseInterfaces';
import { useAuthStore } from '../features/auth/data/authStore';

export interface CardholderDataPayload {
  company_name: string;
  dba_name: string;
  billing_address_line1: string;
  billing_address_line2?: string;
  billing_address_city: string;
  billing_address_state: string;
  billing_address_postal_code: string;
  company_tax_id: string;
}

export interface AdditionalCompanyData {
  title: string;
  business_vertical: string;
  legal_formation_type: string;
  formation_date: string;
  website_url: string;
  state_of_incorporation: string;
}

export interface SignUpPayload {
  email: string;
  password: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  cardholder_data?: CardholderDataPayload | null;
  additional_company_data?: AdditionalCompanyData | null;
  sms_verification_token?: string | null;
  email_verification_token: string;
  invite_token?: string | null;
  company_name?: string | null;
  formation_type?: string | null;
}

class UserService {
  public async getCurrentUser() {
    const user = await baseService.getJSON('user');
    if (user && !user.error) {
      useAuthStore.setState({ appUser: user });
    }
    if (user.error) {
      baseService.clearAuthToken(true);
    }
    return user;
  }

  public async checkAuth() {
    return await baseService.getJSON('check-auth');
  }

  public async inviteSignUp(payload: SignUpPayload) {
    const res = await baseService.postJSON('onboarding/invite/sign-up', payload);
    if (res && !res.error && res.token) {
      baseService.saveAuthToken(res.token, res.user);
    }
    return res;
  }

  public async signIn(user: { password: string; email: string }) {
    const res = await baseService.postJSON(
      'sign-in',
      { email: formatEmail(user.email), password: user.password },
      false,
    );
    if (res && !res.error && res.token) {
      baseService.saveAuthToken(res.token, res.user);
    }
    return res;
  }

  public async updateUser(payload: any) {
    return await baseService.patchJSON('user', payload);
  }

  public async forgotPassword(email: string) {
    return await baseService.postJSON('users/forgot-password', { email: formatEmail(email) });
  }

  public async resetPassword(password: string, token: string | null) {
    return await baseService.patchJSON('users/reset-password', { password, token });
  }

  public async requestSms(phone_number: string) {
    return await baseService.postJSON('sms/request', {
      phone_number,
    });
  }

  public async requestEmailCode(email: string) {
    return await baseService.postJSON('email/request', {
      email,
    });
  }

  public async requestNewCardholderLimit(
    new_limit_requested: number,
    new_limit_requested_reason: string,
    product_feedback: string,
  ) {
    return await baseService.postJSON('user/request-new-cardholder-limit', {
      new_limit_requested,
      new_limit_requested_reason,
      product_feedback,
    });
  }

  public async verifySmsCode(phone_number: string, access_code: string) {
    return await baseService.postJSON('sms/verify', {
      phone_number,
      access_code,
    });
  }

  public async verifyEmailCode(email: string, access_code: string) {
    return await baseService.postJSON('email/verify', {
      email,
      access_code,
    });
  }

  public async addCompanyOwners(company_owners: CompanyOwner[]) {
    return await baseService.postJSON('company-owners', company_owners);
  }

  public async captureTermsAcceptance(email: string) {
    return await baseService.patchJSON('users/terms-acceptance', {
      email,
    });
  }

  public async captureOatfiDebitAuthorization() {
    return await baseService.patchJSON('/users/oatfi-acceptance', {});
  }

  public async uploadDocumentFile(file: File, uploadType: string) {
    const formData: any = new FormData();
    formData.append(file.name, file);
    formData.append('upload_type', uploadType);

    return await baseService.postFormData('documents/upload', formData, 'post');
  }

  public async listUploadedDocuments() {
    return await baseService.getJSON('documents');
  }
}

const userService = new UserService();

export default userService;
