import { useState, useEffect } from 'react';
import companyService from 'services/companyService';
import { CompanyTeamMember } from 'interfaces/baseInterfaces';
import { useUserRole } from '../../features/auth/data/userRole';

export const useTeamMembers = (): CompanyTeamMember[] => {
  const [teamMembers, setTeamMembers] = useState<CompanyTeamMember[]>([]);
  const { isAdmin } = useUserRole();

  useEffect(() => {
    const loadTeamMembers = async () => {
      if (!isAdmin) return;
      const resp = await companyService.getTeam({
        include_removed: false,
        include_self: true,
      });

      if (!resp.error) {
        setTeamMembers(resp);
      }
    };

    loadTeamMembers();
  }, []);

  return teamMembers;
};
