import baseService from './base.service';

class UnitService {
  public async createTokenVerification() {
    return await baseService.postJSON('unit/token-verification', {});
  }

  public async createToken(verificationCode: string, scope: string) {
    return await baseService.postJSON('unit/token', {
      verification_code: verificationCode,
      scope: scope,
    });
  }

  public async updateCounterParty(counterparty_id: string) {
    return await baseService.patchJSON(`unit/counterparties/${counterparty_id}`, {});
  }

  public async updateBusinessCustomer(business_customer_id: string, payload: any) {
    return await baseService.patchJSON(`unit/customers/${business_customer_id}`, payload);
  }
}

export default new UnitService();
