import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import LinearProgress from '@mui/material/LinearProgress';
import { ALLOWABLE_FILE_TYPES } from '../../../constants/system';

interface DragDropProps {
  label: string;
  dataCy: string;
  uploadAction(files: any): void;
  maxSize?: number;
  maxFiles?: number;
}

type FileValidationError = {
  message: string;
  code: string;
};

const DragDrop = memo(function DragDrop(props: DragDropProps) {
  const { label, uploadAction, maxSize = 2000000, maxFiles = 1 } = props;
  const [files, setFiles] = useState([]);
  const [fileRejectedMessage, setFileRejectedMessage] = useState<string>('');
  const [fileAcceptedMessage, setFileAcceptedMessage] = useState<string>('');
  const [progress, setProgress] = useState<number>(0);

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: any) => {
    const errMessage: string = rejectedFiles?.length
      ? (rejectedFiles[0].errors[0] as FileValidationError)?.message
      : '';
    setFileRejectedMessage(errMessage);
    setFiles(acceptedFiles.map((file: File) => Object.assign(file, {})) as any);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noDragEventsBubbling: true,
    maxFiles,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    onDrop,
    onDropAccepted: () => {
      setFileAcceptedMessage('File uploading...');
    },
    validator: (file: File) => {
      const validationErrors: FileValidationError[] = [];
      const fileNameLen: number = file.name?.lastIndexOf('.');
      const fileSize: number = file.size;

      if (fileNameLen < 5) {
        validationErrors.push({
          code: 'name-too-small',
          message: 'Filename must be at least 5 characters',
        });
      }

      if (fileSize > maxSize) {
        validationErrors.push({
          code: 'size-too-big',
          message: `File size must be less than ${maxSize / 1000000}mb`,
        });
      }

      return validationErrors.length ? validationErrors : null;
    },
  });

  useEffect(() => {
    uploadAction(files);
  }, [files]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress === 100) return 0;
        return Math.min(oldProgress + Math.random() * 10, 100);
      });
    }, 500);

    return () => clearInterval(timer);
  }, []);

  return (
    <section
      {...getRootProps()}
      className="px-2 py-2 pointer rounded-sm border-dashed"
      data-cy={props.dataCy}
    >
      <input {...getInputProps()} accept={ALLOWABLE_FILE_TYPES} />
      {!isDragActive ? (
        <>
          <AddCircleOutlineIcon className="font-2xl mt-half vertical-bottom" />
          <span className="font-noto-sans vertical-super pl-half">{label}</span>
        </>
      ) : (
        <>
          <AddCircleOutlineIcon className="font-2xl mt-half vertical-bottom" />
          <span className="font-noto-sans vertical-super pl-half">Drop your file here now</span>
        </>
      )}
      {fileRejectedMessage && <div className="font-noto-sans text-red">{fileRejectedMessage}</div>}
      {fileAcceptedMessage && (
        <div className="font-noto-sans">
          {fileAcceptedMessage}
          <LinearProgress variant="determinate" value={progress} className="rounded-sm" />
        </div>
      )}
    </section>
  );
});

export default DragDrop;
