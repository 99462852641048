import PageLayout from '../../../components/PageLayout';
import TagsTable from '../../../../features/tags/components/TagsTable';
import CreateTagButton from '../../../../features/tags/components/CreateTagButton';
import TagGroupSelector from '../../../../features/tags/components/TagGroupSelector/TagGroupSelector';
import { useState } from 'react';

interface TagsPageProps {}

const TagsPage = (props: TagsPageProps) => {
  const [selectedTagGroupId, setSelectedTagGroupId] = useState<number>();

  return (
    <PageLayout
      title="Tags"
      navBar={
        <section className="md:flex items-center justify-between">
          <TagGroupSelector
            className="w-20 mb-1 lg:mb-0"
            label="Filter by category"
            onChange={selectedId => setSelectedTagGroupId(selectedId)}
          />
          <CreateTagButton />
        </section>
      }
    >
      <TagsTable tagGroupId={selectedTagGroupId} />
    </PageLayout>
  );
};

export default TagsPage;
