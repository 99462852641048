import { useQuery } from '@tanstack/react-query';
import depositService from '../../../services/deposit.service';
import { useUserRole } from '../../auth/data/userRole';

export const useGetDepositAccount = () => {
  const { isAdmin } = useUserRole();
  return useQuery({
    queryKey: ['admin/unit/deposit-account'],
    queryFn: depositService.getDepositAccount,
    enabled: isAdmin,
  });
};
