import { ThemeOptions } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#09442F',
      dark: '#063021',
    },
    secondary: {
      main: '#1D1D1D',
    },
    success: {
      main: '#6FCF97',
    },
  },
});

export const toolboxDefaultTheme: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // Hide scrollbar for Chrome, Edge, and Safari
        '*::-webkit-scrollbar': {
          width: '0.4em',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          boxShadow: 'unset',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '2px',
          fontWeight: 'bold',
          textTransform: 'unset',
          padding: '5px 10px',
        },
      },
      variants: [
        {
          props: { color: 'primary' },
          style: {
            backgroundColor: '#09442F',
            color: '#fff',
            border: '1px solid transparent',
            ':hover': {
              backgroundColor: '#3A6959',
            },
            '&.Mui-disabled': {
              backgroundColor: '#D0D1D7',
              color: '#fff',
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            backgroundColor: '#015FCE',
            color: '#fff',
            ':hover': {
              backgroundColor: '#30638E',
            },
            '&.Mui-disabled': {
              backgroundColor: '#D0D1D7',
              color: '#fff',
            },
          },
        },

        {
          props: { color: 'secondary', variant: 'outlined' },
          style: {
            backgroundColor: 'transparent',
            border: '1px solid',
            color: theme.palette.secondary.main,
            borderColor: '#e5e7eb',
            ':hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.20)',
              border: '1px solid #09442F',
            },
            '&.Mui-disabled': {
              backgroundColor: '#fff',
            },
          },
        },
        {
          props: { color: 'info' },
          style: {
            backgroundColor: '#fff',
            color: '#000',
            border: '1px solid #EFEDED',
            ':hover': {
              backgroundColor: '#e0e8e5',
            },
          },
        },
      ],
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'Noto-Sans',
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: { color: 'primary' },
          style: {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            ':hover': {
              backgroundColor: theme.palette.primary.dark,
            },
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root': {
            fontFamily: 'Noto-Sans',
          },
        },
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { color: 'secondary', variant: 'outlined' },
          style: {
            backgroundColor: '#F7F7F7',
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '21px',
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
        },
      },
      variants: [
        {
          props: { color: 'success' },
          style: {
            backgroundColor: '#D4EFD5',
            color: '#5A975C',
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            backgroundColor: '#e6f3ff',
            color: '#5ca5e6',
          },
        },
        {
          props: { color: 'warning' },
          style: {
            backgroundColor: '#FDE5B1',
            color: '#D09922',
          },
        },
        {
          props: { color: 'default' },
          style: {
            backgroundColor: '#F5DCE2',
            color: '#A0485D',
          },
        },
      ],
    },
  },
  palette: {
    primary: {
      main: theme.palette.primary.main,
      dark: theme.palette.primary.dark,
    },
    success: {
      main: theme.palette.success.main,
    },
    action: {
      active: '#747583',
      selectedOpacity: 0.88,
    },
    background: {
      paper: '#fff',
      default: '#fff',
    },
  },
};
