import { Helmet } from 'react-helmet';
import qb_logo from 'app/assets/images/qb.png';
import IntegrationPageHeader from '../../../../../features/integrations/components/IntegrationPageHeader/IntegrationPageHeader';
import IntegrationAccountMapper from '../../../../../features/integrations/components/IntegrationAccountMapper';
import { RutterPlatform } from '../../../../../features/integrations/rutter/rutter.types';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Skeleton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useGetRutterConnectionStatus } from '../../../../../features/integrations/rutter/data/getRutterConnectionStatus';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import Show from '../../../../components/Show';

interface QuickBooksOnlinePageProps {}

const QuickBooksOnlinePage = (props: QuickBooksOnlinePageProps) => {
  const isFlagEnabled = useFeatureFlagEnabled('Rutter');

  const { data: connectionStatusData, isLoading: isLoadingConnectionStatus } =
    useGetRutterConnectionStatus(RutterPlatform.QUICKBOOKS, Boolean(isFlagEnabled));
  const connectionStatus = connectionStatusData?.connectionStatus;

  return (
    <div className="max-w-3xl">
      <Helmet>
        <title className="capitalize">{`Toolbox - QuickBooks Online Integration`}</title>
      </Helmet>
      <IntegrationPageHeader
        className="mt-2"
        logo={qb_logo}
        title="Quickbooks Online"
        actionItems={
          <div className="flex grow items-start justify-start">
            <Show when={!!connectionStatus}>
              <span
                className={`capitalize text-xs inline-block rounded-full items-center justify-center px-[10px] py-[5px] ${
                  connectionStatus == 'ready' && 'text-lime-600'
                } ${connectionStatus == 'syncing' && 'text-orange-600'} ${
                  (connectionStatus == 'disabled' ||
                    connectionStatus == 'needs update' ||
                    connectionStatus == 'accounts not mapped') &&
                  'text-red-600'
                } `}
              >
                {connectionStatus}
              </span>
            </Show>
            <Show when={isLoadingConnectionStatus}>
              <Skeleton variant="rounded" height={15} width={60} className="ml-1" />
            </Show>
          </div>
        }
      />
      <Accordion defaultExpanded className="border px-[10px] pb-2 md:px-[20px]">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div>
            <p className="font-bold text-[15px]">Account Field Mapping</p>
            <Show when={connectionStatus == 'accounts not mapped'}>
              <p className="text-orange-500">
                Your Quickbooks data cannot be synchronized until you configure the account mapping
                below.
              </p>
            </Show>
          </div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <IntegrationAccountMapper
            className="mt-1"
            platformTitle={'Quickbooks'}
            platform={RutterPlatform.QUICKBOOKS}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default QuickBooksOnlinePage;
