import { objectToUrlParams } from 'helpers/base';
import baseService from './base.service';
import { AccountType } from 'interfaces/account';
import httpClient from './httpClient';

export interface InvitePayload {
  email: string;
  role: string;
  title: string;
}

export interface GetCompanyAddressResp {
  address: string;
}

class CompanyService {
  public async inviteTeamMember(payload: InvitePayload) {
    const res = await baseService.postJSON('company/invite', payload);
    if (res && !res.error && res.token) {
      baseService.saveAuthToken(res.token, res.user);
    }
    return res;
  }

  public async getPendingInvites() {
    return await baseService.getJSON('company/invites');
  }

  public async resendInvite(company_invite_id: number) {
    return await baseService.postJSON(`company/invite/${company_invite_id}/resend`, {});
  }

  public async getTeam(params: any) {
    const filters = objectToUrlParams(params);
    return await baseService.getJSON(`company/team${filters}`);
  }

  public async setMemberCard(role_id: number, cardId: string) {
    return await baseService.patchJSON('company/members/' + role_id, {
      unit_card_id: cardId,
    });
  }

  public async removeUser(role_id: number) {
    return await baseService.patchJSON(`company/members/${role_id}/remove`, {});
  }

  public async restoreUser(role_id: number) {
    return await baseService.patchJSON(`company/members/${role_id}/restore`, {});
  }

  public async getCompanyAddress() {
    return httpClient.get<GetCompanyAddressResp>('/company/address').then(res => res.data);
  }

  public async notifyIntegrationInterest(integration: string) {
    return await httpClient.post('company/integration-interest', { integration });
  }
}

const companyService = new CompanyService();

export default companyService;
