import React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

type TabContent = {
  label: string;
  content: React.ReactNode;
  count?: number;
};

type FullWidthTabsProps = {
  tabContents: TabContent[];
  parentState: any;
  belowTabComponent?: React.ReactNode;
  onTabChange?: (event: React.SyntheticEvent, newValue: number) => void;
};

const TabPanel: React.FC<TabContent & { index: number; value: number }> = ({
  content,
  value,
  index,
}) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && <Box sx={{ p: 0 }}>{content}</Box>}
  </div>
);

const FullWidthTabs: React.FC<FullWidthTabsProps> = ({
  tabContents,
  belowTabComponent,
  onTabChange,
  parentState,
}) => {
  const { currentTab } = parentState;
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (onTabChange) {
      onTabChange(event, newValue);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
      }}
    >
      <Tabs
        value={currentTab}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="full width tabs"
        sx={{
          '.MuiTabs-indicator': {
            backgroundColor: '#007149',
          },
          '.Mui-selected': {
            color: '#007149',
          },
          '.MuiTab-root': {
            flexGrow: 1,
            flexBasis: 0,
            maxWidth: 'none',
            textTransform: 'none',
          },
          borderBottom: 1,
          borderColor: '#D3D3D3',
        }}
      >
        {tabContents.map((tab, index) => (
          <Tab
            key={index}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {tab.label}
                {tab.count !== undefined && tab.count !== 0 && currentTab !== index && (
                  <Box
                    component="span"
                    sx={{
                      ml: 1,
                      backgroundColor: 'var(--greyscale-200, #EEE)',
                      color: 'gray',
                      borderRadius: '2px',
                      fontSize: '0.75rem',
                      px: '5px',
                      height: '20px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {tab.count}
                  </Box>
                )}
              </Box>
            }
          />
        ))}
      </Tabs>
      {belowTabComponent && (
        <Box
          sx={{
            mb: 6,
            mt: 2,
          }}
        >
          {belowTabComponent}
        </Box>
      )}
      {tabContents.map((tab, index) => (
        <TabPanel key={index} content={tab.content} index={index} value={currentTab} label={''} />
      ))}
    </Box>
  );
};

export default FullWidthTabs;
