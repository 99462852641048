import { useEffect, useState } from 'react';
import './styles.scss';
import Input from '../Input';
import CustomDialog from '../CustomDialog';
import { Button, InputAdornment, Typography } from '@mui/material';
import { DollarSignIcon } from '../Icon/fontawesome';

interface CardLimitsPopupProps {
  onCardLimitsUpdateClick(cardLimit: number, transactionLimit?: number): void;
  onClose(): void;
  isOpen: boolean;
  cardId: string;
  cardLimit: number | null;
  transactionLimit: number | null;
  cardholderLimit: number;
  adminView: boolean;
}

export function CardLimitsPopup(props: CardLimitsPopupProps) {
  const [card_limit, set_card_limit] = useState(props.cardLimit ? props.cardLimit / 100.0 : 0);
  const [transaction_limit, set_transaction_limit] = useState(
    props.transactionLimit ? props.transactionLimit / 100.0 : 0,
  );

  const dollarLimit = props.cardholderLimit / 100.0;

  const overMaxLimit = card_limit > dollarLimit || transaction_limit > dollarLimit;

  useEffect(() => {
    set_card_limit(props.cardLimit ? props.cardLimit / 100.0 : 0);
  }, [props.cardLimit]);

  return (
    <CustomDialog
      title="Edit Card"
      open={props.isOpen}
      data-cy="editCardLimitDialog"
      onClose={() => props.onClose()}
      footerContent={
        <div
          data-id="new-card-button-box"
          className=" md:px-4 mb-1 items-center flex justify-between "
        >
          <Button
            data-id="new-card-cancel-button"
            data-cy="CancelButton"
            className="w-1/4"
            variant="outlined"
            color="secondary"
            onClick={() => props.onClose()}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button
            disabled={overMaxLimit || !props.adminView}
            data-cy="SaveButton"
            style={{ marginLeft: 8 }}
            onClick={() => props.onCardLimitsUpdateClick(card_limit, transaction_limit)}
          >
            {overMaxLimit
              ? 'Over $' + dollarLimit.toLocaleString() + ' account limit'
              : 'Update Card Limit'}
          </Button>
        </div>
      }
    >
      <div>
        <div style={{ paddingTop: 16 }}>
          <Typography
            sx={{
              marginBottom: 1,
              color: '#3A6959',
              fontSize: '14px',
              fontStyle: 'normal',
              lineHeight: '140%',
              fontVariant: 'all-small-caps',
            }}
          >
            Card Details
          </Typography>

          <Typography
            sx={{
              color: '#9D9D9D',
              fontSize: '14px',
              fontStyle: 'normal',
              lineHeight: '140%',
              fontVariant: 'all-small-caps',
            }}
          >
            Monthly spending limit
          </Typography>

          <div className="">
            <Input
              dataId="edit-view-transaction-limit-value"
              dataCy="editViewTransLimit"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DollarSignIcon
                      style={{ color: '#3A6959', verticalAlign: 'middle', height: 26, width: 26 }}
                    />
                  </InputAdornment>
                ),
                style: {
                  fontSize: '44px',
                  fontWeight: 'bold',

                  display: 'flex',
                  alignItems: 'center',
                },
                sx: {
                  '& .MuiInputBase-input::placeholder': {
                    color: '#D3D3D3',
                    opacity: 1,
                  },
                  '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button':
                    {
                      '-webkit-appearance': 'none',
                      margin: 0,
                    },
                  '& input[type="number"]': {
                    '-moz-appearance': 'textfield',
                  },
                },
              }}
              type={'number'}
              placeholder={'no limit'}
              className="w-full text-left"
              variant="standard"
              disabled={!props.adminView}
              onChange={e => set_card_limit(parseFloat(e))}
              value={card_limit ? card_limit : ''}
            />
          </div>
        </div>
      </div>
    </CustomDialog>
  );
}
