import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import CustomDialog from 'app/components/CustomDialog';
import { Check2CircleIcon } from 'app/components/Icon/fontawesome';

interface CardCreatedDialogProps {
  isOpen: boolean;
  onClose: () => void;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  matchesMobile: boolean;
}

const CardCreatedDialog: React.FC<CardCreatedDialogProps> = ({
  isOpen,
  onClose,
  handleTabChange,
  matchesMobile,
}) => {
  return (
    <CustomDialog
      showHeader={false}
      open={isOpen}
      onClose={onClose}
      footerContent={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: 2,
            width: '100%',
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: matchesMobile ? '100%' : '90%',
            }}
            color="primary"
            onClick={e => {
              onClose();
              handleTabChange(e, 1);
            }}
          >
            View Cards
          </Button>
        </Box>
      }
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: 4,
        }}
      >
        <Check2CircleIcon style={{ fontSize: 30, marginBottom: 2, color: '#6FCF97' }} />

        <Typography variant="body1" sx={{ marginY: 1, paddingX: 6, fontWeight: 700 }}>
          Success!
        </Typography>
        <Typography variant="body1" sx={{ paddingX: 6, color: '#4D4D4D' }}>
          Your new card has been created.
        </Typography>
      </Box>
    </CustomDialog>
  );
};

export default CardCreatedDialog;
