/**
 * Ensures user has created a company profile or belongs to one.
 *
 * If not, navigate to page to create company.
 *
 * Depends on user being signed in.
 */

import RequiresSignIn from 'app/pages/authWrappers/RequiresSignIn';
import { ScreenProps } from 'interfaces/baseInterfaces';
import { Navigate } from 'react-router';

interface RequiresCompanySetupProps extends ScreenProps {
  children: JSX.Element;
}

const RequiresCompanySetup = (props: RequiresCompanySetupProps) => {
  const { currentUser, children } = props;

  return <RequiresSignIn {...props}>{children}</RequiresSignIn>;
};

export default RequiresCompanySetup;
