import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import posthog from 'posthog-js';
import { CloseIcon } from '../../../../app/components/Icon/fontawesome';
import { depositAccountAgreementUrl } from '../../../../constants/externalAppUrls';
import { useEffect, useState } from 'react';
import { useUserRole } from '../../../auth/data/userRole';
import { useCreateDepositAccount } from '../../../depositAccount/data/createDepositAccount';

interface PayWithLineOfCreditModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PayWithLineOfCreditModal = (props: PayWithLineOfCreditModalProps) => {
  const { isOpen, onClose } = props;
  const { isDepositCustomer } = useUserRole();

  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const {
    mutate: createDepositAccount,
    isError: isCreateDepositAccountError,
    isSuccess: isCreateDepositAccountSuccess,
    error: createDepositAccountError,
  } = useCreateDepositAccount();

  const handleModalClose = () => {
    onClose();
    posthog.capture('Close Pay with LOC Modal Click');
  };

  const handleLearnMoreClick = () => {
    onClose();
    posthog.capture('Line of credit modal - learn more button click');
    window.open('https://usemotion.com/meet/michael-colgan/checking-accounts?d=15', '_blank');
  };

  const handleApplyForLineOfCreditFromMainModal = () => {
    if (isDepositCustomer) {
      posthog.capture('Line of credit modal - go to Vaya link');
      window.open('https://toolbox.direct.vaya.finance/login', '_blank');
    } else {
      posthog.capture('Line of credit modal - open checking account button click');
      setIsTermsModalOpen(true);
    }
  };

  const handleApplyForLineOfCreditFromTermsModal = async () => {
    if (!isDepositCustomer && isTermsAccepted) {
      createDepositAccount();
      posthog.capture('Terms & Conditions - Apply for LOC Button Click');
    }
  };

  const closeTermsModal = () => {
    setIsTermsModalOpen(false);
  };

  useEffect(() => {
    if (isCreateDepositAccountSuccess) {
      setIsTermsModalOpen(false);
      window.open('https://toolbox.direct.vaya.finance/login', '_blank');
    }
  }, [isCreateDepositAccountSuccess]);

  return (
    <>
      <Dialog open={isOpen} fullWidth={true} maxWidth="sm" onClose={handleModalClose}>
        <DialogTitle sx={{ m: 0, p: 3, borderBottom: '1px solid #ddd' }}></DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleModalClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <p className="text-center font-2xl">💡</p>
          <h2 className="text-center font-bold mb-1">Pay with Line of Credit</h2>
          <p className="mb-1 text-center">
            Did you know you can use a Line of Credit from our partner to pay off your charge card
            balance and access more capital to run your business. Here's how it works 👇
          </p>
          <ol>
            <li className="text-bold">1. Apply for a line of credit through our partner, Vaya</li>
            <li className="text-bold">2. Deposit your funds into your Toolbox checking account</li>
            <li className="text-bold">
              3. Pay off your charge card <i>instantly!</i>
            </li>
          </ol>
          <p className="mt-1 text-center">
            Learn more about how a line of credit works with your Toolbox account or apply now!
          </p>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', paddingBottom: '1.5rem' }}>
          <Button
            onClick={handleLearnMoreClick}
            color="secondary"
            variant="outlined"
            data-cy="locLearnMoreButton"
          >
            Learn More
          </Button>
          <Button
            onClick={handleApplyForLineOfCreditFromMainModal}
            color="primary"
            variant="contained"
            data-cy="applyForLineOfCredit"
          >
            Apply for Line of Credit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isTermsModalOpen} fullWidth={true} maxWidth="sm" onClose={closeTermsModal}>
        <DialogTitle
          sx={{ m: 0, p: 2, borderBottom: '1px solid #ddd' }}
          id="customized-dialog-title"
        >
          Accept Terms & Conditions
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeTermsModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <p className="text-center">
            Next, we'll send you to our partner Vaya to Apply for a Line of Credit. If approved,
            you'll be able to make draw requests directly to your Toolbox-powered checking account.
          </p>
          <p className="mt-1 text-center">
            <Checkbox
              checked={isTermsAccepted}
              onChange={event => setIsTermsAccepted(event.target.checked)}
            />
            By checking this box, you are accepting our{' '}
            <a
              href={depositAccountAgreementUrl}
              target="_blank"
              className="font-bold text-dark-green"
            >
              Banking Terms
            </a>
          </p>
          {isCreateDepositAccountError && (
            <p className="text-center text-red-500">
              {createDepositAccountError as unknown as string}
            </p>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            onClick={closeTermsModal}
            color="secondary"
            variant="outlined"
            data-cy="locTermsCancelButton"
          >
            Cancel
          </Button>
          <Button
            onClick={handleApplyForLineOfCreditFromTermsModal}
            color="primary"
            variant="contained"
            data-cy="locTermsApplyButton"
            disabled={!isTermsAccepted}
          >
            Apply for Line of Credit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PayWithLineOfCreditModal;
