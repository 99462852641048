import React, { Dispatch, Fragment, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import {
  TransactionPageAction,
  TransactionPageActionType,
} from 'app/pages/dashboard/transactions/reducer';
import { formatCentsAsDollars } from 'helpers/formattings';
import { standardError } from 'helpers/base';
import { Transaction } from '../../../features/transactions/types';
import CategoryIcon from '../../../features/categories/components/CategoryIcon';
import TransactionRichDataCell from 'features/transactions/components/TransactionRichDataCell';

interface TransactionsTableProps {
  transactions: Transaction[];
  loadTransactions?: () => void;
  dispatcher: Dispatch<TransactionPageAction>;
}

const tableHeadCellStyles: React.CSSProperties = {
  padding: '16px 24px',
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 100%), #F7F7F7',
};

const tableRowCellStyles: React.CSSProperties = {
  padding: '16px 24px',
  borderBottom: '1px solid #EEE',
  background: '#FFF',
  textTransform: 'capitalize',
};

const descriptionStyle: React.CSSProperties = {
  ...tableRowCellStyles,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const defaultWidth = '191px';
const typeWidth = '81px';
const detailsWidth = '96px';

const TransactionsTable: React.FC<TransactionsTableProps> = ({
  transactions,
  dispatcher,
  loadTransactions,
}) => {
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const [matchesMobile, setMatchesMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setMatchesMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className="flex-row mt-1"
      style={{
        padding: '0 32px 32px 32px',
      }}
      data-cy="recentActivity"
    >
      <div style={{ maxWidth: '100%' }}>
        <TableContainer style={{ width: '100%', border: '1px solid #EEE' }}>
          <Table size="small" style={{ width: '100%', tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                {matchesMobile ? (
                  <></>
                ) : (
                  <>
                    <TableCell style={{ ...tableHeadCellStyles, width: defaultWidth }}>
                      Description
                    </TableCell>
                    <TableCell style={{ ...tableHeadCellStyles, width: defaultWidth }}>
                      Date
                    </TableCell>
                    <TableCell style={{ ...tableHeadCellStyles, width: defaultWidth }}>
                      Amount
                    </TableCell>
                    <TableCell style={{ ...tableHeadCellStyles, width: typeWidth }}>Type</TableCell>
                    <TableCell style={{ ...tableHeadCellStyles, width: defaultWidth }}>
                      Category
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((t: Transaction) => (
                <Fragment key={t.id}>
                  <TableRow key={t.id}>
                    {matchesMobile ? (
                      <>
                        <TableCell style={{ ...tableRowCellStyles, padding: 0 }}>
                          <TransactionRichDataCell transaction={t} />
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell style={tableRowCellStyles}>
                          <TransactionRichDataCell transaction={t} />
                        </TableCell>
                        <TableCell style={tableRowCellStyles}>
                          {dayjs(t.created_at).format('MMM DD, YYYY')}
                        </TableCell>
                        <TableCell style={tableRowCellStyles}>
                          {formatCentsAsDollars(t.amount)}
                        </TableCell>
                        <TableCell style={tableRowCellStyles}>{t.account_type}</TableCell>
                        <TableCell style={tableRowCellStyles}>
                          {t.category && <CategoryIcon category={t.category} className="mr-1" />}
                          {t.category}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                  {matchesMobile && expandedRow === t.id && (
                    <TableRow>
                      <TableCell colSpan={2} style={{ backgroundColor: '#F9FAFB' }}>
                        <div style={{ paddingLeft: '24px' }}>
                          Description: {t.merchant}
                          <br />
                          Amount: {formatCentsAsDollars(t.amount)}
                          <br />
                          Type: {t.type}
                          <br />
                          Category: {t.category ? t.category : 'N/A'}
                          <br />
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default TransactionsTable;
