import { Autocomplete, Chip, TextField } from '@mui/material';
import {
  TransactionPageAction,
  TransactionPageState,
} from 'app/pages/dashboard/transactions/reducer';
import React from 'react';
import { Dispatch, memo } from 'react';

interface CustomAutocompleteProps {
  dispatcher: Dispatch<TransactionPageAction>;
  value: string;
  dataCy?: string;
  onChange(choice: any): void;

  options: any[];
  title: string;
  multiple?: boolean;
  className?: string;
}

const CustomAutoComplete = memo(function CustomAutoComplete(props: CustomAutocompleteProps) {
  if (!props.multiple) {
    return (
      <Autocomplete
        freeSolo
        data-cy={props.dataCy}
        className={props.className}
        value={props.value}
        size="small"
        getOptionLabel={(p: string) => p}
        onChange={(event: any, choice: string | null) => {
          props.onChange(choice);
        }}
        options={props.options}
        sx={{ width: 180 }}
        renderInput={params => <TextField {...params} placeholder={props.title} />}
      />
    );
  }

  const [value, setValue] = React.useState<any>([]);
  return (
    <Autocomplete
      className={props.className}
      value={value}
      multiple
      renderTags={(value: string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <Chip variant="outlined" color="primary" label={option} {...getTagProps({ index })} />
        ))
      }
      size="small"
      onChange={(event, newValue) => {
        setValue(newValue);
        props.onChange(newValue.slice(-1)[0]);
      }}
      getOptionLabel={(p: string) => p}
      options={props.options}
      sx={{ width: 180 }}
      renderInput={params => <TextField {...params} label={props.title} />}
    />
  );
});

export default CustomAutoComplete;
