import { usePostIntegrationInterest } from '../../companies/data/postIntegrationInterest';
import posthog from 'posthog-js';
import IntegrationConnectionCard from './IntegrationConnectionCard';
import ns_logo from 'app/assets/images/ns.png';

interface NetSuiteConnectionCardProps {}

const NetSuiteConnectionCard = (props: NetSuiteConnectionCardProps) => {
  const { mutate: postInterest, isPending, isSuccess } = usePostIntegrationInterest();

  const handleConnection = () => {
    postInterest('netsuite');
    posthog.capture('Clicked Netsuite Button');
  };

  return (
    <IntegrationConnectionCard
      title="Netsuite"
      subtitle="Coming Soon"
      onConnect={handleConnection}
      connectLabel="Indicate Interest"
      logo={ns_logo}
      disabled={isSuccess || isPending}
    />
  );
};

export default NetSuiteConnectionCard;
