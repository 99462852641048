import { CreditCard } from 'interfaces/baseInterfaces';
import { ADMIN_JOB_TITLES } from '../../../../constants/system';

export type CardPageState = {
  cards: CreditCard[];
  requestCardPopupOpen: boolean;
  confirmPopupOpen: boolean;
  activationCard: null | CreditCard;
  createCardRequest: boolean;
  cardToDelete: null | CreditCard;
  unitCardSensitiveData: null | CreditCard;
  debitCardSetPin: null | CreditCard;
  cardLimitsPopupOpen: boolean;
  cardLimitsCard: null | CreditCard;
  cardToFreeze: null | CreditCard;
  cardToUnfreeze: null | CreditCard;
  cardTypePopupOpen: boolean;
  cardType: string;
  cardProduct: string;
  sensitiveCardInfoPopupOpen: boolean;
  sensitiveCardNumber: string;
  sensitiveCardCvc: string;
  cardExpMonth: number;
  cardExpYear: number;
  loading: boolean;
  loadingActiveCards: boolean;
  loadingPendingCards: boolean;
  loadingCanceledCards: boolean;
  paymentPopupOpen: boolean;
  cardholderLimit: number;
  cardAssignmentPopupCard: null | CreditCard;
  shouldShowCardToUpdate: boolean;
  isAdmin: boolean;
  currentTab: number;
  activeCards: CreditCard[];
  canceledCards: CreditCard[];
  inactiveCards: CreditCard[];
  frozenCards: CreditCard[];
  selectedCardDetails: CreditCard;
  confirmationCodePopupOpen: boolean;
  verificationCode: string;
  vgsDebitCardSetPinPopupOpen: boolean;
  vgsDebitCardChangePinPopupOpen: boolean;
  vgsPopupOpen: boolean;
  unitCustomerToken: string;
  vgsInputLoaded: boolean;
  verificationCodeSubmitLoading: boolean;
  deleteCardLoading: boolean;
  requestedCardMonthlyLimit: number | null;
  requestCardTransactionLimit: number | null;
  vgsShowSensitivePopupOpen: boolean;
  activateCardCvvValid: boolean;
  activateCardExpDateValid: boolean;
  debitCardSetPinValid: boolean;
  debitCardChangePinValid: string;
  debitCardChangePinSubmitLoading: boolean;
  vgsDebitCardSetPinLoaded: boolean;
  vgsDebitCardChangePinLoaded: boolean;
  showImmediateActionRequiredModal: boolean;
  requestCardDesignationPopupOpen: boolean;
  requestCardDesignationType: string;
  inviteTeamMemberPopupOpen: boolean;
  inviteEmail: string;
  inviteRole: string;
  inviteTitle: string;
  roleOptions: string[];
  titleOptions: string[];
  isSendingInvite: boolean;
  showOatfiDebitModal: boolean;
};

export type CardPageAction = {
  readonly type: string;
  readonly payload: any;
};

export enum CardPageActionType {
  Cards = 'cards',
  RequestCardPopupOpen = 'requestCardPopupOpen',
  ConfirmPopupOpen = 'confirmPopupOpen',
  ActivationCard = 'activationCard',
  CardToDelete = 'cardToDelete',
  CreateCardRequest = 'createCardRequest',
  CardLimitsPopupOpen = 'cardLimitsPopupOpen',
  CardLimitsCard = 'cardLimitsCard',
  CardTypePopupOpen = 'cardTypePopupOpen',
  CardType = 'cardType',
  CardProduct = 'cardProduct',
  SensitiveCardInfoPopupOpen = 'sensitiveCardInfoPopupOpen',
  SensitiveCardNumber = 'sensitiveCardNumber',
  SensitiveCardCvc = 'sensitiveCardCvc',
  CardExpMonth = 'cardExpMonth',
  CardExpYear = 'cardExpYear',
  Loading = 'loading',
  LoadingActiveCards = 'loadingActiveCards',
  LoadingPendingCards = 'loadingPendingCards',
  LoadingCanceledCards = 'loadingCanceledCards',
  PaymentPopupOpen = 'paymentPopupOpen',
  CardholderLimit = 'cardholderLimit',
  CardAssignmentPopupCard = 'cardAssignmentPopupCard',
  ShouldShowCardToUpdate = 'shouldShowCardToUpdate',
  IsAdmin = 'isAdmin',
  CurrentTab = 'currentTab',
  ActiveCards = 'activeCards',
  CanceledCards = 'canceledCards',
  InactiveCards = 'inactiveCards',
  FrozenCards = 'frozenCards',
  SelectedCardDetails = 'selectedCardDetails',
  ConfirmationCodePopupOpen = 'confirmationCodePopupOpen',
  InviteTeamMemberPopupOpen = 'inviteTeamMemberPopupOpen',
  TitleOptions = 'titleOptions',
  InviteRole = 'inviteRole',
  InviteTitle = 'inviteTitle',
  InviteEmail = 'inviteEmail',
  IsSendingInvite = 'isSendingInvite',
  AddMemberError = 'addMemberError',
  VerificationCode = 'verificationCode',
  VgsPopupOpen = 'vgsPopupOpen',
  VgsDebitCardSetPinPopupOpen = 'vgsDebitCardSetPinPopupOpen',
  VgsDebitCardChangePinPopupOpen = 'vgsDebitCardChangePinPopupOpen',
  VgsShowSensitivePopupOpen = 'vgsShowSensitivePopupOpen',
  UnitCustomerToken = 'unitCustomerToken',
  VgsInputLoaded = 'vgsInputLoaded',
  VgsDebitCardSetPinLoaded = 'vgsDebitCardSetPinLoaded',
  VgsDebitCardChangePinLoaded = 'vgsDebitCardChangePinLoaded',
  VerificationCodeSubmitLoading = 'verificationCodeSubmitLoading',
  DeleteCardLoading = 'deleteCardLoading',
  RequestCardMonthlyLimit = 'requestedCardMonthlyLimit',
  RequestCardTransactionLimit = 'requestCardTransactionLimit',
  UnitCardSensitiveData = 'unitCardSensitiveData',
  CardToFreeze = 'cardToFreeze',
  CardToUnfreeze = 'cardToUnfreeze',
  ActivateCardCvvValid = 'activateCardCvvValid',
  ActivateCardExpDateValid = 'activateCardExpDateValid',
  DebitCardSetPinValid = 'debitCardSetPinValid',
  DebitCardChangePinValid = 'debitCardChangePinValid',
  DebitCardChangePinSubmitLoading = 'debitCardChangePinSubmitLoading',
  ShowImmediateActionRequiredModal = 'showImmediateActionRequiredModal',
  RequestCardDesignationPopupOpen = 'requestCardDesignationPopupOpen',
  RequestCardDesignationType = 'requestCardDesignationType',
  DebitCardSetPin = 'debitCardSetPin',
  ShowOatfiDebitModal = 'showOatfiDebitModal',
}

export const cardPageInitialState: CardPageState = {
  cards: [],
  requestCardPopupOpen: false,
  confirmPopupOpen: false,
  activationCard: null,
  cardToDelete: null,
  cardLimitsPopupOpen: false,
  createCardRequest: false,
  cardLimitsCard: null,
  cardTypePopupOpen: false,
  cardType: '',
  cardProduct: 'debit',
  sensitiveCardInfoPopupOpen: false,
  sensitiveCardNumber: '',
  sensitiveCardCvc: '',
  cardExpMonth: 0,
  cardExpYear: 0,
  loading: true,
  loadingActiveCards: true,
  loadingPendingCards: true,
  loadingCanceledCards: true,
  paymentPopupOpen: false,
  cardholderLimit: 0,
  cardAssignmentPopupCard: null,
  shouldShowCardToUpdate: false,
  isAdmin: false,
  currentTab: 0,
  activeCards: [],
  canceledCards: [],
  inactiveCards: [],
  frozenCards: [],
  selectedCardDetails: {} as CreditCard,
  confirmationCodePopupOpen: false,
  verificationCode: '',
  vgsPopupOpen: false,
  vgsDebitCardSetPinPopupOpen: false,
  vgsDebitCardSetPinLoaded: false,
  vgsDebitCardChangePinLoaded: false,
  vgsDebitCardChangePinPopupOpen: false,
  unitCustomerToken: '',
  vgsInputLoaded: false,
  verificationCodeSubmitLoading: false,
  deleteCardLoading: false,
  requestedCardMonthlyLimit: null,
  requestCardTransactionLimit: null,
  vgsShowSensitivePopupOpen: false,
  unitCardSensitiveData: null,
  debitCardSetPin: null,
  cardToFreeze: null,
  cardToUnfreeze: null,
  activateCardCvvValid: false,
  activateCardExpDateValid: false,
  debitCardSetPinValid: false,
  debitCardChangePinValid: '',
  debitCardChangePinSubmitLoading: false,
  showImmediateActionRequiredModal: false,
  requestCardDesignationPopupOpen: false,
  requestCardDesignationType: '',
  inviteTeamMemberPopupOpen: false,
  roleOptions: ['admin', 'employee', 'bookkeeper'],
  titleOptions: ADMIN_JOB_TITLES,
  inviteEmail: '',
  inviteRole: '',
  inviteTitle: '',
  isSendingInvite: false,
  showOatfiDebitModal: false,
};

export function cardPageReducer(state: CardPageState, action: CardPageAction) {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
}
