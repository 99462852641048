import { CircularProgress, CircularProgressProps } from '@mui/material';
import { memo } from 'react';

const Spinner = memo(function Spinner(props: CircularProgressProps) {
  return (
    <div className="flex justify-center items-center h-screen w-screen">
      <CircularProgress {...props} />
    </div>
  );
});

export default Spinner;
