export enum RutterPlatform {
  QUICKBOOKS = 'QUICKBOOKS',
  NETSUITE = 'NETSUITE',
}

export const rutterAccountTypes = [
  'accounts_receivable',
  'bank',
  'fixed_asset',
  'other_asset',
  'other_current_asset',
  'liability',
  'equity',
  'expense',
  'other_expense',
  'income',
  'other_income',
  'credit_card',
  'cost_of_goods_sold',
  'other_current_liability',
  'long_term_liability',
  'non_posting',
  'unknown',
];

export type RutterAccountType = (typeof rutterAccountTypes)[number];

export interface RutterConnection {
  id: number;
  company_id: number;
  connection_id: string;
  public_token: string;
  platform: RutterPlatform;
  store_unique_name?: string;
  is_ready?: boolean;
  is_disabled?: boolean;
  disabled_reason?: string;
  needs_update?: boolean;
  last_synced_at: string;
}

export interface RutterConnectionStatus {
  status: 'ready' | 'syncing';
  is_initial_sync_completed: boolean;
  is_disabled: boolean;
  disabled_reason: string;
  needs_update: boolean;
  last_synced_at: string;
  historical_sync_completed: boolean;
  platform: RutterPlatform;
  accounts_mapped: boolean;
}

export type RutterConnectionStatusValue =
  | 'ready'
  | 'syncing'
  | 'disabled'
  | 'needs update'
  | 'accounts not mapped';

export interface RutterAccount {
  id: number;
  company_id: number;
  rutter_id: string;
  account_type: string;
  platform_id: string;
  category: string;
  status: string;
  balance?: number;
  currency_code?: string;
  name?: string;
  nominal_code?: string;
  created_at: string;
  updated_at?: string;
  last_synced_at?: string;
}

export interface RutterAccountMapping {
  id: number;
  rutter_account_id: number;
  platform: RutterPlatform;
  company_id: number;
  account_mapping_type: AccountMappingType;
  rutter_account: RutterAccount;
}

export interface RutterCategory {
  id: string;
  platform_id: string;
  name?: string;
  parent_id?: string;
  has_children?: boolean;
  status?: 'active' | 'archived';
  created_at?: string;
  updated_at?: string;
  last_synced_at: string;
  platform_data?: {
    id?: string | number;
    data: unknown;
  };
}

export enum AccountMappingType {
  ChargeCardExpense = 'ChargeCardExpense',
  ChargeCardPayment = 'ChargeCardPayment',
  DepositAccount = 'DepositAccount',
}
