import { useAuthStore } from 'features/auth/data/authStore';
import currentEnv from '../config/environment';
import { capitalize, createClone, lastProperty, objectToUrlParams } from '../helpers/base';
import { MyUser } from '../interfaces/baseInterfaces';

export interface ApiError {
  error: unknown;
}

class BaseService {
  public MAIN_API = currentEnv().MAIN_API;

  public saveAuthToken(authToken: string, appUser: MyUser) {
    if (authToken) {
      useAuthStore.setState({ authToken, appUser });
    }
  }

  public loadAuthToken(): string | null {
    return useAuthStore.getState().authToken;
  }

  public clearAuthToken(setUserAsNull?: boolean) {
    useAuthStore.setState({ authToken: '', appUser: setUserAsNull == true ? null : undefined });
  }

  public handle401 = (response: Response) => {
    if (response.status !== 401) return null;

    this.clearAuthToken();
    const signInPage = window.location.origin + '/web/sign-in';
    const signUpPage = window.location.origin + '/web/onboarding/account-owner/sign-up';
    const inviteSignUpPage = window.location.origin + '/web/onboarding/invite/sign-up';
    const resetPasswordPage = window.location.origin + '/web/reset-password';

    if (
      !window.location.href.startsWith(signInPage) &&
      !window.location.href.startsWith(signUpPage) &&
      !window.location.href.startsWith(inviteSignUpPage) &&
      !window.location.href.startsWith(resetPasswordPage)
    ) {
      return window.location.assign(signInPage);
    }
  };

  private handleError = (res: { error: { any: string[] } | string }): ApiError => {
    if (typeof res.error === 'string') return res;
    const values = Object.values(res.error);
    const keys = Object.keys(res.error);
    const err = {
      error: keys
        .map((key, i) => {
          const message = Array.isArray(values[i])
            ? values[i].join(', ')
            : Object.values(values[i]).join(', ');
          return capitalize(key) + ': ' + message;
        })
        .join('\n'),
    };
    if (typeof err === 'object') {
      return {
        error: lastProperty(err),
      };
    }
    return err;
  };

  // check if type of response is JSON
  private handleResponseType = async (res: Response) => {
    const contentType = res.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return await res.json();
    }
    return await res.blob();
  };

  // define request headers
  private headers = () => {
    return {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': '*',
      'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      Authorization: `Bearer ${this.loadAuthToken()}`,
    };
  };

  getJSON = async <T = any>(url: string, params?: Record<string, unknown>) => {
    const res = await fetch(this.MAIN_API + url + `${objectToUrlParams(params)}`, {
      method: 'GET',
      headers: this.headers(),
      credentials: 'include',
    });
    this.handle401(res);
    const response = await this.handleResponseType(res);
    if (response && response.error) {
      return this.handleError(response);
    }
    return response as T;
  };

  postJSON = async <T = any>(url: string, data: any, handle401 = true) => {
    try {
      const res = await fetch(this.MAIN_API + url, {
        method: 'POST',
        headers: this.headers(),
        body: JSON.stringify(data),
        credentials: 'include',
      });

      if (handle401) {
        this.handle401(res);
      }

      const response = await this.handleResponseType(res);
      if (response && response.error) {
        return this.handleError(response);
      }
      return response as T;
    } catch (e) {
      // log('Error doing postJSON in base.service.ts: ', e)
      throw e;
    }
  };

  patchJSON = async (url: string, data: any) => {
    try {
      const res = await fetch(this.MAIN_API + url, {
        method: 'PATCH',
        headers: this.headers(),
        body: JSON.stringify(data),
        credentials: 'include',
      });
      const response = await this.handleResponseType(res);
      if (response && response.error && typeof response.error === 'object') {
        return this.handleError(response);
      }
      return response;
    } catch (e) {
      // log('Error doing patchJSON in base.service.ts: ', e)
      throw e;
    }
  };

  deleteJSON = async (url: string) => {
    try {
      const res = await fetch(this.MAIN_API + url, {
        method: 'DELETE',
        headers: this.headers(),
        credentials: 'include',
      });
      const response = await this.handleResponseType(res);
      if (response && response.error && typeof response.error === 'object') {
        return this.handleError(response);
      }
      return response;
    } catch (e) {
      // log('Error doing patchJSON in base.service.ts: ', e)
      throw e;
    }
  };

  formData = async (url: string, data: FormData, method?: 'post' | 'PATCH') => {
    try {
      const res = await fetch(this.MAIN_API + url, {
        method: method || 'post',
        credentials: 'include',
        body: data,
      });
      const response = await this.handleResponseType(res);
      if (response && response.error) {
        return this.handleError(response);
      }
      return response;
    } catch (e) {
      // log('Error doing postFormData in base.service.ts: ', e)
      throw e;
    }
  };

  postFormData = async (url: string, data: FormData, method?: 'post' | 'PATCH') => {
    try {
      const init_headers: any = this.headers();
      const headers: any = createClone(init_headers);
      delete headers['Content-Type'];

      const res = await fetch(this.MAIN_API + url, {
        method: method || 'post',
        credentials: 'include',
        body: data,
        headers,
      });

      if (res.status == 413) {
        return {
          error: 'File is too large',
        };
      }

      const response = await this.handleResponseType(res);

      if (response && response.error && typeof response.error === 'object') {
        return this.handleError(response);
      }
      return response;
    } catch (e) {
      // log('Error doing postFormData in base.service.ts: ', e)
      throw e;
    }
  };
}

const baseService = new BaseService();

export default baseService;
