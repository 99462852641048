import { Outlet } from 'react-router-dom';

interface VendorPaymentLayoutProps {}

const VendorPaymentLayout = (props: VendorPaymentLayoutProps) => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default VendorPaymentLayout;
