import { AccountBalance, ContentCopy, SyncAlt } from '@mui/icons-material';
import { Dialog, DialogTitle, IconButton, DialogContent, Tooltip } from '@mui/material';
import LoadingButton from 'app/components/LoadingButton';
import { maskAccountNumber } from 'app/pages/dashboard/vendors/pay/helper';
import { DepositPageAction, DepositPageActionType } from 'app/pages/dashboard/deposit/reducer';
import { DepositAccount } from 'interfaces/account';
import posthog from 'posthog-js';

interface AchFundingDialogProps {
  updateState: React.Dispatch<DepositPageAction>;
  isOpen: boolean;
  account: DepositAccount;
}

const AchFundingDialog = (props: AchFundingDialogProps) => {
  const { isOpen, updateState, account } = props;

  const handleGoBack = () => {
    updateState({ type: DepositPageActionType.AchFundingPopupOpen, payload: false });
    updateState({ type: DepositPageActionType.AddFundsPopupOpen, payload: true });
  };

  const handleShowMoreDetails = () => {
    posthog.capture('Banking Page - Actions - ACH Credit/Wire Transfer - Show More Details Click');
    updateState({ type: DepositPageActionType.AchFundingPopupOpen, payload: false });
    updateState({ type: DepositPageActionType.AchAccountDetailsOpen, payload: true });
  };

  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <p className="text-bold font-lg">ACH Credit / Wire Transfer</p>
        <p className="text-normal">Arrives in 1-2 business days</p>
      </DialogTitle>
      <IconButton
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <SyncAlt fontSize="medium" className="rounded-circle bg-gray-10 p-1" />
      </IconButton>

      <DialogContent>
        <p className="text-[11px] mb-[5px]">RECEIVING ACCOUNT</p>
        <section className="flex items-center px-1 py-1 rounded-md bg-gray-100">
          <AccountBalance fontSize="medium" className="rounded-circle bg-gray-10 p-1.5 mr-2" />
          <div className="text-[12px]">
            <p className="font-bold text-dark-green">
              Checking {maskAccountNumber(account?.account_number)}
            </p>
            <p className="text-[11px]">{account.available}</p>
          </div>
        </section>
        <section className="flex items-center justify-between mt-2">
          <div className="w-[46%]">
            <p className="text-[11px] mb-[3px]">ROUTING NUMBER</p>
            <p className="bg-gray-100 px-1 py-[6px] rounded-md  flex items-center justify-between">
              <span>{account.routing_number}</span>
              <Tooltip title="Copy routing number" placement="top">
                <ContentCopy
                  fontSize="small"
                  className="text-dark-green cursor-pointer"
                  onClick={() => {
                    posthog.capture(
                      'Banking Page - Actions - ACH Credit/Wire Transfer - Copy Routing Number Click',
                    );
                    navigator.clipboard.writeText(account.routing_number);
                  }}
                />
              </Tooltip>
            </p>
          </div>
          <div className="w-[46%]">
            <p className="text-[11px] mb-[3px]">ACCOUNT NUMBER</p>
            <p className="bg-gray-100 px-1 py-[6px] rounded-md flex items-center justify-between">
              <span>{maskAccountNumber(account.account_number, true)}</span>
              <Tooltip title="Copy account number" placement="top">
                <ContentCopy
                  fontSize="small"
                  className="text-dark-green cursor-pointer"
                  onClick={() => {
                    posthog.capture(
                      'Banking Page - Actions - ACH Credit/Wire Transfer - Copy Account Number Click',
                    );
                    navigator.clipboard.writeText(account.account_number);
                  }}
                />
              </Tooltip>
            </p>
          </div>
        </section>
        <section className="flex items-center justify-between mt-2">
          <LoadingButton variant="outlined" onClick={handleGoBack}>
            Go Back
          </LoadingButton>
          <LoadingButton onClick={handleShowMoreDetails}>Show More Details</LoadingButton>
        </section>
      </DialogContent>
    </Dialog>
  );
};

export default AchFundingDialog;
