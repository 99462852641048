import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { GiftIcon } from '../Icon/fontawesome';

interface GiftButtonProps {
  link?: string;
  label?: string;
}

const GiftButton: React.FC<GiftButtonProps> = ({
  link = 'https://toolbox.tolt.io/login',
  label = 'Earn $2,000',
}) => {
  const handleClick = () => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  return (
    <Button
      onClick={handleClick}
      sx={{
        borderRadius: '4px',
        border: '1px solid #073424',
        background: 'linear-gradient(90deg, #073424 0%, #0B4933 70.62%, #0C5239 100%)',
        boxShadow: '0px 4px 24px 0px rgba(255, 255, 255, 0.06)',
        display: 'flex',
        width: 260,
        p: '10px 16px',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        gap: '8px',
      }}
    >
      <GiftIcon style={{ opacity: 0.5 }} />
      <Box
        component="span"
        sx={{
          color: '#E9FBF0',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '140%',
        }}
      >
        {label}
      </Box>
    </Button>
  );
};

export default GiftButton;
