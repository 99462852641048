import { useState } from 'react';
import { Pagination } from '../../../../../common/pagination';
import { useGetPayments } from '../../../data/getPayments';
import {
  Pagination as MuiPagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import { titleCase } from '../../../../../helpers/base';
import { formatCentsAsDollars } from '../../../../../helpers/formattings';
import TableSkeletonLoader from '../../../../../app/components/SkeletonLoaders/TableSkeletonLoader';
import Show from '../../../../../app/components/Show';

interface TableColumns {
  label: string;
}

interface AccountingPaymentsTableProps {}

const AccountingPaymentsTable = (props: AccountingPaymentsTableProps) => {
  const [pagination, setPagination] = useState<Pagination>({ page: 1, per_page: 5 });
  const { data, isLoading, isSuccess } = useGetPayments(pagination);
  const payments = data?.data ?? [];
  const paymentsCount = data?.count ?? 0;

  const columns: TableColumns[] = [
    { label: 'Date' },
    { label: 'Status' },
    { label: 'Amount' },
    { label: 'Settlement Date' },
  ];

  const onPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const page_size = event.target.value;
    setPagination(prevPagination => ({ ...prevPagination, per_page: Number(page_size) }));
  };

  return (
    <>
      <Show when={isLoading}>
        <TableSkeletonLoader rows={pagination.per_page} columns={columns.length} />
      </Show>
      <Show when={isSuccess}>
        <TableContainer className="table border rounded">
          <Table>
            <TableHead className="bg-gray-100">
              <TableRow>
                {columns?.map(column => (
                  <TableCell key={column.label}>
                    <span className="font-bold">{column.label}</span>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {payments?.map(payment => {
                return (
                  <TableRow key={payment.created_at}>
                    <TableCell datatype="statement-row-period-start-value">
                      {dayjs(payment.created_at).format('MM/DD/YYYY')}
                    </TableCell>
                    <TableCell>{titleCase(payment.status)}</TableCell>
                    <TableCell datatype="statement-row-period-start-value">
                      {formatCentsAsDollars(payment.amount)}
                    </TableCell>
                    <TableCell>
                      {payment.settlement_date
                        ? dayjs(payment.settlement_date).format('MM/DD/YYYY')
                        : ''}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <MuiPagination
          className="mt-1"
          count={Math.ceil(paymentsCount / pagination.per_page)}
          showFirstButton
          showLastButton
          page={pagination.page}
          onChange={(_, page) => {
            setPagination(prev => ({ ...prev, page }));
          }}
          sx={{
            '& .MuiPagination-ul': { 'justify-content': 'center' },
          }}
        />
      </Show>
    </>
  );
};

export default AccountingPaymentsTable;
