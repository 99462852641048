import { memo } from 'react';
import ChipImg from './chip.svg';
import TopLeftImg from './LeftTop.svg';
import TopRightImg from './RightTopImg.svg';
import BottomCenterImg from './BottomCenterImg.svg';
import SemiImg from './background.svg';
import VisaLogo from './cclogo.svg';
import { formatCentsAsDollars } from 'helpers/formattings';
import { CreditCard as ICreditCard } from 'interfaces/baseInterfaces';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import styled from 'styled-components';

interface CreditCardProps {
  creditCard: ICreditCard;
}

const BorderLinearProgress = styled(LinearProgress)({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#444446',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,

    backgroundColor: '#6FCF97',
  },
});

const ImageOverlay = styled.img`
  position: absolute;
  z-index: 0;
`;

const BottomCenterOverlay = styled.img`
  position: absolute;
  z-index: 2;
`;

const BackgroundImageOverlay = styled.img`
  position: absolute;
  z-index: 1;
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 3;
`;

const CreditCard = memo(function CreditCard(props: CreditCardProps) {
  const { creditCard } = props;

  const totalBalance = creditCard?.max_spent_limit;
  const progress = totalBalance ? (creditCard.spent / totalBalance) * 100 : 0;

  return (
    <div
      data-id="card-component"
      data-cy="cardComponent"
      className="CreditCard-component rounded-md p-4"
      style={{
        position: 'relative',
        background: 'linear-gradient(58deg, #000 2.15%, #626365 97.85%)',
        borderRadius: '2px',
        color: '#fff',
        width: '363.637px',
        height: '229.222px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        opacity: creditCard?.status === 'frozen' ? 0.5 : 1,
      }}
    >
      <BackgroundImageOverlay
        src={SemiImg}
        style={{ bottom: 0, left: 0, width: '100%', height: '86%' }}
        data-cy="cardBackgroundImage"
      />
      <ImageOverlay
        src={TopLeftImg}
        style={{ top: 0, left: 0, width: '34%', zIndex: 2, opacity: 0.6 }}
        data-cy="cardTopLeftImage"
      />
      <ImageOverlay src={TopRightImg} style={{ top: 0, right: 0, width: '46%', opacity: 0.9 }}
        data-cy="cardTopRightImage"
       />
      <BottomCenterOverlay
        src={BottomCenterImg}
        style={{
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          width: '100%',
          height: '33%',
          opacity: 0.8,
        }}
        data-cy="cardBottomCenterImage"
      />

      <ContentContainer>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img src={ChipImg} alt="Chip" style={{ width: '41px', marginRight: '8px' }} />
            <div style={{ marginLeft: 8 }}>
              <div
                style={{
                  fontSize: '8px',
                  opacity: 0.7,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  letterSpacing: 0.32,
                }}
                data-cy="cardHolderName"
              >
                Card Holder Name
              </div>
              <div style={{ fontSize: '14px' }}
              data-cy="cardHolderNameValue"
              >
                {`${creditCard?.first_name} ${creditCard?.last_name}` || 'Cardholder Name'}
              </div>
            </div>
          </div>
          <img src={VisaLogo} alt="Visa" 
          style={{ width: '60px' }}
          data-cy="cardLogo"
           />
        </div>

        <div style={{ fontSize: '22px', letterSpacing: '1px', margin: '28px 0 18px 0' }}
        data-cy="cardNumber"
        >
          <span style={{ fontSize: 18 }}>**** **** **** </span>
          <span>{creditCard?.last4}</span>
        </div>

        <div className="flex justify-between mt-1" style={{ fontSize: '14px', opacity: 0.9 }}
        data-cy="cardAvailableBalanceLabel"
        >
          <div>Available</div>
          <div className="font-xl font-bold text-white" data-cy="cardAvailableBalance">
            {formatCentsAsDollars(creditCard.max_spent_limit)}
          </div>
        </div>

        <BorderLinearProgress variant="determinate" value={progress} />

        <div style={{ fontSize: '11px', opacity: 0.9, marginTop: '12px' }} 
        data-cy="cardUsedBalanceLabel"
        >
          {' '}
          <span style={{ opacity: 0.8 }}>Used balance: </span>
          <span
          data-cy="cardUsedBalance"
          >{formatCentsAsDollars(creditCard?.spent)}</span>
        </div>
      </ContentContainer>
    </div>
  );
});

export default CreditCard;
