import { Button, Paper, Skeleton } from '@mui/material';
import Show from '../../../../app/components/Show';
import './style.scss';
import { RutterConnectionStatusValue } from '../../rutter/rutter.types';

interface IntegrationConnectionCardProps {
  title: string;
  subtitle?: string;
  onConnect: () => void;
  onViewIntegration?: () => void;
  disabled?: boolean;
  connectLabel: string;
  logo: string;
  isConnected?: boolean;
  connectionStatus?: RutterConnectionStatusValue;
  isLoading?: boolean;
  isReadyForConnection?: boolean;
}

const IntegrationConnectionCard = (props: IntegrationConnectionCardProps) => {
  const {
    title,
    subtitle,
    onConnect,
    connectLabel,
    disabled,
    logo,
    isConnected = false,
    isLoading = false,
    isReadyForConnection = false,
    connectionStatus,
    onViewIntegration,
  } = props;

  const handleViewIntegration = () => {
    if (onViewIntegration) {
      onViewIntegration();
    }
  };

  return (
    <div>
      <Paper variant="outlined" sx={{ padding: '1rem', height: '100%', position: 'relative' }}>
        <div className="flex items-center h-5">
          <img className="w-4" src={logo} alt="logo" />
          <div className="px-1">
            <p className="font-bold">{title}</p>
            <p className="font-light text-gray">{subtitle}</p>
          </div>
        </div>
        <Show when={isConnected}>
          <div className="flex items-center justify-center mt-1">
            <Show when={connectionStatus === 'ready'}>
              <span className="mr-[10px] animate-pulse w-[15px] h-[15px] rounded-full bg-lime-600 absolute top-[20px] right-[20px]"></span>
            </Show>
            <Show when={connectionStatus === 'syncing'}>
              <span className="mr-[10px] animate-pulse w-[15px] h-[15px] rounded-full bg-orange-300 absolute top-[20px] right-[20px]"></span>
            </Show>
            <Show
              when={
                connectionStatus === 'disabled' ||
                connectionStatus === 'needs update' ||
                connectionStatus === 'accounts not mapped'
              }
            >
              <span className="mr-[10px] animate-pulse w-[15px] h-[15px] rounded-full bg-red-600 absolute top-[20px] right-[20px]"></span>
            </Show>

            <Button
              disabled={disabled}
              onClick={handleViewIntegration}
              color="primary"
              fullWidth={true}
            >
              View Integration
            </Button>
          </div>
        </Show>
        <Show when={isLoading}>
          <Skeleton variant="text" height={70} className="m-0" />
          <Skeleton
            variant="rounded"
            height={15}
            width={15}
            className="absolute top-[20px] right-[20px] rounded-full"
          />
        </Show>
        <Show when={!isConnected && !isLoading}>
          <Show when={isReadyForConnection}>
            <Button
              disabled={disabled}
              onClick={onConnect}
              color="primary"
              fullWidth={true}
              className="mt-1"
            >
              {connectLabel}
            </Button>
          </Show>
          <Show when={!isReadyForConnection}>
            <Button
              variant="outlined"
              disabled={disabled}
              sx={{ color: '#1D1D1D', '&.Mui-disabled': { color: '#dddddd' } }}
              onClick={onConnect}
              color="secondary"
              fullWidth={true}
              className="mt-1"
            >
              {connectLabel}
            </Button>
          </Show>
        </Show>
      </Paper>
    </div>
  );
};

export default IntegrationConnectionCard;
