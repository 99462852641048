import { useQuery } from '@tanstack/react-query';
import depositService from '../../../services/deposit.service';
import { useUserRole } from 'features/auth/data/userRole';

export const useGetDepositAccountLimits = () => {
  const { isAdmin } = useUserRole();

  return useQuery({
    queryKey: ['deposit/limits'],
    queryFn: depositService.getDepositAccountLimits,
    enabled: isAdmin,
  });
};
