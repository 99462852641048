import { keepPreviousData, useQuery } from '@tanstack/react-query';
import tagsService, { GetTagsFilters } from '../services/tags.service';
import { Pagination } from '../../../common/pagination';
import { useUserRole } from '../../auth/data/userRole';

const defaultPagination = { page: 0, per_page: 500, sort_field: 'name' };

export const useGetTags = (
  pagination: Pagination = defaultPagination,
  filters: GetTagsFilters = {},
) => {
  const { isAdmin } = useUserRole();

  return useQuery({
    queryKey: ['/tags', pagination, filters],
    queryFn: () => tagsService.getTags(pagination, filters),
    placeholderData: keepPreviousData,
    enabled: isAdmin,
  });
};
