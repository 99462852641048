import React, { useState, createContext, useContext } from 'react';

import { languageOptions, dictionaryList, DictionaryKey } from '../languages';

type LanguageContextType = {
  userLanguage: DictionaryKey;
  dictionary: Record<string, string>;
  userLanguageChange: (selected: DictionaryKey) => void;
};

// create the language context with default selected language
export const LanguageContext = createContext<LanguageContextType>({
  userLanguage: 'en',
  dictionary: dictionaryList.en,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  userLanguageChange: (selected: DictionaryKey) => {},
});

// it provides the language context to app
export function LanguageProvider({ children }: { children: JSX.Element }) {
  const defaultLanguage = window.localStorage.getItem('tlbx-lang') as DictionaryKey;
  const [userLanguage, setUserLanguage] = useState<DictionaryKey>(defaultLanguage || 'en');

  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    userLanguageChange: (selected: DictionaryKey) => {
      const newLanguage = languageOptions[selected] ? selected : 'en';
      setUserLanguage(newLanguage);
      window.localStorage.setItem('tlbx-lang', newLanguage);
    },
  };

  return <LanguageContext.Provider value={provider}>{children}</LanguageContext.Provider>;
}

// get text according to id & current language
export function Text({ tid }: { tid: string }) {
  const languageContext = useContext(LanguageContext);

  return <>{languageContext.dictionary[tid] || tid}</>;
}

export function getLangText(tid: string) {
  const languageContext = useContext(LanguageContext);
  return languageContext.dictionary[tid] || tid;
}
