import React, { CSSProperties, ReactNode } from 'react';
import { TextField, InputAdornment, OutlinedInputProps } from '@mui/material';

interface InputProps {
  onChange(e: string): void;
  onBlur?(e: string): void;
  onKeyDown?(e: string): void;
  dataId?: string;
  dataCy?: string;
  value: string | number;
  placeholder?: string;
  className?: string;
  style?: CSSProperties;
  type?: string;
  disabled?: boolean;
  error?: string | null | undefined;
  maxLength?: number;
  size?: 'small' | 'medium';
  stopKeyDownProp?: boolean;
  fullWidth?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  InputProps?: Partial<OutlinedInputProps> & {
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
  };
}

export default function Input(props: InputProps) {
  const { error, stopKeyDownProp = false, InputProps } = props;
  const isError = error && error.length > 0;

  return (
    <TextField
      inputProps={{
        maxLength: props.maxLength || '',
      }}
      InputProps={{
        ...InputProps,

        inputProps: {
          'data-cy': props.dataCy,
          min: 0,
        },
        startAdornment: InputProps?.startAdornment ? (
          <InputAdornment position="start">{InputProps.startAdornment}</InputAdornment>
        ) : null,
        endAdornment: InputProps?.endAdornment ? (
          <InputAdornment position="end">{InputProps.endAdornment}</InputAdornment>
        ) : null,
      }}
      fullWidth={props.fullWidth}
      error={!!isError}
      variant={props.variant || 'outlined'}
      disabled={props.disabled}
      data-id={props.dataId}
      className={props.className}
      type={props.type || 'text'}
      onChange={e => props.onChange(e.target.value)}
      onBlur={e => props.onBlur && props.onBlur(e.target.value)}
      style={props.style}
      placeholder={props.placeholder}
      value={props.value || ''}
      helperText={props.error}
      size={props.size || 'small'}
      onKeyDown={e => {
        if (stopKeyDownProp) e.stopPropagation();
        props.onKeyDown && props.onKeyDown(e.key);
      }}
    />
  );
}
