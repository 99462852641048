import React from 'react';
import { styled, SxProps, Theme } from '@mui/system';

interface InfoCardProps {
  title: string;
  subtitle?: string;
  firstIcon?: JSX.Element;
  firstLabel: string;
  firstValue: string;
  secondIcon?: JSX.Element;
  secondLabel: string;
  secondValue: string;
  linkText: string;
  className?: string;
  style?: React.CSSProperties;
  sx?: SxProps<Theme>;
}

const mainContainerStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '194px',
  padding: '16px 16px 24px 16px',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  gap: '5px 16px',
  flex: '1 0 0%',
  borderRadius: '2px',
  border: '1px solid #EEE',
  background: '#FFF',
  position: 'relative',
};

const labelValueContainerStyles: React.CSSProperties = {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '40px',
};

const labelIconContainerStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 12,
  paddingBottom: 2,
};

const titleStyles: React.CSSProperties = {
  color: 'var(--greyscale-700, #1D1D1D)',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '22.4px',
};

const subtitleStyles: React.CSSProperties = {
  color: 'var(--greyscale-400, #9D9D9D)',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '16.8px',
  marginLeft: 20,
};

const labelStyles: React.CSSProperties = {
  color: 'var(--greyscale-500, #4D4D4D)',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '19.6px',
  marginBottom: '4px',
  fontVariant: 'all-small-caps',
  opacity: 0.7,
};

const amountStyles: React.CSSProperties = {
  color: 'var(--greyscale-700, #1D1D1D)',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '24px',
  marginBottom: 12,
};

const StyledContainer = styled('div')(({ theme }) => ({
  ...mainContainerStyles,
}));

const InfoCard: React.FC<InfoCardProps> = ({
  title,
  subtitle,
  firstIcon,
  firstLabel,
  firstValue,
  secondIcon,
  secondLabel,
  secondValue,
  linkText,
  className,
  style = {},
  sx,
}) => {
  const combinedMainContainerStyles: React.CSSProperties = {
    ...mainContainerStyles,
    ...style,
  };

  return (
    <StyledContainer style={combinedMainContainerStyles} sx={sx} className={`${className}`}>
      <p style={titleStyles}>
        {title}{' '}
        {subtitle && (
          <span style={subtitleStyles} className="capitalize">
            {subtitle}
          </span>
        )}{' '}
      </p>

      <div style={labelValueContainerStyles}>
        <div style={labelIconContainerStyles}>
          {firstIcon}
          <p style={labelStyles}>{firstLabel}</p>
          <p style={amountStyles}>{firstValue}</p>
        </div>
        <div style={labelIconContainerStyles}>
          {secondIcon}
          <p style={labelStyles}>{secondLabel}</p>
          <p style={amountStyles}>{secondValue}</p>
        </div>
      </div>
    </StyledContainer>
  );
};

export default InfoCard;
