import { useQuery } from '@tanstack/react-query';
import { Pagination } from '../../../common/pagination';
import tagsService from '../services/tags.service';
import { useUserRole } from '../../auth/data/userRole';

export const useGetTagGroups = (
  pagination: Pagination = {
    page: 0,
    per_page: 100,
    sort_field: 'name',
    sort_direction: 'asc',
  },
) => {
  const { isAdmin } = useUserRole();

  return useQuery({
    queryKey: ['/tag_groups', pagination],
    queryFn: () => tagsService.getTagGroups(pagination),
    enabled: isAdmin,
  });
};
