import { PlaidAuth } from 'interfaces/baseInterfaces';
import baseService from './base.service';
import { PlaidAccount, PlaidInstitution } from 'react-plaid-link';

class PlaidService {
  public async createLinkToken(samedayMicrodeposits: boolean) {
    return await baseService.postJSON(
      'plaid/create-link-token',
      samedayMicrodeposits ? { sameday_microdeposits: true } : {},
    );
  }

  public async createRelinkToken() {
    return await baseService.postJSON('plaid/create-relink-token', {});
  }

  public async relinkedPlaid(public_token: string, account_id: string) {
    return await baseService.postJSON('plaid/relink-successful', { public_token, account_id });
  }

  public async setPublicToken(
    public_token: string,
    account: PlaidAccount,
    institution: PlaidInstitution | null,
  ) {
    return await baseService.postJSON('plaid/create-access-token', {
      public_token,
      account,
      institution,
    });
  }

  public async getInstitution() {
    return await baseService.getJSON('plaid/institution');
  }

  public async getPlaidAuth(designation_filter: string) {
    return await baseService.getJSON(`plaid/get-auth/${designation_filter}`);
  }

  public async setAuthDesignation(plaid_auth: PlaidAuth | null) {
    return await baseService.postJSON('plaid/set-auth-designation', { plaid_auth });
  }

  public async checkCreditPrequalifications() {
    return await baseService.postJSON(`plaid/credit/prequal`, {});
  }
}

const plaidService = new PlaidService();

export default plaidService;
