import { CSSProperties, Dispatch, FC, useReducer, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { formatCentsAsDollars } from 'helpers/formattings';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TransactionDetails } from 'features/credit/services/credit.service';
import CategoryIcon from '../../../categories/components/CategoryIcon';
import TransactionRichDataCell from 'features/transactions/components/TransactionRichDataCell';

interface CreditTransactionTableProps {
  transactions: TransactionDetails[];
  loadTransactions?: () => void;
}

const tableHeadCellStyles: CSSProperties = {
  padding: '16px 24px',
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 100%), #F7F7F7',
};

const tableRowCellStyles: CSSProperties = {
  padding: '16px 24px',
  borderBottom: '1px solid #EEE',
  background: '#FFF',
  textTransform: 'capitalize',
};

const descriptionStyle: CSSProperties = {
  ...tableRowCellStyles,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const defaultWidth = '191px';
const typeWidth = '81px';

const CreditTransactionTable: FC<CreditTransactionTableProps> = ({
  transactions,
  loadTransactions,
}) => {
  const muiTheme = useTheme();

  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  if (transactions.length === 0) {
    return (
      <div
        className="flex-row mt-1"
        style={{
          padding: '0 32px 32px 32px',
          width: '100%',
        }}
      >
        <div style={{ width: '100%' }}>
          <TableContainer
            style={{ border: '1px solid #EEE', borderRadius: 4 }}
            data-cy="emptyStateTable"
          >
            <Table size="small" style={{ width: '100%', tableLayout: 'fixed' }}>
              {isMobile ? (
                <></>
              ) : (
                <TableHead>
                  <TableRow>
                    <TableCell style={tableHeadCellStyles}>Description</TableCell>
                    <TableCell style={tableHeadCellStyles}>Date</TableCell>
                    <TableCell style={tableHeadCellStyles}>Amount</TableCell>
                    <TableCell style={tableHeadCellStyles}>Type</TableCell>
                    <TableCell style={tableHeadCellStyles}>Category</TableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} style={{ textAlign: 'center', padding: '32px' }}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      style={{ fontSize: '1rem', fontWeight: 'bold' }}
                    >
                      You don’t have any transactions yet.
                    </Typography>
                    <Typography style={{ fontSize: '0.87rem' }}>
                      Your most recent transactions will appear here once you begin using your
                      Toolbox account or cards.
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  }

  return (
    <div
      className="flex-row mt-1"
      style={{
        padding: '0 32px 32px 32px',
      }}
    >
      <div style={{ maxWidth: '100%' }}>
        <TableContainer
          style={{ width: '100%', border: '1px solid #EEE' }}
          data-cy="creditTransactionsTable"
        >
          <Table size="small" style={{ width: '100%', tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                {isMobile ? (
                  <></>
                ) : (
                  <>
                    <TableCell
                      style={{ ...tableHeadCellStyles, width: defaultWidth }}
                      data-cy="descriptionTableHead"
                    >
                      Description
                    </TableCell>
                    <TableCell style={{ ...tableHeadCellStyles, width: defaultWidth }}>
                      Date
                    </TableCell>
                    <TableCell
                      style={{ ...tableHeadCellStyles, width: defaultWidth }}
                      data-cy="amountTableHead"
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      style={{ ...tableHeadCellStyles, width: typeWidth }}
                      data-cy="typeTableHead"
                    >
                      Type
                    </TableCell>
                    <TableCell
                      style={{ ...tableHeadCellStyles, width: defaultWidth }}
                      data-cy="categoryTableHead"
                    >
                      Category
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((t: TransactionDetails) => (
                <>
                  <TableRow key={t.id}>
                    {isMobile ? (
                      <TableCell style={{ ...tableRowCellStyles, padding: 0 }}>
                        <TransactionRichDataCell transaction={t} />
                      </TableCell>
                    ) : (
                      <>
                        <TableCell style={descriptionStyle} data-cy="descriptionTableBody">
                          <TransactionRichDataCell transaction={t} />
                        </TableCell>
                        <TableCell style={tableRowCellStyles}>
                          {dayjs(t.created_at).format('MMM DD, YYYY')}
                        </TableCell>
                        <TableCell style={tableRowCellStyles} data-cy="amountTableBody">
                          {formatCentsAsDollars(t.amount)}
                        </TableCell>
                        <TableCell style={tableRowCellStyles}>{t.account_type}</TableCell>
                        <TableCell style={tableRowCellStyles}>
                          {t.category && <CategoryIcon category={t.category} className="mr-1" />}
                          {t.category}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default CreditTransactionTable;
