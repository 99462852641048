import { useQuery } from '@tanstack/react-query';
import { RutterPlatform } from '../rutter.types';
import rutterService from './rutter.service';
import { useUserRole } from 'features/auth/data/userRole';

export const useGetRutterAccounts = (platform: RutterPlatform) => {
  const { isAdmin, isBookkeeper } = useUserRole();
  const isAllowed = isAdmin || isBookkeeper;
  return useQuery({
    queryKey: [`rutter/${platform}/accounts`],
    queryFn: () => rutterService.getAccounts(platform),
    enabled: isAllowed,
  });
};
