export const STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const STATE_ABBRVS = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export const BUSINESS_VERTICALS = [
  'AgricultureForestryFishingOrHunting',
  'ArtsEntertainmentAndRecreation',
  'BusinessSupportOrBuildingServices',
  'Construction',
  'DirectMarketingOrTelemarketing',
  'EducationalServices',
  'FinancialServicesCryptocurrency',
  'FinancialServicesDebitCollectionOrConsolidation',
  'FinancialServicesMoneyServicesBusinessOrCurrencyExchange',
  'FinancialServicesOther',
  'FinancialServicesPaydayLending',
  'GamingOrGambling',
  'HealthCareAndSocialAssistance',
  'HospitalityAccommodationOrFoodServices',
  'LegalAccountingConsultingOrComputerProgramming',
  'Manufacturing',
  'Mining',
  'Nutraceuticals',
  'PersonalCareServices',
  'PublicAdministration',
  'RealEstate',
  'ReligiousCivicAndSocialOrganizations',
  'RepairAndMaintenance',
  'RetailTrade',
  'TechnologyMediaOrTelecom',
  'TransportationOrWarehousing',
  'Utilities',
  'WholesaleTrade',
];

export const TITLES = [
  'CEO',
  'CFO',
  'COO',
  'Director',
  'Manager',
  'Member',
  'Other',
  'Owner',
  'President',
  'Vice President',
];

export const COMPANY_TYPES = ['LLC', 'LP', 'LLP', 'Corporation: C-Corp', 'Corporation: S-Corp'];

export const EMPLOYEE_COUNTS = ['1-5', '5-10', '10-30', '30-100', '>100'];

export const SYSTEM_COLORS: any = {
  MainPrimary: '#09442f',
  MainPrimaryNew: '#000000',
  MainSecondary: '#015FCE',
  MainSuccess: '#21b881',
  MainError: '#dc3545',
  MainWarning: '#ffc107',
  MainInfo: '#17a2b8',
  MainLight: '#f2f5f9',
  MainWhite: '#ffffff',
  BodyLight: '#8795a9',
  Gray: '#747583',
  DimGray: '#5e6672',
  Title: '#354151',
  Border: '#e1e8ee',
  BorderDark: '#c6ccd1',
  Background: '#f2f3f4',
  ActivePrimary: '#4152ab',
  ActiveSecondary: '#282833',
  ActiveSuccess: '#18aa75',
  ActiveError: '#bd2130',
  ActiveWarning: '#d39e00',
  ActiveInfo: '#117a8b',
  ActiveLight: '#e4e7eb',
  ActiveBorder: '#c8ced4',
  HoverPrimary: '#4558b8',
  HoverSecondary: '#323340',
  HoverError: '#c82333',
  HoverSuccess: '#218838',
  HoverInfo: '#138496',
  HoverWarning: '#e0a800',
  HoverLight: '#ebeef2',
  VeryLightBlue: '#dadbe3',
  NewBlue: '#4A5DC3',
  FormLabel: '#747583',
  BabyBlue: 'rgba(220, 239, 245, 1)',
};

export const CHART_COLORS = {
  Green: '#1CDFA5',
  Purple: '#A966B4',
  Yellow: '#FFBA1E',
  Blue: '#399EE9',
  Pink: '#E41391',
  DarkPurple: '#E41391',
};

export const TOS_URL = 'https://www.trytoolbox.com/legal/terms-and-conditions';
export const PRIVACY_POLICY_URL = 'https://www.trytoolbox.com/legal/privacy-policy';
export const E_CONSENT_URL = 'https://www.trytoolbox.com/legal/esign-consent';
export const CHARGE_CARD_TOU_URL =
  'https://cdn.trytoolbox.com/files/toolbox-charge-and-terms-of-use.pdf';
export const ELECTRONIC_DISCLOSURE_AND_CONSENT_URL =
  'https://cdn.trytoolbox.com/files/toolbox-electronic-disclosure-and-consent.pdf';
export const CHARGE_CARD_USER_TERMS_URL =
  'https://cdn.trytoolbox.com/files/toolbox-charge-card-authorized-user-terms.pdf';
export const REWARD_TERMS_URL = 'https://cdn.trytoolbox.com/files/toobox-rewards-terms.pdf';
export const TOOLBOX_LOGO = 'https://cdn.trytoolbox.com/web/high-res-tb-logo.png';
export const TOOLBOX_WH_LOGO =
  'https://uploads.convertflow.co/production/websites/41162/Y9rlIUenTo66ONpcvOsA_logo-ai-white.png';
export const FAQ_URL = 'https://help.trytoolbox.com/en';

export const ALLOWABLE_FILE_TYPES = '.gif,.jpg,.jpeg,.png,.doc,.docx,.pdf,.txt';

export const IMPROPER_EMAIL_FORMAT = 'Email must be in the proper format';

export const DISALLOWED_EMAIL_VENDOR = 'Please use a different email vendor';

export const HIGH_RISK_EMAIL_DOMAIN = 'High risk email domain';

export const DASHBOARD_PATHS = {
  root: '/web/dashboard',
  home: '/web/dashboard/home',
  credit: '/web/dashboard/credit',
  transactions: '/web/dashboard/transactions',
  cards: '/web/dashboard/cards',
  accounting: '/web/dashboard/accounting',
  team: '/web/dashboard/team',
  settingsBankConnection: '/web/settings?tab=Bank%20Connections',
  settingsDocumentUpload: '/web/settings?tab=Document%20Upload',
  payVendor: '/web/dashboard/vendors/pay',
  payVendorAmount: '/web/dashboard/vendors/pay/amount',
  payVendorMoreDetails: '/web/dashboard/vendors/pay/more-details',
  payVendorReview: '/web/dashboard/vendors/pay/review',
  addVendor: '/web/dashboard/vendors/add',
  vendors: '/web/dashboard/vendors',
};

export const TRANSACTION_CATEGORIES = [
  'Equipment',
  'Equipment Maintenance',
  'Fuel',
  'Payroll',
  'Project Materials',
  'Sales, General & Admin',
  'Subcontractors',
];

export const ADMIN_JOB_TITLES = [
  'Assistant Project Manager',
  'Construction Manager',
  'Project Manager',
  'Office Manager',
  'Superintendent',
  'Controller',
  'Foreman',
  'Other',
];

export const EMPLOYEE_JOB_TITLES = [
  'Assistant Project Manager',
  'Construction Manager',
  'Project Manager',
  'Superintendent',
  'Laborer',
  'Foreman',
  'Other',
];

export const TRANSACTION_TYPES = ['All Transactions', 'Charges', 'Payments', 'Rewards'];
