import { useQuery } from '@tanstack/react-query';
import { useUserRole } from 'features/auth/data/userRole';
import { CreditAccountService } from 'features/credit/services/credit.service';

const service = new CreditAccountService();

export const useGetAmountOverdue = () => {
  return useQuery({
    queryKey: ['/credit-account/amount-overdue'],
    queryFn: () => service.getAmountOverdue(),
  });
};

export const useGetLatestTransactions = () => {
  return useQuery({
    queryKey: ['/credit-account/latest-transactions'],
    queryFn: () => service.getLatestTransactions(),
  });
};

export const useGetRewards = () => {
  return useQuery({
    queryKey: ['/credit-account/rewards'],
    queryFn: () => service.getRewards(),
  });
};

export const useGetCreditAccountSummary = () => {
  const { isAdmin } = useUserRole();
  return useQuery({
    queryKey: ['/credit-account/summary'],
    queryFn: () => service.getCreditAccountSummary(),
    enabled: isAdmin,
  });
};

export const useGetCreditAccountLimit = () => {
  return useQuery({
    queryKey: ['/credit-account/limit'],
    queryFn: () => service.getCreditAccountLimit(),
  });
};
