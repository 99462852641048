import { ChangeEvent, useEffect, useState } from 'react';
import { useGetTagGroups } from '../../data/getTagGroups';
import { MenuItem, TextField } from '@mui/material';
import Show from '../../../../app/components/Show';

interface TagGroupSelectorProps {
  value?: string;
  className?: string;
  onChange: (selectedId?: number) => void;
  textFieldError?: boolean;
  helperText?: React.ReactNode;
  label?: string;
}

const TagGroupSelector = (props: TagGroupSelectorProps) => {
  const { className, label, onChange, textFieldError, helperText, value = '' } = props;
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const { data, isLoading, error, isError } = useGetTagGroups();
  const tagGroups = data?.data;

  const handleSelect = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const tagGroupId = event.target.value;
    setSelectedGroupId(tagGroupId);
    const tagGroup = tagGroups?.find(group => `${group.id}` == tagGroupId);
    onChange(tagGroup?.id);
  };

  useEffect(() => {
    if (value) {
      setSelectedGroupId(value);
    }
  });

  return (
    <div className={className}>
      <TextField
        select
        value={selectedGroupId}
        onChange={handleSelect}
        label={label ?? 'Category'}
        className="w-full"
        error={textFieldError}
        helperText={helperText}
      >
        <Show when={isLoading}>
          <p className="text-center">Loading...</p>
        </Show>
        <Show when={isError}>
          <p className="text-center">Could not load data</p>
        </Show>
        <MenuItem className="w-full max-w-2xl" key={0} value="">
          <i>All categories</i>
        </MenuItem>
        {tagGroups?.map(tagGroup => (
          <MenuItem className="w-full max-w-2xl bg-white" key={tagGroup.id} value={tagGroup.id}>
            {tagGroup.name}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default TagGroupSelector;
