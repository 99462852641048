import AccountingPaymentsTable from '../../../../../features/accounting/credit/components/AccountingPaymentsTable';

interface TableColumns {
  label: string;
}

interface CreditPaymentsPageProps {}

const CreditPaymentsPage = (props: CreditPaymentsPageProps) => {
  return <AccountingPaymentsTable />;
};

export default CreditPaymentsPage;
