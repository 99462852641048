import { Transaction } from '../../../features/transactions/types';

export type TransactionRowsState = {
  transactionHeaders: string[];
};

type TransactionRowsAction = {
  readonly type: string;
  readonly payload: any;
};

export enum TransactionRowsActionType {
  TransactionHeaders = 'transactionHeaders',
}

export const transactionRowsInitialState: TransactionRowsState = {
  transactionHeaders: ['Description', 'Date', 'Amount', 'Type', 'Category', 'Tags', 'Details'],
};

export function transactionRowsReducer(state: TransactionRowsState, action: TransactionRowsAction) {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
}
