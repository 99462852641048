import { MenuItem, TextField } from '@mui/material';
import { RutterAccountType, rutterAccountTypes } from '../../rutter/rutter.types';
import { ChangeEvent } from 'react';

interface RutterAccountTypeSelectorProps {
  className?: string;
  required?: boolean;
  value: string;
  label?: string;
  textFieldError?: boolean;
  helperText?: React.ReactNode;
  onChange: (accountType: RutterAccountType) => void;
  allowedAccountTypes?: RutterAccountType[];
}

const RutterAccountTypeSelector = (props: RutterAccountTypeSelectorProps) => {
  const {
    className,
    value: accountType,
    label,
    required,
    helperText,
    textFieldError,
    onChange,
    allowedAccountTypes,
  } = props;

  const handleSelect = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const accountType = event.target.value;
    onChange(accountType);
  };

  return (
    <div className={className}>
      <TextField
        required={required}
        select
        value={accountType}
        onChange={handleSelect}
        label={label ?? 'Account type'}
        className="w-full"
        error={textFieldError}
        helperText={helperText}
      >
        {rutterAccountTypes
          ?.filter(type => (allowedAccountTypes ? allowedAccountTypes.includes(type) : true))
          .sort((a, b) => a.localeCompare(b))
          .map(type => (
            <MenuItem className="w-full max-w-2xl bg-white" key={type} value={type}>
              <span className="text-capitalize">{type.replace('_', ' ')}</span>
            </MenuItem>
          ))}
      </TextField>
    </div>
  );
};

export default RutterAccountTypeSelector;
