import { EntityTag, Tag } from './types';

export const getGroupedEntityTags = (
  tags: EntityTag[],
  sortByGroup = false,
): Record<string, EntityTag[]> => {
  if (sortByGroup) {
    tags.sort((a, b) => a.tag.group_name.localeCompare(b.tag.group_name));
  }

  const groupedTags = tags.reduce(
    (groupedTags, entityTag) => {
      if (groupedTags[entityTag.tag.group_name]) {
        groupedTags[entityTag.tag.group_name].push(entityTag);
      } else {
        groupedTags[entityTag.tag.group_name] = [entityTag];
      }
      return groupedTags;
    },
    {} as Record<string, EntityTag[]>,
  );

  if (sortByGroup) {
    Object.keys(groupedTags).forEach(groupName => {
      groupedTags[groupName].sort((currentEntityTag, nextEntityTag) =>
        currentEntityTag.tag.name.localeCompare(nextEntityTag.tag.name),
      );
    });
  }

  return groupedTags;
};

export const getGroupedTags = (tags: Tag[], sortByGroup = false): Record<string, Tag[]> => {
  if (sortByGroup) {
    tags.sort((a, b) => a.group_name.localeCompare(b.group_name));
  }

  const groupedTags = tags.reduce(
    (groupedTags, tag) => {
      if (groupedTags[tag.group_name]) {
        groupedTags[tag.group_name].push(tag);
      } else {
        groupedTags[tag.group_name] = [tag];
      }
      return groupedTags;
    },
    {} as Record<string, Tag[]>,
  );

  if (sortByGroup) {
    Object.keys(groupedTags).forEach(groupName => {
      groupedTags[groupName].sort((currentTag, nextTag) =>
        currentTag.name.localeCompare(nextTag.name),
      );
    });
  }

  return groupedTags;
};

export const getTagGroupColor = (group: string): string => {
  switch (group) {
    case 'Project':
      return '#008D28';
    case 'Customers':
      return '#0082BC';
    case 'Department':
      return '#905600';
    default:
      return '#3a6959';
  }
};
