import React, { useEffect, useState } from 'react';
import { IconButton, useTheme } from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';
import UserInfo from '../UserInfo';
import UserAvatar from '../UserAvatar';
import PopoverMenu from '../PopOver';
import { popupnavigationConfig } from '../PopOver/utils/popupConfigs';

interface UserProfileWidgetProps {
  sx?: React.CSSProperties;
  userName?: string;
  companyName?: string;
  userNameStyle?: React.CSSProperties;
  companyNameStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  avatarStyle?: React.CSSProperties;
  avatarTextStyle?: React.CSSProperties;
  showMoreIcon?: boolean;
  variant?: 'default' | 'small';
}

const UserProfileWidget = ({
  sx,
  userName,
  companyName,
  userNameStyle,
  companyNameStyle,
  iconStyle,
  avatarStyle,
  avatarTextStyle,
  showMoreIcon = true,
  variant = 'default',
}: UserProfileWidgetProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [items] = useState(popupnavigationConfig);

  const theme = useTheme();

  const isMobileDevice = () => {
    return window.innerWidth <= 768;
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget as HTMLButtonElement);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const defaultIconStyle: React.CSSProperties = {
    color: theme.palette.common.white,
  };

  const defaultAvatarStyle: React.CSSProperties = {
    backgroundColor: theme.palette.common.white,
  };

  const defaultAvatarTextStyle: React.CSSProperties = {
    color: theme.palette.primary.main,
    fontSize: '16px',
  };

  const companyInitial = companyName?.toUpperCase().charAt(0);

  const containerStyles: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '8%',
    marginBottom: '16px',
    ...sx,
  };

  const mobileContainerStyles: React.CSSProperties = {
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    ...sx,
  };

  const handleAvatarClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (variant === 'small') {
      handlePopoverOpen(event);
    }
  };

  const handleAvatarKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handlePopoverOpen(event as unknown as React.MouseEvent<HTMLButtonElement, MouseEvent>);
    }
  };
  useEffect(() => {
    return () => setAnchorEl(null);
  }, []);

  return (
    <div
      data-cy="theUserName"
      style={variant === 'small' ? mobileContainerStyles : containerStyles}
    >
      <div
        role="button"
        tabIndex={0}
        onClick={handleAvatarClick}
        style={{ cursor: 'pointer' }}
        onKeyDown={handleAvatarKeyDown}
      >
        <UserAvatar
          avatarStyle={{ marginRight: '12px', ...defaultAvatarStyle, ...avatarStyle }}
          avatarTextStyle={{ ...defaultAvatarTextStyle, ...avatarTextStyle }}
          companyInitial={companyInitial}
        />
      </div>

      {variant !== 'small' && (
        <>
          <UserInfo
            userName={userName}
            companyName={companyName}
            userNameStyle={userNameStyle}
            companyNameStyle={companyNameStyle}
          />

          {showMoreIcon && (
            <IconButton
              edge="end"
              aria-label="more"
              data-cy="showMoreIcon"
              size="small"
              style={{ ...defaultIconStyle, ...iconStyle }}
              onMouseOver={isMobileDevice() ? undefined : handlePopoverOpen}
              onClick={isMobileDevice() ? handlePopoverOpen : undefined}
            >
              <MoreIcon />
            </IconButton>
          )}
        </>
      )}

      <PopoverMenu
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
        items={items}
        userName={userName}
        companyName={companyName}
      />
    </div>
  );
};

export default UserProfileWidget;
