/**
 * Ensures user's unit application is either approved (after KYC/KYB) or being processed.
 *
 * If application is denied, navigate to rejection screen.
 *
 * If user has not started a unit application creation yet, show page with unit application creation banner.
 *
 * Depends on successful Plaid identity verification
 *
 * * NOTE!!!: fallbackPage is not wrapped by any auth wrapper.
 * This is to ensure flexibility in how other pages want to use it.
 * * Always ensure the necessary auth wrapper is provided
 * around your fallbackPage as you deem fit.
 *
 */
import { ScreenProps } from 'interfaces/baseInterfaces';
import { UnitApplicationStatus } from 'interfaces/user';
import { Navigate } from 'react-router';

interface RequiresUniApplicationProps extends ScreenProps {
  children: JSX.Element;
  dangerouslySetFallbackPage?: JSX.Element;
}

const RequiresUnitApplication = (props: RequiresUniApplicationProps) => {
  const { currentUser, children, dangerouslySetFallbackPage: fallbackPage } = props;

  switch (currentUser?.unit_application_status) {
    case UnitApplicationStatus.DENIED:
      return <Navigate to="/onboarding/rejected-application" />;

    case UnitApplicationStatus.APPROVED:
      return <>{children}</>;
    default:
      return <Navigate to="/onboarding/rejected-application" />;
  }
};

export default RequiresUnitApplication;
