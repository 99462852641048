import { useState } from 'react';
import { Pagination } from '../../../../../common/pagination';
import Show from '../../../../../app/components/Show';
import TableSkeletonLoader from '../../../../../app/components/SkeletonLoaders/TableSkeletonLoader';
import {
  Pagination as MuiPagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import { TableColumns } from '../../../../../common/table';
import { useGetStatements } from '../../../data/getStatements';
import PdfDownloadButton from '../PdfDownloadButton';
import SaveToGoogleDriveBtn from 'app/components/SaveToGoogleDriveBtn';
import httpClient from 'services/httpClient';
import { UnitStatement } from 'features/accounting/data/accounting.service';

interface CreditStatementsTableProps {}

const CreditStatementsTable = (props: CreditStatementsTableProps) => {
  const [pagination, setPagination] = useState<Pagination>({ page: 1, per_page: 10 });

  const { data, isLoading, isSuccess } = useGetStatements(0, pagination);
  const statements = data?.data ?? [];
  const statementsCount = data?.total ?? 0;

  const columns: TableColumns[] = [
    { label: 'Statement Period' },
    { label: 'PDF Download' },
    // { label: 'Save to Google Drive' },
  ];

  const getStatementUrl = (statement: UnitStatement) => {
    return `${httpClient.getUri()}/statement/pdf?statement_id=${statement.id}`;
  };

  return (
    <>
      <Show when={isLoading}>
        <TableSkeletonLoader rows={pagination.per_page} columns={columns.length} />
      </Show>
      <Show when={isSuccess}>
        <TableContainer className="table border rounded">
          <Table>
            <TableHead className="bg-gray-100">
              <TableRow>
                {columns?.map(column => (
                  <TableCell key={column.label}>
                    <span className="font-bold">{column.label}</span>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {statements?.map(statement => {
                return (
                  <TableRow key={statement.id}>
                    <TableCell>{dayjs(statement?.attributes.period).format('MMMM YYYY')}</TableCell>
                    <TableCell>
                      <PdfDownloadButton
                        statementId={statement.id}
                        statementPeriod={statement.attributes.period}
                      />
                    </TableCell>
                    {/* <TableCell>
                      <SaveToGoogleDriveBtn
                        iconOnly={true}
                        fileUrl={getStatementUrl(statement)}
                        fileName={`Toolbox Statement (${statement.attributes.period})`}
                      />
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <MuiPagination
          className="mt-1"
          count={Math.ceil(statementsCount / pagination.per_page)}
          showFirstButton
          showLastButton
          page={pagination.page}
          onChange={(_, page) => {
            setPagination(prev => ({ ...prev, page }));
          }}
          sx={{
            '& .MuiPagination-ul': { 'justify-content': 'center' },
          }}
        />
      </Show>
    </>
  );
};

export default CreditStatementsTable;
