import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import { Tag } from '../../types';
import { useEffect, useState } from 'react';
import Show from '../../../../app/components/Show';

interface TagCheckboxProps {
  index: number;
  tag: Tag;
  isChecked: boolean;
  onChange: (checked: boolean, tag: Tag) => void;
  enableLoaders?: boolean;
}

const TagCheckbox = (props: TagCheckboxProps) => {
  const { tag, index, isChecked, onChange, enableLoaders = false } = props;

  const [isUpdating, setIsUpdating] = useState(false);

  const showLoader = enableLoaders && isUpdating;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUpdating(true);
    onChange(event.target.checked, tag);
  };

  useEffect(() => {
    setIsUpdating(false);
  }, [isChecked]);

  return (
    <div>
      <FormControlLabel
        className="mb-[5px] flex items-center"
        control={
          <span className="w-[20px] h-[20px] flex items-center">
            <Show when={!showLoader}>
              <Checkbox
                data-cy={`checkbox-${tag.name.replaceAll(' ', '')}-${index}`}
                checked={isChecked}
                size="small"
                onChange={handleChange}
                style={{ padding: 0, margin: 0 }}
              />
            </Show>
            <Show when={showLoader}>
              <CircularProgress size={17} className="mr-[5px]" />
            </Show>
          </span>
        }
        label={
          <span
            data-cy={`label-${tag.name.replaceAll(' ', '')}-${index}`}
            className="ml-[5px] text-[12px]"
          >
            {tag.name}
          </span>
        }
      />
    </div>
  );
};

export default TagCheckbox;
