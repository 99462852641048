import { Tab, Tabs } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import posthog from 'posthog-js';
import { useUserRole } from '../../auth/data/userRole';

interface AccountingPageNavbarProps {}

const AccountingPageNavbar = (props: AccountingPageNavbarProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isCreditCustomer, isDepositCustomer } = useUserRole();
  const tabs = [{ title: 'Integrations', path: '/dashboard/accounting/integrations' }];

  if (isDepositCustomer) {
    tabs.push({ title: 'Banking', path: '/dashboard/accounting/banking' });
  }

  if (isCreditCustomer) {
    tabs.push({ title: 'Credit', path: '/dashboard/accounting/credit' });
  }

  let activeTabIndex = 0;

  tabs.forEach((tab, index) => {
    if (pathname.includes(tab.path)) {
      activeTabIndex = index;
      return;
    }
  });

  const onTabChange = (tabIndex: number) => {
    if (tabIndex === 0) {
      posthog.capture(`Viewed Integrations Tab`);
    }
    navigate(tabs[tabIndex].path);
  };

  return (
    <Tabs
      value={activeTabIndex}
      variant="fullWidth"
      onChange={(_, tabIndex) => {
        onTabChange(tabIndex);
      }}
    >
      {tabs.map(({ title, path }) => (
        <Tab label={title} key={title} style={{ textTransform: 'none' }} />
      ))}
    </Tabs>
  );
};

export default AccountingPageNavbar;
