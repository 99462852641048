import { Outlet } from 'react-router-dom';

interface OnboardingPagesWrapperProps {}

const OnboardingPagesLayout = (props: OnboardingPagesWrapperProps) => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default OnboardingPagesLayout;
