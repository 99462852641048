import { Skeleton } from '@mui/material';
import './style.scss';

interface TableSkeletonLoaderProps {
  rows: number;
  columns: number;
  className?: string;
}

const TableSkeletonLoader = (props: TableSkeletonLoaderProps) => {
  const { rows, className, columns } = props;
  const rowArray = Array.from({ length: rows });
  const columnArray = Array.from({ length: columns });
  return (
    <div className={className}>
      <div className="row flex items-center justify-around bg-gray-100">
        {columnArray.map((_, colomnIndex) => (
          <Skeleton key={colomnIndex} variant="text" height={60} width={100} />
        ))}
      </div>
      {rowArray.map((_, rowIndex) => (
        <div key={rowIndex} className="row flex items-center justify-around">
          {columnArray.map((_, colomnIndex) => (
            <Skeleton key={colomnIndex} variant="text" height={50} width={100} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default TableSkeletonLoader;
