import React from 'react';

interface WelcomeHeaderProps {
  firstName: string;
  style?: React.CSSProperties;
  className?: string;
}

const WelcomeHeader: React.FC<WelcomeHeaderProps> = ({ firstName, style, className }) => (
  <div className={`flex-grow-8 ${className}`}>
    <h1
      style={{
        fontSize: 38,
        fontWeight: 500,
        lineHeight: '140%',
        ...style,
      }}
    >
      Welcome, {firstName}
    </h1>
  </div>
);

export default WelcomeHeader;
