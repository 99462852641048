import { List, ListItem, ListItemButton, ListItemText, Badge, Collapse } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { NavigationTab } from 'app/components/DesktopNavigation/utils/navigationTabs';
import Show from 'app/components/Show';
import posthog from 'posthog-js';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import baseService from 'services/base.service';
import './style.scss';

type toggleEvent =
  | React.MouseEvent<SVGSVGElement, MouseEvent>
  | React.MouseEvent<HTMLDivElement, MouseEvent>;

interface NavigationItemProps {
  navItem: NavigationTab;
  isDrawerOpen: boolean;
  onItemSelect?: (tab: NavigationTab) => void;
}

const NavigationItem = (props: NavigationItemProps) => {
  const { navItem, isDrawerOpen, onItemSelect } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const isSelected = location.pathname.includes(navItem.linkTo);
  const isCardsTab = navItem.title === 'Cards';
  const hasSubTabs = (navItem.subTabs?.length ?? 0) > 0;
  const isOnSubTabPage =
    hasSubTabs && navItem.subTabs?.some(subTab => location.pathname === subTab.linkTo);
  const isShowingSubTabsInitialValue = isOnSubTabPage || (hasSubTabs && !!navItem.defaultExpand);

  const [isShowingSubTabs, setIsShowingSubTabs] = useState(isShowingSubTabsInitialValue);

  const signOut = () => {
    posthog.capture('Sign out button click');
    baseService.clearAuthToken();
    posthog.reset();
    navigate('/sign-in');
  };

  const handleClick = (tab: NavigationTab) => {
    posthog.capture(tab.logEvent);
    tab?.callback?.includes('SignOut') && signOut();
    tab.linkTo.includes('https') ? window.open(tab.linkTo, '_blank') : navigate(tab.linkTo);
    if (onItemSelect) {
      onItemSelect(navItem);
    }
  };

  const toggleShowSubTabs = (event: toggleEvent) => {
    event.stopPropagation();
    setIsShowingSubTabs(prevVal => !prevVal);
  };

  const handleListClick = (event: toggleEvent) => {
    if (!navItem.subTabs) {
      handleClick(navItem);
    } else {
      toggleShowSubTabs(event);
    }
  };

  return (
    <>
      <ListItem
        key={navItem.title}
        disablePadding
        sx={{ display: 'block' }}
        className="px-[0.5rem]"
        onClick={() => handleClick(navItem)}
      >
        <ListItemButton
          selected={isSelected}
          onClick={handleListClick}
          data-cy={`nav-${navItem.title.replaceAll(' ', '')}`}
          sx={{
            borderRadius: '6px',
            justifyContent: 'space-around',
            flexDirection: isDrawerOpen ? 'row' : 'column',
            color: '#fff',
            minHeight: '48px',
            backgroundColor: 'inherit',
            '&.Mui-selected, &.Mui-selected:hover': {
              backgroundColor: '#333333',
            },
            '&:hover': {
              backgroundColor: '#333333',
            },
          }}
        >
          <Show when={isCardsTab}>
            <Badge color="primary">{navItem.icon}</Badge>
          </Show>
          <Show when={!isCardsTab}>{navItem.icon}</Show>

          <Show when={isDrawerOpen}>
            <span className={`flex grow ml-[1rem] ${isSelected && 'font-bold'}`}>
              {navItem.title}
            </span>
          </Show>
          <Show when={hasSubTabs}>
            {isShowingSubTabs ? (
              <ExpandLess onClick={e => toggleShowSubTabs(e)} />
            ) : (
              <ExpandMore onClick={e => toggleShowSubTabs(e)} />
            )}
          </Show>
        </ListItemButton>
      </ListItem>
      <Collapse
        in={isShowingSubTabs}
        className="collapsible border-l border-red px-[0.65rem] mt-[4px] mb-[6px]"
        sx={{ marginLeft: isDrawerOpen ? '1.25rem' : '0.5rem' }}
      >
        <List component="div" disablePadding>
          {navItem.subTabs?.map(subTab => (
            <ListItemButton
              selected={location.pathname === subTab.linkTo}
              className="h-[40px]"
              data-cy={`nav-${subTab.title.replaceAll(' ', '')}`}
              key={subTab.title}
              sx={{
                borderRadius: '6px',
                mt: 1,
                padding: 0,
                color: '#eaeaea',
                backgroundColor: 'inherit',
                '&.Mui-selected, &.Mui-selected:hover': {
                  backgroundColor: '#333333',
                },
                '&:hover': {
                  backgroundColor: '#333333',
                },
              }}
              onClick={() => handleClick(subTab)}
            >
              <div className="flex grow items-center justify-center">
                <Show when={!isDrawerOpen}>{subTab.icon}</Show>
                <Show when={isDrawerOpen}>
                  <ListItemText className="px-1">{subTab.title}</ListItemText>
                </Show>
              </div>
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default NavigationItem;
