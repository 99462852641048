import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useGetTags } from '../../data/getTags';
import { useState } from 'react';
import { Pagination } from '../../../../common/pagination';
import './style.scss';
import { Tag } from '../../types';
import EditTagModal from '../EditTagModal';
import Show from '../../../../app/components/Show';
import DeleteTagModal from '../DeleteTagModal';

interface TagsTableProps {
  tagGroupId?: number;
}

interface TableColumns {
  label: string;
}

const TagsTable = (props: TagsTableProps) => {
  const defaultPagination: Omit<Pagination, 'total'> = {
    page: 0,
    per_page: 10,
    sort_field: 'name',
    sort_direction: 'asc',
  };
  const { tagGroupId } = props;

  const [isEditTagModalOpen, setIsEditTagModalOpen] = useState(false);
  const [selectedTagForEdit, setSelectedTagForEdit] = useState<Tag>();
  const [isDeleteTagModalOpen, setIsDeleteTagModalOpen] = useState(false);
  const [selectedTagForDelete, setSelectedTagForDelete] = useState<Tag>();

  const [pagination, setPagination] = useState<Pagination>(defaultPagination);
  const filters = { tag_group_id: tagGroupId };

  const { data, isLoading, error, isError } = useGetTags(pagination, filters);
  const tags = data?.data;

  const onPageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ) => {
    setPagination(prevPagination => ({ ...prevPagination, page }));
  };

  const onPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const page_size = event.target.value;
    setPagination(prevPagination => ({ ...prevPagination, per_page: Number(page_size) }));
  };

  const closeEditTagModal = () => {
    setIsEditTagModalOpen(false);
    setSelectedTagForEdit(undefined);
  };

  const closeDeleteTagModal = () => {
    setIsDeleteTagModalOpen(false);
    setSelectedTagForDelete(undefined);
  };

  const selectTagForEdit = (tag: Tag) => {
    setSelectedTagForEdit(tag);
    setIsEditTagModalOpen(true);
  };

  const selectTagForDelete = (tag: Tag) => {
    setSelectedTagForDelete(tag);
    setIsDeleteTagModalOpen(true);
  };

  const columns: TableColumns[] = [
    { label: 'Tag Name' },
    { label: 'Category' },
    { label: 'Actions' },
  ];

  return (
    <>
      <TableContainer className="table mt-1 border rounded">
        <Table>
          <TableHead className="bg-gray-100">
            <TableRow>
              {columns?.map(column => (
                <TableCell key={column.label}>
                  <span className="font-bold">{column.label}</span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tags?.map((tag, index) => (
              <TableRow className={`${index % 2 == 0 ? '' : 'bg-gray-50'}`} key={tag.id}>
                <TableCell>
                  <span>{tag.name}</span>
                </TableCell>
                <TableCell>
                  <span>{tag.group_name}</span>
                </TableCell>
                <TableCell>
                  <span
                    onClick={() => selectTagForEdit(tag)}
                    className="underline text-dark-green cursor-pointer mr-1"
                  >
                    Edit
                  </span>
                  <span
                    onClick={() => selectTagForDelete(tag)}
                    className="underline text-dark-green cursor-pointer"
                  >
                    Delete
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100]}
        component="div"
        count={data?.total ?? 0}
        rowsPerPage={pagination.per_page}
        page={pagination.page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onPageSizeChange}
      />
      <Show when={!!selectedTagForEdit}>
        <EditTagModal
          tag={selectedTagForEdit!}
          isOpen={isEditTagModalOpen}
          onClose={closeEditTagModal}
        />
      </Show>
      <Show when={!!selectedTagForDelete}>
        <DeleteTagModal
          tag={selectedTagForDelete!}
          isOpen={isDeleteTagModalOpen}
          onClose={closeDeleteTagModal}
        />
      </Show>
    </>
  );
};

export default TagsTable;
