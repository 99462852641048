import Button from '../Button';
import PopUp from '../PopUp';
import { memo, useEffect, useState } from 'react';
import './styles.scss';
import { CompanyTeamMember, CreditCard } from '../../../interfaces/baseInterfaces';
import companyService from '../../../services/companyService';
import Select from '../Select/Select';
import posthog from 'posthog-js';
import { standardError } from 'helpers/base';
import { useUserRole } from '../../../features/auth/data/userRole';

interface CardAssignmentPopupProps {
  onCardAssignmentChanged(): void;

  onClose(): void;

  isOpen: boolean;
  card: CreditCard;
}

const CardAssignmentPopup = memo(function (props: CardAssignmentPopupProps) {
  const [team_members, set_team_members] = useState<CompanyTeamMember[]>([]);
  const [selected_team_member, set_selected_team_member] = useState<CompanyTeamMember | null>(null);
  const { isAdmin } = useUserRole();

  const loadTeamMembers = async () => {
    if (!isAdmin) return;

    const resp = await companyService.getTeam({
      include_removed: false,
      include_self: true,
    });

    if (resp.error) {
      standardError(resp.error);
      return;
    }

    set_team_members(resp);
  };

  const setMemberCard = async () => {
    posthog.capture('Assign card to member click');
    if (!selected_team_member) return;

    const resp = await companyService.setMemberCard(selected_team_member.id, props.card.id);

    if (resp.error) {
      standardError(resp.error);
      return;
    }

    props.onCardAssignmentChanged();
  };

  useEffect(() => {
    posthog.capture('Card assignment popup loaded');
    loadTeamMembers();
  }, []);

  return (
    <PopUp
      className="CardAssignmentPopup-component px-2 py-2"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <p data-id="card-assignment-title" className="font-2xl font-bold font-noto-sans">
        Card Assignment
      </p>

      <div data-id="card-assignment-box" className="limits-container flex-row justify-around mt-1">
        <div>
          <p
            data-id="card-assignment-label"
            className="limit-label font-bold font-noto-sans font-xs"
          >
            Assign card to:
          </p>

          <Select
            dataId="card-assignment-value"
            className="member-select"
            value={selected_team_member?.name || null}
            options={team_members.map(t => t.name)}
            onChange={v => {
              for (const member of team_members) {
                if (member.name == v) {
                  set_selected_team_member(member);
                  break;
                }
              }
            }}
            placeholder={''}
          />
        </div>
      </div>

      <div
        data-id="card-assignment-save-button-box"
        className="flex-row justify-end"
        style={{ marginTop: 12 }}
      >
        <Button
          dataId="card-assignment-save-button"
          disabled={!selected_team_member}
          className="btn-save mt-1 w-auto"
          onClick={setMemberCard}
        >
          Assign
        </Button>
      </div>
    </PopUp>
  );
});

export default CardAssignmentPopup;
