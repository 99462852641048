import { Helmet } from 'react-helmet';
import QuickbooksConnectionCard from '../../../../../features/integrations/quickbooks/components/QuickbooksConnectionCard';
import NetSuiteConnectionCard from '../../../../../features/integrations/components/NetSuiteConnectionCard';
import SageConnectionCard from '../../../../../features/integrations/components/SageConnectionCard';
import XeroConnectionCard from '../../../../../features/integrations/components/XeroConnectionCard';
import QuickbooksDesktopConnectionCard from '../../../../../features/integrations/quickbooks/components/QuickbooksDesktopConnectionCard';
import ToolboxAiConnectionCard from 'features/integrations/components/ToolboxAiConnectionCard';

interface AccountingIntegrationsProps {}

const AccountingIntegrations = (props: AccountingIntegrationsProps) => {
  return (
    <>
      <Helmet>
        <title>Toolbox - Accounting Integrations</title>
      </Helmet>
      <div className="flex flex-col gap-6 py-8 md:grid grid-cols-2 xl:grid-cols-4">
        <ToolboxAiConnectionCard />
        <QuickbooksConnectionCard />
        <QuickbooksDesktopConnectionCard />
        <NetSuiteConnectionCard />
        <SageConnectionCard />
        <XeroConnectionCard />
      </div>
    </>
  );
};

export default AccountingIntegrations;
