import {
  TransactionPageAction,
  TransactionPageActionType,
  TransactionPageState,
} from 'app/pages/dashboard/transactions/reducer';
import { formatCentsAsDollars } from 'helpers/formattings';
import { Cashback, Charge } from 'interfaces/baseInterfaces';
import dayjs from 'dayjs';
import { Dispatch, memo, useReducer } from 'react';
import { transactionRowsInitialState, transactionRowsReducer } from './reducer';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Tooltip from '@mui/material/Tooltip';
import { TableCell, TableRow } from '@mui/material';
import Card from '../Card';
import TableWrapper from '../TableWrapper';
import Button from '../Button';
import posthog from 'posthog-js';
import { getLangText, Text } from '../../../contexts/language';
import { titleCase } from 'helpers/base';
import TransactionTagCell from '../../../features/transactions/components/TransactionTagCell';
import { Transaction } from '../../../features/transactions/types';
import CategoryIcon from '../../../features/categories/components/CategoryIcon';
import TransactionRichDataCell from 'features/transactions/components/TransactionRichDataCell';
import { useGetRutterCategories } from '../../../features/integrations/rutter/data/getRutterCategories';
import { RutterPlatform } from '../../../features/integrations/rutter/rutter.types';

interface TransactionRowsProps {
  parentState: TransactionPageState;
  loadTransactions: any;
  dispatcher: Dispatch<TransactionPageAction>;
}

const TransactionRows = memo(function TransactionRows(props: TransactionRowsProps) {
  const { parentState, dispatcher } = props;
  const [state, dispatch] = useReducer(transactionRowsReducer, transactionRowsInitialState);
  const { data, isLoading, isSuccess, isError } = useGetRutterCategories(RutterPlatform.QUICKBOOKS);

  const renderCategory = (transaction: Transaction) => {
    if (transaction.category) {
      return (
        <>
          <CategoryIcon category={transaction.category} className="mr-1" />
          {transaction.category}
        </>
      );
    }

    if (transaction.rutter_category_id) {
      const category = data?.categories?.find(
        category => category.id === transaction.rutter_category_id?.toString(),
      );
      if (category) {
        return (
          <>
            <CategoryIcon category={category.name || ''} className="mr-1" />
            {category.name}
          </>
        );
      }
    }

    return null;
  };
  const createRow = (item: Transaction | Charge, index: number) => {
    if (item.type == 'transaction') {
      const transaction = item as Transaction;

      return (
        <TableRow key={index} data-id="transaction-row" className="table-row-content">
          <TableCell>
            <TransactionRichDataCell transaction={transaction} />
          </TableCell>
          <TableCell data-id="transaction-row-created-at-value">
            <p>
              <span className="pr-1">{dayjs(transaction.created_at).format('MM/DD/YYYY')}</span>
            </p>
          </TableCell>

          <TableCell data-id="transaction-row-amount-value">
            <div>
              {formatCentsAsDollars(transaction.amount)}
              {transaction.receipt_url && (
                <Tooltip title="Receipt uploaded, see transaction details for more info">
                  <ReceiptLongIcon className="vertical-middle m-w-1 ml-quarter" />
                </Tooltip>
              )}
            </div>
          </TableCell>

          <TableCell data-id="transaction-row-amount-value">
            <span className="pr-1 block">{transaction.account_type}</span>
          </TableCell>

          <TableCell>
            <div className="flex-row items-center">
              <span data-id="transaction-row-category-value">{renderCategory(transaction)}</span>
            </div>
          </TableCell>

          <TableCell data-id="transaction-row-status-value">
            <TransactionTagCell
              index={index}
              entityTags={transaction.tags ?? []}
              transaction={transaction}
            />
          </TableCell>

          <TableCell data-id="transaction-row-status-value">
            <Button
              dataCy={`trans-detail-btn-${index}`}
              className="text-dark-green cursor-pointer flex items-center mr-1"
              onClick={() => {
                posthog.capture('See Transaction Details Button Click');

                dispatcher({
                  type: TransactionPageActionType.SelectedTransactionForPopup,
                  payload: transaction,
                });
              }}
            >
              <Text tid="Details" />
            </Button>
          </TableCell>
        </TableRow>
      );
    } else if (item.type == 'charge') {
      const charge = item as Charge;
      return (
        <TableRow
          key={index}
          data-id="payment-row"
          className={`rounded-sm bg-white flex-row items-center ${
            charge.status === 'pending' ? 'table-row-content-pending' : 'table-row-content'
          }`}
        >
          <TableCell data-id="payment-row-created-at-value">
            <span className="pr-1 pl-1">{dayjs(charge.created_at).format('MM/DD/YYYY')}</span>
          </TableCell>
          <TableCell>
            <span className="pr-1 pl-1">{charge.summary}</span>
          </TableCell>
          <TableCell>
            <span className="pr-1 pl-1">{'Charge Card Payment'}</span>
          </TableCell>
          <TableCell>
            <span className="pr-1 pl-1">{'N/A'}</span>
          </TableCell>
          <TableCell data-id="payment-row-amount-value">
            <span className="pr-1 pl-1">{formatCentsAsDollars(charge.amount)}</span>
          </TableCell>
          <TableCell data-id="payment-row-status-value">{titleCase(item.status)}</TableCell>
          <TableCell data-id="transaction-row-status-value">
            <span className="pr-1 pl-1">-</span>
          </TableCell>
          <TableCell data-id="transaction-row-status-value">
            <span className="pr-1 pl-1">-</span>
          </TableCell>
        </TableRow>
      );
    } else if (item.type == 'cashback') {
      const cashback = item as Cashback;
      return (
        <TableRow
          key={index}
          data-id="cashback-row"
          className={`rounded-sm bg-white flex-row items-center table-row-content`}
        >
          <TableCell data-id="cashback-row-created-at-value">
            <span className="pr-1 pl-1">{dayjs(cashback.created_at).format('MM/DD/YYYY')}</span>
          </TableCell>
          <TableCell data-id="cashback-row-name-value">
            <span className="pr-1 pl-1">{'CASHBACK'}</span>
          </TableCell>
          <TableCell>
            <span className="pr-1 pl-1">{'N/A'}</span>
          </TableCell>
          <TableCell>
            <span className="pr-1 pl-1">{'N/A'}</span>
          </TableCell>
          <TableCell data-id="cashback-row-amount-value">
            <span className="pr-1 pl-1">{formatCentsAsDollars(cashback.amount)}</span>
          </TableCell>
          <TableCell data-id="cashback-row-status-value">{titleCase(item.status)}</TableCell>
          <TableCell data-id="transaction-row-status-value">
            <span className="pr-1 pl-1">-</span>
          </TableCell>
          <TableCell data-id="transaction-row-status-value">
            <span className="pr-1 pl-1">-</span>
          </TableCell>
        </TableRow>
      );
    }
  };

  const createMobileRow = (item: Transaction | Charge, index: number) => {
    if (item.type == 'transaction') {
      const transaction = item as Transaction;

      const clickedCard = () =>
        dispatcher({
          type: TransactionPageActionType.SelectedTransactionForPopup,
          payload: transaction,
        });

      return <TransactionRichDataCell transaction={transaction} onClick={clickedCard} />;
    } else if (item.type == 'charge') {
      const charge = item as Charge;
      return (
        <Card
          key={index}
          className={`flex-row w-full bg-white rounded-lg justify-between mb-1 px-1 py-1 ${
            charge.status === 'pending' ? 'table-row-mobile-pending' : 'table-row-mobile'
          }`}
        >
          <div className="flex-column">
            <span className="date font-noto-sans font-xs mt-quarter">
              {dayjs(charge.created_at).format('MM/DD/YYYY')}
            </span>
            <span className="merchant font-bold font-noto-sans">{charge.summary}</span>
            <span className="category font-noto-sans font-xs">{'Charge Card Payment'}</span>
            <span className="card font-noto-sans font-xs">{''}</span>
          </div>

          <div className="flex-column">
            <span className="amount font-noto-sans mt-quarter">
              {formatCentsAsDollars(-charge.amount)}
            </span>
          </div>
        </Card>
      );
    } else if (item.type == 'cashback') {
      const cashback = item as Cashback;
      return (
        <TableRow
          key={index}
          data-id="cashback-row"
          className={`pointer rounded-sm bg-white flex-row items-center table-row-content`}
        >
          <TableCell data-id="cashback-row-created-at-value">
            <span className="pr-2 pl-2">{dayjs(cashback.created_at).format('MM/DD/YYYY')}</span>
          </TableCell>
          <TableCell data-id="cashback-row-name-value">
            <span className="pr-2 pl-2">{'CASHBACK'}</span>
          </TableCell>
          <TableCell>
            <span className="pr-2 pl-2">{''}</span>
          </TableCell>
          <TableCell>
            <span className="pr-2 pl-2">{''}</span>
          </TableCell>
          <TableCell data-id="cashback-row-amount-value">
            <span className="pr-2 pl-2">{formatCentsAsDollars(cashback.amount)}</span>
          </TableCell>
          <TableCell data-id="cashback-row-status-value">{titleCase(item.status)}</TableCell>
          <TableCell>
            <span className="pr-2 pl-2">{''}</span>
          </TableCell>
        </TableRow>
      );
    }
  };

  return (
    <>
      <div className="sm-no-display w-full">
        <TableWrapper
          data-id="transaction-row-headers"
          headers={state.transactionHeaders.map(header => getLangText(header))}
        >
          {parentState.transactions.map((t: Transaction, i: number) => createRow(t, i))}
        </TableWrapper>
      </div>

      <div className="transactions-mobile none sm-flex bg-page sm-mr-1 flex-column">
        {parentState.transactions.map((t: Transaction, i: number) => createMobileRow(t, i))}
      </div>
    </>
  );
});

export default TransactionRows;
