import { QueryKey, useMutation } from '@tanstack/react-query';
import tagsService from '../services/tags.service';
import { queryClient } from '../../../common/queryClient';
import { PaginatedData } from '../../../common/pagination';
import { Tag } from '../types';

export const useDeleteTag = () =>
  useMutation({
    mutationFn: tagsService.deleteTag,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/tags'] });
    },
  });
