import { useNavigate, useLocation } from 'react-router-dom';

interface CreditAccountingNavBarProps {
  tabs: { title: string; path: string }[];
}

const CustomNavBar = (props: CreditAccountingNavBarProps) => {
  const { tabs } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const onTabChange = (path: string) => {
    navigate(path);
  };

  const noTabSelected = tabs.every(({ path }) => pathname !== path);

  return (
    <div className="flex items-center py-1">
      {tabs.map(({ title, path }) => {
        const isActive = pathname === path || (noTabSelected && path === tabs[0].path);
        return (
          <span
            key={title}
            onClick={() => onTabChange(path)}
            className={`text-center py-[7px] px-[18px] text-sm cursor-pointer mr-1 rounded-full transition-opacity ease-in-out hover:opacity-80 ${
              isActive ? 'bg-[#063021] text-white' : 'bg-slate-100 text-black'
            }`}
          >
            {title}
          </span>
        );
      })}
    </div>
  );
};

export default CustomNavBar;
