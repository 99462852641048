import { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes as R } from 'react-router-dom';
import { useLocation } from 'react-router';
import { intOrFloat } from './helpers/base';
import { ENV_IS_DEV } from './config/environment';
import { ScreenProps } from './interfaces/baseInterfaces';
import Spinner from 'app/components/Spinner';
import UnmatchedRoutes from 'app/pages/public/unmatched-routes/UnmatchedRoutes';
import RequiresCompanySetup from 'app/pages/authWrappers/RequiresCompanySetup';
import RequiresDepositAccount from 'app/pages/authWrappers/RequiresDepositAccount';
import DashboardPagesLayout from './app/pages/dashboard/layout';
import OnboardingPagesLayout from './app/pages/onboarding/layout';
import VendorPaymentLayout from './app/pages/dashboard/vendors/pay/layout';
import VendorPagesLayout from './app/pages/dashboard/vendors/layout';
import RequiresAnyAccount from './app/pages/authWrappers/RequiresAnyAccount';
import TransactionPage from './app/pages/dashboard/transactions';
import DepositDummyPage from './features/depositAccount/components/dummyPage';
import DepositPage from './app/pages/dashboard/deposit';
import CardPage from './app/pages/dashboard/cards';
import AccountingCreditPage from './app/pages/dashboard/accounting/credit';
import AccountingCreditStatementsPage from './app/pages/dashboard/accounting/credit/statements';
import AccountingCreditPaymentsPage from './app/pages/dashboard/accounting/credit/payments';
import CreditHistoricalStatementsPage from './app/pages/dashboard/accounting/credit/historicalStatements';
import AccountingIntegrationsPage from './app/pages/dashboard/accounting/integrations';
import RequiresUnitApplication from './app/pages/authWrappers/RequiresUnitApplication';
import TeamPage from './app/pages/dashboard/team';
import CreditPage from 'features/credit';
import TagsPage from './app/pages/dashboard/tags';
import AccountingPagesLayout from './app/pages/dashboard/accounting/layout';
import { useUserRole } from './features/auth/data/userRole';
import BankingAccountingPage from './app/pages/dashboard/accounting/banking';
import BankingStatementsPage from './app/pages/dashboard/accounting/banking/statements';
import QuickBooksOnlinePage from './app/pages/dashboard/accounting/integrations/QuickBooksOnlinePage';

const InviteOnboardingPage = lazy(() => import('./app/pages/public/invite-onboarding'));
const ForgotPassword = lazy(() => import('./app/pages/public/forgot-password'));
const ResetPassword = lazy(() => import('./app/pages/public/reset-password'));
const SignInPage = lazy(() => import('./app/pages/public/sign-in'));
const GetStartedPage = lazy(() => import('./app/pages/public/get-started'));
const NotFound = lazy(() => import('./app/pages/public/404'));
const SettingsPage = lazy(() => import('./app/pages/dashboard/settings'));
const FundingPage = lazy(() => import('./app/pages/dashboard/funding'));
const RejectedApplicationPage = lazy(() => import('./app/pages/onboarding/rejected-application'));
const VendorsPage = lazy(() => import('./app/pages/dashboard/vendors'));

const PayVendorPage = lazy(() => import('./app/pages/dashboard/vendors/pay'));

const AddVendorPage = lazy(() => import('./app/pages/dashboard/vendors/add'));

const PayVendorAmountPage = lazy(() => import('./app/pages/dashboard/vendors/pay/amountPage'));

const PayVendorMoreDetailsPage = lazy(
  () => import('./app/pages/dashboard/vendors/pay/moreDetailsPage'),
);

const PayVendorReviewPage = lazy(() => import('./app/pages/dashboard/vendors/pay/reviewPage'));

export default function Routes(routerProps: ScreenProps) {
  const { isCreditCustomer, isDepositCustomer, isAdmin, isBookkeeper, isEmployee } = useUserRole();
  const isAllowedToViewAccounting = isAdmin || isBookkeeper;
  const isAllowedToViewCards = isAdmin || isEmployee;

  return (
    <BrowserRouter basename={'/web'}>
      <Suspense fallback={<Spinner />}>
        <Tracker>
          <R>
            <Route path="/sign-in" element={<SignInPage {...routerProps} />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword {...routerProps} />} />
            <Route path="/get-started" element={<GetStartedPage {...routerProps} />} />

            <Route path="/onboarding" element={<OnboardingPagesLayout />}>
              <Route path="invite/sign-up" element={<InviteOnboardingPage {...routerProps} />} />
              {/* the two routes below are for marketing. please do not remove and maintain the order. */}
              <Route
                path="not-qualified"
                element={
                  <RequiresCompanySetup {...routerProps}>
                    <RejectedApplicationPage {...routerProps} />
                  </RequiresCompanySetup>
                }
              />
            </Route>

            <Route path="/dashboard" element={<DashboardPagesLayout {...routerProps} />}>
              <Route
                index
                element={
                  <RequiresAnyAccount {...routerProps}>
                    <TransactionPage {...routerProps} />
                  </RequiresAnyAccount>
                }
              />
              <Route
                path="home"
                element={
                  <RequiresDepositAccount
                    {...routerProps}
                    dangerouslySetFallbackPage={<DepositDummyPage {...routerProps} />}
                  >
                    <DepositPage {...routerProps} />
                  </RequiresDepositAccount>
                }
              />
              <Route
                path="transactions"
                element={
                  <RequiresAnyAccount {...routerProps}>
                    <TransactionPage {...routerProps} />
                  </RequiresAnyAccount>
                }
              />
              {isAllowedToViewCards && (
                <Route
                  path="cards"
                  element={
                    <RequiresAnyAccount {...routerProps}>
                      <CardPage {...routerProps} />
                    </RequiresAnyAccount>
                  }
                />
              )}
              {isAllowedToViewAccounting && (
                <Route
                  path="accounting"
                  element={
                    <RequiresAnyAccount {...routerProps}>
                      <AccountingPagesLayout />
                    </RequiresAnyAccount>
                  }
                >
                  <Route index element={<AccountingIntegrationsPage />} />
                  {isDepositCustomer && (
                    <Route path="banking" element={<BankingAccountingPage />}>
                      <Route index element={<BankingStatementsPage />} />
                      <Route path="statements" element={<BankingStatementsPage />} />
                    </Route>
                  )}
                  {isCreditCustomer && (
                    <Route path="credit" element={<AccountingCreditPage />}>
                      <Route index element={<AccountingCreditPaymentsPage />} />
                      <Route path="payments" element={<AccountingCreditPaymentsPage />} />
                      <Route path="statements" element={<AccountingCreditStatementsPage />} />
                      <Route
                        path="historical-statements"
                        element={<CreditHistoricalStatementsPage />}
                      />
                    </Route>
                  )}
                  <Route path="integrations">
                    <Route index element={<AccountingIntegrationsPage />} />
                    <Route path="quickbooks-online" element={<QuickBooksOnlinePage />} />
                  </Route>
                </Route>
              )}
              <Route
                path="team"
                element={
                  <RequiresUnitApplication {...routerProps}>
                    <TeamPage {...routerProps} />
                  </RequiresUnitApplication>
                }
              />
              <Route
                path="funding"
                element={
                  <RequiresCompanySetup {...routerProps}>
                    <FundingPage {...routerProps} />
                  </RequiresCompanySetup>
                }
              />
              <Route
                path="/dashboard/credit"
                element={
                  <RequiresCompanySetup {...routerProps}>
                    <CreditPage {...routerProps} />
                  </RequiresCompanySetup>
                }
              />
              <Route path="settings" element={<SettingsPage {...routerProps} />} />
              <Route
                path="vendors"
                element={
                  <RequiresDepositAccount {...routerProps}>
                    <VendorPagesLayout />
                  </RequiresDepositAccount>
                }
              >
                <Route index element={<VendorsPage {...routerProps} />} />
                <Route path="add" element={<AddVendorPage {...routerProps} />} />
                <Route path="pay" element={<VendorPaymentLayout />}>
                  <Route index element={<PayVendorPage {...routerProps} />} />
                  <Route path="amount" element={<PayVendorAmountPage {...routerProps} />} />
                  <Route
                    path="more-details"
                    element={<PayVendorMoreDetailsPage {...routerProps} />}
                  />
                  <Route path="review" element={<PayVendorReviewPage {...routerProps} />} />
                </Route>
              </Route>
              <Route
                path="tags"
                element={
                  <RequiresAnyAccount {...routerProps}>
                    <TagsPage />
                  </RequiresAnyAccount>
                }
              />
            </Route>

            <Route path="/*" element={<UnmatchedRoutes {...routerProps} />} />

            {/* 404 */}
            <Route path="*" element={<NotFound />} />
          </R>
        </Tracker>
      </Suspense>
    </BrowserRouter>
  );
}

const Tracker = (props: any) => {
  const location = useLocation();

  useEffect(() => {
    if (!ENV_IS_DEV) {
      const route = location.pathname.split('/').reduce(
        (acc: { page: string; id: string | null }, str: string) => {
          const pageOrId = str[0] ? str.charAt(0).toUpperCase() + str.slice(1) : str;
          return {
            ...acc,
            page: acc.page + (intOrFloat(pageOrId) ? '' : pageOrId),
            id: intOrFloat(pageOrId) ? pageOrId : null,
          };
        },
        { page: '', id: null },
      );
    }
  }, [location.pathname]);
  return props.children;
};
