import { usePostIntegrationInterest } from '../../companies/data/postIntegrationInterest';
import posthog from 'posthog-js';
import IntegrationConnectionCard from './IntegrationConnectionCard';
import xero_logo from 'app/assets/images/xero.png';

interface XeroConnectionCardProps {}

const XeroConnectionCard = (props: XeroConnectionCardProps) => {
  const { mutate: postInterest, isPending, isSuccess } = usePostIntegrationInterest();

  const handleConnection = () => {
    postInterest('xero');
    posthog.capture('Clicked Xero Button');
  };

  return (
    <IntegrationConnectionCard
      title="Xero"
      subtitle="Coming Soon"
      onConnect={handleConnection}
      connectLabel="Indicate Interest"
      logo={xero_logo}
      disabled={isSuccess || isPending}
    />
  );
};

export default XeroConnectionCard;
