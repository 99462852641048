import React from 'react';
import { Typography, Box, InputAdornment } from '@mui/material';
import AddressAutoComplete from '../AddressAutoComplete';
import Input from '../Input';

interface ShippingDetailsProps {
  setFirstAddress: React.Dispatch<React.SetStateAction<any>>;
  setSecondAddress: React.Dispatch<React.SetStateAction<any>>;
  setPostalCode: React.Dispatch<React.SetStateAction<string>>;
  secondAddress: string;
  postalCode: string;
}

const ShippingDetails: React.FC<ShippingDetailsProps> = ({
  setFirstAddress,
  setSecondAddress,
  setPostalCode,
  secondAddress,
  postalCode,
}) => {
  return (
    <div>
      <Typography
        sx={{
          marginBottom: 1,
          marginTop: 2,
          color: '#3A6959',
          fontSize: '14px',
          fontStyle: 'normal',
          lineHeight: '140%',
          fontVariant: 'all-small-caps',
        }}
      >
        Shipping Details
      </Typography>

      <Box sx={{ gap: '16px', my: 2 }}>
        {' '}
        <AddressAutoComplete
          customStyle
          helperText="Address Line 1"
          cb={(address: string, city: string, state: string, zipCode: string) => {
            setFirstAddress({ address, city, state, zipCode });
          }}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        <Input
          dataCy="addressLine2"
          style={{ flex: 1 }}
          size="medium"
          placeholder={'Address Line 2 (optional)'}
          value={secondAddress}
          onChange={value => {
            setSecondAddress(value);
          }}
        />

        <Input
          dataCy="postalCode"
          style={{ flex: 1 }}
          size="medium"
          placeholder="Postal Code"
          value={postalCode}
          onChange={value => {
            setPostalCode(value);
          }}
        />
      </Box>
    </div>
  );
};

export default ShippingDetails;
