import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions, IconButton, Menu, MenuItem, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FinancialCardProps } from 'interfaces/components';
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CreditPaymentButton from '../../../features/credit/components/CreditPaymentButton';

export const FinancialCard: React.FC<FinancialCardProps> = ({
  title,
  subtitle,
  amount,
  highlight = false,
  showPaymentButton,
  backgroundColor = '#F7F7F7',
  titleColor = 'black',
  subtitleColor = '#09442F',
  height = '100%',
  width = 322,
  additionalSectionTitle,
  additionalSectionAmount,
  pendingPaymentsText,
  pendingPaymentsTextColor = 'black',
  key,
  tooltipText,
  icon,
  iconBackgroundColor = 'rgba(58, 105, 89, 0.10)',
}) => {
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const sanitizeTitle = (title: string) => {
    return title.replace(/\s+/g, '_');
  };

  const cardStyle: React.CSSProperties = {
    backgroundColor,
    color: titleColor,
    height,
    minWidth: width,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    position: 'relative',
  };

  const newTitle = sanitizeTitle(title);

  const subtitleStyle: React.CSSProperties = {
    fontSize: '0.8rem',
    color: subtitleColor,
    opacity: 0.6,
  };

  const iconContainerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '36px',
    width: '36px',
    backgroundColor: iconBackgroundColor,
    borderRadius: '50%',
    marginRight: '10px',
    flexShrink: 0,
  };

  const pendingPaymentsTextStyle: React.CSSProperties = {
    fontSize: '0.8rem',
    color: matchesMobile ? '#fff' : pendingPaymentsTextColor,
  };

  const additionalSectionTextStyle: React.CSSProperties = {
    fontSize: '1rem',
    color: titleColor,
    lineHeight: '1.4',
  };

  const additionalSectionLabelStyle: React.CSSProperties = {
    fontSize: '0.6rem',
    color: 'rgba(247, 247, 247, 0.7)',
  };

  const additionalSectionStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexShrink: 0,
    flexGrow: 1,
    paddingLeft: 28,
    borderLeft: '1px solid rgba(247, 247, 247, 0.2)',
  };

  return (
    <Card
      className={`shadow-lg mb-4 md:mb-2 ${highlight ? 'bg-green-500 text-white' : ''}`}
      style={cardStyle}
      key={key}
    >
      <CardActionArea>
        <CardContent className="flex items-start justify-between py-1 px-0 md:py-4 md:px-4">
          <div
            style={{
              display: 'flex',
              width: '100%',
            }}
          >
            {icon && <div style={iconContainerStyle}>{icon}</div>}
            <div className="w-full">
              <Typography
                gutterBottom
                component="div"
                className="text-md "
                style={{ color: titleColor, opacity: 0.7 }}
                data-cy={`cardTitle-${newTitle}`}
              >
                {title}
              </Typography>
              {subtitle && (
                <Typography component="div" style={subtitleStyle} data-cy="dateDueText">
                  {subtitle}
                </Typography>
              )}
              <div className="flex">
                <div className="w-1/2">
                  {!subtitle && (
                    <Typography
                      variant="h6"
                      component="div"
                      className="font-bold"
                      data-cy={`amount-${newTitle}`}
                    >
                      {amount}
                    </Typography>
                  )}
                </div>
                <div className="flex w-1/2">
                  {additionalSectionTitle && additionalSectionAmount && (
                    <div style={additionalSectionStyle}>
                      <Typography
                        component="div"
                        style={additionalSectionLabelStyle}
                        data-cy="holdAmountLabel"
                      >
                        {additionalSectionTitle}
                      </Typography>
                      <Typography
                        component="div"
                        style={additionalSectionTextStyle}
                        data-cy="holdAmount"
                      >
                        {additionalSectionAmount}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {tooltipText && (
            <Tooltip
              title={tooltipText}
              placement="top"
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              style={{ padding: 0 }}
            >
              <IconButton
                onClick={event => {
                  event.stopPropagation();
                  setTooltipOpen(!tooltipOpen);
                }}
                onBlur={() => setTooltipOpen(false)}
              >
                <InfoOutlinedIcon style={{ color: titleColor, width: 20, height: 20 }} />
              </IconButton>
            </Tooltip>
          )}
        </CardContent>

        {subtitle && (
          <CardContent
            sx={{
              py: 0,
            }}
          >
            <div>
              <Typography
                variant="h6"
                component="div"
                className="font-bold"
                data-cy="pendingPaymentsAmount"
              >
                {amount}
              </Typography>
              {pendingPaymentsText && (
                <Typography
                  variant="h6"
                  component="div"
                  style={pendingPaymentsTextStyle}
                  data-cy="pendingPaymentsText"
                >
                  {pendingPaymentsText}
                </Typography>
              )}
            </div>
          </CardContent>
        )}
      </CardActionArea>

      {showPaymentButton && (
        <CardActions className="justify-center w-[96%] mx-auto">
          <CreditPaymentButton buttonText="Pay Now" className="w-full" />
        </CardActions>
      )}
    </Card>
  );
};

export default FinancialCard;
