import { MenuItem, Select } from '@mui/material';
import CategoryIcon from '../CategoryIcon';

interface CategoryPopupSelectorInputFieldProps {
  selectedCategories: string[];
  onClick: () => void;
  className?: string;
  placeholder?: string;
}

const CategoryPopupSelectorInputField = (props: CategoryPopupSelectorInputFieldProps) => {
  const { selectedCategories, onClick, className, placeholder } = props;
  const noTagSelected = selectedCategories.length === 0;
  const showCategoryIcon = selectedCategories.length === 1;

  return (
    <Select
      labelId="tag-popup-selector"
      id="demo-simple-select-readonly"
      data-cy="trans-select-category"
      size="small"
      value={['']}
      onClick={onClick}
      className={className}
      inputProps={{
        readOnly: true,
      }}
      renderValue={() => (
        <span className="flex items-center">
          <span className="ml-[5px] text-gray-600">
            {showCategoryIcon && <CategoryIcon category={selectedCategories[0]} className="mr-1" />}
            {noTagSelected
              ? placeholder || 'Select categories'
              : selectedCategories.map(category => category).join(', ')}
          </span>
        </span>
      )}
    >
      <MenuItem value={''}>Select Tags</MenuItem>
    </Select>
  );
};

export default CategoryPopupSelectorInputField;
