import React, { CSSProperties } from 'react';
import { FormControl, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

interface CustomSelectProps {
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  options: Array<{ value: string; label: string }>;
  style?: React.CSSProperties;
}

const selectStyles: { layout: CSSProperties; select: CSSProperties } = {
  layout: {
    display: 'flex',
    padding: '0px ',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '0px',
    flex: '1 0 0',
  },
  select: {
    width: '155px',
  },
};

const StyledExpandMoreIcon = styled(ExpandMoreIcon)({
  marginRight: '6px',
});

const CustomSelect: React.FC<CustomSelectProps> = ({ value, onChange, options, style }) => (
  <div className="flex-grow-2 pt-1" style={{ ...selectStyles.layout, ...style }}>
    <FormControl fullWidth={true} size="small" style={selectStyles.select}>
      <Select
        inputProps={{
          'data-cy': 'selectTeam',
        }}
        value={value}
        onChange={onChange}
        IconComponent={StyledExpandMoreIcon}
      >
        {options.map((option, index) => (
          <MenuItem
            data-cy={`team-${option.value}-${index}`}
            key={option.value}
            value={option.value}
            sx={{
              '&.Mui-selected': {
                color: 'white',
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
);

export default CustomSelect;
