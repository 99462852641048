import './style.scss';
import { useEffect, useReducer, memo, useState, Suspense, useRef } from 'react';
import financialService from '../../../../services/financial.service';
import {
  CompanyTeamMember,
  CreditCard,
  CreditCard as ICreditCard,
  ScreenProps,
} from 'interfaces/baseInterfaces';
import DeleteConfirmPopUp from '../../../components/GenericPopUp';
import { CardLimitsPopup } from '../../../components/CardLimitsPopup';
import { SensitiveCardInfoPopUp } from '../../../components/SensitiveCardInfoPopUp';
import { Helmet } from 'react-helmet';
import posthog from 'posthog-js';
import { cardPageInitialState, cardPageReducer, CardPageActionType } from './reducer';
import Spinner from 'app/components/Spinner';
import { CompanyRole } from 'interfaces/company';
import {
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CardList from 'app/components/CardList';
import unitService from '../../../../services/unit.service';
import userService from 'services/user.service';
import currentEnv from '../../../../config/environment';
import GenericPopUp from '../../../components/GenericPopUp';
import Input from '../../../components/Input';
import { standardError, validateEmail } from 'helpers/base';
import { Text } from '../../../../contexts/language';
import { TeamMemberInvite } from '../../../components/TeamMemberInvite';
import Tabs from 'app/components/Tabs';
import CustomSelect from 'app/components/CustomSelect';
import AddIcon from '@mui/icons-material/Add';
import CustomDialog from 'app/components/CustomDialog';
import StyledGenericPopup from 'app/components/StyledGenericPopUp';
import { formatCentsAsDollars, formatDollarNumber } from 'helpers/formattings';
import companyService from 'services/companyService';
import CardCreatedDialog from 'app/components/Dialogs/CardCreatedDialog';
import DialogFooterButtons from 'app/components/DialogFooterButtons';
import AddTeamMember from 'app/components/AddTeamMember';
import AccountTypeSelection from 'app/components/AccountTypeSelection';
import CardTypeSelection from 'app/components/CardTypeSelection';
import CardDetails from 'app/components/CardDetails';
import ShippingDetails from 'app/components/ShippingDetails';
import AddTeamMemberForm from 'app/components/AddTeamMemberForm';
import { useTeamMembers } from 'app/hooks/useTeamMembers';
import { TabContent } from 'interfaces/components';
import { CloseIcon } from 'app/components/Icon/fontawesome';
import { isAdministrative } from '../../../../common/company';
import CardAssignmentPopup from '../../../components/CardAssignmentPopUp';
import { useGetDepositAccount } from '../../../../features/depositAccount/data/getDepositAccount';
import FailureDialogModal from 'app/components/Dialogs/FailureDialog';
import { useGetDepositAccountLimits } from 'features/depositAccount/data/getDepositAccountLimits';
import Show from '../../../components/Show';

interface CardsPageProps extends ScreenProps {}

const CardPage = memo(function CardPage(props: CardsPageProps) {
  const { currentUser } = props;
  const theme = useTheme();
  const [state, dispatch] = useReducer(cardPageReducer, cardPageInitialState);
  const [is_vgs_collect_loaded, set_is_vgs_collect_loaded] = useState(false);
  const [is_vgs_show_loaded, set_is_vgs_show_loaded] = useState(false);
  const [isLoadingCardData, setIsLoadingCardData] = useState(false);
  const card_id = new URLSearchParams(window.location.search).get('update_card_id');
  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [addMemberError, setAddMemberError] = useState('');

  const [isSendingInvite, setIsSendingInvite] = useState(false);

  const [inviteEmail, setInviteEmail] = useState('');
  const [firstAddress, setFirstAddress] = useState({
    address: '',
    city: '',
    state: '',
    zipCode: '',
  });
  const [secondAddress, setSecondAddress] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [inviteRole, setInviteRole] = useState('');
  const [roleOptions] = useState(['super_admin', 'admin', 'employee', 'bookkeeper']);

  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isFailureDialogOpen, setIsFailureDialogOpen] = useState(false);

  const [physicalDialogOpen, setPhysicalDialogOpen] = useState(false);

  const [createCardLoading, setCreateCardLoading] = useState(false);

  const [inviteStatus, setInviteStatus] = useState({ sent: false, email: '' });

  const [initial_monthly_limit, set_initial_monthly_limit] = useState(0);

  const [selectedCardType, setSelectedCardType] = useState('all_cards');

  const [createCardDialogOpen, setCreateCardDialogOpen] = useState(false);

  const [selectedAccountType, setSelectedAccountType] = useState('');

  const [token_verification_requested, set_token_verification_requested] = useState(false);

  const [cardType, setCardType] = useState('');

  const showAddCardButton = ['super_admin', 'admin', 'account_owner'].includes(
    props.currentUser?.team_role,
  );

  const [selectedTeamMember, setSelectedTeamMember] = useState<CompanyTeamMember | null>(null);

  const [showAddButton, setShowAddButton] = useState(false);

  const [showAddMember, setShowAddMember] = useState(false);

  const [debitCardDailyLimit, setDebitCardDailyLimit] = useState<string>('');

  const addButtonRef = useRef<HTMLButtonElement>(null);

  const { data: depositAccount } = useGetDepositAccount();

  const {
    data: depositAccountLimits,
    isLoading: isFetchingLimits,
    isError: isDepositAccountLimitError,
  } = useGetDepositAccountLimits();

  const availableBalance = depositAccount?.available ?? 0;

  const getsExpeditedShipping =
    (currentUser.is_deposit_customer && availableBalance >= 30000) ||
    (currentUser.is_credit_customer && state.cardholderLimit >= 30000);

  const teamMembers = useTeamMembers();

  const handleCreateCardDialogClose = () => {
    setCreateCardDialogOpen(false);
  };

  const handleSuccessDialogClose = () => {
    setIsSuccessDialogOpen(false);
  };

  const handleFailureDialogClose = () => {
    setIsFailureDialogOpen(false);
  };

  const onPhysicalCardCancel = () => {
    setPhysicalDialogOpen(false);
    setCreateCardDialogOpen(true);
  };

  const onRequestPhysicalCardClick = () => {
    posthog.capture('Add new card click');
    setPhysicalDialogOpen(false);
    dispatch({ type: CardPageActionType.CreateCardRequest, payload: true });
    dispatch({ type: CardPageActionType.ConfirmationCodePopupOpen, payload: true });
  };

  const onCardRequestContinue = () => {
    posthog.capture('Add new card click');
    setCreateCardDialogOpen(false);
    if (cardType === 'physical') {
      return setPhysicalDialogOpen(true);
    }
    dispatch({ type: CardPageActionType.CreateCardRequest, payload: true });
    dispatch({ type: CardPageActionType.ConfirmationCodePopupOpen, payload: true });
  };

  const handleAddTeamMember = () => {
    setSelectedTeamMember(null);
    setShowAddMember(true);
  };

  const handleAddMember = async () => {
    setIsSendingInvite(true);
    try {
      const res = await companyService.inviteTeamMember({
        email: inviteEmail,
        role: inviteRole || 'employee',
        title: 'Other',
      });
      if (res?.error) {
        standardError(res.error);
        setAddMemberError(res.error);
        return;
      }
      if (res) {
        setInviteEmail('');
        setInviteRole('');
        setInviteStatus({ sent: true, email: inviteEmail });
        setShowAddMember(false);
      }
    } catch (e) {
      standardError(e);
    } finally {
      setIsSendingInvite(false);
    }
  };

  const handleDropdownOpen = () => {
    setShowAddButton(true);
  };

  const handleDropdownClose = (e: React.SyntheticEvent) => {
    if (addButtonRef.current && addButtonRef.current.contains(e.target as Node)) {
      return;
    }
    setShowAddButton(false);
  };

  const requestTokenVerification = async () => {
    const resp = await unitService.createTokenVerification();

    if (resp.error) {
      standardError(Error(resp.error));
      set_token_verification_requested(true);
      props.handleError(resp.error);
      return;
    }
  };

  const clearCardFields = () => {
    setFirstName('');
    setLastName('');
    setInviteEmail('');
    setInviteRole('');
    setPostalCode('');
    setFirstAddress({
      address: '',
      city: '',
      state: '',
      zipCode: '',
    });
    setSecondAddress('');
    setCardType('');
    setSelectedAccountType('');
    set_initial_monthly_limit(0);
    setSelectedTeamMember(null);
    setInviteStatus({ sent: false, email: '' });
  };

  const createCard = async () => {
    let payload: any =
      cardType == 'physical'
        ? {
            first_name: inviteStatus.sent
              ? firstName
              : selectedTeamMember?.first_name ?? currentUser.first_name,
            last_name: inviteStatus.sent
              ? lastName
              : selectedTeamMember?.last_name ?? currentUser.last_name,
            line1: firstAddress.address,
            line2: secondAddress,
            city: firstAddress.city,
            state: firstAddress.state,
            postal_code: postalCode,
            type: cardType,
            product: selectedAccountType,
            initial_monthly_spending_limit: Math.trunc(initial_monthly_limit * 100),
          }
        : {
            first_name: inviteStatus.sent
              ? firstName
              : selectedTeamMember?.first_name ?? currentUser.first_name,
            last_name: inviteStatus.sent
              ? lastName
              : selectedTeamMember?.last_name ?? currentUser.last_name,
            type: cardType,
            product: selectedAccountType,
            initial_monthly_spending_limit: Math.trunc(initial_monthly_limit * 100),
          };

    payload = { ...payload, unit_verification_code: state.verificationCode };

    const selected_user_id = selectedTeamMember?.id;

    setCreateCardLoading(true);
    if (selected_user_id) {
      const resp = await financialService.addNewMemberCard(selected_user_id, payload);

      setCreateCardLoading(false);
      clearCardFields();
      loadCards('Active');
      loadCards('Inactive');
      loadCards('Frozen');

      if (!resp.error) {
        if (cardType == 'physical') {
          setPhysicalDialogOpen(false);
        }
        setIsSuccessDialogOpen(true);
      }

      if (resp.error) {
        setIsFailureDialogOpen(true);
        standardError(resp.error);
      }
    } else {
      const resp = await financialService.addNewCard(payload);

      setCreateCardLoading(false);

      if (!resp.error) {
        if (cardType == 'physical') {
          setPhysicalDialogOpen(false);
        }
        setIsSuccessDialogOpen(true);
      }

      clearCardFields();
      loadCards('Active');
      loadCards('Inactive');
      loadCards('Frozen');

      if (resp.error) {
        setIsFailureDialogOpen(true);
        standardError(resp.error);
      }
    }
  };

  const loadCards = async (status: string) => {
    if (status != 'Active') dispatch({ type: CardPageActionType.Loading, payload: false });
    if (status == 'Active')
      dispatch({ type: CardPageActionType.LoadingActiveCards, payload: true });
    else if (status == 'Inactive')
      dispatch({ type: CardPageActionType.LoadingPendingCards, payload: true });
    else if (status == 'Canceled')
      dispatch({ type: CardPageActionType.LoadingCanceledCards, payload: true });

    await financialService
      .getCards({ status: status })
      .then(async res => {
        if (res?.error) throw new Error();
        if (res) {
          const { cards, cardholder_limit } = res;
          if (status == 'Active')
            dispatch({ type: CardPageActionType.ActiveCards, payload: cards });
          if (status == 'Inactive')
            dispatch({ type: CardPageActionType.InactiveCards, payload: cards });
          if (status == 'Canceled')
            dispatch({ type: CardPageActionType.CanceledCards, payload: cards });
          if (status == 'Frozen')
            dispatch({ type: CardPageActionType.FrozenCards, payload: cards });
          dispatch({ type: CardPageActionType.CardholderLimit, payload: cardholder_limit });
          if (state.shouldShowCardToUpdate && card_id) {
            const filteredCards: ICreditCard[] = cards.filter((c: ICreditCard) => c.id === card_id);

            if (filteredCards.length) {
              const card = filteredCards[0];
              dispatch({ type: CardPageActionType.CardLimitsPopupOpen, payload: true });
              dispatch({ type: CardPageActionType.CardLimitsCard, payload: card });
              dispatch({ type: CardPageActionType.ShouldShowCardToUpdate, payload: false });
            }
          }
        }
      })
      .catch(err => {
        // TODO: show the user there was an error getting cards
        standardError(err);
      })
      .finally(() => {
        if (status === 'Active')
          dispatch({ type: CardPageActionType.LoadingActiveCards, payload: false });
        else if (status === 'Inactive')
          dispatch({ type: CardPageActionType.LoadingPendingCards, payload: false });
        else if (status === 'Canceled')
          dispatch({ type: CardPageActionType.LoadingCanceledCards, payload: false });
      });
    dispatch({ type: CardPageActionType.Loading, payload: false });
  };

  const deleteCard = async (unitVerificationCode?: string) => {
    dispatch({ type: CardPageActionType.DeleteCardLoading, payload: true });

    const resp = await financialService.cancelCard(state.cardToDelete!.id, unitVerificationCode);

    dispatch({ type: CardPageActionType.DeleteCardLoading, payload: false });

    if (resp.error) {
      standardError(Error(resp.error));
      props.handleError(resp.error);
      return;
    }

    dispatch({ type: CardPageActionType.ConfirmPopupOpen, payload: false });
    dispatch({ type: CardPageActionType.ConfirmationCodePopupOpen, payload: false });
    dispatch({ type: CardPageActionType.CardToDelete, payload: null });

    setTimeout(() => {
      loadCards('Canceled');
    }, 1500);
  };

  const changeFreezeStatus = async (unitVerificationCode: string) => {
    let cardToChange = null;
    let freeze: boolean | null = null;
    if (state.cardToFreeze) {
      cardToChange = state.cardToFreeze;
      freeze = true;
    } else {
      cardToChange = state.cardToUnfreeze;
      freeze = false;
    }

    dispatch({ type: CardPageActionType.Loading, payload: true });

    const resp = await financialService.changeCardFreezeStatus(
      cardToChange!.id,
      unitVerificationCode,
      freeze,
    );

    dispatch({ type: CardPageActionType.Loading, payload: false });

    if (resp.error) {
      standardError(Error(resp.error));
      props.handleError(resp.error);
      return;
    }

    dispatch({ type: CardPageActionType.ConfirmPopupOpen, payload: false });
    dispatch({ type: CardPageActionType.ConfirmationCodePopupOpen, payload: false });
    dispatch({ type: CardPageActionType.CardToUnfreeze, payload: null });
    dispatch({ type: CardPageActionType.CardToFreeze, payload: null });
    setTimeout(() => {
      loadCards(freeze ? 'Active' : 'Frozen');
    }, 1000);
  };

  const handleTabChange = (e: any, newValue: any) => {
    dispatch({ type: CardPageActionType.CurrentTab, payload: newValue });
  };

  const handleVerificationCodeSubmit = async () => {
    if (state.activationCard) {
      dispatch({ type: CardPageActionType.VerificationCodeSubmitLoading, payload: true });

      const resp = await unitService.createToken(state.verificationCode, 'cards-sensitive-write');

      dispatch({ type: CardPageActionType.VerificationCodeSubmitLoading, payload: false });

      if (resp.error) {
        standardError(Error(resp.error));
        props.handleError(resp.error);
        return;
      }

      dispatch({ type: CardPageActionType.ConfirmationCodePopupOpen, payload: false });
      dispatch({ type: CardPageActionType.UnitCustomerToken, payload: resp.token });
      dispatch({ type: CardPageActionType.VgsPopupOpen, payload: true });
    } else if (state.cardToDelete) {
      dispatch({ type: CardPageActionType.VerificationCodeSubmitLoading, payload: true });

      await deleteCard(state.verificationCode);

      dispatch({ type: CardPageActionType.VerificationCodeSubmitLoading, payload: false });
      dispatch({ type: CardPageActionType.VerificationCode, payload: '' });
      return setTimeout(() => {
        loadCards('Active');
      }, 2500);
    } else if (state.cardLimitsCard) {
      dispatch({ type: CardPageActionType.VerificationCodeSubmitLoading, payload: true });

      await saveLimits(
        state.requestedCardMonthlyLimit!,
        state.requestCardTransactionLimit,
        state.verificationCode,
      );

      dispatch({ type: CardPageActionType.VerificationCodeSubmitLoading, payload: false });
      dispatch({ type: CardPageActionType.ConfirmationCodePopupOpen, payload: false });
      dispatch({ type: CardPageActionType.RequestCardMonthlyLimit, payload: null });
      dispatch({ type: CardPageActionType.RequestCardTransactionLimit, payload: null });
      dispatch({ type: CardPageActionType.VerificationCode, payload: '' });
    } else if (state.unitCardSensitiveData) {
      const resp = await unitService.createToken(state.verificationCode, 'cards-sensitive');

      dispatch({ type: CardPageActionType.ConfirmationCodePopupOpen, payload: false });
      dispatch({ type: CardPageActionType.UnitCustomerToken, payload: resp.token });
      dispatch({ type: CardPageActionType.VgsShowSensitivePopupOpen, payload: true });
    } else if (state.debitCardSetPin) {
      dispatch({ type: CardPageActionType.VerificationCodeSubmitLoading, payload: true });

      const resp = await unitService.createToken(state.verificationCode, 'cards-sensitive-write');
      if (resp.error) {
        dispatch({ type: CardPageActionType.VerificationCodeSubmitLoading, payload: false });
        standardError(Error(resp.error));
        props.handleError(resp.error);
        return;
      }

      const respPinStatus = await financialService.getCardPinStatus(state.debitCardSetPin!.id);
      dispatch({ type: CardPageActionType.VerificationCodeSubmitLoading, payload: false });

      if (respPinStatus.error) {
        standardError(Error(respPinStatus.error));
        props.handleError(respPinStatus.error);
        return;
      }

      dispatch({ type: CardPageActionType.ConfirmationCodePopupOpen, payload: false });
      dispatch({ type: CardPageActionType.UnitCustomerToken, payload: resp.token });
      if (respPinStatus.status == 'Set') {
        dispatch({ type: CardPageActionType.VgsDebitCardChangePinPopupOpen, payload: true });
      } else {
        dispatch({ type: CardPageActionType.VgsDebitCardSetPinPopupOpen, payload: true });
      }
    } else if (state.cardToFreeze || state.cardToUnfreeze) {
      await changeFreezeStatus(state.verificationCode);

      setTimeout(() => {
        loadCards(state.cardToFreeze ? 'Frozen' : 'Active');
      }, 2500);
    } else if (state.createCardRequest) {
      dispatch({ type: CardPageActionType.ConfirmationCodePopupOpen, payload: false });
      createCard();
    }

    dispatch({ type: CardPageActionType.VerificationCode, payload: '' });
  };

  const saveLimits = async (
    cardLimit: number,
    transactionLimit?: number | null,
    unitVerificationCode?: string | null,
  ) => {
    posthog.capture('Card Limits Changed', {
      'Max Spent Limit': cardLimit,
      'Transaction Limit': transactionLimit,
    });

    const cardLimitDollars = cardLimit * 100;
    const transactionLimitDollars = transactionLimit ? transactionLimit * 100 : cardLimitDollars;

    const resp = await financialService.modifyCardLimits(
      state.cardLimitsCard!.id,
      cardLimitDollars,
      transactionLimitDollars,
      unitVerificationCode,
    );

    if (resp.error) {
      standardError(resp.error);
      return;
    }

    dispatch({ type: CardPageActionType.CardLimitsCard, payload: null });
    loadCards('Active');
  };

  const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} {...other} data-cy="tabPanel">
        <Box>{children}</Box>
      </div>
    );
  };

  const inviteTeamMemberForm = () => {
    return (
      <TeamMemberInvite
        currentUser={currentUser}
        onSave={() =>
          dispatch({ type: CardPageActionType.InviteTeamMemberPopupOpen, payload: false })
        }
      />
    );
  };

  const handleOatfiDebitAcceptance = async () => {
    const resp = await userService.captureOatfiDebitAuthorization();
    if (resp.error) {
      standardError(resp.error);
      return;
    }
    dispatch({ type: CardPageActionType.ShowOatfiDebitModal, payload: false });
    window.location.reload();
  };

  useEffect(() => {
    dispatch({ type: CardPageActionType.ShouldShowCardToUpdate, payload: !!card_id });
    dispatch({
      type: CardPageActionType.IsAdmin,
      payload: props.currentUser.team_role != CompanyRole.EMPLOYEE,
    });
    loadCards('Active');
  }, [card_id, props.currentUser]);

  useEffect(() => {
    if (state.confirmationCodePopupOpen) {
      requestTokenVerification();
    }
  }, [state.confirmationCodePopupOpen]);

  useEffect(() => {
    if (is_vgs_collect_loaded && state.vgsPopupOpen && !state.vgsInputLoaded) {
      const inputBoxCss = {
        height: '50px',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
        paddingLeft: '16px',
        borderRadius: '4px',
        '&:focus': {
          border: '1px solid green',
        },
      };

      // @ts-ignore
      const vgsForm = window.VGSCollect.create(
        currentEnv().VGS_VAULT_ID,
        currentEnv().VGS_ENVIRONMENT,
        (formState: any) => {
          formState = JSON.parse(JSON.stringify(formState));

          dispatch({
            type: CardPageActionType.ActivateCardCvvValid,
            payload: formState['data.attributes.cvv2']['errorMessages'].length == 0,
          });
          dispatch({
            type: CardPageActionType.ActivateCardExpDateValid,
            payload: formState['data.attributes.expirationDate']['errorMessages'].length == 0,
          });
        },
      );

      vgsForm.field('#cc-cvv2', {
        type: 'card-security-code',
        name: 'data.attributes.cvv2',
        successColor: '#4F8A10',
        errorColor: '#D8000C',
        placeholder: '123',
        maxLength: 3,
        validations: ['required', 'validCardSecurityCode'],
        css: inputBoxCss,
      });

      vgsForm.field('#cc-expiration-date', {
        type: 'card-expiration-date',
        name: 'data.attributes.expirationDate',
        successColor: '#4F8A10',
        errorColor: '#D8000C',
        placeholder: 'MM / YYYY',
        yearLength: 4,
        validations: ['required', 'validCardExpirationDate'],
        // Change to required expiration date format
        serializers: [vgsForm.SERIALIZERS.replace('(\\d{2})\\s\\/\\s(\\d{4})', '$2-$1')],
        css: inputBoxCss,
      });

      // Submit by executing a POST request.
      document.getElementById('cc-form')!.addEventListener('submit', function (e: any) {
        e.preventDefault();
        vgsForm.submit(
          '/cards/' + state.activationCard!.id + '/activate',
          {
            // This converts the dot-separated field name strings into a JSON object
            mapDotToObject: 'true',
            headers: {
              'Content-Type': 'application/vnd.api+json',
              Authorization: 'Bearer ' + state.unitCustomerToken,
            },
          },

          function (status: any, data: any) {
            dispatch({ type: CardPageActionType.ActivationCard, payload: null });
            dispatch({ type: CardPageActionType.VgsPopupOpen, payload: false });
            dispatch({ type: CardPageActionType.Loading, payload: true });
            loadCards('Active');
          },
        );
      });

      dispatch({ type: CardPageActionType.VgsInputLoaded, payload: true });
    }
  }, [is_vgs_collect_loaded, state.vgsPopupOpen]);

  useEffect(() => {
    if (
      is_vgs_collect_loaded &&
      state.vgsDebitCardSetPinPopupOpen &&
      !state.vgsDebitCardSetPinLoaded
    ) {
      // @ts-ignore
      const vgsForm = window.VGSCollect.create(
        currentEnv().VGS_VAULT_ID,
        currentEnv().VGS_ENVIRONMENT,
        (formState: any) => {
          formState = JSON.parse(JSON.stringify(formState));
          dispatch({
            type: CardPageActionType.DebitCardSetPinValid,
            payload: formState['data.attributes.pin']['errorMessages'].length === 0,
          });
        },
      );

      vgsForm.field('#cc-pin', {
        type: 'text',
        name: 'data.attributes.pin',
        successColor: '#4F8A10',
        errorColor: '#D8000C',
        placeholder: '1234',
        maxLength: 6,
        validations: ['required', '/^([0-9]{4,6})$/'],
      });

      document.getElementById('cc-form')!.addEventListener('submit', function (e: any) {
        e.preventDefault();
        vgsForm.submit(
          '/cards/' + state.debitCardSetPin!.id + '/secure-data/pin',
          {
            // This converts the dot-separated field name string into a JSON object
            mapDotToObject: 'true',
            headers: {
              'Content-Type': 'application/vnd.api+json',
              Authorization: 'Bearer ' + state.unitCustomerToken,
            },
          },
          function (status: any, data: any) {
            dispatch({ type: CardPageActionType.DebitCardSetPinValid, payload: null });
            dispatch({ type: CardPageActionType.VgsDebitCardSetPinPopupOpen, payload: false });
          },
        );
      });
      dispatch({ type: CardPageActionType.VgsDebitCardSetPinLoaded, payload: true });
    }
  }, [is_vgs_collect_loaded, state.vgsDebitCardSetPinPopupOpen]);

  useEffect(() => {
    if (
      is_vgs_collect_loaded &&
      state.vgsDebitCardChangePinPopupOpen &&
      !state.vgsDebitCardChangePinLoaded
    ) {
      // @ts-ignore
      const vgsForm = window.VGSCollect.create(
        currentEnv().VGS_VAULT_ID,
        currentEnv().VGS_ENVIRONMENT,
        (formState: any) => {
          formState = JSON.parse(JSON.stringify(formState));
          dispatch({
            type: CardPageActionType.DebitCardChangePinValid,
            payload: formState['data.attributes.oldPin']['errorMessages'].length === 0,
          });
        },
      );

      // Create VGS Collect field for Old PIN
      vgsForm.field('#cc-old-pin', {
        type: 'text',
        name: 'data.attributes.oldPin',
        successColor: '#4F8A10',
        errorColor: '#D8000C',
        placeholder: '1234',
        maxLength: 6,
        validations: ['required', '/^([0-9]{4,6})$/'],
      });

      // Create VGS Collect field for New PIN
      vgsForm.field('#cc-new-pin', {
        type: 'text',
        name: 'data.attributes.newPin',
        successColor: '#4F8A10',
        errorColor: '#D8000C',
        placeholder: '1234',
        maxLength: 6,
        validations: ['required', '/^([0-9]{4,6})$/'],
      });

      document.getElementById('cc-form-change')!.addEventListener('submit', function (e: any) {
        dispatch({ type: CardPageActionType.DebitCardChangePinSubmitLoading, payload: true });
        e.preventDefault();
        vgsForm.submit(
          '/cards/' + state.debitCardSetPin!.id + '/secure-data/pin',
          {
            // This converts the dot-separated field name string into a JSON object
            method: 'PUT',
            mapDotToObject: 'true',
            headers: {
              'Content-Type': 'application/vnd.api+json',
              Authorization: 'Bearer ' + state.unitCustomerToken,
            },
          },
          function (status: any, data: any) {
            dispatch({ type: CardPageActionType.DebitCardChangePinSubmitLoading, payload: false });
            if (status >= 400) {
              standardError(Error(data.errors[0].title));
              props.handleError(data.errors[0].title);
              dispatch({
                type: CardPageActionType.DebitCardChangePinValid,
                payload: data.errors[0].title,
              });
              return;
            }
            dispatch({ type: CardPageActionType.DebitCardChangePinValid, payload: '' });
            dispatch({ type: CardPageActionType.VgsDebitCardChangePinPopupOpen, payload: false });
          },
          function (errors: any) {
            dispatch({ type: CardPageActionType.DebitCardChangePinSubmitLoading, payload: false });
            standardError(Error(errors[0].title));
            props.handleError(errors[0].title);
          },
        );
      });
      dispatch({ type: CardPageActionType.VgsDebitCardChangePinLoaded, payload: true });
    }
  }, [is_vgs_collect_loaded, state.vgsDebitCardChangePinPopupOpen]);

  useEffect(() => {
    if (is_vgs_show_loaded && state.vgsShowSensitivePopupOpen) {
      setIsLoadingCardData(true);
      // @ts-ignore
      const show = window.VGSShow.create(currentEnv().VGS_VAULT_ID);
      const customerToken = state.unitCustomerToken;
      const cardId = state.unitCardSensitiveData!.id;
      let cardCvvFetched = false;
      let cardNumberFetched = false;

      const cvv2iframe = show.request({
        name: 'data-text',
        method: 'GET',
        path: '/cards/' + cardId + '/secure-data/cvv2',
        headers: {
          Authorization: 'Bearer ' + customerToken,
        },
        htmlWrapper: 'text',
        jsonPathSelector: 'data.attributes.cvv2',
      });
      cvv2iframe.on('requestSuccess', () => {
        cardCvvFetched = true;
        if (cardNumberFetched) setIsLoadingCardData(false);
      });
      cvv2iframe.render('#cvv2');

      const cardNumberIframe = show.request({
        name: 'data-text',
        method: 'GET',
        path: '/cards/' + cardId + '/secure-data/pan',
        headers: {
          Authorization: 'Bearer ' + customerToken,
        },
        htmlWrapper: 'text',
        jsonPathSelector: 'data.attributes.pan',
      });
      cardNumberIframe.on('requestSuccess', () => {
        cardNumberFetched = true;
        if (cardCvvFetched) setIsLoadingCardData(false);
      });
      cardNumberIframe.render('#cardNumber');
    }
  }, [is_vgs_show_loaded, state.vgsShowSensitivePopupOpen]);

  useEffect(() => {
    posthog.capture('Cards Screen');

    const loadScript = (src: string, onLoadCallback: () => void) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = onLoadCallback;
      document.body?.appendChild(script);
    };

    loadScript('https://js.verygoodvault.com/vgs-collect/2.17.0/vgs-collect.js', () =>
      set_is_vgs_collect_loaded(true),
    );
    loadScript('https://js.verygoodvault.com/vgs-show/1.5/ACh8JJTM42LYxwe2wfGQxwj5.js', () =>
      set_is_vgs_show_loaded(true),
    );
  }, []);

  useEffect(() => {
    if (!isFetchingLimits && !isDepositAccountLimitError) {
      const limit = depositAccountLimits?.card?.limits?.daily_card_transaction ?? 0;
      setDebitCardDailyLimit(formatDollarNumber(limit));
    }
  }, [isFetchingLimits]);

  const loadAllCardTypes = () => {
    setTimeout(() => {
      loadCards('Inactive');
    }, 500);
    setTimeout(() => {
      loadCards('Active');
    }, 500);
    setTimeout(() => {
      loadCards('Canceled');
    }, 750);
    setTimeout(() => {
      loadCards('Frozen');
    }, 1000);
  };

  useEffect(() => {
    loadAllCardTypes();
  }, []);

  useEffect(() => {
    if (
      currentUser.is_credit_customer &&
      !currentUser.has_authorized_oatfi_debit &&
      isAdministrative(currentUser.team_role)
    ) {
      dispatch({ type: CardPageActionType.ShowOatfiDebitModal, payload: true });
    }
  }, [currentUser]);

  const filterCards = (cards: CreditCard[], type: string) => {
    if (type === 'all_cards') return cards;
    if (type === 'myCards') return cards.filter(card => card.team_member?.id === currentUser.id);
    return cards.filter(card => card.product === type);
  };

  const tabsToShow = ['active', 'inactive', 'canceled', 'frozen'];

  const frozen = filterCards(state.frozenCards, selectedCardType);
  const active = filterCards(state.activeCards, selectedCardType);

  const combinedActive = [...active, ...frozen];

  const tabData: TabContent[] = [
    {
      label: 'Active cards',
      content: (
        <div data-id="cards-box" className="mb-2 my-1 min-h-70vh cards-box">
          <Box style={{ width: '100%' }}>
            <TabPanel value={state.currentTab} index={tabsToShow.indexOf('active')}>
              <CardList
                cards={filterCards(combinedActive, selectedCardType)}
                status="active"
                isAdmin={state.isAdmin}
                isLoading={state.loadingActiveCards}
                dispatcher={dispatch}
                onCreateClick={t => {
                  posthog.capture('Add new card click');
                  dispatch({ type: CardPageActionType.CardProduct, payload: t });
                  dispatch({ type: CardPageActionType.CardTypePopupOpen, payload: true });
                }}
                currentUser={props.currentUser}
              />
            </TabPanel>
          </Box>
        </div>
      ),

      count: state.activeCards.length,
    },
    {
      label: 'Pending activation',
      content: (
        <Box style={{ width: '100%' }}>
          <TabPanel value={state.currentTab} index={tabsToShow.indexOf('inactive')}>
            <CardList
              cards={filterCards(state.inactiveCards, selectedCardType)}
              status="inactive"
              isAdmin={state.isAdmin}
              isLoading={state.loadingPendingCards}
              dispatcher={dispatch}
              currentUser={props.currentUser}
            />
          </TabPanel>
        </Box>
      ),

      count: state.inactiveCards.length,
    },
    {
      label: 'Cancelled cards',
      content: (
        <Box style={{ width: '100%' }}>
          {' '}
          <TabPanel value={state.currentTab} index={tabsToShow.indexOf('canceled')}>
            <CardList
              cards={filterCards(state.canceledCards, selectedCardType)}
              status="canceled"
              isAdmin={state.isAdmin}
              isLoading={state.loadingCanceledCards}
              dispatcher={dispatch}
              currentUser={props.currentUser}
            />
          </TabPanel>{' '}
        </Box>
      ),

      count: state.canceledCards.length,
    },
  ];

  return state.loading ? (
    <Spinner />
  ) : (
    <div className="cards-page sm-flex-column ">
      <Helmet>
        <title>Toolbox - Cards</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <div>
          <div className="header">
            <p data-id="cards-title" className="font-bold font-lg font-noto-sans mb-half pb-4">
              <Text tid="Cards" />
            </p>
          </div>

          <Tabs
            tabContents={tabData}
            parentState={state}
            data-cy="cardTabs"
            onTabChange={handleTabChange}
            belowTabComponent={
              <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Box
                  sx={{
                    px: matchesMobile ? 2 : 0,
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  <CustomSelect
                    value={selectedCardType}
                    onChange={event => setSelectedCardType(event.target.value)}
                    options={[
                      { value: 'all_cards', label: 'All Cards' },
                      { value: 'debit', label: 'Debit Cards' },
                      { value: 'credit', label: 'Charge Cards' },
                      { value: 'myCards', label: 'Only My Cards' },
                    ]}
                  />
                </Box>

                <Box
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    pr: matchesMobile ? 2 : 0,
                  }}
                >
                  {showAddCardButton && (
                    <Button
                      className={`h-10 px-6 flex justify-center items-center bg-[#09442F] text-white text-sm font-normal rounded shadow-md hover:bg-[#073a29] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1b5e45] ${
                        matchesMobile ? '' : 'mr-2'
                      }`}
                      data-cy="createCardButton"
                      onClick={() => setCreateCardDialogOpen(true)}
                    >
                      <AddIcon
                        className="text-white"
                        style={{
                          marginRight: 5,
                        }}
                        fontSize="small"
                      />
                      <span style={{ paddingRight: 3 }}>Add new card</span>
                    </Button>
                  )}
                </Box>
              </Box>
            }
          />
        </div>
        <Box>{inviteTeamMemberForm()}</Box>
      </Container>

      {state.inviteTeamMemberPopupOpen && (
        <Dialog
          fullScreen={true}
          data-cy="inviteTeamMemberPopup"
          onClose={() => {
            dispatch({ type: CardPageActionType.InviteTeamMemberPopupOpen, payload: false });
          }}
          PaperProps={{
            style: {
              backgroundColor: '#fff',
            },
          }}
          open={state.inviteTeamMemberPopupOpen}
        >
          <div className="px-half py-half">
            <div>
              <IconButton
                onClick={() => {
                  dispatch({ type: CardPageActionType.InviteTeamMemberPopupOpen, payload: false });
                }}
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </div>
            <div className="px-half">{inviteTeamMemberForm()}</div>
          </div>
        </Dialog>
      )}

      <DeleteConfirmPopUp
        onClick={() => {
          dispatch({ type: CardPageActionType.ConfirmationCodePopupOpen, payload: true });
        }}
        onClose={() => {
          dispatch({ type: CardPageActionType.CardToDelete, payload: null });
          dispatch({ type: CardPageActionType.ConfirmPopupOpen, payload: false });
        }}
        isOpen={state.confirmPopupOpen}
        dataCyText="permCardCancel"
        title={'Cancel Card'}
        data-cy="deleteCardPopup"
        description={'Are you sure you want to cancel the card? This is permanent.'}
        btnText={'Cancel Card'}
        btnLoading={state.deleteCardLoading}
      />

      {state.cardLimitsCard && (
        <CardLimitsPopup
          adminView={state.isAdmin}
          onCardLimitsUpdateClick={(cardLimit: number, transactionLimit?: number) => {
            dispatch({ type: CardPageActionType.CardLimitsPopupOpen, payload: false });

            dispatch({ type: CardPageActionType.RequestCardMonthlyLimit, payload: cardLimit });
            dispatch({
              type: CardPageActionType.RequestCardTransactionLimit,
              payload: transactionLimit,
            });
            dispatch({ type: CardPageActionType.ConfirmationCodePopupOpen, payload: true });
          }}
          cardholderLimit={state.cardholderLimit}
          onClose={() => dispatch({ type: CardPageActionType.CardLimitsPopupOpen, payload: false })}
          isOpen={state.cardLimitsPopupOpen}
          cardId={state.cardLimitsCard!.id!}
          cardLimit={state.cardLimitsCard!.max_spent_limit}
          transactionLimit={state.cardLimitsCard!.max_transaction_limit}
        />
      )}

      {state.sensitiveCardInfoPopupOpen && (
        <SensitiveCardInfoPopUp
          exp_month={state.cardExpMonth}
          exp_year={state.cardExpYear}
          isOpen={state.sensitiveCardInfoPopupOpen}
          onClose={() =>
            dispatch({ type: CardPageActionType.SensitiveCardInfoPopupOpen, payload: false })
          }
          number={state.sensitiveCardNumber}
          cvc={state.sensitiveCardCvc}
        />
      )}

      <CustomDialog
        title="Confirm with code"
        open={state.confirmationCodePopupOpen}
        data-cy="confirmationCodePopup"
        onClose={() =>
          dispatch({ type: CardPageActionType.ConfirmationCodePopupOpen, payload: false })
        }
        footerContent={
          <div data-id="new-card-button-box" className="w-full items-center flex justify-center">
            <Button
              data-id="verificationResendButton"
              data-cy="verificationCodeResendButton"
              disabled={state.verificationCodeSubmitLoading}
              onClick={() => {
                dispatch({ type: CardPageActionType.VerificationCode, payload: '' });
                requestTokenVerification();
              }}
              style={{ flex: 1, marginRight: 8 }}
            >
              Re-send code
            </Button>
            <Button
              data-cy="verificationCodeConfirmButton"
              disabled={state.verificationCode.length != 6 || state.verificationCodeSubmitLoading}
              style={{ flex: 1, marginLeft: 8 }}
              onClick={() => handleVerificationCodeSubmit()}
            >
              Confirm
            </Button>
          </div>
        }
      >
        <div className="pt-2 pb-2">
          <span className="block text-left">
            For enhanced security, enter the 6-digit code sent to {props.currentUser.phone_number}:
          </span>
          <div className="mt-2">
            <Input
              className="w-full text-left"
              dataCy="verificationCode"
              onChange={v => dispatch({ type: CardPageActionType.VerificationCode, payload: v })}
              value={state.verificationCode}
            />
          </div>
        </div>
      </CustomDialog>

      <Suspense fallback={<Spinner />}>
        <GenericPopUp
          isOpen={state.showOatfiDebitModal}
          onClose={() => null}
          title="Debit Authorization"
          onClick={() => handleOatfiDebitAcceptance()}
          className="w-32"
          btnText="Accept"
          background="MainPrimary"
          noClose={true}
          noOverlayClose={true}
          data-cy="oatfiDebitModal"
        >
          <p>
            I authorize Oat Financial ("OatFi"; on behalf of Toolbox) to electronically debit my
            selected account and, if necessary, electronically credit this account to correct
            erroneous debits. Toolbox will notify me via the email on my user account before such
            debits/credits occur.
          </p>
        </GenericPopUp>
      </Suspense>

      {state.vgsPopupOpen && (
        <GenericPopUp
          onClick={handleVerificationCodeSubmit}
          onClose={() => dispatch({ type: CardPageActionType.VgsPopupOpen, payload: false })}
          title={'Activate Card'}
          isOpen={state.vgsPopupOpen}
          data-cy="activateCardPopup"
        >
          <form id="cc-form" className="mt-1 flex-column">
            <span className="mb-1 mt-half">Enter CVV code and expiration to activate:</span>
            <span id="cc-cvv2" className="h-4" />
            <span id="cc-expiration-date" className="h-4 mb-1" />
            <button
              type="submit"
              data-cy="activateCardButton"
              className={
                'font-noto-sans font-bold bg-primary text-white rounded-3xl h-2 border-none' +
                (!state.activateCardCvvValid || !state.activateCardExpDateValid
                  ? ' bg-primary-alpha-50'
                  : '')
              }
              disabled={!state.activateCardCvvValid || !state.activateCardExpDateValid}
            >
              SUBMIT
            </button>
          </form>
        </GenericPopUp>
      )}

      {state.vgsDebitCardSetPinPopupOpen && (
        <GenericPopUp
          onClick={handleVerificationCodeSubmit}
          onClose={() =>
            dispatch({ type: CardPageActionType.VgsDebitCardSetPinPopupOpen, payload: false })
          }
          data-cy="setPinPopup"
          isOpen={state.vgsDebitCardSetPinPopupOpen}
        >
          <div>
            <h3 className="text-center mt-2">Set PIN</h3>
            <div className="p-2">Please fill in and submit the form</div>
            <form id="cc-form">
              <div className="form-group">
                <span id="cc-pin" className="h-4 block"></span>
              </div>
              <div className="text-center">
                <Button type="submit" data-cy="setPinButton">
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </GenericPopUp>
      )}

      {state.vgsDebitCardChangePinPopupOpen && (
        <GenericPopUp
          onClick={handleVerificationCodeSubmit}
          onClose={() => {
            dispatch({ type: CardPageActionType.VgsDebitCardChangePinPopupOpen, payload: false });
            dispatch({ type: CardPageActionType.VgsDebitCardChangePinLoaded, payload: false });
          }}
          data-cy="changePinPopup"
          isOpen={state.vgsDebitCardChangePinPopupOpen}
        >
          <div>
            <h3 className="text-center mt-2">Change PIN</h3>
            <div className="p-2">Please fill in and submit the form</div>
            <form id="cc-form-change">
              <div className="form-group">
                <label htmlFor="cc-old-pin">Old PIN</label>
                <span id="cc-old-pin" className="h-3 block mt-half mb-half form-field"></span>
              </div>
              <div className="form-group">
                <label htmlFor="cc-new-pin">New PIN</label>
                <span id="cc-new-pin" className="h-3 block mt-half form-field"></span>
              </div>
              {state.debitCardChangePinValid.length > 0 && (
                <p className="text-red">{state.debitCardChangePinValid}</p>
              )}
              <div className="text-center mt-1">
                <Button
                  type="submit"
                  disabled={state.debitCardChangePinSubmitLoading}
                  data-cy="changePinButton"
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </GenericPopUp>
      )}

      {state.vgsShowSensitivePopupOpen && (
        <StyledGenericPopup
          open={state.vgsShowSensitivePopupOpen}
          onClose={() =>
            dispatch({ type: CardPageActionType.VgsShowSensitivePopupOpen, payload: false })
          }
          title={'Card Details'}
          data-cy="cardDetailsPopup"
          subtitle={
            <>
              <div style={{ marginTop: 4 }}>
                {state.selectedCardDetails.type === 'virtual' ? (
                  <Chip
                    label="Virtual"
                    variant="outlined"
                    sx={{
                      borderRadius: '2px',
                      borderColor: '#16A34A',
                      borderWidth: '1.5px',
                      background: 'none',
                      color: '#16A34A',
                    }}
                  />
                ) : (
                  <Chip
                    label="Physical"
                    color="success"
                    variant="outlined"
                    sx={{
                      borderRadius: '2px',
                      borderColor: '#0284C7',
                      borderWidth: '1.5px',
                      background: 'none',
                      color: '#0284C7',
                    }}
                  />
                )}

                {state.selectedCardDetails.status == 'active' && (
                  <Chip
                    label="Active"
                    sx={{ borderRadius: '2px', ml: 1, color: '#14532D', background: '#DCFCE7' }}
                  />
                )}
                {state.selectedCardDetails.product == 'credit' ? (
                  <Chip
                    label="Charge"
                    sx={{ borderRadius: '2px', ml: 1, color: 'white', background: '#4102C7' }}
                  />
                ) : (
                  <Chip
                    label="Debit"
                    sx={{ borderRadius: '2px', ml: 1, color: 'white', background: '#3A6959' }}
                  />
                )}

                {state.selectedCardDetails.status == 'inactive' && (
                  <Chip
                    label="Pending Activation"
                    sx={{ borderRadius: '2px', ml: 1, background: '#FFEDD5', color: '#7C2D12' }}
                  />
                )}
                {state.selectedCardDetails.status == 'canceled' && (
                  <Chip label="Canceled" color="default" sx={{ borderRadius: '2px', ml: 1 }} />
                )}
                {state.selectedCardDetails.status == 'frozen' && (
                  <Chip
                    label="Frozen"
                    sx={{
                      borderRadius: '2px',
                      ml: 1,
                      color: '#164E63',
                      background: 'rgba(243, 9, 51, 0.38)',
                    }}
                  />
                )}
              </div>
            </>
          }
        >
          <div style={{ marginLeft: 8 }}>
            <div
              style={{
                fontSize: '12px',
                opacity: 0.7,
                fontStyle: 'bold',
                fontWeight: 500,
                lineHeight: 'normal',
                letterSpacing: 0.32,
              }}
            >
              Card Holder Name
            </div>
            <div style={{ fontSize: '14px' }}>
              {`${state.selectedCardDetails?.first_name} ${state.selectedCardDetails?.last_name}` ||
                ''}
            </div>
          </div>
          <form
            id="cc-form"
            className="mt-1 flex-column relative"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <span className="mt-half">Card Number:</span>
            <span id="cardNumber" className="h-4" />
            <span className="mt-half">CVV:</span>
            <span id="cvv2" className="h-4" />
            <span className="mt-half">Expiration Date:</span>
            <span className="h-4">
              {state.unitCardSensitiveData!.exp_month}-{state.unitCardSensitiveData!.exp_year}
            </span>
            <Show when={isLoadingCardData}>
              <div className="absolute inset-0 flex items-center justify-center bg-white/90">
                <Spinner size={20} />
              </div>
            </Show>
          </form>
        </StyledGenericPopup>
      )}

      {physicalDialogOpen ? (
        <CustomDialog
          title="Add New Physical Card"
          data-cy="physicalCardDialog"
          subtitle={
            getsExpeditedShipping
              ? "You're getting expedited shipping! Your card will arrive in 2-3 business days via FedEx."
              : 'Please allow 3-5 business days for physical cards to arrive.'
          }
          open={physicalDialogOpen}
          onClose={() => setPhysicalDialogOpen(false)}
          footerContent={
            <DialogFooterButtons
              matchesMobile={matchesMobile}
              onCancel={onPhysicalCardCancel}
              onRequestCard={onRequestPhysicalCardClick}
              continueText="Request Card"
              isRequestDisabled={
                !selectedAccountType ||
                !cardType ||
                createCardLoading ||
                !firstAddress.address ||
                !postalCode
              }
              data-cy="requestDialogFooterButtonsPhysical"
            />
          }
        >
          <div style={{ paddingTop: 16, paddingBottom: 8 }}>
            <CardDetails
              initialMonthlyLimit={initial_monthly_limit}
              setInitialMonthlyLimit={set_initial_monthly_limit}
              formatCentsAsDollars={formatCentsAsDollars}
              cardholderLimit={state.cardholderLimit}
              data-cy="cardDetailsPhysical"
            />

            {showAddMember ? (
              <AddTeamMemberForm
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                inviteEmail={inviteEmail}
                setInviteEmail={setInviteEmail}
                inviteRole={inviteRole}
                setInviteRole={setInviteRole}
                roleOptions={roleOptions}
                validateEmail={validateEmail}
                isSendingInvite={isSendingInvite}
                setInviteStatus={setInviteStatus}
                setIsSendingInvite={setIsSendingInvite}
                addMemberError={addMemberError}
                setAddMemberError={setAddMemberError}
                setShowAddMember={setShowAddMember}
                sendInvite={handleAddMember}
                data-cy="addTeamMemberPhysical"
              />
            ) : (
              <AddTeamMember
                showAddButton={showAddButton}
                addButtonRef={addButtonRef}
                handleAddTeamMember={handleAddTeamMember}
                teamMembers={teamMembers}
                inviteStatus={inviteStatus}
                selectedTeamMember={selectedTeamMember}
                setShowAddButton={setShowAddButton}
                setInviteStatus={setInviteStatus}
                setSelectedTeamMember={setSelectedTeamMember}
                handleDropdownOpen={handleDropdownOpen}
                handleDropdownClose={handleDropdownClose}
                currentUser={props.currentUser}
                firstName={firstName}
                lastName={lastName}
                data-cy="addTeamMemberPhysical"
              />
            )}
          </div>
          <hr />

          <ShippingDetails
            setFirstAddress={setFirstAddress}
            setSecondAddress={setSecondAddress}
            setPostalCode={setPostalCode}
            postalCode={postalCode}
            secondAddress={secondAddress}
            data-cy="shippingDetails"
          />
        </CustomDialog>
      ) : (
        <CustomDialog
          title="Add New Card"
          subtitle="Choose an option and add more information below"
          open={createCardDialogOpen}
          onClose={handleCreateCardDialogClose}
          data-cy="createCardDialog"
          footerContent={
            <DialogFooterButtons
              matchesMobile={matchesMobile}
              onCancel={handleCreateCardDialogClose}
              onRequestCard={onCardRequestContinue}
              continueText={
                {
                  virtual: 'Request Card',
                }[cardType] ?? 'Continue'
              }
              isRequestDisabled={
                !selectedAccountType || !cardType || !initial_monthly_limit || createCardLoading
              }
            />
          }
        >
          <div style={{ paddingTop: 16, paddingBottom: 8 }}>
            <Typography
              sx={{
                marginBottom: 1,
                color: '#9D9D9D',
                fontSize: '14px',
                fontStyle: 'normal',
                lineHeight: '140%',
                fontVariant: 'all-small-caps',
              }}
            >
              Select an account
            </Typography>

            <AccountTypeSelection
              selectedAccountType={selectedAccountType}
              setSelectedAccountType={setSelectedAccountType}
              currentUser={props.currentUser}
              data-cy="accountTypeSelection"
            />

            <hr style={{ margin: '16px 0' }} />
            <Typography
              sx={{
                marginBottom: 2,
                color: '#9D9D9D',
                fontSize: '14px',
                fontStyle: 'normal',
                lineHeight: '140%',
                fontVariant: 'all-small-caps',
              }}
            >
              Select a card type
            </Typography>

            <CardTypeSelection
              cardType={cardType}
              setCardType={setCardType}
              selectedAccountType={selectedAccountType}
              isEligibleForPhysical={getsExpeditedShipping}
              currentUser={props.currentUser}
              availableBalance={availableBalance}
              data-cy="cardTypeSelection"
            />

            <hr style={{ margin: '16px 0' }} />

            <CardDetails
              initialMonthlyLimit={initial_monthly_limit}
              setInitialMonthlyLimit={set_initial_monthly_limit}
              formatCentsAsDollars={formatCentsAsDollars}
              cardholderLimit={state.cardholderLimit}
              debitCardDailyLimit={debitCardDailyLimit}
              selectedAccountType={selectedAccountType}
              data-cy="cardDetails"
            />

            {showAddMember ? (
              <AddTeamMemberForm
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                inviteEmail={inviteEmail}
                setInviteEmail={setInviteEmail}
                inviteRole={inviteRole}
                setInviteRole={setInviteRole}
                addMemberError={addMemberError}
                roleOptions={roleOptions}
                validateEmail={validateEmail}
                isSendingInvite={isSendingInvite}
                setInviteStatus={setInviteStatus}
                setIsSendingInvite={setIsSendingInvite}
                setAddMemberError={setAddMemberError}
                setShowAddMember={setShowAddMember}
                sendInvite={handleAddMember}
                data-cy="addTeamMemberForm"
              />
            ) : (
              <AddTeamMember
                showAddButton={showAddButton}
                addButtonRef={addButtonRef}
                handleAddTeamMember={handleAddTeamMember}
                teamMembers={teamMembers}
                inviteStatus={inviteStatus}
                selectedTeamMember={selectedTeamMember}
                setShowAddButton={setShowAddButton}
                setInviteStatus={setInviteStatus}
                setSelectedTeamMember={setSelectedTeamMember}
                handleDropdownOpen={handleDropdownOpen}
                handleDropdownClose={handleDropdownClose}
                currentUser={props.currentUser}
                firstName={firstName}
                lastName={lastName}
                data-cy="addTeamMember"
              />
            )}
          </div>
        </CustomDialog>
      )}

      <CardCreatedDialog
        data-cy="cardCreatedDialog"
        isOpen={isSuccessDialogOpen}
        onClose={handleSuccessDialogClose}
        handleTabChange={handleTabChange}
        matchesMobile={matchesMobile}
      />

      <FailureDialogModal
        isOpen={isFailureDialogOpen}
        onClose={handleFailureDialogClose}
        data-cy="failureDialog"
        title="Card Creation Failed"
        description="We were unable to create your card. Please try again."
      />

      {state.cardAssignmentPopupCard && (
        <CardAssignmentPopup
          onCardAssignmentChanged={() => {
            dispatch({ type: CardPageActionType.CardAssignmentPopupCard, payload: null });
            loadAllCardTypes();
          }}
          card={state.cardAssignmentPopupCard}
          onClose={() =>
            dispatch({ type: CardPageActionType.CardAssignmentPopupCard, payload: null })
          }
          isOpen={!!state.cardAssignmentPopupCard}
          data-cy="cardAssignmentPopup"
        />
      )}
    </div>
  );
});

export default CardPage;
