import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { memo, ReactNode } from 'react';

interface TableWrapperProps {
  headers: string[];
  children: ReactNode;
  dataId?: string;
  className?: string;
}

const TableWrapper = memo(function TableWrapper(props: TableWrapperProps) {
  const { headers, children } = props;
  return (
    <TableContainer datatype={props.dataId}>
      <Table datatype="table-inner-box" className={`bg-white ${props.className}`} size="small">
        <TableHead datatype="table-header-box">
          <TableRow datatype="table-header-inner-box">
            {headers.map((header: string, i: number) => {
              return (
                <TableCell datatype="table-header-label" key={i}>
                  <span datatype="table-header-value" className="font-bold">
                    {header}
                  </span>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
});

export default TableWrapper;
