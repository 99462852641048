import EntityTagsWidget from '../../../tags/components/EntityTagsWidget';
import { useGetEntityTags } from '../../../tags/data/getEntityTags';
import { useRef, useState } from 'react';
import AddTagToEntityPopup from '../../../tags/components/AddTagToEntityPopup';
import { Transaction } from '../../types';

interface TransactionDrawerTagsProps {
  transaction: Transaction;
}

const TransactionDrawerTags = (props: TransactionDrawerTagsProps) => {
  const { transaction } = props;

  const [isAddTagPopupOpen, setIsAddTagPopupOpen] = useState(false);

  const anchorElRef = useRef<HTMLElement>(null);

  const { data: entityTagsData, refetch } = useGetEntityTags(
    transaction.entity_id,
    transaction.entity_type,
    transaction.tags,
  );
  const entityTags = entityTagsData?.entity_tags ?? [];

  const openAddTagPopup = () => {
    setIsAddTagPopupOpen(true);
  };

  return (
    <>
      <span ref={anchorElRef}></span>

      <EntityTagsWidget
        entityTags={entityTags}
        onAddTagButtonClick={openAddTagPopup}
        onUpdate={refetch}
      />

      <AddTagToEntityPopup
        isOpen={isAddTagPopupOpen}
        onClose={() => setIsAddTagPopupOpen(false)}
        anchorEl={anchorElRef.current!}
        entityId={transaction.entity_id}
        entityType={transaction.entity_type}
        entityTags={entityTags}
        onUpdate={refetch}
      />
    </>
  );
};

export default TransactionDrawerTags;
