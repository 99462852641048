import {
  faHandHoldingUsd,
  faBullhorn,
  faFireBurner,
  faMoneyBillWave,
  faPhoneVolume,
  faHelmetSafety,
  faScrewdriverWrench,
  faUtensils,
  faLaptop,
  faGears,
  faUsers,
  faBasketShopping,
  faCreditCard,
  faHandHoldingDollar,
  faFileInvoiceDollar,
  faFilm,
  faGasPump,
  faChair,
  faFileAlt,
  faCartShopping,
  faHospital,
  faUserClock,
  faPencilAlt,
  faChartBar,
  faHandshake,
  faLaptopCode,
  faCar,
  faPlane,
  faBolt,
  faTruckMoving,
  faTools,
  faPrint,
  faChartPie,
  faBuilding,
  faExchangeAlt,
  faLandmark,
  faShieldAlt,
  faBoxes,
  faPiggyBank,
  faExclamationTriangle,
  faHome,
  faTruck,
  faWallet,
  faCoins,
  faMoneyBillTransfer,
  faMoneyBillWheat,
  faCircleDollarToSlot,
  faFileSignature,
  faTruckLoading,
} from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { cloneElement } from 'react';
import { GenericFontAwesomeIcon } from '../../../../app/components/Icon/fontawesome';

interface CategoryIconProps {
  category: string;
  className?: string;
}

export const getCategoryIcon = (category: string) => {
  switch (category) {
    case '1099 Payouts': {
      return {
        icon: <GenericFontAwesomeIcon icon={faWallet} />,
        iconColor: '#CC9A06',
        bgColor: '#FFF3CD',
      };
    }
    case 'Advertising & Marketing': {
      return {
        icon: <GenericFontAwesomeIcon icon={faBullhorn} />,
        iconColor: '#4CAF50',
        bgColor: '#E4F3E5',
      };
    }
    case 'Appliances': {
      return {
        icon: <GenericFontAwesomeIcon icon={faFireBurner} />,
        iconColor: '#795548',
        bgColor: '#E4DDDA',
      };
    }
    case 'Cash Payment': {
      return {
        icon: <GenericFontAwesomeIcon icon={faMoneyBillWave} />,
        iconColor: '#FF9800',
        bgColor: '#FFF0D9',
      };
    }
    case 'Charity': {
      return {
        icon: <GenericFontAwesomeIcon icon={faHeart} />,
        iconColor: '#EF5350',
        bgColor: '#FDE5E5',
      };
    }
    case 'Communication': {
      return {
        icon: <GenericFontAwesomeIcon icon={faPhoneVolume} />,
        iconColor: '#03A9F4',
        bgColor: '#D9F2FE',
      };
    }
    case 'Construction Project Materials': {
      return {
        icon: <GenericFontAwesomeIcon icon={faHelmetSafety} />,
        iconColor: '#4F7D94',
        bgColor: '#EDF2F4',
      };
    }
    case 'Construction & Service Contractors': {
      return {
        icon: <GenericFontAwesomeIcon icon={faScrewdriverWrench} />,
        iconColor: '#118888',
        bgColor: '#E7F3F3',
      };
    }
    case 'Cost of Sales': {
      return {
        icon: <GenericFontAwesomeIcon icon={faChartPie} />,
        iconColor: '#696969',
        bgColor: '#FFFFFF',
      };
    }
    case 'Credit Card Payment': {
      return {
        icon: <GenericFontAwesomeIcon icon={faCreditCard} />,
        iconColor: '#BFAD04',
        bgColor: '#F6F3DA',
      };
    }
    case 'Debt Repayment': {
      return {
        icon: <GenericFontAwesomeIcon icon={faHandHoldingDollar} />,
        iconColor: '#FF5722',
        bgColor: '#FFE6DE',
      };
    }
    case 'Dining & Restaurants': {
      return {
        icon: <GenericFontAwesomeIcon icon={faUtensils} />,
        iconColor: '#5821FF',
        bgColor: '#F0EBFF',
      };
    }
    case 'Electronics': {
      return {
        icon: <GenericFontAwesomeIcon icon={faLaptop} />,
        iconColor: '#0277BD',
        bgColor: '#E3F2FD',
      };
    }
    case 'Employees & Contractors': {
      return {
        icon: <GenericFontAwesomeIcon icon={faUsers} />,
        iconColor: '#8E24AA',
        bgColor: '#F3E5F5',
      };
    }
    case 'Entertainment': {
      return {
        icon: <GenericFontAwesomeIcon icon={faFilm} />,
        iconColor: '#D81B60',
        bgColor: '#FCE4EC',
      };
    }
    case 'Equipment & Maintenance': {
      return {
        icon: <GenericFontAwesomeIcon icon={faGears} />,
        iconColor: '#00897B',
        bgColor: '#E0F2F1',
      };
    }
    case 'Facilities Expenses': {
      return {
        icon: <GenericFontAwesomeIcon icon={faBuilding} />,
        iconColor: '#5D4037',
        bgColor: '#EFEBE9',
      };
    }
    case 'Finance Charges/Fees': {
      return {
        icon: <GenericFontAwesomeIcon icon={faFileInvoiceDollar} />,
        iconColor: '#4527A0',
        bgColor: '#EDE7F6',
      };
    }
    case 'Food Stores': {
      return {
        icon: <GenericFontAwesomeIcon icon={faBasketShopping} />,
        iconColor: '#1B5E20',
        bgColor: '#E8F5E9',
      };
    }
    case 'Fuel': {
      return {
        icon: <GenericFontAwesomeIcon icon={faGasPump} />,
        iconColor: '#FF6F00',
        bgColor: '#FFE0B2',
      };
    }
    case 'Funds Transfer': {
      return {
        icon: <GenericFontAwesomeIcon icon={faExchangeAlt} />,
        iconColor: '#6A1B9A',
        bgColor: '#F3E5F5',
      };
    }
    case 'Furniture': {
      return {
        icon: <GenericFontAwesomeIcon icon={faChair} />,
        iconColor: '#E65100',
        bgColor: '#FFCCBC',
      };
    }
    case 'General & Admin': {
      return {
        icon: <GenericFontAwesomeIcon icon={faFileAlt} />,
        iconColor: '#37474F',
        bgColor: '#ECEFF1',
      };
    }
    case 'General Merchandise': {
      return {
        icon: <GenericFontAwesomeIcon icon={faCartShopping} />,
        iconColor: '#004D40',
        bgColor: '#E0F2F1',
      };
    }
    case 'Government Fees': {
      return {
        icon: <GenericFontAwesomeIcon icon={faLandmark} />,
        iconColor: '#BF360C',
        bgColor: '#FFEBEE',
      };
    }
    case 'Insurance': {
      return {
        icon: <GenericFontAwesomeIcon icon={faShieldAlt} />,
        iconColor: '#01579B',
        bgColor: '#E1F5FE',
      };
    }
    case 'Inventory': {
      return {
        icon: <GenericFontAwesomeIcon icon={faBoxes} />,
        iconColor: '#4E342E',
        bgColor: '#EFEBE9',
      };
    }
    case 'Medical Expenses': {
      return {
        icon: <GenericFontAwesomeIcon icon={faHospital} />,
        iconColor: '#AD1457',
        bgColor: '#FCE4EC',
      };
    }
    case 'Memberships': {
      return {
        icon: <GenericFontAwesomeIcon icon={faUserClock} />,
        iconColor: '#00796B',
        bgColor: '#E0F2F1',
      };
    }
    case 'New Debt': {
      return {
        icon: <GenericFontAwesomeIcon icon={faMoneyBillWheat} />,
        iconColor: '#3F51B5',
        bgColor: '#E8EAF6',
      };
    }
    case 'New Investment': {
      return {
        icon: <GenericFontAwesomeIcon icon={faCircleDollarToSlot} />,
        iconColor: '#0D47A1',
        bgColor: '#E3F2FD',
      };
    }
    case 'Non-Revenue Deposit': {
      return {
        icon: <GenericFontAwesomeIcon icon={faPiggyBank} />,
        iconColor: '#4CAF50',
        bgColor: '#E8F5E9',
      };
    }
    case 'NSF Fees': {
      return {
        icon: <GenericFontAwesomeIcon icon={faExclamationTriangle} />,
        iconColor: '#F44336',
        bgColor: '#FFEBEE',
      };
    }
    case 'Office Supplies': {
      return {
        icon: <GenericFontAwesomeIcon icon={faPencilAlt} />,
        iconColor: '#6D4C41',
        bgColor: '#EFEBE9',
      };
    }
    case 'Other Income': {
      return {
        icon: <GenericFontAwesomeIcon icon={faCoins} />,
        iconColor: '#1E88E5',
        bgColor: '#E3F2FD',
      };
    }
    case "Owner's Draw": {
      return {
        icon: <GenericFontAwesomeIcon icon={faFileSignature} />,
        iconColor: '#FF8F00',
        bgColor: '#FFF3E0',
      };
    }
    case 'Payroll': {
      return {
        icon: <GenericFontAwesomeIcon icon={faFileInvoiceDollar} />,
        iconColor: '#4CAF50',
        bgColor: '#E8F5E9',
      };
    }
    case 'Printing & Publishing Services': {
      return {
        icon: <GenericFontAwesomeIcon icon={faPrint} />,
        iconColor: '#1A237E',
        bgColor: '#E8EAF6',
      };
    }
    case 'Recruiting': {
      return {
        icon: <GenericFontAwesomeIcon icon={faUsers} />,
        iconColor: '#01579B',
        bgColor: '#E1F5FE',
      };
    }
    case 'Rent': {
      return {
        icon: <GenericFontAwesomeIcon icon={faHome} />,
        iconColor: '#FF6D00',
        bgColor: '#FFF3E0',
      };
    }
    case 'Revenue': {
      return {
        icon: <GenericFontAwesomeIcon icon={faHandHoldingUsd} />,
        iconColor: '#1E88E5',
        bgColor: '#E3F2FD',
      };
    }
    case 'Sales Expense': {
      return {
        icon: <GenericFontAwesomeIcon icon={faChartBar} />,
        iconColor: '#004D40',
        bgColor: '#E0F2F1',
      };
    }
    case 'Service Providers': {
      return {
        icon: <GenericFontAwesomeIcon icon={faHandshake} />,
        iconColor: '#1565C0',
        bgColor: '#E3F2FD',
      };
    }
    case 'Shipping': {
      return {
        icon: <GenericFontAwesomeIcon icon={faTruck} />,
        iconColor: '#0288D1',
        bgColor: '#E1F5FE',
      };
    }
    case 'Software & IT': {
      return {
        icon: <GenericFontAwesomeIcon icon={faLaptopCode} />,
        iconColor: '#424242',
        bgColor: '#FAFAFA',
      };
    }
    case 'Storage, Moving & Delivery': {
      return {
        icon: <GenericFontAwesomeIcon icon={faTruckLoading} />,
        iconColor: '#827717',
        bgColor: '#FFF8E1',
      };
    }
    case 'Taxes': {
      return {
        icon: <GenericFontAwesomeIcon icon={faFileInvoiceDollar} />,
        iconColor: '#C62828',
        bgColor: '#FFEBEE',
      };
    }
    case 'Transportation': {
      return {
        icon: <GenericFontAwesomeIcon icon={faCar} />,
        iconColor: '#6A1B9A',
        bgColor: '#F3E5F5',
      };
    }
    case 'Travel': {
      return {
        icon: <GenericFontAwesomeIcon icon={faPlane} />,
        iconColor: '#1565C0',
        bgColor: '#E3F2FD',
      };
    }
    case 'Uncategorized cash outflow': {
      return {
        icon: <GenericFontAwesomeIcon icon={faMoneyBillTransfer} />,
        iconColor: '#B71C1C',
        bgColor: '#FFEBEE',
      };
    }
    case 'Utilities': {
      return {
        icon: <GenericFontAwesomeIcon icon={faBolt} />,
        iconColor: '#1A237E',
        bgColor: '#E8EAF6',
      };
    }
    case 'Wholesale suppliers': {
      return {
        icon: <GenericFontAwesomeIcon icon={faTruckMoving} />,
        iconColor: '#827717',
        bgColor: '#FFF8E1',
      };
    }
    case 'Work Supplies': {
      return {
        icon: <GenericFontAwesomeIcon icon={faTools} />,
        iconColor: '#1B5E20',
        bgColor: '#E8F5E9',
      };
    }
  }
};
const CategoryIcon = (props: CategoryIconProps) => {
  const { category, className } = props;

  const categoryIcon = getCategoryIcon(category);

  if (!categoryIcon) return null;

  return cloneElement(categoryIcon.icon, {
    className,
    color: categoryIcon.iconColor,
  });
};

export default CategoryIcon;
