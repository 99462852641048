import { Alert, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { SyncAlt } from '@mui/icons-material';
import { DepositAccount } from '../../../../interfaces/account';
import {
  DepositPageAction,
  DepositPageActionType,
} from '../../../../app/pages/dashboard/deposit/reducer';
import React from 'react';
import LoadingButton from '../../../../app/components/LoadingButton';
import { formatCentsAsDollars } from '../../../../helpers/formattings';
import Grid from '@mui/material/Unstable_Grid2';
import { PlaidAuth } from '../../../../interfaces/baseInterfaces';
import { LandMarkIcon } from '../../../../app/components/Icon/fontawesome';
import depositService from '../../../../services/deposit.service';

interface AchDebitConfirmationFundingDialogProps {
  isOpen: boolean;
  updateState: React.Dispatch<DepositPageAction>;
  account: DepositAccount;
  amount: string;
  selectedExternalAccount: PlaidAuth;
  creating: boolean;
}

const AchDebitConfirmationFundingDialog = (props: AchDebitConfirmationFundingDialogProps) => {
  const handleGoBack = () => {
    props.updateState({
      type: DepositPageActionType.AchDebitConfirmationFundingPopupOpen,
      payload: false,
    });
    props.updateState({ type: DepositPageActionType.AchDebitFundingPopupOpen, payload: true });
  };

  const handleConfirm = async () => {
    if (props.selectedExternalAccount) {
      try {
        props.updateState({ type: DepositPageActionType.AchDebitCreatingFunding, payload: true });
        await depositService.createACHDebit(
          props.selectedExternalAccount.id,
          Number(props.amount) * 100,
        );
        props.updateState({
          type: DepositPageActionType.AchDebitConfirmationFundingPopupOpen,
          payload: false,
        });
        props.updateState({
          type: DepositPageActionType.AchDebitSuccessFundingPopupOpen,
          payload: true,
        });
        props.updateState({ type: DepositPageActionType.AchDebitCreatingFunding, payload: false });
      } catch (e) {
        props.updateState({ type: DepositPageActionType.AchDebitCreatingFunding, payload: false });
      }
    }
  };
  return (
    <Dialog open={props.isOpen} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <p className="text-bold font-lg">Bank Transfer</p>
        <p className="text-normal">Confirm your transaction details to confirm</p>
      </DialogTitle>
      <IconButton
        sx={{ position: 'absolute', right: 8, top: 8, color: theme => theme.palette.grey[500] }}
      >
        <LandMarkIcon fontSize="medium" className="rounded-circle bg-gray-10 p-1" />
      </IconButton>
      <DialogContent>
        <p className="font-xs">AMOUNT</p>
        <p className="font-2xl mb-1">{formatCentsAsDollars(Number(props.amount) * 100)}</p>
        <Grid container spacing={2} className="mb-1">
          <Grid xs={6}>
            <p className="font-bold font-xs mb-half">TRANSFER FROM</p>
            <p className="font-xs">Account Number</p>
            <p className="mb-half">
              **{props.selectedExternalAccount && props.selectedExternalAccount.last_four}
            </p>
            <p className="font-xs">Beneficiary Bank Name</p>
            <p className="mb-half">
              {props.selectedExternalAccount && props.selectedExternalAccount.name}
            </p>
          </Grid>
          <Grid xs={6}>
            <p className="font-bold font-xs mb-half">TRANSFER TO</p>
            <p className="font-xs">Account Number</p>
            <p className="mb-half">{props.account.account_number}</p>
            <p className="font-xs">Beneficiary Name</p>
            <p className="mb-half">{props.account.name}</p>
            <p className="font-xs">Type of Account</p>
            <p className="mb-half text-capitalize">{props.account.deposit_product}</p>
          </Grid>
        </Grid>
        {/*<Alert icon={false} severity="success">*/}
        {/*    <p className="font-bold mb-quarter">*/}
        {/*        Enable instant-settlement payments to your charge card when you use your Toolbox checking account*/}
        {/*        as your primary repayment account.*/}
        {/*    </p>*/}
        {/*    <FormGroup>*/}
        {/*        <FormControlLabel*/}
        {/*            control={<Checkbox />}*/}
        {/*            label={<span className="font-sm">By checking this box you will update your charge card repayment account to your Toolbox account</span>} />*/}
        {/*    </FormGroup>*/}
        {/*</Alert>*/}
        <section className="flex items-center justify-between mt-2">
          <LoadingButton variant="outlined" onClick={handleGoBack}>
            Go Back
          </LoadingButton>
          <LoadingButton onClick={handleConfirm} disabled={props.creating}>
            {props.creating ? 'Transfering' : 'Confirm & Transfer'}
          </LoadingButton>
        </section>
      </DialogContent>
    </Dialog>
  );
};

export default AchDebitConfirmationFundingDialog;
