import CategoryIcon from '../CategoryIcon';

interface CategorySelectItemProps {
  index: number;
  category: string;
  className?: string;
  selectedCategories: string[];
  onChange: (selectedCategories: string[], category: string, isSelected: boolean) => void;
}

const CategorySelectItem = (props: CategorySelectItemProps) => {
  const { category, className, index, selectedCategories, onChange } = props;
  const isSelected = selectedCategories.includes(category);

  const handleClick = () => {
    if (isSelected) {
      const updatedCategories = selectedCategories.filter(
        selectedCategory => selectedCategory !== category,
      );
      onChange(updatedCategories, category, !isSelected);
    } else {
      const updatedCategories = [category, ...selectedCategories];
      onChange(updatedCategories, category, !isSelected);
    }
  };

  return (
    <div
      data-cy={`trans-${category.replaceAll(' ', '')}-${index}`}
      className={`py-[10px] flex items-center hover:bg-teal-50 px-1 ${className} ${
        isSelected && 'bg-slate-200'
      } cursor-pointer`}
      onClick={handleClick}
    >
      <CategoryIcon category={category} className="mr-1" />
      <span>{category}</span>
    </div>
  );
};

export default CategorySelectItem;
