import { useQuery } from '@tanstack/react-query';
import { RutterPlatform } from '../rutter.types';
import rutterService from './rutter.service';
import { useUserRole } from '../../../auth/data/userRole';

export const useGetRutterCategories = (platform: RutterPlatform) => {
  const { isAdmin, isBookkeeper } = useUserRole();
  return useQuery({
    queryKey: [`/rutter/${platform}/categories`],
    queryFn: () => rutterService.getCategories(platform),
    enabled: isAdmin || isBookkeeper,
  });
};
