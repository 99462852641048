import React from 'react';
import { Typography } from '@mui/material';
import { CardIcon, GlobeIcon } from '../Icon/fontawesome';

interface AccountTypeSelectionProps {
  selectedAccountType: string;
  setSelectedAccountType: React.Dispatch<React.SetStateAction<string>>;
  currentUser: { is_deposit_customer: boolean; is_credit_customer: boolean };
}

const AccountTypeSelection: React.FC<AccountTypeSelectionProps> = ({
  selectedAccountType,
  setSelectedAccountType,
  currentUser,
}) => {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {currentUser?.is_credit_customer && (
          <div
            style={{
              display: 'flex',
              width: '49%',
              padding: '12px',
              alignItems: 'center',
              gap: '12px',
              cursor: 'pointer',
              borderRadius: '8px',
              border: selectedAccountType === 'credit' ? '1px solid #09442F' : '1px solid #EEE',
              background:
                selectedAccountType === 'credit'
                  ? 'white'
                  : 'var(--primary-light, linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #E7EDEA)',
            }}
            data-cy="chargeCard"
            onClick={() => setSelectedAccountType('credit')}
          >
            <div
              style={{
                display: 'flex',
                width: '40px',
                height: '40px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '100px',
                background: 'rgba(58, 105, 89, 0.10)',
              }}
            >
              <CardIcon style={{ color: '#3A6959', margin: 0, padding: 0 }} />
            </div>

            <div style={{ flex: 1, marginLeft: '12px' }}>
              <Typography
                variant="body1"
                style={{
                  color: selectedAccountType === 'credit' ? '#09442F' : '#181527',
                }}
              >
                Charge Card
              </Typography>
            </div>
          </div>
        )}

        {currentUser?.is_deposit_customer && (
          <div
            style={{
              display: 'flex',
              width: '49%',
              padding: '12px',
              alignItems: 'center',
              gap: '12px',
              cursor: 'pointer',
              borderRadius: '8px',
              opacity: currentUser.is_deposit_customer ? 1 : 0.5,
              border: selectedAccountType === 'debit' ? '2px solid #09442F' : '1px solid #EEE',
              background:
                selectedAccountType === 'debit'
                  ? 'white'
                  : 'var(--primary-light, linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #E7EDEA)',
            }}
            data-cy="debitCard"
            onClick={() => currentUser.is_deposit_customer && setSelectedAccountType('debit')}
          >
            <div
              style={{
                display: 'flex',
                width: '40px',
                height: '40px',
                padding: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                flexShrink: 0,
                borderRadius: '100px',
                border: selectedAccountType === 'debit' ? '2px solid #09442F' : '1px solid #EEE',
                background: selectedAccountType === 'debit' ? 'white' : '#E7EDEA',
              }}
            >
              <GlobeIcon style={{ color: '#3A6959' }} />
            </div>
            <div style={{ flex: 1, marginLeft: 'var(--3, 12px)' }}>
              <Typography
                variant="body1"
                style={{
                  color: selectedAccountType === 'debit' ? '#09442F' : '#181527',
                }}
              >
                Debit Card
              </Typography>
            </div>
          </div>
        )}
      </div>

      {!currentUser?.is_deposit_customer && (
        <div style={{ marginBottom: 12, marginTop: 8 }}>
          <Typography variant="caption" style={{ color: '#007149', lineHeight: 1 }}>
            Get access to instant charge card payments, 1% deposit yield, and up to 1.3% cashback on
            debit purchases with a Toolbox checking account.{' '}
            <strong
              style={{
                textDecoration: 'underline',
              }}
            >
              Learn more.
            </strong>
          </Typography>
        </div>
      )}
    </>
  );
};

export default AccountTypeSelection;
