import { Snackbar, SnackbarProps, Alert, AlertColor } from '@mui/material';
import { useEffect, useState } from 'react';

type SnackbarMessageType = AlertColor | 'custom';

interface SnackbarNotificationProps extends SnackbarProps {
  type: SnackbarMessageType;
  customContent?: React.ReactElement;
}

const SnackbarNotification = (props: SnackbarNotificationProps) => {
  const autoHideDurationSeconds = 6000;
  const { type, customContent, open, onClose, autoHideDuration, ...otherProps } = props;

  const [isVisible, setIsVisible] = useState(open);

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsVisible(false);
  };

  const showContent = (type: SnackbarMessageType) => {
    switch (type) {
      case 'custom':
        return customContent;
      default:
        return (
          <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
            {props.message}
          </Alert>
        );
    }
  };

  useEffect(() => {
    setIsVisible(open);
  }, [open]);

  return (
    <Snackbar
      open={isVisible}
      onClose={onClose ?? handleClose}
      autoHideDuration={autoHideDuration ?? autoHideDurationSeconds}
      {...otherProps}
    >
      {showContent(type)}
    </Snackbar>
  );
};

export default SnackbarNotification;
