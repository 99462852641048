import { Divider, InputAdornment, Popover, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import Show from '../../../../app/components/Show';
import CategoryPopupSelectorInputField from './CategoryPopupSelectorInputField';
import { useGetToolboxCategories } from '../../data/getToolboxCategories';
import LoadingButton from '../../../../app/components/LoadingButton';
import CategorySelectItem from './CategorySelectItem';

type ValueType = string & string[];

interface CategoryPopupSelectorProps {
  selectedCategories?: string[];
  value?: string;
  multiple?: boolean;
  onChange:
    | ((selectedCategories: string[], category: string, isSelected: boolean) => void)
    | ((selectedCategory: string, category: string, isSelected: boolean) => void);
  popupAnchorElement?: HTMLElement;
  title?: string;
  isOpen?: boolean;
  onClose?: () => void;
  className?: string;
  selectorPlaceholder?: string;
}

interface MultipleSelectorProps {
  selectedCategories: string[];
  multiple: true;
  onChange: (selectedCategories: string[], category: string, isSelected: boolean) => void;
  popupAnchorElement?: HTMLElement;
  title?: string;
  isOpen?: boolean;
  onClose?: () => void;
  className?: string;
  selectorPlaceholder?: string;
}

interface SingleSelectorProps {
  value: string;
  multiple?: false;
  onChange: (selectedCategory: string, category: string, isSelected: boolean) => void;
  popupAnchorElement?: HTMLElement;
  title?: string;
  isOpen?: boolean;
  onClose?: () => void;
  className?: string;
  selectorPlaceholder?: string;
}

function CategoryPopupSelector(props: SingleSelectorProps): JSX.Element;

function CategoryPopupSelector(props: MultipleSelectorProps): JSX.Element;

function CategoryPopupSelector(props: CategoryPopupSelectorProps): JSX.Element {
  const anchorElRef = useRef<HTMLElement>(null);

  const {
    selectedCategories,
    value: selectedCategory,
    multiple,
    onChange,
    popupAnchorElement,
    title,
    isOpen = false,
    onClose,
    className,
    selectorPlaceholder,
  } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(isOpen);

  const { data } = useGetToolboxCategories();
  const categories = data?.categories ?? [];

  const clearSelection = () => {
    if (multiple) {
      onChange([] as unknown as ValueType, '', false);
    } else {
      onChange('' as unknown as ValueType, '', false);
    }
    closePopup();
  };

  const togglePopupOpen = () => {
    setIsPopupOpen(prevState => !prevState);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    onClose && onClose();
  };

  const handleChange = (selectedCategories: string[], category: string, isSelected: boolean) => {
    if (multiple) {
      onChange(selectedCategories as ValueType, category, isSelected);
    } else {
      onChange(selectedCategories[0] as ValueType, category, isSelected);
    }
    closePopup();
  };

  useEffect(() => {
    setIsPopupOpen(isOpen);
  }, [isOpen]);

  const userSelection = multiple ? selectedCategories! : selectedCategory ? [selectedCategory] : [];

  return (
    <>
      <Show when={!popupAnchorElement}>
        <div className="relative">
          <span ref={anchorElRef} className="absolute"></span>
          <CategoryPopupSelectorInputField
            className={className}
            selectedCategories={userSelection}
            onClick={togglePopupOpen}
            placeholder={selectorPlaceholder}
          />
        </div>
      </Show>
      <Popover
        open={isPopupOpen}
        onClose={closePopup}
        anchorEl={popupAnchorElement ?? anchorElRef.current}
      >
        <section className="rounded min-w-[300px] max-h-[70vh] overflow-scroll">
          <div className="py-1">
            <div className="px-1">
              <p className="flex items-center justify-between">
                <span className="font-bold">{title ?? 'Select Categories'}</span>
                <span
                  className="cursor-pointer text-[12px] text-dark-green"
                  onClick={clearSelection}
                >
                  Clear all
                </span>
              </p>

              <TextField
                focused
                inputProps={{
                  'data-cy': 'trans-search-category',
                }}
                className="mt-1 p-0"
                size="small"
                variant="outlined"
                type="search"
                value={searchTerm}
                placeholder="Search category"
                onChange={event => setSearchTerm(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          {categories
            .filter(category => category.toLowerCase().includes(searchTerm))
            .map((category, index) => (
              <CategorySelectItem
                index={index}
                key={category}
                category={category}
                selectedCategories={userSelection}
                onChange={handleChange}
              />
            ))}
          <Divider className="mt-1" />
          <div className="px-1 flex items-center justify-end shadow mt-1 pb-1 px-1">
            <LoadingButton variant="outlined" onClick={closePopup}>
              <span className="px-1">Cancel</span>
            </LoadingButton>
          </div>
        </section>
      </Popover>
    </>
  );
}

export default CategoryPopupSelector;
