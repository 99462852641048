//eslint-disable-next-line
export const EMAIL =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const JOB_TITLE = /^[a-zA-Z\s.]{3,}$/;

//eslint-disable-next-line
export const PHONE = /^\d{3}\)?[\-]\d{3}[\-]\d{4}$/;

//eslint-disable-next-line
export const ADDRESS =
  /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i;

export const EIN = /[^-0-9]+/g;

export const SSN = /[0-9]{9}/;
