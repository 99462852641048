import {
  AccountBalance,
  CreditCard,
  Groups,
  Money,
  Home,
  Paid,
  Storefront,
} from '@mui/icons-material';
import { NavigationPermission } from '../../AppNavigation/AppDrawerWrapper';
import {
  ArrowLeftRightIcon,
  CardListIcon,
  CaseIcon,
  CashStackIcon,
  FolderOpenIcon,
  TagIcon,
} from 'app/components/Icon/fontawesome';

export interface NavigationTab {
  title: string;
  logEvent: string;
  linkTo: string;
  icon: React.ReactElement;
  callback: string;
  permissions?: (keyof NavigationPermission)[];
  isAccordion?: boolean;
  subTabs?: NavigationTab[];
  defaultExpand?: boolean;
}

export const navigationTabs: NavigationTab[] = [
  {
    title: 'Banking',
    logEvent: 'Banking link clicked',
    linkTo: '/dashboard/home',
    icon: <Home />,
    callback: '',
    permissions: ['isAdministrative'],
  },
  {
    title: 'Credit',
    logEvent: 'Credit link clicked',
    linkTo: '/dashboard/credit',
    icon: <CardListIcon />,
    callback: '',
    permissions: ['isAdministrative'],
  },
  {
    title: 'Transactions',
    logEvent: 'Transactions link clicked',
    linkTo: '/dashboard/transactions',
    icon: <ArrowLeftRightIcon />,
    callback: '',
  },
  {
    title: 'Toolbox AP',
    logEvent: 'Toolbox AP link clicked',
    linkTo: '/dashboard/vendors',
    icon: <CashStackIcon />,
    callback: '',
    permissions: ['isAdministrative'],
    defaultExpand: false,
    subTabs: [
      {
        title: 'Vendors',
        logEvent: 'Vendors link clicked',
        linkTo: '/dashboard/vendors',
        icon: <Storefront />,
        callback: '',
      },
      {
        title: 'Pay Vendor',
        logEvent: 'Pay link clicked',
        linkTo: '/dashboard/vendors/pay',
        icon: <Paid />,
        callback: '',
      },
    ],
  },
  {
    title: 'Cards',
    logEvent: 'Cards link clicked',
    linkTo: '/dashboard/cards',
    icon: <CreditCard />,
    callback: '',
    permissions: ['isEmployee'],
  },
  {
    title: 'Accounting',
    logEvent: 'Accounting link clicked',
    linkTo: '/dashboard/accounting',
    icon: <AccountBalance />,
    callback: '',
    permissions: ['isBookkeeper'],
  },
  {
    title: 'Admin',
    logEvent: 'Admin link clicked',
    linkTo: '/dashboard/cards',
    icon: <CaseIcon />,
    callback: '',
    permissions: ['isAdministrative'],
    defaultExpand: false,
    subTabs: [
      {
        title: 'Cards',
        logEvent: 'Cards link clicked',
        linkTo: '/dashboard/cards',
        icon: <CreditCard />,
        callback: '',
        permissions: ['isInternal'],
      },
      {
        title: 'Accounting',
        logEvent: 'Accounting link clicked',
        linkTo: '/dashboard/accounting',
        icon: <AccountBalance />,
        callback: '',
        permissions: ['isAdministrative', 'isBookkeeper'],
      },
      {
        title: 'Team',
        logEvent: 'Team link clicked',
        linkTo: '/dashboard/team',
        icon: <Groups />,
        callback: '',
        permissions: ['isAdministrative'],
      },
    ],
  },
  {
    title: 'Funding',
    logEvent: 'Funding link clicked',
    linkTo: '/dashboard/funding',
    icon: <Money />,
    callback: '',
    permissions: ['isAdministrative'],
  },
  {
    title: 'Tags',
    logEvent: 'Tags link clicked',
    linkTo: '/dashboard/tags',
    icon: <TagIcon style={{ width: 20, fontSize: 20 }} />,
    callback: '',
    permissions: ['isAdministrative'],
  },
];
