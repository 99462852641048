import { useEffect, useState } from 'react';
import { Statement } from '../../../../../interfaces/baseInterfaces';
import { useGetCsvStatement } from '../../../data/getCsvStatement';
import Show from '../../../../../app/components/Show';
import { GenericFontAwesomeIcon } from '../../../../../app/components/Icon/fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress } from '@mui/material';

interface CsvDownloadButtonProps {
  statement: Statement;
}

const CsvDownloadButton = (props: CsvDownloadButtonProps) => {
  const [statement, setStatement] = useState<Statement>();

  const { data: csvData, isLoading } = useGetCsvStatement(statement);

  const triggerDownload = (csvData: Blob) => {
    const url = window.URL.createObjectURL(csvData);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Toolbox Statement (${statement?.statement_period_start} - ${statement?.statement_period_end})`;
    a.click();
  };

  const downloadStatement = () => {
    setStatement(props.statement);
  };

  useEffect(() => {
    if (csvData) {
      triggerDownload(csvData);
      setStatement(undefined);
    }
  }, [csvData]);

  return (
    <span className="w-[15px] h-[15px]">
      <Show when={isLoading}>
        <CircularProgress size={15} />
      </Show>
      <Show when={!isLoading}>
        <GenericFontAwesomeIcon
          icon={faDownload}
          className="cursor-pointer"
          onClick={downloadStatement}
        />
      </Show>
    </span>
  );
};

export default CsvDownloadButton;
