import { Button, Dialog, DialogTitle } from '@mui/material';
import { Check2CircleIcon } from '../../../../app/components/Icon/fontawesome';
import {
  DepositPageAction,
  DepositPageActionType,
} from '../../../../app/pages/dashboard/deposit/reducer';
import React from 'react';
import { formatCentsAsDollars } from '../../../../helpers/formattings';

interface AchDebitSuccessFundingDialogProps {
  isOpen: boolean;
  amount: string;
  updateState: React.Dispatch<DepositPageAction>;
}
const AchDebitSuccessFundingDialog = (props: AchDebitSuccessFundingDialogProps) => {
  return (
    <Dialog open={props.isOpen} fullWidth={true} maxWidth="xs">
      <DialogTitle>
        <p className="text-center">
          <Check2CircleIcon style={{ fontSize: 21, marginBottom: 2, color: '#6FCF97' }} />
        </p>
        <p className="text-bold font-lg text-center">Success!</p>
        <p className="font-weight-lighter text-center px-2 mb-1">
          Your {formatCentsAsDollars(Number(props.amount) * 100)} bank transfer has been
          successfully initiated.
        </p>
        <p className="font-weight-lighter text-center px-2 mb-1">
          Your funds will be available in 3-5 business days.
        </p>
        <Button
          fullWidth={true}
          onClick={() =>
            props.updateState({
              type: DepositPageActionType.AchDebitSuccessFundingPopupOpen,
              payload: false,
            })
          }
        >
          View in Transactions
        </Button>
      </DialogTitle>
    </Dialog>
  );
};

export default AchDebitSuccessFundingDialog;
