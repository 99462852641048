import React, { CSSProperties } from 'react';
import MuiAvatar from '@mui/material/Avatar';

interface UserAvatarProps {
  avatarStyle: CSSProperties;
  avatarTextStyle: CSSProperties;
  companyInitial?: string;
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  avatarStyle,
  avatarTextStyle,
  companyInitial,
}) => (
  <MuiAvatar style={avatarStyle}>
    <span style={avatarTextStyle}>{companyInitial}</span>
  </MuiAvatar>
);

export default UserAvatar;
