import { memo, ReactNode } from 'react';
import Button, { ButtonBackground } from '../Button';
import PopUp from '../PopUp';

interface GenericPopUpProps {
  onClick(): void;
  onClose(): void;
  dataCyText?: string;
  isOpen: boolean;
  includeFooter?: boolean;
  title?: string;
  description?: string;
  children?: ReactNode;
  className?: string;
  btnText?: string;
  btnStyle?: string;
  btnDisabled?: boolean;
  btnLoading?: boolean;
  background?: ButtonBackground;
  dataId?: string;
  noOverlayClose?: boolean;
  noClose?: boolean;
}

const GenericPopUp = memo(function GenericPopUp(props: GenericPopUpProps) {
  const {
    isOpen,
    noOverlayClose,
    noClose,
    includeFooter = true,
    onClose,
    title,
    onClick,
    children,
    description,
    className = '',
    btnText = '',
    btnStyle = '',
    btnDisabled = false,
    background = 'MainError',
    btnLoading = false,
    dataId = '',
  } = props;
  return (
    <PopUp
      dataId={dataId}
      className={`px-1 ${className}`}
      isOpen={isOpen}
      onClose={onClose}
      noClose={noClose}
      noOverlayClose={noOverlayClose}
    >
      {title && (
        <h2 className="font-2xl font-bold font-noto-sans mt-2" datatype="generic-popup-header">
          {title}
        </h2>
      )}
      {description && <p style={{ marginTop: 12 }}>{description}</p>}
      {children}
      {includeFooter && (
        <div data-id="generic-popup-text" className="flex-row justify-end my-1">
          {btnText && (
            <Button
              dataCy={props.dataCyText}
              dataId="generic-popup-button"
              background={background}
              onClick={onClick}
              loading={btnLoading}
              disabled={btnDisabled}
              className={btnStyle}
            >
              {btnText}
            </Button>
          )}
        </div>
      )}
    </PopUp>
  );
});

export default GenericPopUp;
