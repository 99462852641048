import { CreditCard } from 'interfaces/baseInterfaces';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

export const formatDollarNumber = (num: number) => {
  const wasNegative = num < 0;
  num = Math.abs(num);

  const sign = wasNegative ? '-' : '';
  return (
    sign +
    '$' +
    Number(num)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  );
};

export const formatCentsAsDollars = (cents: number, flipSign?: boolean) => {
  return flipSign ? formatDollarNumber((cents / 100.0) * -1) : formatDollarNumber(cents / 100.0);
};

export const secondsToHoursAndMinutes = (seconds: number) => {
  const d = Number(seconds);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  let hDisplay = h > 0 ? h + ' hours ' : '';
  if (hDisplay.length === 2) {
    hDisplay = '0' + hDisplay;
  }

  const mDisplay = m > 0 ? m + ' minutes' : '';

  const hoursMinutes = hDisplay + mDisplay;

  if (hoursMinutes.length === 0) {
    return '0 minutes';
  }

  return hoursMinutes;
};

export const formatPhoneNumber = (phoneNumber: string) => {
  const phoneNumLength = phoneNumber.length;
  let finalNumber = phoneNumber;

  if (phoneNumLength < 7) {
    finalNumber = phoneNumber.replace(/(\d{3})(\d{1})/, '$1-$2');
  } else if (phoneNumLength < 10) {
    finalNumber = phoneNumber.replace(/(\d{3})-(\d{3})(\d{1})/, '$1-$2-$3');
  }

  finalNumber = fixAutocompletedPhoneNumber(finalNumber);

  return finalNumber;
};

export const formatDate = (date: string) => dayjs(date).format('MM/DD/YYYY');

export const formatDateToMonthDay = (date: string) => dayjs(date).format('MMMM DD');

export const formatTimeAgo = (date: string) => dayjs(date).fromNow();

export const formatApiDate = (date: string) => dayjs(date).format('YYYY-MM-DD');

export const formatEmail = (email: string) => email.replace(/ /g, '').toLowerCase();

// 4:11 pm
export const formatTime24hTo12h = (time: string) =>
  time ? dayjs(time, 'HH:mm:ss').format('h:mm a') : '--:--';

// 2020/06/18 04:11 pm
export const formatTimeToDateWithTime = (time: any) =>
  time ? dayjs(time, 'hh:mm:ss').format('YYYY/MM/DD hh:mm a') : '';

// 2020-06-18T16:46:18
export const dateStringToPayloadUTC = (date: string) =>
  date ? dayjs(date).utc().format('YYYY-MM-DDTHH:mm:ss') : '';

// 2020-06-18T16:46:18
export const dayjsToPayloadUTC = (m: dayjs.Dayjs) => m.utc().format('YYYY-MM-DDTHH:mm:ss');

// Jan 03 / 04:11 pm
export const formatUtcToLocalLongDate = (d: string) =>
  dayjs(dayjs.utc(d).toDate()).local().format('MMM DD / hh:mm a');

// Jan 03, 2021 / 04:11 pm
export const formatUtcToLocalDate = (d: string) =>
  dayjs(dayjs.utc(d).toDate()).local().format('MMM DD, YYYY / hh:mm a');

// Jan 03 04:11 pm
export const formatUtcToShortDate = (d: string) =>
  dayjs(dayjs.utc(d).toDate()).local().format('MMM DD hh:mm a');

export const formatMonthDay = (d: string | Date) => dayjs(d).format('MMM Do');

export const formatCardForDisplay = (c: CreditCard) => {
  return `${c.first_name} ${c.last_name.slice(0, 1)}'s ${c.status} card x${c.last4}`;
};

export const validPastDayMonthYear = (date: Date | null) => {
  if (!date) {
    return false;
  }

  if (isNaN(date.getTime())) {
    return false;
  }

  const now = dayjs();
  const oldestAllowed = dayjs('1776/01/01');
  const dateInQuestion = dayjs(date);

  if (dateInQuestion.isAfter(now)) {
    return false;
  }

  return !dateInQuestion.isBefore(oldestAllowed);
};

export const fixAutocompletedPhoneNumber = (phone: string) => {
  let regex = new RegExp(/\+1([0-9]{3})([0-9]{3})([0-9]{4})/); // +17145555309

  if (regex.test(phone)) {
    const match = phone.match(regex)!;

    return match[1] + '-' + match[2] + '-' + match[3];
  }

  regex = new RegExp(/\(([0-9]{3})\) ([0-9]{3})-([0-9]{4})/); // (714) 555-5309

  if (regex.test(phone)) {
    const match = phone.match(regex)!;

    return match[1] + '-' + match[2] + '-' + match[3];
  }

  regex = new RegExp(/([0-9]{3})([0-9]{3})([0-9]{4})/); // 7145555309

  if (regex.test(phone)) {
    const match = phone.match(regex)!;

    return match[1] + '-' + match[2] + '-' + match[3];
  }

  return phone;
};

export const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatCurrency = (num: number) => {
  return currencyFormatter.format(Number(num));
};

export const dollarToCents = (amount: number) => {
  return amount * 100;
};

export const countableNoun = (noun: string, count: number) => {
  return count === 1 ? noun : noun + 's';
};
