import React from 'react';
import { Box, Button } from '@mui/material';
import CustomDialog from 'app/components/CustomDialog';
import { Text } from 'contexts/language';
import { XCircleIcon } from 'app/components/Icon/fontawesome';

interface FailureDialogModalProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  description: string;
}

const FailureDialogModal: React.FC<FailureDialogModalProps> = ({
  isOpen,
  title,
  onClose,
  description,
}) => {
  return (
    <CustomDialog
      showHeader={true}
      width={442}
      fullWidth
      open={isOpen}
      onClose={() => onClose()}
      footerContent={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 2,
            gap: 1.5,
            paddingX: 2,
          }}
        >
          <Button
            variant="contained"
            className="h-3"
            color="primary"
            fullWidth
            onClick={() => onClose()}
          >
            Go back
          </Button>
        </Box>
      }
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          paddingTop: 4,
          paddingBottom: 2,
        }}
      >
        <XCircleIcon
          style={{ fontSize: 30, height: 32, width: 32, marginBottom: 4, color: '#FF4D4D' }}
        />

        <Box
          data-id="basic-details-box"
          bgcolor="white"
          px={6}
          borderRadius="borderRadius"
          sx={{ textAlign: 'center' }}
        >
          <p className="font-lg font-noto-sans-bold  my-half">
            <Text tid="Something went wrong" />
          </p>

          <div className="font-noto-sans-normal font-sm label-text my-half">
            <Text tid={title} />
          </div>
          <div className="font-noto-sans-normal font-sm label-text">
            <Text tid={description} />
          </div>
        </Box>
      </Box>
    </CustomDialog>
  );
};

export default FailureDialogModal;
