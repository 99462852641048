import { Outlet } from 'react-router-dom';
import { PlaidPopUp } from '../../components/PlaidPopUp';
import { standardError } from '../../../helpers/base';
import { useEffect, useState } from 'react';
import plaidService from '../../../services/plaid.service';
import { MyUser, ScreenProps } from '../../../interfaces/baseInterfaces';
import posthog from 'posthog-js';
import { CompanyRole } from '../../../interfaces/company';
import { ENV_IS_PRODUCTION } from '../../../config/environment';
import Show from '../../components/Show';
import AppDrawerWrapper from '../../components/AppNavigation/AppDrawerWrapper';
import Spinner from '../../components/Spinner';

type DashboardLayoutProps = Omit<ScreenProps, 'currentUser'> & {
  currentUser?: MyUser;
};

const DashboardLayout = (props: DashboardLayoutProps) => {
  const { currentUser } = props;
  const [isPlaidVerificationPopupOpen, setIsPlaidVerificationPopupOpen] = useState(false);
  const [linkToken, setLinkToken] = useState('');

  const loadLinkToken = async () => {
    ``;
    if (!currentUser?.plaid_pending || !currentUser.plaid_microdeposits_needed) {
      return;
    }
    const resp = await plaidService.createRelinkToken();
    if (resp.error) {
      standardError(resp.error);
      return;
    }
    setLinkToken(resp.link_token);
  };

  useEffect(() => {
    if (currentUser) {
      if (ENV_IS_PRODUCTION) {
        (window as any)._cio.identify({
          id: currentUser.id,
          email: currentUser.email,
          first_name: currentUser.first_name,
          last_name: currentUser.last_name,
        });
      }
    }
  }, [currentUser, linkToken]);

  useEffect(() => {
    posthog.capture('Dashboard Screen');

    if (currentUser?.team_role == CompanyRole.ACCOUNT_OWNER) {
      loadLinkToken();
    }
  }, []);

  useEffect(() => {
    setIsPlaidVerificationPopupOpen(
      !!(currentUser?.plaid_pending && currentUser?.plaid_microdeposits_needed),
    );
  }, [currentUser, linkToken]);

  return !currentUser ? (
    <Spinner />
  ) : (
    <div className="flex-column">
      <AppDrawerWrapper {...props} currentUser={currentUser}>
        <Outlet />
      </AppDrawerWrapper>
      <Show when={Boolean(linkToken && isPlaidVerificationPopupOpen)}>
        <PlaidPopUp
          relinkMode={true}
          samedayMicrodepositsVerification={true}
          isOpen={isPlaidVerificationPopupOpen}
          onClose={() => setIsPlaidVerificationPopupOpen(false)}
          linkToken={linkToken}
          onAccessTokenSet={async () => {
            await props.fetchUser();
            setIsPlaidVerificationPopupOpen(false);
          }}
          onPlaidError={async err => {
            setIsPlaidVerificationPopupOpen(false);
            const errorPayload = JSON.stringify(err);
            standardError(new Error(errorPayload));
            alert(errorPayload);

            await props.fetchUser();
          }}
        />
      </Show>
    </div>
  );
};

export default DashboardLayout;
