import { TRANSACTION_CATEGORIES } from 'constants/system';
import { CreditCard } from 'interfaces/baseInterfaces';
import { Transaction } from '../../../../features/transactions/types';
import { RutterCategory } from '../../../../features/integrations/rutter/rutter.types';

export type TransactionPageState = {
  availableFilterCategories: string[];
  availableFilterMerchants: string[];
  card: CreditCard | null;
  cards: CreditCard[];
  category: string[];
  customRangeStart: Date | undefined;
  customRangeEnd: Date | undefined;
  loading: boolean;
  saveMemoLoading: boolean;
  selectedTransactionForPopup: Transaction | null;
  totalSpent: number;
  transactionCount: number;
  transactions: Transaction[];
  isCustomRangeUndefined: boolean;
  isAdministrative: boolean;
  isReplacingReceipt: boolean;
  shouldRefresh: boolean;
  errorMessage: string;
  isMobile: boolean;
  transactionFiltersOpen: boolean;
  filterSelectedCount: number;
  transactionIdToAssign: string;
  showPayments: boolean;
  showRewards: boolean;
  showImmediateActionRequiredModal: boolean;
  selectedTransactionCashback: number;
  showOatfiDebitModal: boolean;
  quickBooksSelectedAccount: string | null;
};

export type TransactionPageAction = {
  readonly type: string;
  readonly payload: any;
};

export enum TransactionPageActionType {
  AvailableFilterCategories = 'availableFilterCategories',
  AvailableFilterMerchants = 'availableFilterMerchants',
  Card = 'card',
  Cards = 'cards',
  Category = 'category',
  CustomRangeStart = 'customRangeStart',
  CustomRangeEnd = 'customRangeEnd',
  Loading = 'loading',
  SaveMemoLoading = 'saveMemoLoading',
  SelectedTransactionForPopup = 'selectedTransactionForPopup',
  TotalSpent = 'totalSpent',
  TransactionCount = 'transactionCount',
  Transactions = 'transactions',
  IsCustomRangeUndefined = 'isCustomRangeUndefined',
  IsAdministrative = 'isAdministrative',
  IsReplacingReceipt = 'isReplacingReceipt',
  ShouldRefresh = 'shouldRefresh',
  ErrorMessage = 'errorMessage',
  IsMobile = 'isMobile',
  TransactionFiltersOpen = 'transactionFiltersOpen',
  FilterSelectedCount = 'filterSelectedCount',
  TransactionIdToAssign = 'transactionIdToAssign',
  ShowPayments = 'showPayments',
  ShowRewards = 'showRewards',
  ShowImmediateActionRequiredModal = 'showImmediateActionRequiredModal',
  SelectedTransactionCashback = 'selectedTransactionCashback',
  ShowOatfiDebitModal = 'showOatfiDebitModal',
  QuickBooksSelectedAccount = 'quickBooksSelectedAccount',
}

export const transactionPageInitialState: TransactionPageState = {
  availableFilterCategories: TRANSACTION_CATEGORIES,
  availableFilterMerchants: [],
  card: null,
  cards: [],
  category: [],
  customRangeStart: undefined,
  customRangeEnd: undefined,
  loading: false,
  saveMemoLoading: false,
  selectedTransactionForPopup: null,
  totalSpent: 0,
  transactionCount: 0,
  transactions: [],
  isCustomRangeUndefined: false,
  isAdministrative: false,
  isReplacingReceipt: false,
  shouldRefresh: false,
  errorMessage: '',
  isMobile: false,
  transactionFiltersOpen: false,
  filterSelectedCount: 0,
  transactionIdToAssign: '',
  showPayments: false,
  showRewards: false,
  showImmediateActionRequiredModal: false,
  selectedTransactionCashback: 0,
  showOatfiDebitModal: false,
  quickBooksSelectedAccount: null,
};

export function transactionPageReducer(state: TransactionPageState, action: TransactionPageAction) {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
}
