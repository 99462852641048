import { MyUser, ScreenProps } from 'interfaces/baseInterfaces';
import { memo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, SelectChangeEvent, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Chart, CategoryScale, LinearScale, LineElement, PointElement } from 'chart.js';
import WelcomeHeader from 'app/components/WelcomeHeader';
import TimePeriodSelect from 'app/components/CustomSelect';
import ActionMenuButton from 'app/components/ActionMenuButton';
import BalanceInfo from 'app/components/BalanceInfo';
import LineChart from 'app/components/LineChart';
import InfoCard from 'app/components/InfoCard';
import TransactionsTable from 'app/components/TransactionTable';
import { formatCentsAsDollars } from 'helpers/formattings';
import './style.scss';
import {
  ArrowDownIcon,
  ArrowUpRightIcon,
  GiftIcon,
  PercentageIcon,
} from 'app/components/Icon/fontawesome';

Chart.register(CategoryScale, LinearScale, LineElement, PointElement);

interface DepositDummyPageProps extends ScreenProps {
  currentUser: MyUser;
}

const nonFunction = () => {
  return null;
};

interface TransactionMenuProps {
  className?: string;
}

const TransactionMenus = ({ className }: TransactionMenuProps) => {
  const [transactionPeriod, setTransactionPeriod] = useState('last_30_days');

  const updateTransactionPeriod = (event: SelectChangeEvent<string>) => {
    setTransactionPeriod(event.target.value);
  };

  return (
    <div className={`items-center justify-between ${className}`}>
      <span>
        <TimePeriodSelect
          value={transactionPeriod}
          onChange={updateTransactionPeriod}
          style={{ padding: 0 }}
          options={[
            { value: 'last_30_days', label: 'Last 30 Days' },
            { value: 'last_60_days', label: 'Last 60 Days' },
            { value: 'last_90_days', label: 'Last 90 Days' },
          ]}
        />
      </span>
      <span>
        <ActionMenuButton
          anchorEl={null}
          open={false}
          handleActionClick={nonFunction}
          handleActionClose={nonFunction}
          handleActionAddsFunds={nonFunction}
          handleShowAccountDetails={nonFunction}
        />
      </span>
    </div>
  );
};

const DepositDummyPage = memo(function (props: DepositDummyPageProps) {
  const theme = useTheme();

  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { currentUser } = props;

  const DesktopViewContent = () => {
    return (
      <section className="hidden xl:flex flex-col">
        <div className="flex items-center mt-4">
          <BalanceInfo
            availableBalance={'$ 0.00'}
            accountInfoTrigger={nonFunction}
            className="bg-[#063021] min-w-[362px] mr-5 h-[185px] py-5"
          />
          <div className="overflow-scroll grow">
            <LineChart title="Cash Flow" subtitle="Last 30 days" />
          </div>
        </div>
        <section className="flex items-center px-4 mt-2">
          <InfoCard
            className="w-33-33-percent px-quarter"
            title="Money In / Out"
            subtitle={'Last 30 Days'}
            firstIcon={
              <ArrowDownIcon
                style={{ width: 18, height: 18, color: '#3A6959', cursor: 'pointer' }}
              />
            }
            secondIcon={
              <ArrowUpRightIcon
                style={{ width: 18, height: 18, color: '#3A6959', cursor: 'pointer' }}
              />
            }
            firstLabel="MONEY IN"
            firstValue={formatCentsAsDollars(0)}
            secondLabel="MONEY OUT"
            secondValue={formatCentsAsDollars(0)}
            linkText="VIEW TRANSACTIONS"
            sx={{
              width: matchesMobile ? '100%' : 'auto',
              marginBottom: matchesMobile ? 2 : 0,
              marginLeft: 0,
            }}
          />

          <InfoCard
            className="w-33-33-percent pr-half"
            title="Pending Transactions"
            firstIcon={
              <ArrowDownIcon
                style={{ width: 18, height: 18, color: '#3A6959', cursor: 'pointer' }}
              />
            }
            secondIcon={
              <ArrowUpRightIcon
                style={{ width: 18, height: 18, color: '#3A6959', cursor: 'pointer' }}
              />
            }
            firstLabel="PENDING DEPOSITS"
            firstValue={formatCentsAsDollars(0)}
            secondLabel="PENDING WITHDRAWALS"
            secondValue="$0.00"
            linkText="VIEW TRANSACTIONS"
            style={{ marginLeft: matchesMobile ? 0 : 16 }}
            sx={{
              width: matchesMobile ? '100%' : 'auto',
              marginBottom: matchesMobile ? 2 : 0,
              marginLeft: matchesMobile ? 0 : 2,
            }}
          />

          <InfoCard
            className="w-33-33-percent pl-half"
            title="Yield & Rewards"
            firstIcon={
              <PercentageIcon
                style={{ width: 18, height: 18, color: '#3A6959', cursor: 'pointer' }}
              />
            }
            secondIcon={
              <GiftIcon style={{ width: 18, height: 18, color: '#3A6959', cursor: 'pointer' }} />
            }
            firstLabel="INTEREST EARNED"
            firstValue={formatCentsAsDollars(0)}
            secondLabel="TOTAL POINTS"
            secondValue={String(0)}
            linkText="VIEW REWARDS"
            sx={{
              width: matchesMobile ? '100%' : 'auto',
              marginLeft: matchesMobile ? 0 : 2,
            }}
          />
        </section>
      </section>
    );
  };

  const MobileViewContent = () => {
    return (
      <section className="flex flex-col xl:hidden">
        <BalanceInfo
          availableBalance={'$ 0.00'}
          accountInfoTrigger={nonFunction}
          className="bg-black xl:bg-[#063021] xl:w-[362px] xl:mr-5"
        />
        <TransactionMenus className="flex mt-2 px-4 w-full" />
        <section className="flex flex-col px-4 mt-2">
          <InfoCard
            className="w-33-33-percent px-quarter"
            title="Money In / Out"
            subtitle={'Last 30 Days'}
            firstIcon={
              <ArrowDownIcon
                style={{ width: 18, height: 18, color: '#3A6959', cursor: 'pointer' }}
              />
            }
            secondIcon={
              <ArrowUpRightIcon
                style={{ width: 18, height: 18, color: '#3A6959', cursor: 'pointer' }}
              />
            }
            firstLabel="MONEY IN"
            firstValue={formatCentsAsDollars(0)}
            secondLabel="MONEY OUT"
            secondValue={formatCentsAsDollars(0)}
            linkText="VIEW TRANSACTIONS"
            sx={{
              width: matchesMobile ? '100%' : 'auto',
              marginBottom: matchesMobile ? 2 : 0,
              marginLeft: 0,
            }}
          />

          <InfoCard
            className="w-33-33-percent pr-half"
            title="Pending Transactions"
            firstIcon={
              <ArrowDownIcon
                style={{ width: 18, height: 18, color: '#3A6959', cursor: 'pointer' }}
              />
            }
            secondIcon={
              <ArrowUpRightIcon
                style={{ width: 18, height: 18, color: '#3A6959', cursor: 'pointer' }}
              />
            }
            firstLabel="PENDING DEPOSITS"
            firstValue={formatCentsAsDollars(0)}
            secondLabel="PENDING WITHDRAWALS"
            secondValue="$0.00"
            linkText="VIEW TRANSACTIONS"
            style={{ marginLeft: matchesMobile ? 0 : 16 }}
            sx={{
              width: matchesMobile ? '100%' : 'auto',
              marginBottom: matchesMobile ? 2 : 0,
              marginLeft: matchesMobile ? 0 : 2,
            }}
          />

          <InfoCard
            className="w-33-33-percent pl-half"
            title="Yield & Rewards"
            firstIcon={
              <PercentageIcon
                style={{ width: 18, height: 18, color: '#3A6959', cursor: 'pointer' }}
              />
            }
            secondIcon={
              <GiftIcon style={{ width: 18, height: 18, color: '#3A6959', cursor: 'pointer' }} />
            }
            firstLabel="INTEREST EARNED"
            firstValue={formatCentsAsDollars(0)}
            secondLabel="TOTAL POINTS"
            secondValue={String(0)}
            linkText="VIEW REWARDS"
            sx={{
              width: matchesMobile ? '100%' : 'auto',
              marginLeft: matchesMobile ? 0 : 2,
            }}
          />
        </section>
        <div className="mt-3 px-4"></div>
      </section>
    );
  };

  return (
    <div className="deposits-page pb-4">
      <Helmet>
        <title>Toolbox - Banking (Incomplete Application)</title>
      </Helmet>
      <div className="xl:flex items-center justify-between">
        <WelcomeHeader
          firstName={currentUser.first_name}
          className="p-4 bg-black text-gray-100 xl:bg-transparent xl:text-black"
        />
        <TransactionMenus className="hidden w-full md:w-[300px] xl:flex" />
      </div>

      <MobileViewContent />
      <DesktopViewContent />

      <div className="mt-3 px-4">
        <div className="flex-grow-1">
          <h2 style={{ fontSize: 20, fontWeight: 'semi-bold' }}>Recent Activity</h2>
        </div>
        <div className="flex-grow-1 text-right">
          <Button
            data-cy="viewAllTransactions1"
            color="secondary"
            variant="outlined"
            onClick={nonFunction}
          >
            View All Transactions
          </Button>
        </div>
      </div>
      <TransactionsTable transactions={[]} dispatcher={nonFunction} />
      <p className="text-center font-bold text-gray-700">No transactions yet.</p>
    </div>
  );
});

export default DepositDummyPage;
