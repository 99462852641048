import { useEffect, useState } from 'react';
import currentEnv, { ENV_IS_PRODUCTION } from './config/environment';
import { Helmet } from 'react-helmet';
import posthog from 'posthog-js';
import './app.scss';
import { FetchGlobalUser, MyUser } from './interfaces/baseInterfaces';
import userService from './services/user.service';
import Routes from './Routes';
import Spinner from 'app/components/Spinner';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { setUser, addBreadcrumb, Severity } from '@sentry/react';
import TagManager from 'react-gtm-module';
import { LanguageProvider } from './contexts/language';
import { toolboxDefaultTheme } from 'styles/theme/toolboxDefaultTheme';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'common/queryClient';
import { ErrorBoundary } from 'react-error-boundary';
import { standardError } from './helpers/base';
import AppErrorPage from './app/pages/errorBoundaries/AppErrorPage';
import { useAuthStore } from './features/auth/data/authStore';

const currEnv = currentEnv();

function App() {
  const [error_message, set_error_message] = useState('');
  const [theme, setTheme] = useState({});

  const appUser = useAuthStore(state => state.appUser);

  posthog.init('phc_Eu9uWlKSFBv7T0scRi0S4vY9uVCEFcoclQHfiuzuV58', {
    api_host: 'https://app.posthog.com',
    session_recording: {
      maskTextSelector: '.sensitive',
    },
  });

  useEffect(() => {
    const muiTheme = createTheme({ ...toolboxDefaultTheme });
    setTheme(muiTheme);
  }, [toolboxDefaultTheme]);

  const handleError = (e: any, timeout?: number) => {
    set_error_message(e.error || e.toString());
    setTimeout(() => set_error_message(''), timeout || 3000);
  };

  const fetchUser: FetchGlobalUser = async returnNewUser => {
    const user = await userService.getCurrentUser();

    if (user.error) {
      addBreadcrumb({
        category: 'auth',
        message: 'User error: ' + user.error,
        level: Severity.Error,
      });
      return;
    }

    if (user) {
      posthog.identify(user.id.toString(), {
        email: user.email,
        phone_number: user.phone_number,
        name: `${user.first_name} ${user.last_name}`,
        company_name: user.company_name,
        team_role: user.team_role,
      });
    }

    if (user && !user.error) {
      addBreadcrumb({
        category: 'auth',
        message: 'User is: [' + user.id + '] ' + user.first_name + ' ' + user.last_name,
        level: Severity.Log,
      });
    } else if (!user) {
      addBreadcrumb({
        category: 'auth',
        message: 'User not present: ' + user,
        level: Severity.Error,
      });
    } else if (user.error) {
      addBreadcrumb({
        category: 'auth',
        message: 'User error: ' + user.error,
        level: Severity.Error,
      });
    }

    if (returnNewUser) {
      return user;
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (ENV_IS_PRODUCTION) {
      TagManager.initialize({ gtmId: 'GTM-5MX66WS' });
    }
  }, []);

  useEffect(() => {
    const loadScript = (src: string) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      // script.onload = onLoadCallback;
      document.head?.appendChild(script);
    };

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${currEnv.GOOGLE_API_KEY}&libraries=places&callback=Function.prototype`,
    );
  }, []);

  useEffect(() => {
    if (appUser) {
      // @ts-ignore
      window.Intercom('boot', {
        app_id: 'ub4181vp',
        name: appUser.first_name, // Full name
        user_id: appUser.id, // a UUID for your user
        email: appUser.email, // the email for your user
        created_at: new Date(), // Signup date as a Unix timestamp
        user_hash: appUser.iukey, // HMAC SHA-256 of the user_id, email, and created_at, with your Intercom HMAC key
        //Website visitor so may not have any user related info
      });
    } else {
      // @ts-ignore
      window.Intercom('shutdown');
    }
  }, [appUser]);

  // We need the undefined state to let us know if we haven't even tried to load the current user, whereas null means
  // the user is logged out.
  // E.G:
  //
  // current_user == undefined -> haven't tried to load user and still need to check if logged in, the initial state
  // current_user == null -> we checked and the user is not logged in, safe to proceed
  // current_user != null -> we checked and the user is logged in, safe to proceed
  return appUser !== undefined ? (
    <ErrorBoundary
      FallbackComponent={AppErrorPage}
      onError={(error, info) => standardError(error, info)}
    >
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <LanguageProvider>
            <div>
              <Helmet></Helmet>
              <Routes
                handleError={handleError}
                currentUser={appUser}
                fetchUser={fetchUser}
                {...({} as any)}
              />
            </div>
          </LanguageProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  ) : (
    <Spinner />
  );
}

export default App;
