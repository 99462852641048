import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { MyUser } from '../../../interfaces/baseInterfaces';

interface AuthStore {
  appUser?: MyUser | null;
  authToken: string;
  setAppUser: (user: MyUser | null) => void;
  setAuthToken: (token: string) => void;
}

export const useAuthStore = create<AuthStore>()(
  persist(
    set => ({
      authToken: '',
      setAuthToken: authToken => set({ authToken }),
      setAppUser: appUser => set({ appUser }),
    }),
    {
      name: 'authStore',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
