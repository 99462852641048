import { isMobile } from 'helpers/base';
import { useState, useEffect } from 'react';

function useWindowDimensions() {
  const [height, setHeight] = useState<number>(window.innerHeight);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [isMobileScreen, setIsMobileScreen] = useState<boolean>(false);

  const handleUpdateWindowDimensions = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleUpdateWindowDimensions);
    return () => window.removeEventListener('resize', () => null);
  }, []);

  useEffect(() => {
    if (isMobile(width)) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }
  }, [height, width]);

  return { height, width, isMobileScreen };
}

export default useWindowDimensions;
