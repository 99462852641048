import React from 'react';
import PopUp from '../PopUp'; // Import your PopUp component
import { Typography } from '@mui/material';
import { CloseIcon } from '../Icon/fontawesome';

interface StyledGenericPopupProps {
  title?: string;
  subtitle?: string | JSX.Element;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  showHeader?: boolean;
  footerContent?: JSX.Element;
}

const StyledGenericPopup: React.FC<StyledGenericPopupProps> = ({
  title,
  subtitle,
  open,
  onClose,
  children,
  showHeader = true,
  footerContent,
}) => {
  return (
    <PopUp isOpen={open} onClose={onClose} noOverlayClose={true} noClose={true}>
      {showHeader && (
        <div
          style={{
            borderBottom: '1px solid #D2D5D8',
            position: 'relative',
            padding: '16px',
            display: 'flex',
            alignItems: title ? 'start' : 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Typography variant="h6" style={{ fontWeight: 'bold', color: 'black', fontSize: 20 }}>
              {title}
            </Typography>
            {subtitle && (
              <Typography style={{ color: '#4D4D4D', fontSize: 16 }}>{subtitle}</Typography>
            )}
          </div>
          <CloseIcon
            onClick={onClose}
            style={{
              color: '#09442F',
              cursor: 'pointer',
              height: 16,
              width: 16,
            }}
          />
        </div>
      )}

      <div style={{ padding: '16px' }}>{children}</div>

      {footerContent && <div style={{ textAlign: 'center', padding: '16px' }}>{footerContent}</div>}
    </PopUp>
  );
};

export default StyledGenericPopup;
