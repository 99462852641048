import logo from '../../../common/assets/logo.svg';
import { FallbackProps, useErrorBoundary } from 'react-error-boundary';

interface ErrorBoundaryProps {
  error: string;
  resetErrorBoundary: string;
}

const AppErrorPage = (props: FallbackProps) => {
  const { resetBoundary } = useErrorBoundary();

  return (
    <div>
      <header className="bg-black h-20 p-4 flex items-center">
        <img src={logo} alt="Toolbox logo" />
      </header>
      <div className="mt-[30vh] p-8 text-center h-96">
        <p className="text-gray-600 text-xl font-bold">Something unexpected happened.</p>
        <p className="font-bold mt-[5px]">Our engineering team is checking it out.</p>
        <p className="mt-2">
          <span
            onClick={resetBoundary}
            className="bg-black text-white px-4 py-1 rounded-lg cursor-pointer"
          >
            Take me back
          </span>
        </p>
      </div>
    </div>
  );
};

export default AppErrorPage;
