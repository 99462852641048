import { GenericFontAwesomeIcon } from '../../../../app/components/Icon/fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { Tooltip } from 'antd';

interface TransactionReferenceProps {
  transactionId: string;
  className?: string;
  titleClassName?: string;
}

const TransactionReference = (props: TransactionReferenceProps) => {
  const { transactionId, className, titleClassName } = props;

  return (
    <div className={`${className}`}>
      <div className={`${titleClassName} text-gray-600 text-xs`}>Transaction ID</div>
      <div>
        <span
          data-cy="transaction-reference-id"
          className="text-sm mr-[4px] text-[#3A6959] font-bold"
        >
          {transactionId}
        </span>
        <Tooltip title="Copy to clipboard" zIndex={9999}>
          <GenericFontAwesomeIcon
            size="sm"
            className="text-gray-600 cursor-pointer hover:text-[#3A6959]"
            icon={faCopy}
            onClick={() => navigator.clipboard.writeText(transactionId)}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default TransactionReference;
