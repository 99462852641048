import { GenericFontAwesomeIcon } from '../../../../app/components/Icon/fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { useNavigate } from 'react-router-dom';

interface IntegrationPageHeaderProps {
  logo: string;
  title: string;
  subtitle?: string;
  actionItems?: React.ReactNode;
  className?: string;
}

const IntegrationPageHeader = (props: IntegrationPageHeaderProps) => {
  const { logo, title, subtitle, actionItems, className } = props;
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <section className={className}>
      <div>
        <span className="flex items-center cursor-pointer" onClick={navigateBack}>
          <GenericFontAwesomeIcon icon={faArrowLeft} className="mr-[5px]" />
          <span className="underline text-sm">Go Back</span>
        </span>
      </div>
      <div className="flex items-center mt-1">
        <div className="flex items-center h-5">
          <img className="w-3 mr-[10px]" src={logo} alt="logo" />
          <div>
            <p className="font-bold text-lg lg:text-xl">{title}</p>
            <p className="font-light text-gray">{subtitle}</p>
          </div>
        </div>
        <div className="grow">
          <div className="flex justify-end">{actionItems}</div>
        </div>
      </div>
    </section>
  );
};

export default IntegrationPageHeader;
