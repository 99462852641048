import React from 'react';
import { Typography } from '@mui/material';
import { CardIcon, VirtualCardIcon } from '../Icon/fontawesome';

interface CardTypeSelectionProps {
  cardType: string;
  setCardType: React.Dispatch<React.SetStateAction<string>>;
  selectedAccountType: string;
  isEligibleForPhysical: boolean;
  currentUser: { is_deposit_customer: boolean; is_credit_customer: boolean };
  availableBalance: number;
}

const CardTypeSelection: React.FC<CardTypeSelectionProps> = ({
  cardType,
  setCardType,
  isEligibleForPhysical,
  currentUser,
  availableBalance,
  selectedAccountType,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 8 }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          padding: '12px',
          alignItems: 'center',
          gap: '12px',
          borderRadius: '8px',
          cursor: 'pointer',
          border: cardType === 'virtual' ? '2px solid #09442F' : '1px solid #EEE',
          background:
            cardType === 'virtual'
              ? 'white'
              : 'var(--primary-light, linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #E7EDEA)',
        }}
        data-cy="virtualCard"
        onClick={() => setCardType('virtual')}
      >
        <div
          style={{
            display: 'flex',
            width: '40px',
            height: '40px',
            padding: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            flexShrink: 0,
            borderRadius: '100px',
            background: 'rgba(58, 105, 89, 0.10)',
          }}
        >
          <VirtualCardIcon style={{ color: '#3A6959' }} />
        </div>
        <div style={{ flex: 1, marginLeft: '12px' }}>
          <Typography variant="body1">Virtual Card</Typography>
          <Typography variant="caption" style={{ marginBottom: 16 }}>
            Used for one-time purchases, or leaving a card on file with a supplier
          </Typography>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          width: '100%',
          padding: '12px',
          marginTop: '12px',
          alignItems: 'center',
          gap: '12px',
          borderRadius: '8px',
          cursor: 'pointer',
          opacity:
            isEligibleForPhysical ||
            (currentUser.is_deposit_customer &&
              availableBalance >= 5000 &&
              selectedAccountType === 'debit')
              ? 1
              : 0.5,
          border: cardType === 'physical' ? '2px solid #09442F' : '1px solid #EEE',
          background:
            cardType === 'physical'
              ? 'white'
              : 'var(--primary-light, linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #E7EDEA)',
        }}
        data-cy="physicalCard"
        onClick={() =>
          currentUser.is_deposit_customer && availableBalance >= 5000 && setCardType('physical')
        }
      >
        <div
          style={{
            display: 'flex',
            width: '40px',
            height: '40px',
            padding: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            flexShrink: 0,
            borderRadius: '100px',
            background: 'rgba(58, 105, 89, 0.10)',
          }}
        >
          <CardIcon style={{ color: '#3A6959' }} />
        </div>
        <div style={{ flex: 1, marginLeft: '12px' }}>
          <Typography variant="body1">Physical Card</Typography>
          <Typography variant="caption" style={{ marginBottom: 16 }}>
            Physical cards are cards for every day usage, like supplies or gas
          </Typography>
        </div>
      </div>

      {currentUser.is_deposit_customer &&
        availableBalance < 5000 &&
        selectedAccountType === 'debit' &&
        !isEligibleForPhysical && (
          <Typography
            variant="caption"
            style={{ marginBottom: 12, marginTop: 16, color: '#D98600' }}
          >
            Deposit $5,000 or more to order a physical Toolbox card.
          </Typography>
        )}
    </div>
  );
};

export default CardTypeSelection;
