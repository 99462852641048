import { Skeleton } from '@mui/material';
import Show from '../../../../app/components/Show';
import { useUserRole } from '../../../auth/data/userRole';

interface IntegrationAccountMapperLoaderProps {}

const IntegrationAccountMapperLoader = (props: IntegrationAccountMapperLoaderProps) => {
  const { isCreditCustomer, isDepositCustomer } = useUserRole();
  return (
    <div>
      <Show when={isCreditCustomer}>
        <div className="gap-y-6 flex flex-column">
          <div className="hidden md:flex mt-1 items-center justify-between gap-x-[15%] mb-1 text-xs font-bold uppercase">
            <Skeleton variant="rounded" className="grow" />
            <Skeleton variant="rounded" className="grow" />
          </div>
          <div className="flex flex-column md:flex-row gap-x-[15%] gap-y-3">
            <Skeleton variant="rounded" className="grow" height={50} />
            <Skeleton variant="rounded" className="grow" height={50} />
          </div>
          <div className="flex flex-column md:flex-row gap-x-[15%] gap-y-3">
            <Skeleton variant="rounded" className="grow" height={50} />
            <Skeleton variant="rounded" className="grow" height={50} />
          </div>
        </div>
      </Show>
      <Show when={isDepositCustomer}>
        <div className="flex flex-column md:flex-row gap-x-[15%] gap-y-3 mt-4">
          <Skeleton variant="rounded" className="grow" height={50} />
          <Skeleton variant="rounded" className="grow" height={50} />
        </div>
      </Show>
      <div className="flex justify-end mt-3">
        <Skeleton variant="rounded" height={50} width={90} />
      </div>
    </div>
  );
};

export default IntegrationAccountMapperLoader;
