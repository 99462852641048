import './style.scss';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface DateAndTimePickerProps {
  label?: string;
  value: string;
  placeholder?: string;
  timeInputLabel?: string;
  onChange(e: any): void;
  noLabel?: boolean;
}

export default function DateAndTimePicker(props: DateAndTimePickerProps) {
  return (
    <section className="DateAndTimePicker-component pr-1">
      {props.noLabel ? (
        ''
      ) : (
        <p style={{ fontFamily: 'Poppins', color: 'gray' }}>{props.label || props.placeholder}</p>
      )}

      <ReactDatePicker
        selected={props.value ? new Date(props.value) : null}
        value={props.value}
        placeholderText={props.placeholder}
        onChange={date => props.onChange(date)}
        timeInputLabel={props.timeInputLabel || 'Time:'}
        dateFormat="MM/dd/yyyy"
        timeIntervals={1}
      />
    </section>
  );
}
