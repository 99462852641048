import { SyncAlt } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import FundingOptionItem from 'features/depositAccount/components/FundingOptionItem/FundingOptionItem';
import { DepositPageAction, DepositPageActionType } from 'app/pages/dashboard/deposit/reducer';
import { CashStackIcon, CloseIcon, LandMarkIcon } from 'app/components/Icon/fontawesome';
import posthog from 'posthog-js';

interface AddFundsDialogProps {
  updateState: React.Dispatch<DepositPageAction>;
  isOpen: boolean;
}

const AddFundsDialog = (props: AddFundsDialogProps) => {
  const { updateState, isOpen } = props;
  const theme = useTheme();

  // const [isAchModalOpen, setIsAchModalOpen] = useState
  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCheckDepositSelect = () => {
    if (!matchesMobile) {
      return;
    }
    posthog.capture('Banking Page - Actions - Check Deposit Click');
    updateState({ type: DepositPageActionType.AddFundsPopupOpen, payload: false });
    updateState({ type: DepositPageActionType.NewCheckDepositSidePhoto, payload: '' });
    updateState({ type: DepositPageActionType.NewCheckDepositSidePhotoBack, payload: '' });
    updateState({ type: DepositPageActionType.NewCheckDepositSidePhotoFront, payload: '' });
    updateState({
      type: DepositPageActionType.NewCheckDepositSidePhotoFilled,
      payload: false,
    });
    updateState({ type: DepositPageActionType.NewCheckDepositAmount, payload: '' });
    updateState({ type: DepositPageActionType.NewCheckDepositPopupOpen, payload: true });
  };

  const handleAchSelect = () => {
    posthog.capture('Banking Page - Actions - ACH Credit/Wire Transfer Click');
    updateState({ type: DepositPageActionType.AddFundsPopupOpen, payload: false });
    updateState({ type: DepositPageActionType.AchFundingPopupOpen, payload: true });
  };

  const handleAchDebitSelect = () => {
    updateState({ type: DepositPageActionType.NewACHDepositAmount, payload: '' });
    updateState({ type: DepositPageActionType.AddFundsPopupOpen, payload: false });
    updateState({ type: DepositPageActionType.AchDebitFundingPopupOpen, payload: true });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => updateState({ type: DepositPageActionType.AddFundsPopupOpen, payload: false })}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>
        <p className="text-bold font-lg">Add funds to your accounts</p>
        <p className="text-normal">Choose an option below</p>
      </DialogTitle>
      <IconButton
        onClick={() =>
          updateState({ type: DepositPageActionType.AddFundsPopupOpen, payload: false })
        }
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon fontSize="large" />
      </IconButton>

      <DialogContent dividers>
        <FundingOptionItem
          onClick={handleAchSelect}
          title="ACH Credit/Wire Transfer"
          subtitle="Arrives in 1-2 business days"
          icon={<SyncAlt className="rounded-circle bg-gray-10 px-quarter py-quarter" />}
        />
        <FundingOptionItem
          onClick={handleAchDebitSelect}
          title="ACH Debit/Direct Bank Transfer"
          subtitle="Transfers between accounts in 1-2 business days"
          icon={<LandMarkIcon className="rounded-circle bg-gray-10 px-quarter py-quarter" />}
        />
        <FundingOptionItem
          mobileOnly
          onClick={handleCheckDepositSelect}
          title="Check deposit"
          subtitle="Appears in 1-7 business days"
          icon={<CashStackIcon className="rounded-circle bg-gray-10 px-quarter py-quarter" />}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddFundsDialog;
