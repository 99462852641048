import { EntityTag } from '../../../tags/types';
import Show from '../../../../app/components/Show';
import { useRef, useState } from 'react';
import EntityTagsPopup from '../../../tags/components/EntityTagsPopup';
import { SellOutlined } from '@mui/icons-material';
import { Tag as Chip } from 'antd';
import { countableNoun } from '../../../../helpers/formattings';
import AddTagToEntityPopup from '../../../tags/components/AddTagToEntityPopup';
import { useGetEntityTags } from '../../../tags/data/getEntityTags';
import { Transaction } from '../../types';
import { useUserRole } from '../../../auth/data/userRole';

interface TransactionTagCellProps {
  index: number;
  entityTags: EntityTag[];
  transaction: Transaction;
}

const TransactionTagCell = (props: TransactionTagCellProps) => {
  const { index, entityTags: initialEntityTags, transaction } = props;

  const [isTagPopupOpen, setIsTagPopupOpen] = useState(false);
  const [isAddTagPopupOpen, setIsAddTagPopupOpen] = useState(false);
  const anchorElRef = useRef<HTMLElement>(null);

  const { data: entityTagsData, refetch } = useGetEntityTags(
    transaction.entity_id,
    transaction.entity_type,
    initialEntityTags,
  );
  const entityTags = entityTagsData?.entity_tags ?? [];
  const hasTags = entityTags.length > 0;

  const { isAdmin } = useUserRole();

  const openTagsPopup = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    setIsTagPopupOpen(true);
  };

  const openAddTagPopup = (e?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (e) {
      e.stopPropagation();
    }
    setIsTagPopupOpen(false);
    setIsAddTagPopupOpen(true);
  };

  return (
    <>
      <span ref={anchorElRef}></span>
      <Show when={!hasTags}>
        <Show when={isAdmin}>
          <span
            data-cy={`Add-tags-${index}`}
            onClick={openAddTagPopup}
            className="underline text-dark-green cursor-pointer flex items-center mr-1"
          >
            <SellOutlined fontSize="small" className="mr-[4px]" />
            <span>Add Tags</span>
          </span>
        </Show>
        <Show when={!isAdmin}>
          <span className="text-[#3a6959] pl-[5px]">No Tags</span>
        </Show>
      </Show>

      <Show when={hasTags}>
        <Chip
          data-cy={`tags-added-${index}`}
          onClick={openTagsPopup}
          className="mr-1 rounded-full text-[#3a6959] cursor-pointer border-[#3a6959] text-[12px]"
        >
          {entityTags.length} {countableNoun('Tag', entityTags.length)} Added
        </Chip>
      </Show>

      <EntityTagsPopup
        isOpen={isTagPopupOpen}
        anchorEl={anchorElRef.current!}
        onClose={() => setIsTagPopupOpen(false)}
        entityTags={entityTags}
        onAddTagButtonClick={openAddTagPopup}
        onUpdate={refetch}
      />
      <AddTagToEntityPopup
        isOpen={isAddTagPopupOpen}
        onClose={() => setIsAddTagPopupOpen(false)}
        anchorEl={anchorElRef.current!}
        entityId={transaction.entity_id}
        entityType={transaction.entity_type}
        entityTags={entityTags}
        onUpdate={refetch}
      />
    </>
  );
};

export default TransactionTagCell;
