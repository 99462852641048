import { EntityTag } from 'features/tags/types';
import httpClient from 'services/httpClient';

export interface LatestPaymentsResponse {
  data: PaymentDetails[];
}

export interface AmountOverdueResponse {
  data: number;
}

export interface LatestTransactionsResponse {
  data: TransactionDetails[];
}

export interface RewardsResponse {
  total_rewards: RewardDetails[];
}

export interface PaymentDetails {
  created_at: string;
  status: string;
  amount: number;
}

export interface TransactionDetails {
  id: string;
  created_at: string;
  status: string;
  amount: number;
  merchant: string;
  category: string | null;
  type: string;
  receiptUrl: string | null;
  memo: string | null;
  summary: string | null;
  account_type: string | null;
  merchant_logo: string | null;
  merchant_address: string | null;
  tags?: EntityTag[];
}

export interface RewardDetails {
  total_rewards: number;
}

export interface CreditAccountSummaryResponse {
  amount_due: number;
  company_available_spend: number;
  company_balance: number;
  company_limit: number;
  current_month_spend: number;
  current_pending_authorizations: number;
  current_statement_period: string;
  due_date: string;
  pending_payments: number;
  is_paying_with_thread: boolean;
}

export interface CreditAccountLimitResponse {
  credit_limit: number;
}

export interface ICreditAccountService {
  getLatestPayments: () => Promise<LatestPaymentsResponse>;
  getAmountOverdue: () => Promise<AmountOverdueResponse>;
  getLatestTransactions: () => Promise<LatestTransactionsResponse>;
  getRewards: () => Promise<RewardsResponse>;
  getCreditAccountSummary: () => Promise<CreditAccountSummaryResponse>;
  getCreditAccountLimit: () => Promise<CreditAccountLimitResponse>;
}

export class CreditAccountService implements ICreditAccountService {
  getLatestPayments = () => {
    return httpClient
      .get<LatestPaymentsResponse>('/credit-account/latest-payments')
      .then(res => res.data);
  };

  getAmountOverdue = () => {
    return httpClient
      .get<AmountOverdueResponse>('/credit-account/amount-overdue')
      .then(res => res.data);
  };

  getLatestTransactions = () => {
    return httpClient
      .get<LatestTransactionsResponse>('/credit-account/latest-transactions')
      .then(res => res.data);
  };

  getRewards = () => {
    return httpClient.get<RewardsResponse>('/credit-account/rewards').then(res => res.data);
  };

  getCreditAccountSummary = async () => {
    return await httpClient
      .get<CreditAccountSummaryResponse>('/credit-account/summary')
      .then(res => res.data);
  };

  getCreditAccountLimit = async () => {
    return await httpClient
      .get<CreditAccountLimitResponse>('/credit-account/limit')
      .then(res => res.data);
  };
}
