import { createElement, CSSProperties } from 'react';

import { ReactComponent as Account } from './icons/Account.svg';
import { ReactComponent as ActiveStar } from './icons/ActiveStar.svg';
import { ReactComponent as AngleDown } from './icons/AngleDown.svg';
import { ReactComponent as ArrowRight } from './icons/ArrowRight.svg';
import { ReactComponent as ArrowUp } from './icons/ArrowUp.svg';
import { ReactComponent as Attachment } from './icons/Attachment.svg';
import { ReactComponent as Briefcase } from './icons/Briefcase.svg';
import { ReactComponent as Calendar } from './icons/Calendar.svg';
import { ReactComponent as Carpentry } from './icons/Carpentry.svg';
import { ReactComponent as Certificate } from './icons/Certificate.svg';
import { ReactComponent as Check } from './icons/Check.svg';
import { ReactComponent as Clock } from './icons/Clock.svg';
import { ReactComponent as Close } from './icons/Close.svg';
import { ReactComponent as Company } from './icons/Company.svg';
import { ReactComponent as Concrete } from './icons/Concrete.svg';
import { ReactComponent as DateArrowLeft } from './icons/DateArrowLeft.svg';
import { ReactComponent as DateArrowRight } from './icons/DateArrowRight.svg';
import { ReactComponent as Delete } from './icons/Delete.svg';
import { ReactComponent as Demo } from './icons/Demo.svg';
import { ReactComponent as Drywall } from './icons/Drywall.svg';
import { ReactComponent as Edit } from './icons/Edit.svg';
import { ReactComponent as Electrical } from './icons/Electrical.svg';
import { ReactComponent as EmptyProjects } from './icons/EmptyProjects.svg';
import { ReactComponent as Eye } from './icons/Eye.svg';
import { ReactComponent as Flooring } from './icons/Flooring.svg';
import { ReactComponent as Gear } from './icons/Gear.svg';
import { ReactComponent as GeneralLabor } from './icons/GeneralLabor.svg';
import { ReactComponent as GigsHandshake } from './icons/GigsHandshake.svg';
import { ReactComponent as Glass } from './icons/Glass.svg';
import { ReactComponent as Hvac } from './icons/Hvac.svg';
import { ReactComponent as Ironwork } from './icons/Ironwork.svg';
import { ReactComponent as Landscaping } from './icons/Landscaping.svg';
import { ReactComponent as Letter } from './icons/Letter.svg';
import { ReactComponent as Loader } from './icons/Loader.svg';
import { ReactComponent as LocationPin } from './icons/LocationPin.svg';
import { ReactComponent as Logo } from './icons/Logo.svg';
import { ReactComponent as Masonry } from './icons/Masonry.svg';
import { ReactComponent as NoJobs } from './icons/NoJobs.svg';
import { ReactComponent as NoProjects } from './icons/NoProjects.svg';
import { ReactComponent as Painting } from './icons/Painting.svg';
import { ReactComponent as Pdf } from './icons/Pdf.svg';
import { ReactComponent as Person } from './icons/Person.svg';
import { ReactComponent as Phone } from './icons/Phone.svg';
import { ReactComponent as Plumbing } from './icons/Plumbing.svg';
import { ReactComponent as Plus } from './icons/Plus.svg';
import { ReactComponent as Refer } from './icons/Refer.svg';
import { ReactComponent as Roofing } from './icons/Roofing.svg';
import { ReactComponent as RoundDown } from './icons/RoundDown.svg';
import { ReactComponent as SavePdf } from './icons/SavePdf.svg';
import { ReactComponent as SaveProfile } from './icons/SaveProfile.svg';
import { ReactComponent as Search } from './icons/Search.svg';
import { ReactComponent as SelectDown } from './icons/SelectDown.svg';
import { ReactComponent as SelectUp } from './icons/SelectUp.svg';
import { ReactComponent as Send } from './icons/Send.svg';
import { ReactComponent as Share } from './icons/Share.svg';
import { ReactComponent as ShareGray } from './icons/ShareGray.svg';
import { ReactComponent as Star } from './icons/Star.svg';
import { ReactComponent as StarOutline } from './icons/StarOutline.svg';
import { ReactComponent as Team } from './icons/Team.svg';
import { ReactComponent as TileStone } from './icons/TileStone.svg';
import { ReactComponent as TooltipArrow } from './icons/TooltipArrow.svg';
import { ReactComponent as TrendingDown } from './icons/TrendingDown.svg';
import { ReactComponent as TrendingUp } from './icons/TrendingUp.svg';
import { ReactComponent as Usa } from './icons/Usa.svg';
import { ReactComponent as Vetted } from './icons/Vetted.svg';
import { ReactComponent as Welding } from './icons/Welding.svg';
import { ReactComponent as WorkHelmet } from './icons/WorkHelmet.svg';
import { ReactComponent as CraneImage } from './icons/CraneImage.svg';
import { ReactComponent as ShareReferral } from './icons/ShareReferral.svg';
import { ReactComponent as FirstTransaction } from './icons/FirstTransaction.svg';
import { ReactComponent as ReferralReward } from './icons/ReferralReward.svg';
import { ReactComponent as USFlag } from './icons/flag-us.svg';
import { ReactComponent as SpainFlag } from './icons/flag-spain.svg';

const Components: any = {
  Account,
  ActiveStar,
  AngleDown,
  ArrowRight,
  ArrowUp,
  Attachment,
  Briefcase,
  Calendar,
  Carpentry,
  Certificate,
  Check,
  Clock,
  Close,
  Company,
  Concrete,
  DateArrowLeft,
  DateArrowRight,
  Delete,
  Demo,
  Drywall,
  Edit,
  Electrical,
  EmptyProjects,
  Eye,
  Flooring,
  Gear,
  GeneralLabor,
  GigsHandshake,
  Glass,
  Hvac,
  Ironwork,
  Landscaping,
  Letter,
  Loader,
  LocationPin,
  Logo,
  Masonry,
  NoJobs,
  NoProjects,
  Painting,
  Pdf,
  Person,
  Phone,
  Plumbing,
  Plus,
  Refer,
  Roofing,
  RoundDown,
  SavePdf,
  SaveProfile,
  Search,
  SelectDown,
  SelectUp,
  Send,
  Share,
  ShareGray,
  Star,
  StarOutline,
  Team,
  TileStone,
  TooltipArrow,
  TrendingDown,
  TrendingUp,
  Usa,
  Vetted,
  Welding,
  WorkHelmet,
  CraneImage,
  ShareReferral,
  FirstTransaction,
  ReferralReward,
  USFlag,
  SpainFlag,
};

export type SystemIcons =
  | 'Account'
  | 'ActiveStar'
  | 'AngleDown'
  | 'ArrowRight'
  | 'ArrowUp'
  | 'Attachment'
  | 'Briefcase'
  | 'Calendar'
  | 'Carpentry'
  | 'Certificate'
  | 'Check'
  | 'Clock'
  | 'Close'
  | 'Company'
  | 'Concrete'
  | 'DateArrowLeft'
  | 'DateArrowRight'
  | 'Delete'
  | 'Demo'
  | 'Drywall'
  | 'Edit'
  | 'Electrical'
  | 'EmptyProjects'
  | 'Eye'
  | 'Flooring'
  | 'Gear'
  | 'GeneralLabor'
  | 'GigsHandshake'
  | 'Glass'
  | 'Hvac'
  | 'Ironwork'
  | 'Landscaping'
  | 'Letter'
  | 'Loader'
  | 'LocationPin'
  | 'Logo'
  | 'Masonry'
  | 'NoJobs'
  | 'NoProjects'
  | 'Painting'
  | 'Pdf'
  | 'Person'
  | 'Phone'
  | 'Plumbing'
  | 'Plus'
  | 'Refer'
  | 'Roofing'
  | 'RoundDown'
  | 'SavePdf'
  | 'SaveProfile'
  | 'Search'
  | 'SelectDown'
  | 'SelectUp'
  | 'Send'
  | 'Share'
  | 'ShareGray'
  | 'Star'
  | 'StarOutline'
  | 'Team'
  | 'TileStone'
  | 'TooltipArrow'
  | 'TrendingDown'
  | 'TrendingUp'
  | 'Usa'
  | 'Vetted'
  | 'Welding'
  | 'WorkHelmet'
  | 'CraneImage'
  | 'ShareReferral'
  | 'FirstTransaction'
  | 'ReferralReward'
  | 'USFlag'
  | 'SpainFlag';

interface IconProps {
  icon: SystemIcons;
  style?: CSSProperties;
  fill?: string;
  className?: string;
  onClick?(): void;
}

export default function Icon(props: IconProps) {
  const icon = props.icon.replace(/ /g, '');
  return Components[icon] ? createElement(Components[icon], props) : null;
}
