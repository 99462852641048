import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemButton,
  ListItemIcon,
  Popover,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import posthog from 'posthog-js';
import baseService from 'services/base.service';
import UserProfileWidget from '../UserProfileWidget';

interface NavItem {
  title: string;
  logEvent: string;
  linkTo: string;
  icon: JSX.Element;
  selected: boolean;
  callback: string;
}

interface PopoverMenuProps {
  anchorEl: HTMLButtonElement | null;
  handlePopoverClose: () => void;
  items: NavItem[];
  userName?: string;
  companyName?: string;
}

const PopoverMenu: React.FC<PopoverMenuProps> = ({
  anchorEl,
  handlePopoverClose,
  items,
  userName,
  companyName,
}) => {
  const muiTheme = useTheme();
  const navigate = useNavigate();

  const handleItemClick = (navItem: NavItem) => {
    if (navItem?.callback?.includes('SignOut')) {
      signOut();
      return;
    }
    if (navItem.linkTo) {
      handlePopoverClose();
      setTimeout(() => {
        navigate(navItem.linkTo);
      }, 250);
    }
  };

  const handleTermsClick = (
    event: React.MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>,
  ): void => {
    event.preventDefault();
    handlePopoverClose();
    setTimeout(() => {
      window.open(
        'https://help.trytoolbox.com/en/articles/8780588-terms-of-use',
        '_blank',
        'noopener,noreferrer',
      );
    }, 250);
  };

  const signOut = () => {
    posthog.capture('Sign out button click');
    baseService.clearAuthToken();
    posthog.reset();
    handlePopoverClose();
    window.location.href = '/web/sign-in';
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      sx={{
        '& .MuiPopover-paper': {
          width: 220,
          boxShadow: `0px 4px 8px 2px ${muiTheme.palette.grey[900]}0A`,
        },
      }}
    >
      <div>
        <UserProfileWidget
          sx={{
            padding: '8% 6% 16px',
            borderBottom: `0.91px ${muiTheme.palette.grey[300]} solid`,
            margin: 0,
          }}
          userNameStyle={{ color: muiTheme.palette.text.primary, fontSize: 16 }}
          companyNameStyle={{ color: muiTheme.palette.text.secondary, fontSize: 14 }}
          iconStyle={{ color: muiTheme.palette.common.white }}
          avatarStyle={{
            width: 34,
            height: 34,
            border: `0.91px ${muiTheme.palette.grey[300]} solid`,
          }}
          avatarTextStyle={{ fontSize: 22.4 }}
          showMoreIcon={false}
          userName={userName}
          companyName={companyName}
        />

        <div>
          <List sx={{ padding: '14px 0' }}>
            {items.map(navItem => (
              <ListItem
                data-cy={`userProfile-${navItem.title}`}
                key={navItem.title}
                disablePadding
                sx={{ display: 'block', padding: '0' }}
                onClick={() => handleItemClick(navItem)}
              >
                <span
                  className={`text-black ${
                    navItem.selected ? 'font-noto-sans-bold' : 'font-noto-sans'
                  }`}
                >
                  <ListItemButton
                    selected={navItem.selected}
                    sx={{
                      minHeight: 48,
                      justifyContent: 'initial',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      className="text-black"
                      sx={{
                        minWidth: 0,
                        mr: 1.5,
                        justifyContent: 'center',
                      }}
                    >
                      {navItem.icon}
                    </ListItemIcon>
                    <ListItemText primary={navItem.title} />
                  </ListItemButton>
                </span>
              </ListItem>
            ))}
          </List>
          <a
            href="https://help.trytoolbox.com/en/collections/7755839-legal"
            onClick={handleTermsClick}
            style={{ textDecoration: 'none' }}
          >
            <Typography
              variant="body2"
              style={{
                padding: '10px 22px 24px 22px',
                color: muiTheme.palette.grey[500],
                fontSize: 14,
              }}
            >
              Terms & Policies
            </Typography>
          </a>
        </div>
      </div>
    </Popover>
  );
};

export default PopoverMenu;
