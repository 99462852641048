import DesktopNavigation from 'app/components/DesktopNavigation';
import Show from 'app/components/Show';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { ScreenProps } from 'interfaces/baseInterfaces';
import { CompanyRole } from 'interfaces/company';
import { useState } from 'react';
import MobileNavigation from '../MobileNavigation';
import { navigationTabs } from '../DesktopNavigation/utils/navigationTabs';

interface AppDrawerWrapperProps extends ScreenProps {
  children: JSX.Element;
}

export interface NavigationPermission {
  isAdministrative: boolean;
  isInternal: boolean;
  isEmployee: boolean;
  isBookkeeper: boolean;
}

const AppDrawerWrapper = (props: AppDrawerWrapperProps) => {
  const { currentUser, children } = props;

  const { isMobileScreen } = useWindowDimensions();
  const [isDesktopDrawerOpen, setIsDesktopDrawerOpen] = useState(true);

  const primaryNavWidth = isMobileScreen ? 0 : isDesktopDrawerOpen ? 240 : 65;
  const contentWidth = `calc(100% - ${primaryNavWidth}px)`;

  const userRoles: NavigationPermission = {
    isAdministrative: !!['admin', 'super_admin', 'account_owner'].find(
      role => role == currentUser.team_role,
    ),
    isInternal: currentUser.team_role !== CompanyRole.BOOKKEEPER,
    isEmployee: !!['employee'].find(role => role == currentUser.team_role),
    isBookkeeper: currentUser.team_role === CompanyRole.BOOKKEEPER,
  };

  const allowedTabs = navigationTabs.filter(tab => {
    return tab.permissions?.some(permission => userRoles[permission]) ?? true;
  });

  return (
    <div style={{ display: 'flex', flexDirection: `${isMobileScreen ? 'column' : 'row'}` }}>
      <Show when={isMobileScreen}>
        <MobileNavigation user={currentUser} tabs={allowedTabs} />
      </Show>

      <Show when={!isMobileScreen}>
        <DesktopNavigation
          isDrawerOpen={isDesktopDrawerOpen}
          setIsDrawerOpen={setIsDesktopDrawerOpen}
          user={currentUser}
          tabs={allowedTabs}
        />
      </Show>

      <div style={{ flex: 1, width: contentWidth }}>{children}</div>
    </div>
  );
};

export default AppDrawerWrapper;
