import { RutterAccount, RutterPlatform } from '../../rutter/rutter.types';
import { Divider, MenuItem, TextField } from '@mui/material';
import { useGetMappedAccounts } from '../../rutter/data/getMappedAccounts';
import {
  ArrowRightIcon,
  GenericFontAwesomeIcon,
} from '../../../../app/components/Icon/fontawesome';
import { faArrowDown, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import RutterAccountSelector from '../RutterAccountSelector';
import Show from '../../../../app/components/Show';
import { useUserRole } from '../../../auth/data/userRole';
import { useEffect, useState } from 'react';
import { useCreateDepositAccountMapping } from '../../rutter/data/createDepositAccountMapping';
import { useCreateExpenseAccountMapping } from '../../rutter/data/createExpenseAccountMapping';
import { useCreatePaymentAccountMapping } from '../../rutter/data/createPaymentAccountMapping';
import LoadingButton from '../../../../app/components/LoadingButton';
import SnackbarNotification from '../../../../app/components/SnackbarNotification';
import IntegrationAccountMapperLoader from './IntegrationAccountMapperLoader';
import { useGetRutterConnectionStatus } from '../../rutter/data/getRutterConnectionStatus';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import CreateRutterAccountModal from '../CreateRutterAccountModal';

interface IntegrationAccountMapperProps {
  platformTitle: string;
  platform: RutterPlatform;
  className?: string;
}

const IntegrationAccountMapper = (props: IntegrationAccountMapperProps) => {
  const { className, platformTitle, platform } = props;
  const { isDepositCustomer, isCreditCustomer } = useUserRole();
  const {
    data: initialMappedAccounts,
    isSuccess: isFetchSuccess,
    refetch,
    isLoading,
  } = useGetMappedAccounts(platform);

  const isFlagEnabled = useFeatureFlagEnabled('Rutter');

  const { refetch: refetchConnectionStatus } = useGetRutterConnectionStatus(
    platform,
    Boolean(isFlagEnabled),
  );

  const [mappedAccounts, setMappedAccounts] = useState(initialMappedAccounts);
  const { depositRutterAccountId, creditExpenseRutterAccountId, creditPaymentRutterAccountId } =
    mappedAccounts ?? {};

  const [isDepositAccountValueError, setIsDepositAccountValueError] = useState(false);
  const [isExpenseAccountValueError, setIsExpenseAccountValueError] = useState(false);
  const [isPaymentAccountValueError, setIsPaymentAccountValueError] = useState(false);
  const [okToSave, setOkToSave] = useState(false);

  const [isCreateAccountModalOpe, setIsCreateAccountModalOpen] = useState(false);

  const {
    mutateAsync: createDepositAccountMapping,
    isError: isDepositAccountMappingError,
    isPending: isCreatingDepositAccountMapping,
    isSuccess: isDepositAccountMappingSuccess,
  } = useCreateDepositAccountMapping();

  const {
    mutateAsync: crateExpenseAccountMapping,
    isError: isExpenseAccountMappingError,
    isPending: isCreatingExpenseAccountMapping,
    isSuccess: isExpenseAccountMappingSuccess,
  } = useCreateExpenseAccountMapping();

  const {
    mutateAsync: cratePaymentAccountMapping,
    isError: isPaymentAccountMappingError,
    isPending: isCreatingPaymentAccountMapping,
    isSuccess: isPaymentAccountMappingSuccess,
  } = useCreatePaymentAccountMapping();

  const isSaving =
    isCreatingDepositAccountMapping ||
    isCreatingExpenseAccountMapping ||
    isCreatingPaymentAccountMapping;
  const isError =
    isDepositAccountMappingError || isExpenseAccountMappingError || isPaymentAccountMappingError;
  const isSaveSuccess =
    isDepositAccountMappingSuccess ||
    isPaymentAccountMappingSuccess ||
    isExpenseAccountMappingSuccess;

  const validateMappedAccounts = () => {
    let isOkToSave = true;
    const { depositRutterAccountId, ...nonDepositAccountIds } = mappedAccounts ?? {};
    const { creditExpenseRutterAccountId, ...nonExpenseAccountIds } = mappedAccounts ?? {};
    const { creditPaymentRutterAccountId, ...nonPaymentAccountIds } = mappedAccounts ?? {};

    if (
      (isDepositCustomer && !depositRutterAccountId) ||
      Object.values(nonDepositAccountIds).includes(depositRutterAccountId!)
    ) {
      setIsDepositAccountValueError(true);
      isOkToSave = false;
    }
    if (
      (isCreditCustomer && !creditExpenseRutterAccountId) ||
      Object.values(nonExpenseAccountIds).includes(creditExpenseRutterAccountId!)
    ) {
      setIsExpenseAccountValueError(true);
      isOkToSave = false;
    }
    if (
      (isCreditCustomer && !creditPaymentRutterAccountId) ||
      Object.values(nonPaymentAccountIds).includes(creditPaymentRutterAccountId!)
    ) {
      setIsPaymentAccountValueError(true);
      isOkToSave = false;
    }

    // If no changes have been made, we don't need to save
    if (
      depositRutterAccountId &&
      depositRutterAccountId === initialMappedAccounts?.depositRutterAccountId &&
      creditExpenseRutterAccountId &&
      creditExpenseRutterAccountId === initialMappedAccounts?.creditExpenseRutterAccountId &&
      creditPaymentRutterAccountId &&
      creditPaymentRutterAccountId === initialMappedAccounts?.creditPaymentRutterAccountId
    ) {
      isOkToSave = false;
    }

    return isOkToSave;
  };

  const saveChanges = async () => {
    /*
     * We only want to save changes if the user has made any changes to the account mappings.
     **/
    try {
      if (
        depositRutterAccountId &&
        depositRutterAccountId !== initialMappedAccounts?.depositRutterAccountId
      ) {
        await createDepositAccountMapping({
          platform: platform,
          rutterAccountId: depositRutterAccountId,
        });
      }
      if (
        creditPaymentRutterAccountId &&
        creditPaymentRutterAccountId !== initialMappedAccounts?.creditPaymentRutterAccountId
      ) {
        await cratePaymentAccountMapping({
          platform: platform,
          rutterAccountId: creditPaymentRutterAccountId,
        });
      }
      if (
        creditExpenseRutterAccountId &&
        creditExpenseRutterAccountId !== initialMappedAccounts?.creditExpenseRutterAccountId
      ) {
        await crateExpenseAccountMapping({
          platform: platform,
          rutterAccountId: creditExpenseRutterAccountId,
        });
      }
      await refetch();
      await refetchConnectionStatus();
    } catch (error) {
      console.error('Error saving changes', error);
    }
  };

  const clearErrors = () => {
    setIsDepositAccountValueError(false);
    setIsExpenseAccountValueError(false);
    setIsPaymentAccountValueError(false);
  };

  useEffect(() => {
    clearErrors();
    const isValid = validateMappedAccounts();
    setOkToSave(isValid);
  }, [mappedAccounts]);

  useEffect(() => {
    setMappedAccounts(initialMappedAccounts);
  }, [isFetchSuccess]);

  const onDepositRutterAccountChange = (account: RutterAccount) => {
    clearErrors();
    setMappedAccounts({ ...mappedAccounts, depositRutterAccountId: account.id });
  };

  const onExpenseRutterAccountChange = (account: RutterAccount) => {
    clearErrors();
    setMappedAccounts({ ...mappedAccounts, creditExpenseRutterAccountId: account.id });
  };

  const onPaymentRutterAccountChange = (account: RutterAccount) => {
    clearErrors();
    setMappedAccounts({ ...mappedAccounts, creditPaymentRutterAccountId: account.id });
  };

  return isLoading ? (
    <IntegrationAccountMapperLoader />
  ) : (
    <div className={className}>
      <Show when={isCreditCustomer}>
        <div>
          <div className="hidden md:flex items-center justify-between gap-x-[15%] mb-1 text-xs font-bold uppercase">
            <span className="grow">Toolbox Credit Account</span>
            <span className="grow">{platformTitle} Account</span>
          </div>

          <div className="text-center bg-gray-100 py-[25px] px-[15px] md:bg-transparent md:p-0 md:flex items-center justify-between rounded-md">
            <TextField
              select
              value={'credit'}
              disabled
              label={'Account Type'}
              className="w-full md:w-[45%]"
            >
              <MenuItem value="credit">Charge Card Expenses</MenuItem>
            </TextField>
            <GenericFontAwesomeIcon icon={faArrowDown} className="my-[10px] md:hidden" />
            <GenericFontAwesomeIcon icon={faArrowRight} className="hidden md:inline-block" />
            <RutterAccountSelector
              label={`Select liability account`}
              platform={platform}
              selectedAccountId={`${creditExpenseRutterAccountId ?? ''}`}
              onChange={onExpenseRutterAccountChange}
              helperText={
                isExpenseAccountValueError
                  ? creditExpenseRutterAccountId
                    ? 'Account already chosen'
                    : 'Select a value'
                  : undefined
              }
              textFieldError={isExpenseAccountValueError}
              className="md:w-[45%]"
              allowedAccountCategories={['liability']}
            />
          </div>

          <div className="mt-3 text-center bg-gray-100 py-[25px] px-[15px] md:bg-transparent md:p-0 md:flex items-center justify-between rounded-md">
            <TextField
              select
              value={'credit'}
              disabled
              label={'Account Type'}
              className="w-full md:w-[45%]"
            >
              <MenuItem value="credit">Charge Card Payments</MenuItem>
            </TextField>
            <GenericFontAwesomeIcon icon={faArrowDown} className="my-[10px] md:hidden" />
            <GenericFontAwesomeIcon icon={faArrowRight} className="hidden md:inline-block" />
            <RutterAccountSelector
              label={`Select cash account`}
              platform={platform}
              selectedAccountId={`${creditPaymentRutterAccountId ?? ''}`}
              onChange={onPaymentRutterAccountChange}
              helperText={
                isPaymentAccountValueError
                  ? creditPaymentRutterAccountId
                    ? 'Account already chosen'
                    : 'Select a value'
                  : undefined
              }
              textFieldError={isPaymentAccountValueError}
              className="md:w-[45%]"
              allowedAccountCategories={['asset']}
            />
          </div>
        </div>
      </Show>
      <Show when={isDepositCustomer}>
        <Divider sx={{ margin: '50px 0' }} />
        <div>
          <div className="hidden md:flex items-center justify-between gap-x-[15%] mb-1 text-xs font-bold uppercase">
            <span className="grow">Toolbox Deposit Account</span>
            <span className="grow">{platformTitle} Account</span>
          </div>

          <div className="text-center bg-gray-100 py-[25px] px-[15px] md:bg-transparent md:p-0 md:flex items-center justify-between rounded-md">
            <TextField
              select
              value={'credit'}
              disabled
              label={'Account Type'}
              className="w-full md:w-[45%]"
            >
              <MenuItem value="credit">Deposit Account</MenuItem>
            </TextField>
            <GenericFontAwesomeIcon icon={faArrowDown} className="my-[10px] md:hidden" />
            <GenericFontAwesomeIcon icon={faArrowRight} className="hidden md:inline-block" />
            <RutterAccountSelector
              label={`Select cash account`}
              platform={platform}
              selectedAccountId={`${depositRutterAccountId ?? ''}`}
              onChange={onDepositRutterAccountChange}
              helperText={
                isDepositAccountValueError
                  ? depositRutterAccountId
                    ? 'Account already chosen'
                    : 'Select a value'
                  : undefined
              }
              textFieldError={isDepositAccountValueError}
              className="md:w-[45%]"
              allowedAccountCategories={['asset']}
            />
          </div>
        </div>
      </Show>
      <Divider sx={{ margin: '50px 0' }} />
      <div className="flex items-center justify-between gap-x-4">
        <div>
          <p>Couldn't find the {platformTitle} account you wanted?</p>
          <p className="mt-[3px]">
            <span
              className="underline cursor-pointer text-dark-green"
              onClick={() => setIsCreateAccountModalOpen(true)}
            >
              Create one now <GenericFontAwesomeIcon className="-rotate-45" icon={faArrowRight} />
            </span>
          </p>
        </div>
        <LoadingButton
          onClick={() => void saveChanges()}
          color="primary"
          disabled={!okToSave}
          loading={isSaving}
        >
          <span className="lg:px-1 font-normal">Save Changes</span>
        </LoadingButton>
      </div>
      <CreateRutterAccountModal
        isOpen={isCreateAccountModalOpe}
        onClose={() => setIsCreateAccountModalOpen(false)}
        platform={platform}
        title={`Create a new ${platformTitle} account`}
        allowedAccountTypes={[
          // we only allow asset and liability accounts to be created
          'accounts_payable',
          'accounts_receivable',
          'bank',
          'credit_card',
          'fixed_asset',
          'long_term_liability',
          'other_asset',
          'other_current_asset',
          'other_current_liability',
        ]}
      />
      <SnackbarNotification
        type={'success'}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSaveSuccess}
        message="Account linkage successful"
      />
      <SnackbarNotification
        type={'error'}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isError}
        message="Failed to create account linkage"
      />
    </div>
  );
};

export default IntegrationAccountMapper;
