import { Typography } from '@mui/material';
import { Tag as Chip } from 'antd';
import Show from '../../../../app/components/Show';
import { Transaction } from '../../types';
import dayjs from 'dayjs';
import { countableNoun, formatCentsAsDollars } from 'helpers/formattings';
import CategoryIcon from 'features/categories/components/CategoryIcon';
import { TransactionDetails } from 'features/credit/services/credit.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faBuildingColumns } from '@fortawesome/free-solid-svg-icons';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TransactionTagCell from '../TransactionTagCell';

interface TransactionRichDataCellProps {
  transaction: Transaction | TransactionDetails;
  onClick?: () => void;
}

const containerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
};

const subContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

const childContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '24px 16px',
};

const tagesWrapperStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const avatarStyle: React.CSSProperties = {
  width: 32,
  height: 32,
  objectFit: 'contain',
};

const defaultIconWrapperStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  backgroundColor: '#e1dfde',
  width: 32,
  height: 32,
  flexShrink: 0,
};

const defaultIconStyle: React.CSSProperties = {
  width: 16,
  height: 16,
};

const textContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '16px',
};

const lgTextStyle: React.CSSProperties = {
  fontSize: '14px',
  fontWeight: '700',
  lineHeight: '19.6px',
};

const mdTextStyle: React.CSSProperties = {
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '26px',
};

const smTextStyle: React.CSSProperties = {
  fontSize: '10px',
  fontWeight: '400',
  lineHeight: '14px',
};

const amtContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  flex: 1,
};

const TransactionRichDataCell = (props: TransactionRichDataCellProps) => {
  const { transaction, onClick } = props;

  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const hasLogo = transaction?.merchant_logo ? true : false;
  const hasAddress = transaction?.merchant_address ? true : false;

  return (
    <div style={containerStyle} onClick={onClick}>
      <div
        style={{
          ...subContainerStyle,
          padding: isMobile ? '24px 16px 24px 16px' : '',
          backgroundColor: `${isMobile ? '#F7F7F7' : 'transparent'}`,
        }}
      >
        <Show when={hasLogo}>
          <img src={transaction.merchant_logo ?? ''} alt="merchant logo" style={avatarStyle} />
        </Show>
        <Show when={!hasLogo}>
          <div style={defaultIconWrapperStyle}>
            <FontAwesomeIcon
              icon={transaction.account_type === 'Deposit' ? faBuildingColumns : faCartShopping}
              style={defaultIconStyle}
            />
          </div>
        </Show>

        <div style={textContainerStyle}>
          <Typography variant="body1" style={lgTextStyle}>
            {transaction.merchant ?? transaction.summary}
          </Typography>
          <Show when={hasAddress}>
            <Typography variant="body1" style={smTextStyle}>
              {isMobile
                ? dayjs(transaction.created_at).format('MMM DD, YYYY')
                : transaction.merchant_address}
            </Typography>
          </Show>
        </div>

        {isMobile ? (
          <div style={amtContainerStyle}>
            <Typography variant="body1" style={smTextStyle}>
              {transaction.account_type}
            </Typography>
            <Typography variant="body1" style={mdTextStyle}>
              {formatCentsAsDollars(transaction.amount)}
            </Typography>
          </div>
        ) : null}
      </div>
      <div style={tagesWrapperStyle}>
        {isMobile ? (
          <div style={childContainerStyle}>
            <div style={subContainerStyle}>
              {transaction.category && <CategoryIcon category={transaction.category} />}
            </div>
            <div className="ml-1">{transaction.category}</div>
          </div>
        ) : null}
        {isMobile && transaction?.tags ? (
          <div>
            <TransactionTagCell
              index={0}
              entityTags={transaction.tags ?? []}
              transaction={transaction as Transaction}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TransactionRichDataCell;
