import { DialogContent } from '@mui/material';
import Show from 'app/components/Show';
import LoadingButton from 'app/components/LoadingButton';
import { DepositAccount } from 'interfaces/account';
import CustomDialog from 'app/components/CustomDialog';
import { useGetCompanyAddress } from '../../../companies/data/getCompanyAddress';

interface AccountInfoDialogProps {
  isOpen: boolean;
  account: DepositAccount;
  onAccountInfoDialogClose: () => void;
}

const AccountInfoDialog = (props: AccountInfoDialogProps) => {
  const { isOpen, account, onAccountInfoDialogClose } = props;

  const { isLoading: isLoadingAddress, isError, error, data } = useGetCompanyAddress(isOpen);
  const companyAddress = data?.address;

  return (
    <CustomDialog title="Account Info" open={isOpen} onClose={onAccountInfoDialogClose}>
      <DialogContent>
        <section>
          <section className="flex justify-start">
            <div>
              <p className="section-title">BENEFICIARY</p>

              <p className="detail-label">Beneficiary Name</p>
              <p data-cy="beneficiaryName" className="detail-value">
                {account?.name}
              </p>

              <p className="detail-label">Account Number</p>
              <p className="detail-value">
                <span data-cy="accountNumber" className="sensitive">
                  {account?.account_number}
                </span>
              </p>

              <p className="detail-label">Routing Number</p>
              <p className="detail-value">
                <span data-cy="routingNumber" className="sensitive">
                  {account?.routing_number}
                </span>
              </p>

              <p className="detail-label">Account Type</p>
              <p data-cy="accountType" className="detail-value capitalize">
                {account?.deposit_product}
              </p>

              <p className="detail-label">Beneficiary Address</p>
              <Show when={!!companyAddress}>
                <p data-cy="companyAddress" className="detail-value whitespace-pre-wrap">
                  {companyAddress}
                </p>
              </Show>
              <Show when={isLoadingAddress}>
                <p className="detail-value">Loading address...</p>
              </Show>
              <Show when={isError}>
                <p className="detail-value italic">Could not fetch address</p>
              </Show>
            </div>
          </section>
        </section>
        <section className="flex items-center justify-center mt-2">
          <LoadingButton dataCy="dismiss" onClick={onAccountInfoDialogClose}>
            Dismiss
          </LoadingButton>
        </section>
      </DialogContent>
    </CustomDialog>
  );
};

export default AccountInfoDialog;
