import {
  DepositPageAction,
  DepositPageActionType,
} from '../../../../app/pages/dashboard/deposit/reducer';
import React, { useEffect } from 'react';
import { DepositAccount } from '../../../../interfaces/account';
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { SyncAlt } from '@mui/icons-material';
import LoadingButton from '../../../../app/components/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import { DollarSignIcon, LandMarkIcon } from '../../../../app/components/Icon/fontawesome';
import { formatCentsAsDollars, formatDollarNumber } from '../../../../helpers/formattings';
import plaidService from '../../../../services/plaid.service';
import { PlaidAuth } from '../../../../interfaces/baseInterfaces';
import { render } from 'react-dom';

interface AchDebitFundingDialogProps {
  updateState: React.Dispatch<DepositPageAction>;
  isOpen: boolean;
  account: DepositAccount;
  externalAccounts: PlaidAuth[];
  amount: string;
  depositAccountLimits: any;
}

const AchDebitFundingDialog = (props: AchDebitFundingDialogProps) => {
  const { updateState, isOpen, account, externalAccounts, amount, depositAccountLimits } = props;
  const [selectedExternalAccountId, setSelectedExternalAccountId] = React.useState<string>('');

  const handleGoBack = () => {
    updateState({ type: DepositPageActionType.AchDebitFundingPopupOpen, payload: false });
    updateState({ type: DepositPageActionType.AddFundsPopupOpen, payload: true });
  };

  const handleNext = () => {
    updateState({ type: DepositPageActionType.AchDebitFundingPopupOpen, payload: false });
    updateState({
      type: DepositPageActionType.AchDebitConfirmationFundingPopupOpen,
      payload: true,
    });
  };

  const handleAchDebitExternalAccountChange = (e: SelectChangeEvent) => {
    setSelectedExternalAccountId(e.target.value);
    if (e.target.value) {
      updateState({
        type: DepositPageActionType.SelectedExternalAccount,
        payload: externalAccounts.find(ea => ea.id === Number(e.target.value)),
      });
    }
  };

  const isValid = () => {
    const parsedAmount = parseFloat(amount);
    return amount.length > 0 && parsedAmount <= 25000;
  };

  const renderExternalAccount = (id: string) => {
    const plaidAuth = externalAccounts.find(ea => ea.id === Number(id));
    if (!plaidAuth) {
      return '';
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <LandMarkIcon className="rounded-circle bg-gray-10 px-quarter py-quarter" />
        <div className="pl-1">
          <p className="text-capitalize font-bold">
            {plaidAuth.name} **{plaidAuth.last_four}
          </p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const loadAccounts = async () => {
      const resp = await plaidService.getPlaidAuth('underwriting');
      updateState({ type: DepositPageActionType.ExternalAccounts, payload: resp });
      updateState({ type: DepositPageActionType.SelectedExternalAccount, payload: resp[0] });
      setSelectedExternalAccountId(resp[0]?.id);
    };
    loadAccounts();
  }, []);
  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <p className="text-bold font-lg">ACH Debit / Direct Bank Transfer</p>
        <p className="text-normal">Transfers between accounts in 1-2 business days</p>
      </DialogTitle>
      <IconButton
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <LandMarkIcon fontSize="medium" className="rounded-circle bg-gray-10 p-1" />
      </IconButton>

      <DialogContent>
        <FormControl variant="standard" fullWidth={true}>
          <InputLabel>SET AN AMOUNT</InputLabel>
          <Input
            type="number"
            inputProps={{ style: { fontSize: 50 }, min: 0.01, step: '0.01' }}
            value={amount}
            onChange={e =>
              updateState({
                type: DepositPageActionType.NewACHDepositAmount,
                payload: e.target.value,
              })
            }
            startAdornment={
              <InputAdornment position="start">
                <DollarSignIcon fontSize="small" color="primary" />
              </InputAdornment>
            }
          />
        </FormControl>
        <Alert icon={false} severity="warning" className="mt-half">
          <span className="font-bold">Note:</span> ACH Debits have transfer limits of{' '}
          {formatDollarNumber(depositAccountLimits?.ach?.limits?.daily_debit ?? 0)}/day and{' '}
          {formatDollarNumber(depositAccountLimits?.ach?.limits?.monthly_debit ?? 0)}/month
        </Alert>
        <p className="mt-1 mb-1 font-xs">TRANSFER FROM</p>
        <Select
          fullWidth={true}
          value={selectedExternalAccountId}
          onChange={handleAchDebitExternalAccountChange}
          renderValue={renderExternalAccount}
        >
          {externalAccounts.map(ea => (
            <MenuItem value={ea.id} key={ea.id}>
              <LandMarkIcon className="rounded-circle bg-gray-10 px-quarter py-quarter" />
              <ListItemText primary={ea.name} sx={{ paddingLeft: '.25rem' }} />
            </MenuItem>
          ))}
        </Select>
        <p className="mt-1 mb-1 font-xs">TRANSFER TO</p>
        <Select
          disabled={true}
          fullWidth={true}
          value={account.id}
          renderValue={selected => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LandMarkIcon className="rounded-circle bg-gray-10 px-quarter py-quarter" />
              <div className="pl-1">
                <p className="text-capitalize font-bold">
                  {account.deposit_product} **{account.account_number.slice(-4)}
                </p>
                <p className="font-xs">{formatCentsAsDollars(account.balance)}</p>
              </div>
            </div>
          )}
        >
          <MenuItem value={account.id}>
            <LandMarkIcon className="rounded-circle bg-gray-10 px-quarter py-quarter" />
            <ListItemText primary="1" sx={{ paddingLeft: '.25rem' }} />
          </MenuItem>
        </Select>
        <section className="flex items-center justify-between mt-2">
          <LoadingButton variant="outlined" onClick={handleGoBack}>
            Go Back
          </LoadingButton>
          <LoadingButton variant="contained" onClick={handleNext} disabled={!isValid()}>
            Next
          </LoadingButton>
        </section>
      </DialogContent>
    </Dialog>
  );
};

export default AchDebitFundingDialog;
