import { usePostIntegrationInterest } from '../../../../companies/data/postIntegrationInterest';
import posthog from 'posthog-js';
import IntegrationConnectionCard from '../../../components/IntegrationConnectionCard';
import qb_logo from 'app/assets/images/qb.png';

interface QuickbooksDesktopConnectionCardProps {}

const QuickbooksDesktopConnectionCard = (props: QuickbooksDesktopConnectionCardProps) => {
  const { mutate: postInterest, isPending, isSuccess } = usePostIntegrationInterest();

  const handleConnection = () => {
    postInterest('quickbooks-desktop');
    posthog.capture('Clicked Quickbooks Desktop Button');
  };

  return (
    <IntegrationConnectionCard
      title="Quickbooks Desktop"
      subtitle="Coming Soon"
      onConnect={handleConnection}
      connectLabel="Indicate Interest"
      logo={qb_logo}
      disabled={isSuccess || isPending}
    />
  );
};

export default QuickbooksDesktopConnectionCard;
