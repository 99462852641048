import React from 'react';
import { Box, TextField, MenuItem, Button } from '@mui/material';
import { titleCase } from 'helpers/base';

interface InviteStatus {
  sent: boolean;
  email: string;
}

interface AddTeamMemberFormProps {
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  inviteEmail: string;
  setInviteEmail: React.Dispatch<React.SetStateAction<string>>;
  inviteRole: string;
  setInviteRole: React.Dispatch<React.SetStateAction<string>>;
  roleOptions: string[];
  validateEmail: (email: string) => boolean;
  isSendingInvite: boolean;
  addMemberError: string;
  setIsSendingInvite: React.Dispatch<React.SetStateAction<boolean>>;
  setAddMemberError: React.Dispatch<React.SetStateAction<string>>;
  setInviteStatus: React.Dispatch<React.SetStateAction<InviteStatus>>;
  setShowAddMember: React.Dispatch<React.SetStateAction<boolean>>;
  sendInvite: () => Promise<void>;
}

const AddTeamMemberForm: React.FC<AddTeamMemberFormProps> = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  inviteEmail,
  setInviteEmail,
  inviteRole,
  setInviteRole,
  roleOptions,
  validateEmail,
  isSendingInvite,
  setIsSendingInvite,
  setAddMemberError,
  addMemberError: errorMessage,
  setInviteStatus,
  setShowAddMember,
  sendInvite,
}) => {
  return (
    <Box
      className="w-full mb-2"
      position="relative"
      sx={{
        backgroundColor: '#F7F7F7',
        padding: '16px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', mb: 2 }}>
        {' '}
        <TextField
          size="small"
          sx={{ flex: 6.5 }}
          className="text-black bg-white"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          placeholder="First Name"
          fullWidth
          label="First Name"
          variant="outlined"
        />
        <TextField
          size="small"
          sx={{ flex: 6.5 }}
          className="text-black bg-white"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
          placeholder="Last Name"
          fullWidth
          label="Last Name"
          variant="outlined"
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        {' '}
        <TextField
          size="small"
          sx={{ flex: 6.5 }}
          className="text-black bg-white"
          value={inviteEmail}
          onChange={e => setInviteEmail(e.target.value)}
          placeholder="Email"
          fullWidth
          label="Email"
          variant="outlined"
        />
        <TextField
          select
          size="small"
          sx={{ flex: 3.5, backgroundColor: 'white' }}
          label="Role"
          variant="outlined"
          value={inviteRole}
          onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
            setInviteRole(e.target.value as string);
          }}
          fullWidth
        >
          {roleOptions.map((option, i) => (
            <MenuItem key={i} value={option}>
              {titleCase(option.split('_').join(' '))}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      {errorMessage && (
        <Box color="red" sx={{ my: 2, fontSize: 14 }}>
          {errorMessage}
        </Box>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: '16px' }}>
        <Button
          sx={{
            textDecoration: 'underline',
            background: 'none',
            color: '#09442F',
            '&:hover': {
              color: '#fff',
            },
          }}
          onClick={() => {
            setShowAddMember(false);
          }}
        >
          Cancel
        </Button>

        <Button
          variant="outlined"
          color="primary"
          disabled={!inviteEmail || !validateEmail(inviteEmail) || isSendingInvite}
          onClick={sendInvite}
          sx={{ whiteSpace: 'nowrap' }}
        >
          Add Member
        </Button>
      </Box>
    </Box>
  );
};

export default AddTeamMemberForm;
