import { countableNoun } from '../../../../helpers/formattings';
import TagChip from '../TagChip';
import { Divider } from '@mui/material';
import LoadingButton from '../../../../app/components/LoadingButton';
import { SellOutlined } from '@mui/icons-material';
import { getGroupedEntityTags } from '../../tags.helpers';
import { EntityTag } from '../../types';
import Show from '../../../../app/components/Show';
import { useUserRole } from '../../../auth/data/userRole';

interface EntityTagsWidgetProps {
  entityTags: EntityTag[];
  onAddTagButtonClick: () => void;
  onUpdate: () => void;
}

const EntityTagsWidget = (props: EntityTagsWidgetProps) => {
  const { entityTags, onAddTagButtonClick, onUpdate } = props;

  const groupedEntityTags = getGroupedEntityTags(entityTags);

  const { isAdmin } = useUserRole();

  return (
    <section className="rounded min-w-[300px] max-w-sm">
      <div className="px-1 py-1 ">
        <p className="font-bold">
          {entityTags.length} {countableNoun('tag', entityTags.length)} added
        </p>
        {Object.keys(groupedEntityTags).map(groupName => (
          <div key={groupName} className="mt-1">
            <p className="uppercase text-[10px] font-bold">{groupName}</p>
            <div className="mt-[5px]">
              {groupedEntityTags[groupName].map(entityTag => (
                <TagChip
                  key={entityTag.tag.id}
                  entityTag={entityTag}
                  onRemovedFromEntity={onUpdate}
                  className="mb-[8px]"
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      <Divider />
      <div className="px-1 flex items-center justify-end mt-1 pb-1">
        <Show when={isAdmin}>
          <LoadingButton
            dataCy="add-tag"
            variant="outlined"
            className="flex items-center"
            onClick={onAddTagButtonClick}
          >
            <SellOutlined fontSize="small" className="mr-[5px]" />
            <span className="inline-block text-[13px] font-normal border">Add Tags</span>
          </LoadingButton>
        </Show>
      </div>
    </section>
  );
};

export default EntityTagsWidget;
