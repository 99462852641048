import { ADMIN_JOB_TITLES } from 'constants/system';
import { CompanyInvite, CompanyTeamMember } from 'interfaces/baseInterfaces';

type TeamPageState = {
  loading: boolean;
  inviteEmail: string;
  inviteRole: string;
  inviteTitle: string;
  invites: CompanyInvite[];
  invitesCount: number;
  inviteTeamMemberPopupOpen: boolean;
  currentTab: number;
  teamMembers: CompanyTeamMember[];
  addMemberError: string;
  isMobileScreen: boolean;
  isSendingInvite: boolean;
  isResendingInvite: boolean;
  isRemovingUser: boolean;
  isRestoringUser: boolean;
  isRemoveConfirmationOpen: boolean;
  isRestoreConfirmationOpen: boolean;
  isResentConfirmationOpen: boolean;
  userToRemove: CompanyTeamMember | null;
  userToRestore: CompanyTeamMember | null;
  roleOptions: string[];
  titleOptions: string[];
  showImmediateActionRequiredModal: boolean;
};

export type TeamPageAction = {
  readonly type: string;
  readonly payload: any;
};

export enum TeamPageActionType {
  Loading = 'loading',
  InviteEmail = 'inviteEmail',
  InviteRole = 'inviteRole',
  InviteTitle = 'inviteTitle',
  Invites = 'invites',
  InvitesCount = 'invitesCount',
  InviteTeamMemberPopupOpen = 'inviteTeamMemberPopupOpen',
  CurrentTab = 'currentTab',
  TeamMembers = 'teamMembers',
  AddMemberError = 'addMemberError',
  IsMobileScreen = 'isMobileScreen',
  IsSendingInvite = 'isSendingInvite',
  IsResendingInvite = 'isResendingInvite',
  IsRemovingUser = 'isRemovingUser',
  IsRestoringUser = 'isRestoringUser',
  IsRemoveConfirmationOpen = 'isRemoveConfirmationOpen',
  IsRestoreConfirmationOpen = 'isRestoreConfirmationOpen',
  IsResentConfirmationOpen = 'isResentConfirmationOpen',
  UserToRemove = 'userToRemove',
  UserToRestore = 'userToRestore',
  RoleOptions = 'roleOptions',
  TitleOptions = 'titleOptions',
  ShowImmediateActionRequiredModal = 'showImmediateActionRequiredModal',
}

export const teamPageInitialState: TeamPageState = {
  loading: false,
  inviteEmail: '',
  inviteRole: '',
  inviteTitle: '',
  invites: [],
  invitesCount: 0,
  inviteTeamMemberPopupOpen: false,
  currentTab: 0,
  teamMembers: [],
  addMemberError: '',
  isMobileScreen: false,
  isSendingInvite: false,
  isResendingInvite: false,
  isRemovingUser: false,
  isRestoringUser: false,
  isRemoveConfirmationOpen: false,
  isRestoreConfirmationOpen: false,
  isResentConfirmationOpen: false,
  userToRemove: null,
  userToRestore: null,
  roleOptions: ['admin', 'employee', 'bookkeeper'],
  titleOptions: ADMIN_JOB_TITLES,
  showImmediateActionRequiredModal: false,
};

export function teamPageReducer(state: TeamPageState, action: TeamPageAction) {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
}
