import { Skeleton } from '@mui/material';

interface BankingAccountingTilesLoaderProps {}

const BankingAccountingTilesLoader = (props: BankingAccountingTilesLoaderProps) => {
  return (
    <div className="flex xl:flex-wrap items-center justify-start gap-x-4 gap-y-4">
      <Skeleton
        variant="rectangular"
        height={80}
        width="100%"
        className="rounded-lg xl:py-[55px]"
      />
    </div>
  );
};

export default BankingAccountingTilesLoader;
