import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Spinner from 'app/components/Spinner';
import { removeUnderscore, standardError, titleCase } from 'helpers/base';
import { CompanyInvite, CompanyTeamMember, MyUser, ScreenProps } from 'interfaces/baseInterfaces';
import { memo, Suspense, useEffect, useReducer, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import posthog from 'posthog-js';
import companyService from 'services/companyService';
import { TeamPageActionType, teamPageInitialState, teamPageReducer } from './reducer';
import dayjs from 'dayjs';
import GenericPopUp from 'app/components/GenericPopUp';
import { Text } from '../../../../contexts/language';
import { TeamMemberInvite } from '../../../components/TeamMemberInvite';
import './style.scss';
import { CloseIcon } from 'app/components/Icon/fontawesome';
import { useUserRole } from '../../../../features/auth/data/userRole';

interface TeamPageProps extends ScreenProps {
  currentUser: MyUser;
}

const TeamPage = memo(function (props: TeamPageProps) {
  const [state, dispatch] = useReducer(teamPageReducer, teamPageInitialState);
  const { currentUser } = props;
  const { isAdmin } = useUserRole();

  const loadTeamMembers = async () => {
    if (!isAdmin) return;
    await companyService
      .getTeam({ include_removed: true })
      .then(res => {
        if (res?.error) throw new Error();
        if (res) {
          dispatch({ type: TeamPageActionType.TeamMembers, payload: res });
        }
      })
      .catch(err => {
        standardError(err);
      });
  };

  const loadInvites = async () => {
    await companyService
      .getPendingInvites()
      .then(res => {
        if (res?.error) throw new Error();
        if (res) {
          dispatch({ type: TeamPageActionType.Invites, payload: res.data });
          dispatch({ type: TeamPageActionType.InvitesCount, payload: res.count });
        }
      })
      .catch(err => {
        standardError(err);
      });
  };

  const handleResendInvite = async (invite: CompanyInvite) => {
    dispatch({ type: TeamPageActionType.IsResendingInvite, payload: true });

    posthog.capture('Resend invite click');

    await companyService
      .resendInvite(invite.id)
      .then(res => {
        if (res?.error) throw new Error();
        if (res) {
          dispatch({ type: TeamPageActionType.IsResentConfirmationOpen, payload: true });
        }
      })
      .catch(err => {
        standardError(err);
      });

    dispatch({ type: TeamPageActionType.IsResendingInvite, payload: false });
  };

  const handleRemoveUser = async (user: any) => {
    dispatch({ type: TeamPageActionType.IsRemovingUser, payload: true });

    posthog.capture('Remove User Click', {
      'User Email Removed': user.email,
    });

    await companyService
      .removeUser(user.id)
      .then(res => {
        if (res?.error) throw new Error();
        if (res) {
          loadTeamMembers();
        }
      })
      .catch(err => {
        standardError(err);
      });

    dispatch({ type: TeamPageActionType.IsRemovingUser, payload: false });
    dispatch({ type: TeamPageActionType.IsRemoveConfirmationOpen, payload: false });
  };

  const handleRestoreUser = async (user: any) => {
    dispatch({ type: TeamPageActionType.IsRestoringUser, payload: true });

    posthog.capture('Re-add User Click', {
      'User Email Re-added': user.email,
    });

    await companyService
      .restoreUser(user.id)
      .then(res => {
        if (res?.error) throw new Error();
        if (res) {
          loadTeamMembers();
        }
      })
      .catch(err => {
        standardError(err);
      });

    dispatch({ type: TeamPageActionType.IsRestoringUser, payload: false });
    dispatch({ type: TeamPageActionType.IsRestoreConfirmationOpen, payload: false });
  };

  const inviteTeamMemberForm = () => {
    return (
      <TeamMemberInvite
        currentUser={currentUser}
        onSave={() => {
          dispatch({ type: TeamPageActionType.InviteTeamMemberPopupOpen, payload: false });
          loadInvites();
        }}
      />
    );
  };

  const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  useEffect(() => {
    loadInvites();
    loadTeamMembers();
    posthog.capture('Team Screen');
  }, []);

  return state.loading ? (
    <Spinner />
  ) : (
    <div className="teams-page">
      <Helmet>
        <title>Toolbox - Team</title>
      </Helmet>
      <div className="w-auto sm-w-full">
        <div className="header">
          <p data-id="team-title" className="font-bold font-noto-sans">
            <Text tid="Team" />
          </p>
          <Button
            onClick={() => {
              posthog.capture('Invite Team Member click - teams page');
              dispatch({ type: TeamPageActionType.InviteTeamMemberPopupOpen, payload: true });
            }}
          >
            {' '}
            Invite Member
          </Button>
        </div>

        <div className="teams-box">
          <div>
            <div className="nowrap overflow-x-auto mb-half py-half">
              <Stack spacing={2} direction="row" className="sections">
                <Button
                  size="small"
                  className="rounded-3xl"
                  color={state.currentTab === 0 ? 'primary' : 'info'}
                  onClick={() => dispatch({ type: TeamPageActionType.CurrentTab, payload: 0 })}
                >
                  Active members
                </Button>
                <Button
                  size="small"
                  className="rounded-3xl"
                  color={state.currentTab === 1 ? 'primary' : 'info'}
                  onClick={() => dispatch({ type: TeamPageActionType.CurrentTab, payload: 1 })}
                >
                  Pending members
                </Button>
                <Button
                  size="small"
                  className="rounded-3xl"
                  color={state.currentTab === 2 ? 'primary' : 'info'}
                  onClick={() => dispatch({ type: TeamPageActionType.CurrentTab, payload: 2 })}
                >
                  Removed members
                </Button>
              </Stack>
            </div>
            <TabPanel value={state.currentTab} index={0}>
              <div>
                <span
                  data-id="team-current-team-label"
                  className="font-lg font-bold font-noto-sans py-half"
                >
                  <Text tid="ActiveMembers" /> (
                  {[...state.teamMembers].filter((m: CompanyTeamMember) => !m.removed).length})
                </span>
                <TableContainer className="mt-1">
                  <Table size="small">
                    <TableHead className="sm-no-display">
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[...state.teamMembers]
                        .filter((m: CompanyTeamMember) => !m.removed)
                        .map(m => (
                          <Fragment key={m.id}>
                            <TableRow className="sm-no-display">
                              <TableCell>{m.email}</TableCell>
                              <TableCell>{m.role}</TableCell>
                              <TableCell>{m.name}</TableCell>
                              <TableCell>
                                <Button
                                  color="error"
                                  size="small"
                                  disabled={
                                    m.role == 'account_owner' ||
                                    (currentUser.team_role == 'admin' && m.role == 'super_admin')
                                  }
                                  onClick={() => {
                                    dispatch({
                                      type: TeamPageActionType.IsRemoveConfirmationOpen,
                                      payload: true,
                                    });
                                    dispatch({ type: TeamPageActionType.UserToRemove, payload: m });
                                  }}
                                >
                                  Revoke
                                </Button>
                              </TableCell>
                            </TableRow>
                            <div className="none sm-flex flex-column mb-1">
                              <div className="flex-row justify-between">
                                <div>
                                  <p className="mb-half">
                                    <Chip label={m.role} color="default" size="small" />
                                  </p>
                                  <p className="mb-half text-bold">{m.email}</p>
                                  <p className="mb-half">{m.name}</p>
                                </div>
                                <div className="flex items-center">
                                  <Button
                                    color="error"
                                    size="small"
                                    disabled={m.role == 'account_owner'}
                                    onClick={() => {
                                      dispatch({
                                        type: TeamPageActionType.IsRemoveConfirmationOpen,
                                        payload: true,
                                      });
                                      dispatch({
                                        type: TeamPageActionType.UserToRemove,
                                        payload: m,
                                      });
                                    }}
                                  >
                                    Revoke
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </TabPanel>

            <TabPanel value={state.currentTab} index={1}>
              <div>
                <span
                  data-id="pending-invite-label"
                  className="font-lg font-bold font-noto-sans py-half"
                >
                  <Text tid="PendingInvites" /> ({state.invitesCount})
                </span>
                <TableContainer className="mt-1">
                  <Table size="small">
                    <TableHead className="sm-no-display">
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Expired On</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[...state.invites].map(m => (
                        <Fragment key={m.id}>
                          <TableRow className="sm-no-display">
                            <TableCell>{m.email}</TableCell>
                            <TableCell>{titleCase(removeUnderscore(m.role))}</TableCell>
                            <TableCell>
                              {m.invite_expiration
                                ? dayjs(m.invite_expiration).format('MM/DD/YYYY')
                                : ''}
                            </TableCell>
                            <TableCell>
                              <Button
                                color="info"
                                size="small"
                                data-id="team-invite-resend-invite"
                                className="resend-invite-btn sm-ml-0 ml-0"
                                disabled={state.isResendingInvite}
                                onClick={() => handleResendInvite(m)}
                              >
                                Resend
                              </Button>
                            </TableCell>
                          </TableRow>
                          <div className="none sm-flex flex-column mb-1">
                            <div className="flex-row justify-between">
                              <div>
                                {m.invite_expiration && (
                                  <p className="mb-half">
                                    {dayjs(m.invite_expiration).format('MM/DD/YYYY')}
                                  </p>
                                )}
                                <p className="mb-half text-bold">{m.email}</p>
                                <p className="mb-half">
                                  <Chip
                                    label={titleCase(removeUnderscore(m.role))}
                                    color="default"
                                    size="small"
                                  />
                                </p>
                              </div>
                              <div className="flex items-center">
                                <Button
                                  color="info"
                                  size="small"
                                  data-id="team-invite-resend-invite"
                                  className="resend-invite-btn sm-ml-0 ml-0"
                                  disabled={state.isResendingInvite}
                                  onClick={() => handleResendInvite(m)}
                                >
                                  Resend
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </TabPanel>

            <TabPanel value={state.currentTab} index={2}>
              <span
                data-id="team-current-team-label"
                className="font-lg font-bold font-noto-sans py-half"
              >
                <Text tid="RemovedTeamMembers" /> (
                {[...state.teamMembers].filter((m: CompanyTeamMember) => m.removed).length})
              </span>
              <TableContainer className="mt-1">
                <Table size="small">
                  <TableHead className="sm-no-display">
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[...state.teamMembers]
                      .filter((m: CompanyTeamMember) => m.removed)
                      .map(m => (
                        <Fragment key={m.id}>
                          <TableRow className="sm-no-display">
                            <TableCell>{m.email}</TableCell>
                            <TableCell>{m.role}</TableCell>
                            <TableCell>{m.name}</TableCell>
                            <TableCell>
                              <Button
                                color="secondary"
                                disabled={state.isRestoringUser}
                                onClick={() => {
                                  dispatch({
                                    type: TeamPageActionType.IsRestoreConfirmationOpen,
                                    payload: true,
                                  });
                                  dispatch({ type: TeamPageActionType.UserToRestore, payload: m });
                                }}
                              >
                                Re-Add
                              </Button>
                            </TableCell>
                          </TableRow>
                          <div className="none sm-flex flex-column mb-1">
                            <div className="flex-row justify-between">
                              <div>
                                <p className="mb-half">
                                  <Chip label={m.role} color="default" size="small" />
                                </p>
                                <p className="mb-half text-bold">{m.email}</p>
                                <p>{m.name}</p>
                              </div>
                              <div className="flex items-center">
                                <Button
                                  color="secondary"
                                  disabled={state.isRestoringUser}
                                  onClick={() => {
                                    dispatch({
                                      type: TeamPageActionType.IsRestoreConfirmationOpen,
                                      payload: true,
                                    });
                                    dispatch({
                                      type: TeamPageActionType.UserToRestore,
                                      payload: m,
                                    });
                                  }}
                                >
                                  Re-Add
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          </div>
        </div>

        <div
          className="mt-48"
          style={{
            width: '98%',
          }}
        >
          {inviteTeamMemberForm()}
        </div>

        <Suspense fallback={<Spinner />}>
          <GenericPopUp
            isOpen={state.isResentConfirmationOpen}
            onClose={() => {
              dispatch({ type: TeamPageActionType.IsResentConfirmationOpen, payload: false });
            }}
            title="Invite Resent"
            description="Your invite has been successfully resent"
            onClick={() =>
              dispatch({ type: TeamPageActionType.IsResentConfirmationOpen, payload: false })
            }
            className="w-16"
            btnText="OK"
            background="MainPrimary"
          />
          <GenericPopUp
            isOpen={state.isRemoveConfirmationOpen}
            onClose={() => {
              dispatch({ type: TeamPageActionType.IsRemoveConfirmationOpen, payload: false });
            }}
            title="Remove User"
            description={`Are you sure you want to remove ${titleCase(
              state.userToRemove?.name ?? '',
            )}? You will need to manually cancel their cards too.`}
            onClick={() => {
              handleRemoveUser(state.userToRemove);
            }}
            className="w-32"
            btnText="Confirm"
            btnLoading={state.isRemovingUser}
            background="MainPrimary"
          />
          <GenericPopUp
            isOpen={state.isRestoreConfirmationOpen}
            onClose={() => {
              dispatch({ type: TeamPageActionType.IsRestoreConfirmationOpen, payload: false });
            }}
            title="Re-add User"
            description={`Are you sure you want to re-add ${titleCase(
              state.userToRestore?.name ?? '',
            )} with the ${state.userToRestore?.role ?? ''} role?`}
            onClick={() => {
              handleRestoreUser(state.userToRestore);
            }}
            className="w-32"
            btnText="Confirm"
            btnLoading={state.isRestoringUser}
            background="MainPrimary"
          />

          <Dialog
            fullScreen={true}
            open={state.inviteTeamMemberPopupOpen}
            onClose={() => {
              dispatch({ type: TeamPageActionType.InviteTeamMemberPopupOpen, payload: false });
            }}
            PaperProps={{
              style: {
                backgroundColor: '#fff',
              },
            }}
          >
            <div className="px-half py-half">
              <div>
                <IconButton
                  onClick={() => {
                    dispatch({
                      type: TeamPageActionType.InviteTeamMemberPopupOpen,
                      payload: false,
                    });
                  }}
                >
                  <CloseIcon fontSize="large" />
                </IconButton>
              </div>
              <div className="px-half">{inviteTeamMemberForm()}</div>
            </div>
          </Dialog>
        </Suspense>
      </div>
    </div>
  );
});

export default TeamPage;
