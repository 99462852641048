import { CSSProperties, useRef, useState } from 'react';
import './style.scss';
import { fixClass, isString, notEmptyArray } from '../../../helpers/base';
import Icon from '../Icon';

interface Option {
  label: string;
  value: any;
}

interface SelectProps {
  value: string | number | null;
  options: Array<Option | string | number> | undefined | null;
  onChange(option: string | any): void;
  placeholder: string;
  label?: string;
  error?: string;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  capitalize?: boolean;
  dataId?: string;
}

export default function Select(props: SelectProps) {
  const [showOptions, setShowOptions] = useState(false);
  const selectRef: any = useRef(null);
  const { capitalize = true } = props;

  const isError = props.error && props.error.length > 0;

  return (
    <div
      data-id={props.dataId}
      ref={selectRef}
      className={`flex-column relative w-auto Select-component${fixClass(
        isError && 'has-error',
      )}${fixClass(props.disabled && 'disabled')}${fixClass(props.className)}`}
    >
      {props.label && <label className="font-noto-sans font-base mb-half">{props.label}</label>}
      <div
        onClick={() => {
          if (!props.disabled && selectRef) {
            const parents: any = (node: any): any =>
              (node.parentElement ? parents(node.parentElement) : []).concat([node]);
            parents(selectRef.current).forEach(
              (el: any) => (el.style.zIndex = !showOptions ? 900 : ''),
            );
            setShowOptions(!showOptions);
          }
        }}
        className={`pt-1 justify-between font-noto-sans font-base relative placeholder pointer ${
          !!props.value ? 'value' : ''
        }`}
        style={props.style}
      >
        {
          <span className={`${capitalize ? 'text-capitalize' : ''}`}>
            {props.value || props.placeholder}
          </span>
        }
        <div className="icons flex-column justify-center">
          <Icon icon="SelectUp" />
          <Icon icon="SelectDown" />
        </div>
        {showOptions && notEmptyArray(props.options) && (
          <div className="options bg-white w-full absolute">
            {props.options!.map((option: any, index: number) => (
              <div
                className={`pointer font-noto-sans font-base items-center option py-1-half${fixClass(
                  isString(option)
                    ? option === props.value
                    : option.value === props.value && 'selected',
                )}`}
                key={index}
                onClick={() => {
                  setShowOptions(false);
                  props.onChange(option);
                }}
              >
                {
                  <span className={`${capitalize ? 'text-capitalize' : ''}`}>
                    {option.label || option}
                  </span>
                }
              </div>
            ))}
          </div>
        )}
      </div>

      {showOptions && (
        <div
          onClick={() => {
            const parents: any = (node: any): any =>
              (node.parentElement ? parents(node.parentElement) : []).concat([node]);
            parents(selectRef.current).forEach(
              (el: any) => (el.style.zIndex = !showOptions ? 900 : ''),
            );
            setShowOptions(!showOptions);
          }}
          className="Select-component-overlay fixed"
        />
      )}
      {isError && <p className="error absolute font-noto-sans">{props.error}</p>}
    </div>
  );
}
