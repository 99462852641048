/* istanbul ignore file */

import { debounce, isEmpty, isEqual, reduce } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { MyUser, User } from '../interfaces/baseInterfaces';
import dayjs from 'dayjs';
import Holidays from 'date-holidays';
import { ADDRESS, EMAIL, JOB_TITLE, PHONE, SSN } from '../constants/regex';
import { captureException } from '@sentry/react';

export const validateEmail = (email: string) => EMAIL.test(String(email).toLowerCase());

export const validateJobTitle = (jobTitle: string) => JOB_TITLE.test(String(jobTitle).trim());

export const noSpecialCharacters = (text: string) => /^[a-zA-Z\s.]*$/.test(text.trim());

export const lengthCheck = (text: string, minLength = 4) => text.length >= minLength;

export const isEmailVendorDisallowed = (email: string) => {
  const disallowedEmailVendors = [
    'dmail',
    'proton',
    'tutanota',
    'mailfence',
    'skiff',
    'duck.com',
    'yopmail',
    'mitico',
  ];

  for (const e of disallowedEmailVendors) {
    if (email.includes(e)) {
      return true;
    }
  }
  return false;
};

export const validateSocialSecurityNumber = (ssn: string) => SSN.test(ssn);

export enum PasswordErrorType {
  TooShort,
  NoUppercaseCharacters,
  NoLowercaseCharacters,
  NoNumbers,
  IllegalCharacter,
  NoSpecialCharacter,
}

export const validatePassword = (password: string) => {
  const errors = [];
  if (password.length < 6) {
    errors.push(PasswordErrorType.TooShort);
  }

  if (new RegExp('[^a-zA-Z0-9!\\]\\[@#$%^&"}{*<\\\\>)(+,|=._:;~\'`?/]+').test(password)) {
    errors.push(PasswordErrorType.IllegalCharacter);
  }

  if (!new RegExp('[0-9]+').test(password)) {
    errors.push(PasswordErrorType.NoNumbers);
  }

  if (!new RegExp('[a-z]+').test(password)) {
    errors.push(PasswordErrorType.NoLowercaseCharacters);
  }

  if (!new RegExp('[A-Z]+').test(password)) {
    errors.push(PasswordErrorType.NoUppercaseCharacters);
  }

  if (!new RegExp('[!\\]\\[@#$%^&"}{*<\\\\>)(+,|=._:;~\'`?/]+').test(password)) {
    errors.push(PasswordErrorType.NoSpecialCharacter);
  }

  return errors;
};

export const validatePhoneNumber = (phoneNumber: string) => PHONE.test(phoneNumber);

export const validateAddress = (address: string) => ADDRESS.test(address);

export const notEmptyArray = (arr: any[] | null | undefined) =>
  Array.isArray(arr) && arr.length > 0;

export const fixClass = (className: string | undefined | boolean | null) =>
  className ? ' ' + className : '';

// helper to count array of objects by object's specific property
export const countArrayByProp = (arr: any[], prop: string, val: any) =>
  notEmptyArray(arr) ? arr.reduce((acc, cur) => (cur[prop] === val ? ++acc : acc), 0) : 0;

// helper to count array of objects length
export const countArrayLength = (arr: any[], prop: string) =>
  notEmptyArray(arr)
    ? arr.reduce((acc, cur) => (cur[prop] ? (acc += cur[prop].length) : acc), 0)
    : 0;

// helper to count array of objects by object's specific property
export const countArrayByPropLength = (arr: any[], prop: string, val: number) =>
  notEmptyArray(arr) ? arr.reduce((acc, cur) => (cur[prop].length === val ? ++acc : acc), 0) : 0;

// helper to count array of objects by object's specific property
export const countArrayByExistProp = (arr: any[], prop: string) =>
  notEmptyArray(arr) ? arr.reduce((acc, cur) => (!!cur[prop] ? ++acc : acc), 0) : 0;

// helper to turn object to url query string params. ex: {a: 1, b:2} to ?a=1&b=2
export const objectToUrlParams = (obj: any) => {
  if (!obj) return '';
  Object.keys(obj).forEach(key => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '' || obj[key].length === 0) {
      delete obj[key];
    }
  });
  if (isEmpty(obj)) return '';
  return (
    '?' +
    Object.keys(obj)
      .map(key => key + '=' + encodeURIComponent(obj[key]))
      .join('&')
  );
};

export function titleCase(str: string) {
  const splitStr: string[] = str.toLowerCase().split(' ');
  const splitStrLen: number = splitStr.length;
  for (let i = 0; i < splitStrLen; i++) {
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

// helper to capitalize string
export const capitalize = (str: string) =>
  str
    ? str
        .split(' ')
        .map(i => i.replace(/^\w/, c => c.toUpperCase()))
        .join(' ')
    : '';

// helper to use debounce from lodash
export const withDebounce = (func: any, delay: number) => debounce(func, delay);

// helper to check if value is number
export const isNumber = (value: number | string) => Number.isInteger(Number(value));

// helper to check if value is boolean
export const isBoolean = (value: any) => typeof value === 'boolean';

// helper to check if current device is mobile phone
export const isMobile = (width: number): boolean => width < 600;

// recursive function to return as a string last property of the object
export const lastProperty = (obj: any): any => {
  if (typeof obj !== 'object') {
    return obj;
  }
  for (const prop in obj) {
    if (Array.isArray(obj[prop])) {
      return `${prop}: ${obj[prop][0]}`;
    }
    return lastProperty(obj[prop]);
  }
};

export const hidePhoneNumber = (num: string) => {
  if (!num) return '';
  const area_code_and_country_code = num.substr(0, Math.min(4, num.length));
  return area_code_and_country_code + ' XXX-XXXX';
};

export const hideEmail = (email: string) =>
  email
    ? email
        .split('@')[0]
        .split('')
        .map(() => 'x')
        .join('') +
      '@' +
      email.split('@')[1]
    : '';

// helper to handle errors, needs as 1st arg stateChanging function as 2nd arg error message as 3rd arg timout ml.secs
export const handleMessage = (
  setState: Dispatch<SetStateAction<string>>,
  value: any,
  timeOut?: number,
) => {
  setState(value);
  return setTimeout(setState('') as any, timeOut || 3000);
};

export const userNamesWithOthersEllipsizing = (users: User[], maxNumberToDisplay: number) => {
  let label = '';
  for (let i = 0; i < users.length && i < maxNumberToDisplay; i++) {
    const user = users[i];
    label += user.first_name + ' ' + user.last_name;

    if (i + 1 !== users.length) {
      label += ',\n';
    }
  }

  if (users.length > maxNumberToDisplay) {
    label += ' and ' + (users.length - maxNumberToDisplay) + ' others.';
  }

  return label;
};

// helper to redirect android or ios device to app or app market
export const redirectToApp = () => {
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf('android') > -1;
  const isIOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  const appLink = 'toolbox-app://';
  if (isIOS || isAndroid) {
    const now = new Date().valueOf();
    const iosLink =
      'https://apps.apple.com/gb/app/toolbox-construction/id1506324260?ign-mpt=uo%3D2';
    const androidLink =
      'https://play.google.com/store/apps/details?id=com.mytoolbox.toolbox.mobile';
    setTimeout(
      () =>
        new Date().valueOf() - now > 100
          ? null
          : (window.location.href = isAndroid ? androidLink : iosLink),
      25,
    );
    window.location.href = appLink;
  }
};

// helper to get 1 lvl object difference
export const objDiff = (a: any, b: any) =>
  reduce(
    a,
    (result, value, key: any) => (isEqual(value, b[key]) ? result : result.concat(key)),
    [],
  );

export const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const isString = (str: any) => typeof str === 'string';
export const isObject = (str: any) => typeof str === 'object';

// the fastest way to clone array or object
export const createClone = (obj: any[]) => JSON.parse(JSON.stringify(obj));

export const removeUnderscore = (str: string) => (isString(str) ? str.replace(/_/g, ' ') : '');

// helper to check if value is number
export const intOrFloat = (value: string) =>
  /^(?=.)([+-]?([0-9]*)(\.([0-9]+))?)$/.test(value) ||
  value === '' ||
  value[value.length - 1] === '.';

export const makeName = (u: any) => (u ? u.first_name + ' ' + u.last_name : '');

// helper to get filename from url
export const getFileName = (url: string) => (url ? url.substring(url.lastIndexOf('/') + 1) : '');

export const handleError = (
  e: any,
  stateFunc: Dispatch<SetStateAction<any>>,
  loadingFunc?: Dispatch<SetStateAction<any>>,
) => {
  loadingFunc && loadingFunc(false);
  stateFunc(e.error || e.toString());
  return setTimeout(() => stateFunc(''), 3000);
};

export const firstAndLastDayOfMonth = () => {
  const date = new Date();

  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return [firstDay, lastDay];
};

export const postedPaymentDate = () => {
  const hd = new Holidays();

  const holidayOrWeekend = (d: dayjs.Dayjs) =>
    hd.isHoliday(d.toDate()) || d.toDate().getDay() == 0 || d.toDate().getDay() == 6;

  let curDate = dayjs().add(3, 'd');

  while (holidayOrWeekend(curDate)) {
    curDate = curDate.add(1, 'd');
  }

  return curDate;
};

// Postal Code Validation
export const validatePostalCode = (postalCode: string) => {
  const validation = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(postalCode);
  return validation;
};

export enum TransactionStatus {
  Closed = 'closed',
  Pending = 'pending',
  Succeeded = 'succeeded',
  Refunded = 'refunded',
  Canceled = 'canceled',
  Declined = 'declined',
}

export function standardError(err: any, info?: unknown) {
  captureException(err);
  console.error(err, info);
}

export function immediateActionRequired(user: MyUser) {
  return (
    user.plaid_microdeposits_needed || user.needs_plaid_auth_re_link || user.needs_pdf_reminder
  );
}

export function bankAccountStatusButtonColor(status: string) {
  let statusColor = '';

  if (status == 'connected') {
    statusColor = 'green';
  } else if (status == 'pending') {
    statusColor = 'gold';
  } else if (status == 'disconnected') {
    statusColor = 'red';
  }
  return statusColor;
}

export function bankAccountStatusButtonPulseColor(status: string) {
  let pulse_color = '';

  if (status == 'connected') {
    pulse_color = 'pulsatingDotGreen';
  } else if (status == 'pending') {
    pulse_color = 'pulsatingDotGolden';
  } else if (status == 'disconnected') {
    pulse_color = 'pulsatingDotRed';
  }
  return pulse_color;
}
