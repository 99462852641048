import httpClient from '../../../services/httpClient';

interface ICategoriesService {
  getToolboxCategories(): Promise<{ categories: string[] }>;
}

class CategoriesService implements ICategoriesService {
  getToolboxCategories = () =>
    httpClient.get<{ categories: string[] }>('/categories').then(res => res.data);
}

export const categoriesService = new CategoriesService();
