import httpClient from '../../../services/httpClient';
import { PaginatedData, Pagination } from '../../../common/pagination';

interface GetPaymentsResponse {
  count: number;
  data: {
    amount: number;
    created_at: string;
    receipt_url: string;
    settlement_date: string;
    status: string;
  }[];
}

interface GetCreditStatementsResponse {
  count: number;
  data: {
    statement_period_start: string;
    statement_period_end: string;
    period: string;
    statement_id: string;
  }[];
}

interface GetCsvStatementParams {
  range_start: string;
  range_end: string;
}

export interface UnitStatement {
  id: string;
  type: string;
  attributes: { period: string };
}

interface GetStatementParams {
  productType: number;
  pagination: Pagination;
}

interface IAccountingService {
  getPayments(pagination: Pagination): Promise<GetPaymentsResponse>;

  getCreditHistoricalStatements(pagination: Pagination): Promise<GetCreditStatementsResponse>;

  getPdfStatement(statementId: string): Promise<Blob>;

  getCsvStatement(params: GetCsvStatementParams): Promise<Blob>;

  getStatements(params: GetStatementParams): Promise<PaginatedData<UnitStatement>>;
}

class AccountingService implements IAccountingService {
  getPayments = (pagination: Pagination) =>
    httpClient
      .get<GetPaymentsResponse>('/payments', {
        params: {
          page_index: pagination.page,
          limit: pagination.per_page,
        },
      })
      .then(res => res.data);

  getCreditHistoricalStatements = (pagination: Pagination) => {
    return httpClient
      .get<GetCreditStatementsResponse>('/credit-statements', {
        params: {
          page_index: pagination.page,
          limit: pagination.per_page,
        },
      })
      .then(res => res.data);
  };

  getPdfStatement = (statementId: string) => {
    return httpClient
      .get<Blob>('/statement/pdf', {
        params: { statement_id: statementId },
        responseType: 'blob',
      })
      .then(res => res.data);
  };

  getCsvStatement = (params: GetCsvStatementParams) => {
    return httpClient
      .get<Blob>('/csv-statements', {
        params,
        responseType: 'blob',
      })
      .then(res => res.data);
  };

  getStatements = (params: GetStatementParams) => {
    const { pagination } = params;
    return httpClient
      .get<PaginatedData<UnitStatement>>('/statements', {
        params: {
          product_type: params.productType,
          ...pagination,
        },
      })
      .then(res => res.data);
  };
}

const accountingService = new AccountingService();

export default accountingService;
