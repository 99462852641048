import en from './en.json';
import es from './es.json';

const enLocal = en as Record<string, string>;
const esLocal = es as Record<string, string>;
export const dictionaryList = { en: enLocal, es: esLocal };

export type DictionaryKey = 'en' | 'es';

export const languageOptions = {
  en: 'English',
  es: 'Español',
};
