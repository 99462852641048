import React from 'react';
import Typography from '@mui/material/Typography';
import { useMediaQuery, useTheme } from '@mui/material';
import { WalletIcon } from '../Icon/fontawesome';
import posthog from 'posthog-js';

interface BalanceInfoProps {
  availableBalance: string;
  accountInfoTrigger?: () => void;
  style?: React.CSSProperties;
  className?: string;
}

const BalanceInfo: React.FC<BalanceInfoProps> = ({
  availableBalance,
  accountInfoTrigger,
  style,
  className,
}) => {
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down(1280));

  return (
    <div
      className={`p-4 custom-balance-info ${className}`}
      style={{ position: 'relative', ...style }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div className={`flex items-center ${!matchesMobile ? 'justify-between' : ''}`}>
          <div
            style={{
              display: matchesMobile ? 'block' : 'flex',
              justifyContent: matchesMobile ? 'flex-start' : 'center',
              flexDirection: matchesMobile ? 'column' : 'row',
            }}
          >
            <div
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '8px',
                backgroundColor: 'rgba(231, 237, 234, 0.10)',
                padding: '2.5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: matchesMobile ? 0 : '12px',
                marginBottom: matchesMobile ? '8px' : 0,
              }}
            >
              <WalletIcon style={{ width: 20, height: 20, color: '#35CF9B' }} />
            </div>

            <div>
              <Typography
                variant="body1"
                data-cy="availableBalance"
                color="textPrimary"
                style={{ color: '#F7F7F7', fontSize: '16px', marginTop: 8, lineHeight: '140%' }}
              >
                Available Balance
              </Typography>
            </div>

            <div>
              <Typography
                className="xl:hidden"
                variant="h3"
                data-cy="balanceFigure1"
                color="textPrimary"
                style={{
                  color: '#FFF',
                  fontSize: '32px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '140%',
                  marginLeft: matchesMobile ? 0 : '34px',
                  marginBottom: 2,
                }}
              >
                {availableBalance}
              </Typography>
              <Typography
                className="xl:hidden"
                data-cy="viewAccountDetails1"
                onClick={() => {
                  posthog.capture('Banking Page - Main View Account Details Click');
                  accountInfoTrigger && accountInfoTrigger();
                }}
                variant="body2"
                color="textPrimary"
                style={{
                  color: '#35CF9B',
                  fontSize: 12,
                  marginTop: 1,
                  textDecoration: 'underline',
                  textTransform: 'uppercase',
                  cursor: 'pointer',
                }}
              >
                View account details
              </Typography>
            </div>
          </div>
        </div>

        <Typography
          className="hidden xl:block"
          variant="h3"
          data-cy="balanceFigure2"
          color="textPrimary"
          style={{
            color: '#FFF',
            fontSize: '32px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '140%',
            marginLeft: '8px',
            marginBottom: 2,
          }}
        >
          {availableBalance}
        </Typography>

        <Typography
          className="hidden xl:block"
          data-cy="viewAccountDetails2"
          onClick={() => accountInfoTrigger && accountInfoTrigger()}
          variant="body2"
          color="textPrimary"
          style={{
            color: '#35CF9B',
            fontSize: 14,
            paddingBottom: '24px',
            textDecoration: 'underline',
            textTransform: 'uppercase',
            marginLeft: '8px',
            cursor: 'pointer',
          }}
        >
          View account details
        </Typography>
      </div>
    </div>
  );
};

export default BalanceInfo;
