import { SyncAlt } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, Divider } from '@mui/material';
import { DepositPageAction, DepositPageActionType } from 'app/pages/dashboard/deposit/reducer';
import LoadingButton from 'app/components/LoadingButton';
import { DepositAccount } from 'interfaces/account';
import { unitBankAddress, unitBankName } from 'constants/unit';
import './style.scss';
import Show from 'app/components/Show';
import { useGetCompanyAddress } from '../../../companies/data/getCompanyAddress';
import financialService from '../../../../services/financial.service';
import { standardError } from '../../../../helpers/base';
import { useState } from 'react';

interface AchAccountDetailsModalProps {
  isOpen: boolean;
  updateState: React.Dispatch<DepositPageAction>;
  account: DepositAccount;
}

const AchAccountDetailsModal = (props: AchAccountDetailsModalProps) => {
  const { isOpen, updateState, account } = props;

  const { isLoading: isLoadingAddress, isError, data } = useGetCompanyAddress(isOpen);
  const [downloading, setDownloading] = useState(false);

  const companyAddress = data?.address;

  const handleGoBack = () => {
    updateState({ type: DepositPageActionType.AchAccountDetailsOpen, payload: false });
    updateState({ type: DepositPageActionType.AchFundingPopupOpen, payload: true });
  };

  const downloadPdf = async () => {
    setDownloading(true);
    const resp = await financialService.getDepositInformation();

    if (resp.error) {
      standardError(resp.error);
      return;
    }

    const data = new Blob([resp], { type: 'application/pdf' });
    const csvURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'Deposit Information.pdf');
    tempLink.click();
    setDownloading(false);
  };

  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <p className="text-bold font-lg">ACH Credit / Wire Transfer</p>
        <p className="text-normal">Arrives in 1-2 business days</p>
      </DialogTitle>
      <SyncAlt fontSize="medium" className="rounded-circle bg-gray-10 p-1 absolute top-4 right-4" />

      <DialogContent>
        <p className="font-bold mb-[5px] text-dark-green">Domestic Transfer</p>
        <Divider />
        <section className="mt-1">
          <section className="flex justify-around">
            <div>
              <p className="section-title">BENEFICIARY</p>

              <p className="detail-label">Beneficiary Name</p>
              <p className="detail-value">{account?.name}</p>

              <p className="detail-label">Account Number</p>
              <p className="detail-value">
                <span className="sensitive">{account?.account_number}</span>
              </p>

              <p className="detail-label">Account Type</p>
              <p className="detail-value capitalize">{account?.deposit_product}</p>

              <p className="detail-label">Beneficiary Address</p>
              <Show when={!!companyAddress}>
                <p className="detail-value whitespace-pre-wrap">{companyAddress}</p>
              </Show>
              <Show when={isLoadingAddress}>
                <p className="detail-value">Loading address data...</p>
              </Show>
              <Show when={isError}>
                <p className="detail-value italic">Could not fetch address</p>
              </Show>
            </div>
            <div>
              <p className="section-title">RECEIVING BANK DETAILS</p>

              <p className="detail-label">ABA Routing Number</p>
              <p className="detail-value">{account?.routing_number}</p>

              <p className="detail-label">Bank Name</p>
              <p className="detail-value">{unitBankName}</p>

              <p className="detail-label">Bank Address</p>
              <p className="detail-value">{unitBankAddress}</p>
            </div>
          </section>
        </section>
        <section className="flex items-center justify-between mt-2">
          <LoadingButton variant="outlined" onClick={handleGoBack}>
            Go Back
          </LoadingButton>
          {/* <LoadingButton onClick={downloadPdf} disabled={downloading}>
            {downloading ? 'Downloading PDF' : 'Download PDF'}
          </LoadingButton> */}
        </section>
      </DialogContent>
    </Dialog>
  );
};

export default AchAccountDetailsModal;
