import './style.scss';
import { ReactNode, createElement } from 'react';
import { fixClass } from '../../../helpers/base';
import Icon from '../Icon';
import Card from '../Card';

interface PopUpProps {
  isOpen: boolean;
  isCard?: boolean;
  onClose(): void;
  className?: string;
  noClose?: boolean;
  children: ReactNode[] | ReactNode;
  dataId?: string;
  noOverlayClose?: boolean;
}

export default function PopUp(props: PopUpProps) {
  return props.isOpen ? (
    <section className="PopUp-component items-center justify-center fixed">
      <style>{`html { overflow: hidden; }`}</style>
      <div
        className="popup-overlay absolute"
        onClick={() => (props.noOverlayClose ? () => null : props.onClose())}
      />
      {createElement(props.isCard ? Card : 'div', {
        children: (
          <>
            {!props.noClose && (
              <Icon
                data-id="close-pointer-icon"
                className="close absolute pointer"
                onClick={props.onClose}
                icon="Close"
              />
            )}
            {props.children}
          </>
        ),
        dataId: `popup-container`,
        className: `relative bg-white rounded-md popup-content${fixClass(props.className)}`,
      })}
    </section>
  ) : null;
}
