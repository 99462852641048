import { Pagination } from '../../../../../common/pagination';
import Show from '../../../../../app/components/Show';
import TableSkeletonLoader from '../../../../../app/components/SkeletonLoaders/TableSkeletonLoader';
import {
  Pagination as MuiPagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import { useGetCreditHistoricalStatements } from '../../../data/getCreditHistoricalStatements';
import CsvDownloadButton from '../CsvDownloadButton';
import { useState } from 'react';

interface CreditHistoricalStatementsTableProps {}

interface TableColumns {
  label: string;
}

const CreditHistoricalStatementsTable = (props: CreditHistoricalStatementsTableProps) => {
  const [pagination, setPagination] = useState<Pagination>({ page: 1, per_page: 5 });
  const { data, isLoading, isSuccess } = useGetCreditHistoricalStatements(pagination);

  const statements = data?.data ?? [];
  const statementsCount = data?.count ?? 0;

  const columns: TableColumns[] = [{ label: 'Statement Period' }, { label: 'CSV Download' }];

  return (
    <>
      <Show when={isLoading}>
        <TableSkeletonLoader rows={pagination.per_page} columns={columns.length} />
      </Show>
      <Show when={isSuccess}>
        <TableContainer className="table border rounded">
          <Table>
            <TableHead className="bg-gray-100">
              <TableRow>
                {columns?.map(column => (
                  <TableCell key={column.label}>
                    <span className="font-bold">{column.label}</span>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {statements?.map(statement => {
                return (
                  <TableRow key={statement.statement_id}>
                    <TableCell>{dayjs(statement.period).format('MMMM YYYY')}</TableCell>
                    <TableCell>
                      <CsvDownloadButton statement={statement} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <MuiPagination
          className="mt-1"
          count={Math.ceil(statementsCount / pagination.per_page)}
          showFirstButton
          showLastButton
          page={pagination.page}
          onChange={(_, page) => {
            setPagination(prev => ({ ...prev, page }));
          }}
          sx={{
            '& .MuiPagination-ul': { 'justify-content': 'center' },
          }}
        />
      </Show>
    </>
  );
};

export default CreditHistoricalStatementsTable;
