import httpClient from './httpClient';

class AccountsService {
  createDepositAccount = async () => {
    return await httpClient.post('/accounts/deposit', { account_type: 'checking' });
  };
}

const accountService = new AccountsService();
export default accountService;
