import { useMemo, memo, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import { Search } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import currentEnv from 'config/environment';
import {
  AddressComponent,
  AddressTextPart,
  Geocode,
  MainTextMatchedSubstrings,
  Place,
} from 'interfaces/baseInterfaces';
import { standardError } from 'helpers/base';
import { getLangText } from '../../../contexts/language';
import { SxProps, Theme } from '@mui/material';

const currEnv = currentEnv();

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) return;

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

type AddressAutoCompleteProps = {
  cb(address: string, city: string, state: string, zipCode: string, rawAddress?: Place): void;
  value?: Place;
  customStyle?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  sx?: SxProps<Theme>;
  displayZipCode?: boolean;
};

const AddressAutoComplete = memo(function AddressAutoComplete(props: AddressAutoCompleteProps) {
  const { cb, error, helperText = '', value, sx, displayZipCode = false } = props;

  const [place, setPlace] = useState<Place | null>(null);
  const [zipCode, setZipCode] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<readonly Place[]>([]);

  const fetchPredictions = useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: readonly Place[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 250),
    [],
  );

  const fetchZipCode = (address: string) => {
    const googleObj = (window as any).google;
    if (!googleObj) return;

    const geocoder = new googleObj.maps.Geocoder();
    geocoder
      .geocode({ address })
      .then((res: any) => {
        const results: Geocode[] = res.results;
        results[0].address_components.map((component: AddressComponent) => {
          const hasZipCode: boolean = component.types.includes('postal_code');
          if (hasZipCode) {
            setZipCode(component.long_name);
          }
        });
      })
      .catch((err: any) => {
        standardError(err);
      });
  };

  useEffect(() => {
    let active = true;
    const googleObj = (window as any)?.google;

    if (!autocompleteService.current && googleObj) {
      autocompleteService.current = new googleObj.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) return;

    if (inputValue.length < 3) {
      setOptions(place ? [place] : []);
      return;
    }

    fetchPredictions({ input: inputValue }, (results?: readonly Place[]) => {
      if (active) {
        let newOptions: readonly Place[] = [];

        if (place) {
          fetchZipCode(place.description);
          newOptions = [place];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [place, inputValue, fetchPredictions]);

  useEffect(() => {
    if (!place || !zipCode) return;
    const structuredFormatting = place?.structured_formatting;
    const secondaryTextParts: string[] = structuredFormatting.secondary_text.split(',');

    cb(
      structuredFormatting.main_text,
      secondaryTextParts[0],
      secondaryTextParts[1].trim(),
      zipCode,
      place,
    );
  }, [zipCode]);

  useEffect(() => {
    setPlace(value ?? null);
  }, [value]);

  return (
    <Autocomplete
      getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x: Place[]) => x} // disables built-in filtering
      options={options}
      sx={sx}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={place}
      onChange={(_: any, newValue: Place | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setPlace(newValue);
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={props.customStyle ? helperText : getLangText('Address')}
          fullWidth
          error={error}
          helperText={!props.customStyle && helperText}
          InputProps={{
            ...params.InputProps,
            // @ts-ignore
            'data-cy': 'verifyAddress',
            startAdornment: props.customStyle ? (
              <Search style={{ marginRight: '4px', marginLeft: '8px' }} />
            ) : (
              params.InputProps.startAdornment
            ),
            endAdornment: props.customStyle ? null : params.InputProps.endAdornment,
          }}
          placeholder={props.customStyle ? 'Enter your Address' : ''}
        />
      )}
      renderOption={(props, option) => {
        const types = option?.types;
        if (
          !(
            types.includes('street_address') ||
            types.includes('premise') ||
            types.includes('route')
          )
        )
          return;

        const structuredFormat = option?.structured_formatting;

        if (!structuredFormat.secondary_text.includes('USA')) return;

        const matches = structuredFormat?.main_text_matched_substrings;
        const parts: AddressTextPart[] = parse(
          structuredFormat?.main_text,
          matches.map((match: MainTextMatchedSubstrings) => [
            match.offset,
            match.offset + match.length,
          ]),
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
              </Grid>
              <Grid item xs>
                {parts.map((part: AddressTextPart, index: number) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
});

export default AddressAutoComplete;
