import { PlaidAuth } from '../../../../interfaces/baseInterfaces';
import { Transaction } from '../../../../features/transactions/types';

export type CashFlowItem = {
  date: string;
  available: number;
  hold: number;
  balance: number;
};

export type Location = {
  accept_deposits: boolean;
  address: {
    city: string;
    country: string;
    postal_code: string;
    state: string;
    street: string;
  };
  coordinates: {
    latitude: number;
    longitude: number;
  };
  distance: number;
  location_name: string;
  network: string;
  surcharge_free: boolean;
};

type DepositPageState = {
  accountDetailsPopupOpen: boolean;
  achFundingPopupOpen: boolean;
  achDebitFundingPopupOpen: boolean;
  achDebitConfirmationFundingPopupOpen: boolean;
  achDebitSuccessFundingPopupOpen: boolean;
  achDebitCreatingFunding: boolean;
  achAccountDetailsOpen: boolean;
  cashFlow: CashFlowItem[];
  latestTransactions: Transaction[];
  totalInterestPayments: number;
  atmLocations: Location[];
  loading: boolean;
  isAdministrative: boolean;
  timePeriod: string;
  actionOpened: string;
  anchorAction: null | HTMLElement;
  transactions: Transaction[];
  addFundsPopupOpen: boolean;
  moneyIn: number;
  moneyOut: number;
  newCheckDepositPopupOpen: boolean;
  newCheckDepositGuidePopupOpen: boolean;
  newCheckDepositFormPopupOpen: boolean;
  newCheckDepositSubmittedPopupOpen: boolean;
  newCheckDepositSidePhoto: string;
  newCheckDepositSidePhotoFront: string;
  newCheckDepositSidePhotoBack: string;
  newCheckDepositSidePhotoFilled: boolean;
  newCheckDepositAmount: string;
  newCheckDepositDescription: string;
  newCheckDepositSubmitting: boolean;
  newACHDepositAmount: string;
  pendingDeposits: number;
  totalRewards: number;
  externalAccounts: PlaidAuth[];
  selectedExternalAccount: PlaidAuth | null;
};

export type DepositPageAction = {
  readonly type: string;
  readonly payload: any;
};

export enum DepositPageActionType {
  AccountDetailsPopupOpen = 'accountDetailsPopupOpen',
  AchFundingPopupOpen = 'achFundingPopupOpen',
  AchDebitFundingPopupOpen = 'achDebitFundingPopupOpen',
  AchDebitConfirmationFundingPopupOpen = 'achDebitConfirmationFundingPopupOpen',
  AchDebitCreatingFunding = 'achDebitCreatingFunding',
  AchDebitSuccessFundingPopupOpen = 'achDebitSuccessFundingPopupOpen',
  AchAccountDetailsOpen = 'achAccountDetailsOpen',
  CashFlow = 'cashFlow',
  LatestTransactions = 'latestTransactions',
  TotalInterestPayments = 'totalInterestPayments',
  ATMLocations = 'atmLocations',
  Loading = 'loading',
  IsAdministrative = 'isAdministrative',
  TimePeriod = 'timePeriod',
  ActionOpened = 'actionOpened',
  AnchorAction = 'anchorAction',
  AddFundsPopupOpen = 'addFundsPopupOpen',
  MoneyIn = 'moneyIn',
  MoneyOut = 'moneyOut',
  NewCheckDepositPopupOpen = 'newCheckDepositPopupOpen',
  NewCheckDepositGuidePopupOpen = 'newCheckDepositGuidePopupOpen',
  NewCheckDepositFormPopupOpen = 'newCheckDepositFormPopupOpen',
  NewCheckDepositSubmittedPopupOpen = 'newCheckDepositSubmittedPopupOpen',
  NewCheckDepositSidePhoto = 'newCheckDepositSidePhoto',
  NewCheckDepositSidePhotoFront = 'newCheckDepositSidePhotoFront',
  NewCheckDepositSidePhotoBack = 'newCheckDepositSidePhotoBack',
  NewCheckDepositSidePhotoFilled = 'newCheckDepositSidePhotoFilled',
  NewCheckDepositAmount = 'newCheckDepositAmount',
  NewCheckDepositDescription = 'newCheckDepositDescription',
  NewCheckDepositSubmitting = 'newCheckDepositSubmitting',
  NewACHDepositAmount = 'newACHDepositAmount',
  PendingDeposits = 'pendingDeposits',
  TotalRewards = 'totalRewards',
  ExternalAccounts = 'externalAccounts',
  SelectedExternalAccount = 'selectedExternalAccount',
}

export const depositPageInitialState: DepositPageState = {
  accountDetailsPopupOpen: false,
  achFundingPopupOpen: false,
  achDebitFundingPopupOpen: false,
  achDebitConfirmationFundingPopupOpen: false,
  achDebitCreatingFunding: false,
  achDebitSuccessFundingPopupOpen: false,
  achAccountDetailsOpen: false,
  cashFlow: [],
  latestTransactions: [],
  totalInterestPayments: 0,
  atmLocations: [],
  loading: true,
  isAdministrative: false,
  timePeriod: 'last_30_days',
  actionOpened: '',
  anchorAction: null,
  transactions: [],
  addFundsPopupOpen: false,
  moneyIn: 0,
  moneyOut: 0,
  newCheckDepositPopupOpen: false,
  newCheckDepositGuidePopupOpen: false,
  newCheckDepositFormPopupOpen: false,
  newCheckDepositSubmittedPopupOpen: false,
  newCheckDepositSidePhoto: '',
  newCheckDepositSidePhotoFront: '',
  newCheckDepositSidePhotoBack: '',
  newCheckDepositSidePhotoFilled: false,
  newCheckDepositAmount: '',
  newCheckDepositDescription: '',
  newCheckDepositSubmitting: false,
  newACHDepositAmount: '',
  pendingDeposits: 0,
  totalRewards: 0,
  externalAccounts: [],
  selectedExternalAccount: null,
};

export function depositPageReducer(state: DepositPageState, action: DepositPageAction) {
  switch (action.type) {
    default:
      return { ...state, [action.type]: action.payload };
  }
}
