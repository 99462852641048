import './style.scss';
import { useEffect, useReducer, memo, Suspense, useState, useCallback } from 'react';
import financialService from '../../../../services/financial.service';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';
import posthog from 'posthog-js';
import {
  transactionPageInitialState,
  transactionPageReducer,
  TransactionPageActionType,
} from './reducer';
import Spinner from 'app/components/Spinner';
import TransactionRows from 'app/components/TransactionRows';
import { CreditCard, MyUser } from 'interfaces/baseInterfaces';
import DrawerWrapper from 'app/components/DrawerWrapper';
import { formatCardForDisplay, formatCentsAsDollars } from 'helpers/formattings';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DragDrop from 'app/components/DragDrop';
import { CompanyRole } from 'interfaces/company';
import { getFileName, standardError } from 'helpers/base';
import { Avatar, Button, Container, Table, TableBody, TableContainer } from '@mui/material';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Input from '../../../components/Input';
import GenericPopUp from '../../../components/GenericPopUp';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVLink } from 'react-csv';
import DateAndTimePicker from 'app/components/DateAndTimePicker';
import CustomAutoComplete from 'app/components/CustomAutoComplete';
import { PriceCheck, TuneRounded } from '@mui/icons-material';
import { getLangText, Text } from '../../../../contexts/language';
import userService from 'services/user.service';
import TransactionDrawerTags from '../../../../features/transactions/components/TransactionDrawerTags';
import Show from '../../../components/Show';
import { Tag } from '../../../../features/tags/types';
import TagPopupSelector from '../../../../features/tags/components/TagPopupSelector';
import { Transaction } from '../../../../features/transactions/types';
import CategoryPopupSelector from '../../../../features/categories/components/CategoryPopupSelector';
import SaveToGoogleDriveBtn from 'app/components/SaveToGoogleDriveBtn';
import TransactionReference from '../../../../features/transactions/components/TransactionReference';

export interface TransactionsPageProps {
  currentUser: MyUser;
}

const TransactionPage = memo(function TransactionPage(props: TransactionsPageProps) {
  const { currentUser } = props;
  const windowDimensions = useWindowDimensions();
  const [state, dispatch] = useReducer(transactionPageReducer, transactionPageInitialState);

  const [receiptUrl, setReceiptUrl] = useState<string | null>(null);

  const [showEnlarged, setShowEnlarged] = useState(false);

  const merchantCategory =
    state.selectedTransactionForPopup && state.selectedTransactionForPopup.merchant_category
      ? state.selectedTransactionForPopup.merchant_category
      : null;
  const stripe_transaction_id: string = state.selectedTransactionForPopup
    ? state.selectedTransactionForPopup.id
    : '';
  const cardNumbers = state.cards.map(formatCardForDisplay);
  const [searchTuneView, setSearchTuneView] = useState(false);

  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const saveTransactionMemo = async () => {
    posthog.capture('Save Memo Click');
    dispatch({ type: TransactionPageActionType.SaveMemoLoading, payload: true });

    const memo = state.selectedTransactionForPopup?.memo ?? '';
    await financialService
      .addTransactionMemo(stripe_transaction_id, memo)
      .then(res => {
        if (res?.error) throw new Error();

        const transactionId = state.selectedTransactionForPopup?.id;
        if (transactionId) {
          const transactions = state.transactions.map(transaction => {
            if (transaction.id === transactionId) {
              return {
                ...transaction,
                memo,
              };
            }
            return transaction;
          });
          dispatch({ type: TransactionPageActionType.Transactions, payload: transactions });
          dispatch({ type: TransactionPageActionType.SelectedTransactionForPopup, payload: null });
        }
        dispatch({ type: TransactionPageActionType.SaveMemoLoading, payload: false });
      })
      .catch(err => {
        standardError(err);
        dispatch({ type: TransactionPageActionType.SaveMemoLoading, payload: false });
      });
  };

  const fetchReceiptUrl = useCallback(async () => {
    try {
      if (state?.selectedTransactionForPopup?.id) {
        const res = await financialService.downloadReceipt(state.selectedTransactionForPopup.id);
        if (res?.data) {
          setReceiptUrl(res.data?.url);
        } else {
          setReceiptUrl(null);
        }
      }
    } catch (error) {
      console.error('Error fetching receipt:', error);
      setReceiptUrl(null);
    }
  }, [state?.selectedTransactionForPopup?.id]);

  useEffect(() => {
    if (state.selectedTransactionForPopup) {
      fetchReceiptUrl();
    } else {
      setReceiptUrl(null);
    }
  }, [state.selectedTransactionForPopup, fetchReceiptUrl]);

  useEffect(() => {
    const t = setTimeout(() => {
      dispatch({
        type: TransactionPageActionType.IsMobile,
        payload: windowDimensions.isMobileScreen,
      });
    }, 1);
    return () => clearTimeout(t);
  }, [windowDimensions]);

  const loadCardFilters = async () => {
    await financialService
      .getCards()
      .then(res => {
        if (res?.error) throw new Error();
        if (res) {
          dispatch({ type: TransactionPageActionType.Cards, payload: res.cards });
        }
      })
      .catch(err => standardError(err));
  };

  const loadTransactions = async () => {
    dispatch({ type: TransactionPageActionType.Loading, payload: true });

    await financialService
      .getTransactions({
        card: state.card ? state.card.id : null,
        custom_range_start: state.customRangeStart
          ? dayjs(state.customRangeStart).format('YYYY-MM-DDTHH:mm:ss')
          : null,
        custom_range_end: state.customRangeEnd
          ? dayjs(state.customRangeEnd).format('YYYY-MM-DDTHH:mm:ss')
          : null,
        categories: JSON.stringify(state.category),
        show_payments: state.showPayments,
        show_rewards: state.showRewards,
        tag_ids: JSON.stringify(selectedTags.map(tag => tag.id)),
      })
      .then(res => {
        dispatch({ type: TransactionPageActionType.Loading, payload: false });
        if (res?.error) throw new Error();
        if (res) {
          dispatch({ type: TransactionPageActionType.Transactions, payload: res.transactions });
          dispatch({ type: TransactionPageActionType.TotalSpent, payload: res.total });
          dispatch({ type: TransactionPageActionType.TransactionCount, payload: res.count });
          dispatch({ type: TransactionPageActionType.Loading, payload: false });
        }
      })
      .catch(err => standardError(err));
  };

  const onCategoryChange = (categories: string[]) => {
    dispatch({ type: TransactionPageActionType.Category, payload: categories });
  };

  const uploadReceipt = async (file: File[]) => {
    setReceiptUrl(null);
    if (!file?.length) return;
    await financialService
      .uploadReceipt(file[0], state.selectedTransactionForPopup?.id ?? '')
      .then(res => {
        if (res?.error) throw new Error();
        fetchReceiptUrl();
        dispatch({ type: TransactionPageActionType.ShouldRefresh, payload: true });
        dispatch({ type: TransactionPageActionType.SelectedTransactionForPopup, payload: null });
      })
      .catch(err => {
        standardError(err);
        dispatch({
          type: TransactionPageActionType.ErrorMessage,
          payload: 'Error uploading receipt',
        });
      });
  };

  const handleDownloadReceipt = async () => {
    await financialService
      .downloadReceipt(state.selectedTransactionForPopup?.id ?? '')
      .then(res => {
        if (res?.error) throw new Error();
        if (res?.data) {
          window.open(res.data?.url);
        }
      })
      .catch(err => {
        standardError(err);
      });
  };

  const handleReplaceReceipt = async () => {
    dispatch({ type: TransactionPageActionType.IsReplacingReceipt, payload: true });
  };

  const replaceReceipt = async (file: File[]) => {
    setReceiptUrl(null);
    if (!file?.length) return;
    await financialService
      .replaceReceipt(file[0], state.selectedTransactionForPopup?.id ?? '')
      .then(res => {
        if (res?.error) throw new Error();
        fetchReceiptUrl();
        dispatch({ type: TransactionPageActionType.IsReplacingReceipt, payload: false });
        dispatch({ type: TransactionPageActionType.ShouldRefresh, payload: true });
        dispatch({ type: TransactionPageActionType.SelectedTransactionForPopup, payload: null });
      })
      .catch(err => {
        standardError(err);
        dispatch({
          type: TransactionPageActionType.ErrorMessage,
          payload: 'Error replacing receipt',
        });
      });
  };

  useEffect(() => {
    dispatch({
      type: TransactionPageActionType.IsAdministrative,
      payload:
        currentUser.team_role == CompanyRole.ACCOUNT_OWNER ||
        currentUser.team_role == CompanyRole.ADMIN,
    });
    if (
      currentUser.is_credit_customer &&
      !currentUser.has_authorized_oatfi_debit &&
      (currentUser.team_role == CompanyRole.ACCOUNT_OWNER ||
        currentUser.team_role == CompanyRole.ADMIN)
    ) {
      dispatch({ type: TransactionPageActionType.ShowOatfiDebitModal, payload: true });
    }
  }, [currentUser]);

  useEffect(() => {
    loadTransactions();
  }, [
    state.category,
    state.card,
    state.customRangeStart,
    state.customRangeEnd,
    state.showPayments,
    state.showRewards,
    selectedTags,
  ]);

  useEffect(() => {
    loadCardFilters();
    posthog.capture('Transactions Screen');
  }, [state.isAdministrative]);

  useEffect(() => {
    loadTransactions();
  }, [state.shouldRefresh]);

  const getCsvData = (transactions: Transaction[]) => {
    const rows = [
      [
        getLangText('Date'),
        getLangText('Summary'),
        getLangText('Category'),
        getLangText('Cardholder'),
        getLangText('Amount'),
        getLangText('Status'),
        'Type',
        'Direction',
        'Account Type',
      ],
    ];

    for (const tx of transactions) {
      rows.push([
        dayjs(tx.created_at).format('MM/DD/YYYY'),
        tx.summary,
        tx.category,
        tx.card_owner_name!,
        formatCentsAsDollars(tx.amount),
        tx.status,
        tx.transaction_type || '',
        tx.direction || '',
        tx.account_type || '',
      ]);
    }

    return rows;
  };

  const getTransactionCashBackReward = async (transaction_id: string | undefined) => {
    if (!transaction_id) {
      return null;
    }

    await financialService
      .getTransactionCashback(transaction_id)
      .then(res => {
        if (res?.error) throw new Error();
        if (res) {
          dispatch({
            type: TransactionPageActionType.SelectedTransactionCashback,
            payload: res['cashback_amount'],
          });
        }
      })
      .catch(err => {
        standardError(err);
      });
    return;
  };

  const handleOatfiDebitAcceptance = async () => {
    const resp = await userService.captureOatfiDebitAuthorization();
    if (resp.error) {
      standardError(resp.error);
      return;
    }
    dispatch({ type: TransactionPageActionType.ShowOatfiDebitModal, payload: false });
    window.location.reload();
  };

  return state.loading ? (
    <Spinner />
  ) : (
    <>
      {state.isMobile && (
        <>
          <div
            className="bg-black mb-half"
            style={{
              width: '100%',
              height: '16rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'left',
              flexDirection: 'column',
            }}
          >
            <p className="mb-1 ml-1 font-3xl font-bold font-noto-sans text-white">
              <Text tid="Transactions" />
            </p>

            <div className="mt-2 ml-1 mr-1 bg-primary" style={{ borderRadius: '4px' }}>
              <CSVLink
                data={getCsvData(state.transactions)}
                filename={'toolbox-transactions.csv'}
                target="_blank"
                onClick={() => {
                  posthog.capture('Transactions CSV Download Click', {
                    Cardholder: state.card?.first_name || '',
                    Category: state.category || '',
                    'Show Payments': state.showPayments,
                    'Show Rewards': state.showRewards,
                  });
                }}
              >
                <p className="pb-1 pt-1 font-sm font-noto-sans font-bold font-xl text-white text-center">
                  <Text tid="DownloadCSV" />
                </p>
              </CSVLink>
            </div>
          </div>
        </>
      )}
      <Helmet>
        <title>Toolbox - Transactions</title>
      </Helmet>
      <div
        style={{ display: 'flex', flexDirection: 'column' }}
        className="pt-1 px-2 pb-2 bg-page sm-flex-column sm-px-tiny-padding sm-no-pr"
      >
        <div>
          <Container maxWidth={false} disableGutters>
            <div>
              <div className="flex-column">
                {!state.isMobile && (
                  <p className="mb-half font-4xl font-bold font-noto-sans">
                    <Text tid="Transactions" />
                  </p>
                )}
                <div
                  className={`flex-column bg-white pb-quarter pt-half px-quarter sm-flex-column sm-w-21-important ${
                    state.transactions.length ? '' : 'max-w-mix-w-70'
                  }`}
                >
                  <div className="mb-half flex-row items-center">
                    {state.isMobile && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p className="font-2xl font-bold font-noto-sans">
                          <Text tid="Transactions" /> ({state.transactions.length})
                        </p>
                        <TuneRounded
                          style={{ marginLeft: '6rem' }}
                          onClick={() => setSearchTuneView(!searchTuneView)}
                        />
                      </div>
                    )}
                  </div>
                  {searchTuneView && (
                    <div className="sm:hidden">
                      <>
                        <div className="sm-mt-half sm-mb-half">
                          <DateAndTimePicker
                            noLabel
                            placeholder="From:"
                            value={state.customRangeStart?.toDateString() || ''}
                            onChange={e => {
                              dispatch({
                                type: TransactionPageActionType.CustomRangeStart,
                                payload: e,
                              });
                              posthog.capture('Time Period Filter Updated', {
                                'Custom Range Start': e,
                              });
                            }}
                          />
                        </div>
                        <div>
                          <DateAndTimePicker
                            noLabel
                            placeholder="To:"
                            value={state.customRangeEnd?.toDateString() || ''}
                            onChange={e => {
                              dispatch({
                                type: TransactionPageActionType.CustomRangeEnd,
                                payload: e,
                              });
                              posthog.capture('Time Period Filter Updated', {
                                'Custom Range End': e,
                              });
                            }}
                          />
                        </div>
                      </>
                      <TagPopupSelector
                        selectedTags={selectedTags}
                        showClearAll
                        className="my-[8px] sm-w-20-half min-w-7"
                        onChange={selectedTags => setSelectedTags(selectedTags)}
                      />
                      <CustomAutoComplete
                        className={'sm-w-20-half min-w-7'}
                        dataCy="trans-cardholder-1"
                        dispatcher={dispatch}
                        value={state.card ? formatCardForDisplay(state.card) : ''}
                        options={cardNumbers}
                        title={'Cardholder'}
                        onChange={(newValue: string | null) => {
                          const selectedCard = state.cards.find(
                            (c: CreditCard) =>
                              formatCardForDisplay(c).toLowerCase() === newValue?.toLowerCase(),
                          );
                          if (state.card == selectedCard) return;
                          if (newValue == null)
                            dispatch({
                              type: TransactionPageActionType.Card,
                              payload: null,
                            });
                          else if (selectedCard) {
                            dispatch({
                              type: TransactionPageActionType.Card,
                              payload: selectedCard,
                            });
                          }
                          posthog.capture('Cardholder Filter Updated');
                        }}
                      />
                      <CategoryPopupSelector
                        multiple
                        selectedCategories={state.category}
                        onChange={selectedCategories => onCategoryChange(selectedCategories)}
                        className="w-[215px] pr-[25px]"
                      />
                    </div>
                  )}
                  {!state.isMobile && (
                    <div className="flex-row gap-x items-center sm-align-flex-start flex-wrap 4xl:flex-nowrap">
                      <>
                        <div className="sm-mt-half sm-mb-half">
                          <DateAndTimePicker
                            noLabel
                            placeholder="From:"
                            value={state.customRangeStart?.toDateString() || ''}
                            onChange={e => {
                              dispatch({
                                type: TransactionPageActionType.CustomRangeStart,
                                payload: e,
                              });
                              posthog.capture('Time Period Filter Updated', {
                                'Custom Range Start': e,
                              });
                            }}
                          />
                        </div>
                        <div className="">
                          <DateAndTimePicker
                            noLabel
                            placeholder="To:"
                            value={state.customRangeEnd?.toDateString() || ''}
                            onChange={e => {
                              dispatch({
                                type: TransactionPageActionType.CustomRangeEnd,
                                payload: e,
                              });
                              posthog.capture('Time Period Filter Updated', {
                                'Custom Range End': e,
                              });
                            }}
                          />
                        </div>
                      </>
                      <TagPopupSelector
                        selectedTags={selectedTags}
                        showClearAll
                        onChange={selectedTags => setSelectedTags(selectedTags)}
                      />
                      <CustomAutoComplete
                        dataCy="trans-cardholder-2"
                        className={'sm-w-20-half min-w-7'}
                        dispatcher={dispatch}
                        value={state.card ? formatCardForDisplay(state.card) : ''}
                        options={cardNumbers}
                        title={'Cardholder'}
                        onChange={(newValue: string | null) => {
                          const selectedCard = state.cards.find(
                            (c: CreditCard) =>
                              formatCardForDisplay(c).toLowerCase() === newValue?.toLowerCase(),
                          );
                          if (state.card == selectedCard) return;
                          if (newValue == null)
                            dispatch({
                              type: TransactionPageActionType.Card,
                              payload: null,
                            });
                          else if (selectedCard) {
                            dispatch({
                              type: TransactionPageActionType.Card,
                              payload: selectedCard,
                            });
                          }
                          posthog.capture('Cardholder Filter Updated');
                        }}
                      />
                      <CategoryPopupSelector
                        multiple
                        selectedCategories={state.category}
                        onChange={selectedCategories => onCategoryChange(selectedCategories)}
                        className="w-[215px] pr-[25px]"
                      />
                      <Button
                        data-cy="trans-export"
                        variant="contained"
                        className="flex items-center justify-center w-[190px] py-3  px-4 rounded-md"
                      >
                        <CSVLink
                          data={getCsvData(state.transactions)}
                          filename={'toolbox-transactions.csv'}
                          target="_blank"
                          className="flex items-center justify-between text-white no-underline"
                          onClick={() => {
                            posthog.capture('Transactions CSV Download Click', {
                              Cardholder: state.card?.first_name || '',
                              Category: state.category || '',
                              'Show Payments': state.showPayments,
                              'Show Rewards': state.showRewards,
                            });
                          }}
                        >
                          <DownloadIcon fontSize="small" className="mx-half " />
                          <span className="font-sm font-noto-sans font-bold mr-1">
                            <Text tid="Export All" />
                          </span>
                        </CSVLink>
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {state.transactions.length === 0 ? (
              <div className="h-32">
                {state.loading ? (
                  <Spinner />
                ) : (
                  <p
                    data-id="empty-transaction-list"
                    className="w-full my-2 mx-1 text-center sm-ml-0"
                  >
                    <Text tid="NoTransactionsFoundForThisTimePeriod" />
                  </p>
                )}
              </div>
            ) : (
              <div style={{ width: '100%', overflow: 'hidden' }}>
                {!state.isMobile && (
                  <p className="mb-1 mt-1 font-2xl font-bold font-noto-sans">
                    <Text tid="Transactions" /> ({state.transactions.length})
                  </p>
                )}
                <div style={{ overflow: 'hidden' }}>
                  <TableContainer className="sm-mt-1">
                    <Table datatype="table-inner-box" size="small">
                      <TableBody>
                        <TransactionRows
                          parentState={state}
                          loadTransactions={loadTransactions}
                          dispatcher={dispatch}
                        />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            )}
          </Container>
        </div>

        <DrawerWrapper
          drawerClasses="bg-white px-2 w-full min-h-100vh"
          onOpen={() => {
            posthog.capture('Transaction Details Drawer Open');

            getTransactionCashBackReward(state.selectedTransactionForPopup?.id);
          }}
          open={!!state.selectedTransactionForPopup}
          onClose={() => {
            dispatch({
              type: TransactionPageActionType.SelectedTransactionForPopup,
              payload: null,
            });
            dispatch({ type: TransactionPageActionType.IsReplacingReceipt, payload: false });
            dispatch({ type: TransactionPageActionType.SelectedTransactionCashback, payload: 0 });
          }}
        >
          <TransactionReference
            transactionId={state.selectedTransactionForPopup?.id ?? ''}
            className="mt-1"
          />
          <div className="flex flex-row items-center justify-between w-full px-0 py-4 mt-1">
            <div className="flex flex-row items-center">
              {state.selectedTransactionForPopup?.merchant_logo && (
                <img
                  src={state.selectedTransactionForPopup?.merchant_logo ?? ''}
                  className="w-[43px] h-[43px] mr-[8px] object-contain"
                  alt="merchant logo"
                />
              )}
              <p className="text-2xl font-bold leading-[34px] tracking-normal text-left text-[#1D1D1D]">
                {state.selectedTransactionForPopup?.merchant}
              </p>
            </div>
            <div className="flex-column flex-1 items-end">
              <p className="text-2xl font-bold leading-[34px] tracking-normal text-left text-[#3A6959]">
                {formatCentsAsDollars(state.selectedTransactionForPopup?.amount ?? 0)}
              </p>
              <p className="text-base font-normal leading-[22px] tracking-normal text-left text-[#4D4D4D];">
                {dayjs(state.selectedTransactionForPopup?.created_at).format('MMM DD, YYYY')}
              </p>
            </div>
          </div>

          {state.selectedTransactionCashback > 0 && (
            <div className="flex-row items-center mt-half">
              <PriceCheck />
              <p className="font-noto-sans">You accrued</p>
              <p className="font-base">
                &nbsp;{formatCentsAsDollars(state.selectedTransactionCashback)}&nbsp;
              </p>
              <p className="font-noto-sans"> in cashback rewards!</p>
            </div>
          )}

          {state.selectedTransactionCashback < 0 && (
            <div className="flex-row items-center mt-half">
              <PriceCheck />
              <p className="font-noto-sans">You had</p>
              <p className="font-base">
                &nbsp;{formatCentsAsDollars(state.selectedTransactionCashback, true)}&nbsp;
              </p>
              <p className="font-noto-sans">deducted in cashback rewards</p>
            </div>
          )}

          <div className="mt-1">
            {!state.isReplacingReceipt &&
              (!state.selectedTransactionForPopup?.receipt_url ? (
                <DragDrop
                  dataCy="trans-detail-drag-drop-0"
                  label="Click or drag receipt here"
                  uploadAction={uploadReceipt}
                />
              ) : (
                <div className="rounded-sm outline-dim-gray px-1 py-1 w-18">
                  <p className="pointer font-noto-sans" onClick={handleDownloadReceipt}>
                    <CloudDownloadIcon className="vertical-middle" /> Download Receipt
                  </p>
                  <p className="pointer font-noto-sans mt-1" onClick={handleReplaceReceipt}>
                    <CloudUploadIcon className="vertical-middle" /> Replace Receipt
                  </p>

                  {/* <div className="mt-1">
                    <SaveToGoogleDriveBtn
                      fileUrl={state.selectedTransactionForPopup?.receipt_url}
                      fileName={getFileName(state.selectedTransactionForPopup?.receipt_url ?? '')}
                    />
                  </div> */}
                </div>
              ))}
            {state.isReplacingReceipt && (
              <DragDrop
                dataCy="trans-detail-drag-drop-1"
                label="Click or drag receipt here"
                uploadAction={replaceReceipt}
              />
            )}
            {state.errorMessage && <p>{state.errorMessage}</p>}

            <Show when={state.selectedTransactionForPopup?.receipt_uploaded_by ? true : false}>
              <p className="font-noto-sans font-base pt-1">
                Receipt Uploaded by: {state.selectedTransactionForPopup?.receipt_uploaded_by}
              </p>
            </Show>

            {receiptUrl && (
              <>
                <div className="mt-1" onClick={() => setShowEnlarged(true)}>
                  <p className="mt-1 mb-1 font-noto-sans font-base">Receipt Preview</p>
                  <div style={{ position: 'relative' }}>
                    <img
                      src={receiptUrl ?? ''}
                      alt="Receipt Preview"
                      className="receipt-preview"
                      style={{ width: 100, height: 100, borderRadius: '5px', objectFit: 'contain' }}
                    />
                    <EyeIcon
                      className="vertical-middle"
                      style={{
                        position: 'absolute',
                        top: 40,
                        left: 40,
                        width: 20,
                        height: 20,
                        cursor: 'pointer',
                        color: 'black',
                        opacity: 0.8,
                      }}
                    />
                  </div>
                </div>

                {showEnlarged && (
                  <div
                    style={{
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0, 0, 0, 0.75)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: 1000,
                    }}
                    onClick={() => setShowEnlarged(false)}
                  >
                    <img
                      src={receiptUrl ?? ''}
                      alt="Enlarged Receipt"
                      style={{ maxWidth: '90%', maxHeight: '90%', borderRadius: '5px' }}
                    />
                  </div>
                )}
              </>
            )}
          </div>

          {state.selectedTransactionForPopup?.category && (
            <>
              <p className="font-noto-sans font-base pt-1">Toolbox Category</p>
              <div className="bg-ghost font-noto-sans font-sm items-center flex mt-half px-half py-half rounded-sm outline-dim-gray default">
                <span data-cy={'trans-toolbox-category'}>
                  {state.selectedTransactionForPopup.category}
                </span>
              </div>
            </>
          )}

          <p className="mt-1 font-noto-sans font-base">Merchant Category</p>
          <p
            className="bg-ghost font-noto-sans font-sm items-center flex mt-half px-half py-half text-capitalize rounded-sm outline-dim-gray default"
            data-cy={'trans-merchant-category'}
          >
            {merchantCategory}
          </p>
          <div className="mt-1">
            <p className="font-noto-sans font-base">Memo</p>
            <Input
              fullWidth
              dataCy="trans-add-memo"
              placeholder="Add a memo here"
              onChange={memoText => {
                dispatch({
                  type: TransactionPageActionType.SelectedTransactionForPopup,
                  payload: { ...state.selectedTransactionForPopup, memo: memoText },
                });
              }}
              value={state.selectedTransactionForPopup?.memo ?? ''}
            />

            <Button
              data-cy={'trans-save'}
              className="mt-1 w-full"
              onClick={saveTransactionMemo}
              disabled={state.saveMemoLoading}
            >
              <Text tid="Save" />
            </Button>
          </div>
          <Show when={!!state.selectedTransactionForPopup}>
            <TransactionDrawerTags transaction={state.selectedTransactionForPopup!} />
          </Show>
        </DrawerWrapper>
        <Suspense fallback={<Spinner />}>
          <GenericPopUp
            isOpen={state.showOatfiDebitModal}
            onClose={() => null}
            title="Debit Authorization"
            onClick={() => handleOatfiDebitAcceptance()}
            className="w-32"
            btnText="Accept"
            background="MainPrimary"
            noClose={true}
            noOverlayClose={true}
          >
            <p>
              I authorize Oat Financial ("OatFi"; on behalf of Toolbox) to electronically debit my
              selected account and, if necessary, electronically credit this account to correct
              erroneous debits. Toolbox will notify me via the email on my user account before such
              debits/credits occur.
            </p>
          </GenericPopUp>
        </Suspense>
      </div>
    </>
  );
});

export default TransactionPage;
