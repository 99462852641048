import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { CloseIcon } from '../Icon/fontawesome';

interface CustomDialogProps {
  title?: string;
  subtitle?: string | JSX.Element;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  showHeader?: boolean;
  footerContent?: JSX.Element;
  fullWidth?: boolean;
  width?: number;
}

const CustomDialog: React.FC<CustomDialogProps> = ({
  title,
  subtitle,
  open,
  onClose,
  children,
  showHeader = true,
  footerContent,
  fullWidth = false,
  width = 676,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      PaperProps={{
        sx: {
          ...(isMobile && {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            bottom: 0,
            left: 0,
            right: 0,
            position: 'fixed',
            maxWidth: '100%',
            maxHeight: 'calc(100% - 56px)',
            borderRadius: 0,
            width: '100%',
            margin: 0,
            overflowY: 'auto',
          }),
          ...(!isMobile && {
            width: `${width}px`,
            margin: 'auto',
          }),
        },
      }}
    >
      {showHeader && (
        <DialogTitle
          style={{
            borderBottom: '1px solid #D2D5D8',
            position: 'relative',
            padding: '16px 32px',
            minHeight: 72,
            display: 'flex',
            alignItems: title ? 'start' : 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            {' '}
            <Typography variant="h6" style={{ fontWeight: 'bold', color: 'black', fontSize: 20 }}>
              {title}
            </Typography>
            <Typography style={{ color: '#4D4D4D', fontSize: 16 }}>{subtitle}</Typography>
          </div>

          <CloseIcon
            onClick={onClose}
            className="ml-1"
            style={{
              color: '#09442F',
              cursor: 'pointer',
              height: 16,
              width: 16,
            }}
          />
        </DialogTitle>
      )}

      <DialogContent
        style={{ padding: isMobile ? '0 16px 16px 16px ' : fullWidth ? 0 : '6px 32px 32px 32px' }}
      >
        {children}
      </DialogContent>

      {footerContent && <div style={{ textAlign: 'center', padding: '16px' }}>{footerContent}</div>}
    </Dialog>
  );
};

export default CustomDialog;
