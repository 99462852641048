/**
 * Generic page to show user when we're not sure what to show if an authorization check fails
 */

import { Helmet } from 'react-helmet';
import { ScreenProps } from 'interfaces/baseInterfaces';
import DangerousIcon from '@mui/icons-material/Dangerous';

interface UnauthorizedPageProps extends ScreenProps {}

const UnauthorizedPage = (props: UnauthorizedPageProps) => {
  return (
    <div className="bg-page h-screen flex-column">
      <Helmet>
        <title>Toolbox - Unauthorized</title>
      </Helmet>

      <section className="mt-2 text-center">
        <DangerousIcon fontSize="large" />

        <div>
          <div className="px-4">
            <p className="smaller-title font-bold font-noto-sans font-xl">
              Hey {props.currentUser.first_name}!
            </p>
            <p className="description font-noto-sans font-sm mt-half">
              Unfortunately, you are unauthorized to view this part of the application.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UnauthorizedPage;
