import { useQuery } from '@tanstack/react-query';
import rutterService from './rutter.service';
import { useUserRole } from '../../../auth/data/userRole';
import { RutterPlatform } from '../rutter.types';

export const useGetRutterConnection = (platform: RutterPlatform, enabled: boolean) => {
  const { isAdmin, isBookkeeper } = useUserRole();
  const isUserAllowed = isAdmin || isBookkeeper;
  return useQuery({
    queryKey: [`/rutter/${platform}/connection`],
    queryFn: () => rutterService.getConnection(platform),
    enabled: enabled && isUserAllowed,
  });
};
