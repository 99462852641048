import { Button } from '@mui/material';
import { WarningAmber } from '@mui/icons-material';
import ConfirmationDialog from '../../../../app/components/SuccessDialog';
import { useEffect, useState } from 'react';
import { Tag } from '../../types';
import LoadingButton from '../../../../app/components/LoadingButton';
import SuccessDialog from '../../../../app/components/SuccessDialog';
import Show from '../../../../app/components/Show';
import { useDeleteTag } from '../../data/deleteTag';

interface DeleteTagModalProps {
  tag: Tag;
  isOpen: boolean;
  onClose: () => void;
}

const DeleteTagModal = (props: DeleteTagModalProps) => {
  const { isOpen: isConfirmationModalOpen, onClose, tag } = props;

  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

  const { mutate: deleteTag, isSuccess, isPending, error, isError, data } = useDeleteTag();

  const closeConfirmationModal = () => {
    onClose();
  };

  const closeSuccessDialog = () => {
    setIsSuccessDialogOpen(false);
    closeConfirmationModal();
  };

  const onDeleteConfirm = () => {
    deleteTag(tag.id);
  };

  useEffect(() => {
    if (isSuccess) {
      setIsSuccessDialogOpen(true);
    }
  }, [isSuccess]);

  return (
    <>
      <ConfirmationDialog
        open={isConfirmationModalOpen && !isSuccess}
        onClose={closeConfirmationModal}
        alertIcon={<WarningAmber style={{ fontSize: 30, marginBottom: 2, color: '#FF4D4D' }} />}
        title={`Are you sure you want to delete ${tag.name}?`}
      >
        <div>
          <p>This action is permanent and cannot be undone</p>
          <div className="flex items-center justify-center mt-1">
            <Button
              color="inherit"
              className="cancel-btn mr-1 w-1/2"
              onClick={closeConfirmationModal}
            >
              Cancel
            </Button>
            <LoadingButton className="w-1/2" loading={isPending} onClick={onDeleteConfirm}>
              Confirm Delete
            </LoadingButton>
          </div>
        </div>
      </ConfirmationDialog>

      <Show when={isSuccess}>
        <SuccessDialog
          open={isSuccessDialogOpen}
          onClose={closeSuccessDialog}
          title={`Tag ${tag.name} has been deleted.`}
        >
          <div>
            <div className="flex items-center justify-between mt-1">
              <Button className="w-full" onClick={closeSuccessDialog}>
                Done
              </Button>
            </div>
          </div>
        </SuccessDialog>
      </Show>
    </>
  );
};

export default DeleteTagModal;
