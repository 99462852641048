import baseService from './base.service';
import { DepositAccount } from 'interfaces/account';
import { CashFlowItem } from '../app/pages/dashboard/deposit/reducer';
import httpClient from './httpClient';

export interface GetCashFlowResponse {
  cash_flow: CashFlowItem[];
}

class DepositService {
  public async getAccountNumbers() {
    return await baseService.getJSON('deposit/account-numbers');
  }

  public async getLatestTransactions() {
    return await baseService.getJSON('deposit/latest-transactions');
  }

  public async getTotalInterestPayment() {
    return await baseService.getJSON('deposit/total-interest-payments');
  }

  public async getAvailableBalance() {
    return await baseService.getJSON('deposit/available-balance');
  }

  public async getMoneyMovementTransactions(period: string) {
    return await baseService.getJSON('deposit/money-movement', {
      period,
    });
  }

  public async getCashFlow(period: string) {
    return await baseService.getJSON<GetCashFlowResponse>('deposit/cash-flow', {
      period,
    });
  }

  public async getPendingTransactions() {
    return await baseService.getJSON('deposit/pending-transactions');
  }

  public async getTotalRewards() {
    return await baseService.getJSON('deposit/rewards');
  }

  public async createCheckDeposit(
    amount: number,
    description: string,
    front_image: string,
    back_image: string,
  ) {
    return baseService.postJSON('check-deposits', { amount, description, front_image, back_image });
  }

  public async createACHDebit(plaidAuthId: number, amount: number) {
    return baseService.postJSON('deposit/ach-debit', { plaid_auth_id: plaidAuthId, amount });
  }

  public async getATMLocations(postal_code: string, search_radius = 10) {
    return baseService.getJSON(
      `atm-locations?postal_code=${postal_code}&search_radius=${search_radius}`,
    );
  }

  public async getDepositAccountLimits() {
    return await baseService.getJSON('deposit/limits');
  }

  getDepositAccount = async () => {
    return httpClient.get<DepositAccount>('/deposit-account').then(res => res.data);
  };
}

export default new DepositService();
