import { usePostIntegrationInterest } from '../../companies/data/postIntegrationInterest';
import posthog from 'posthog-js';
import IntegrationConnectionCard from './IntegrationConnectionCard';
import sage_logo from 'app/assets/images/sage.png';

interface SageConnectionCardProps {}

const SageConnectionCard = (props: SageConnectionCardProps) => {
  const { mutate: postInterest, isPending, isSuccess } = usePostIntegrationInterest();

  const handleConnection = () => {
    postInterest('sage');
    posthog.capture('Clicked Sage Button');
  };

  return (
    <IntegrationConnectionCard
      title="Sage"
      subtitle="Coming Soon"
      onConnect={handleConnection}
      connectLabel="Indicate Interest"
      logo={sage_logo}
      disabled={isSuccess || isPending}
    />
  );
};

export default SageConnectionCard;
