/**
 * In terms of authentication hierarchy, this is at the very foundation.
 *
 * It determines that a user has a profile with us.
 *
 * All other auth wrappers should eventually depend on this.
 */

import Spinner from 'app/components/Spinner';
import { ScreenProps } from 'interfaces/baseInterfaces';
import React from 'react';
import { Navigate } from 'react-router';

interface RequiresSignInProps extends ScreenProps {
  children: JSX.Element;
}

const RequiresSignIn = (props: RequiresSignInProps) => {
  const { currentUser, children, ...pageProps } = props;

  if (currentUser === null) {
    return <Navigate to="/sign-in" />;
  }

  if (currentUser === undefined) {
    return <Spinner />;
  }

  return React.cloneElement(children, { ...pageProps, currentUser });
};

export default RequiresSignIn;
