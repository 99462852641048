import CreditAccountingTilesLoader from './CreditAccountingTilesLoader';
import Show from '../../../../../app/components/Show';
import { formatCentsAsDollars } from '../../../../../helpers/formattings';
import { MakePaymentPopup } from '../../../../../app/components/MakePaymentPopUp';
import CreditPaymentButton from '../../../../credit/components/CreditPaymentButton';
import { useGetCreditAccountSummary } from 'features/credit/data/getCreditServices';

interface CreditAccountingTilesProps {}

const CreditAccountingTiles = (props: CreditAccountingTilesProps) => {
  const { data, isLoading, isSuccess } = useGetCreditAccountSummary();

  return (
    <>
      <Show when={isLoading}>
        <CreditAccountingTilesLoader />
      </Show>
      <Show when={isSuccess}>
        <section className="flex flex-wrap items-center justify-start gap-x-4 gap-y-4">
          <div className="bg-slate-100 w-full lg:w-[300px] py-[8px] px-[20px] rounded-lg xl:w-full xl:text-center xl:py-[30px] min-h-[120px]">
            <p className="text-capitalize">Current statement period</p>
            <p className="mt-[5px] text-xl xl:text-2xl xl:font-bold">
              {data?.current_statement_period}
            </p>
          </div>
          <div className="bg-slate-100 w-full lg:w-[300px] py-[8px] px-[20px] rounded-lg xl:w-full xl:text-center xl:py-[30px] min-h-[120px]">
            <p className="text-capitalize">Next payment date</p>
            <p className="mt-[5px] text-xl xl:text-2xl xl:font-bold">{data?.due_date}</p>
          </div>
          <div className="bg-slate-100 w-full lg:w-[300px] py-[12px] pb-[15px] px-[20px] xl:w-full xl:text-center xl:py-[30px] min-h-[120px] rounded-lg">
            <p className="text-capitalize">Payment due</p>
            <div className="mt-[5px]">
              <span className="text-xl xl:text-2xl xl:font-bold">
                {formatCentsAsDollars(data?.amount_due ?? 0)}
              </span>
              <CreditPaymentButton className="w-full mx-auto mt-[10px]" />
            </div>
          </div>
        </section>
      </Show>
    </>
  );
};

export default CreditAccountingTiles;
