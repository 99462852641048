import { useQuery } from '@tanstack/react-query';
import { AccountMappingType, RutterPlatform } from '../rutter.types';
import rutterService from './rutter.service';
import { logger } from '../../../../common/logging';

export interface AccountMapping {
  depositRutterAccountId?: number;
  creditExpenseRutterAccountId?: number;
  creditPaymentRutterAccountId?: number;
}

export const useGetMappedAccounts = (platform: RutterPlatform) =>
  useQuery({
    queryKey: [`/rutter/${platform}/account-mapping`],
    queryFn: () => rutterService.getMappedAccounts(platform),
    select: data => {
      const mappedDepositAccount = data.account_mappings.find(
        accountMapping => accountMapping.account_mapping_type === AccountMappingType.DepositAccount,
      );
      const mappedCreditExpenseAccount = data.account_mappings.find(
        accountMapping =>
          accountMapping.account_mapping_type === AccountMappingType.ChargeCardExpense,
      );
      const mappedCreditPaymentAccount = data.account_mappings.find(
        accountMapping =>
          accountMapping.account_mapping_type === AccountMappingType.ChargeCardPayment,
      );

      return {
        depositRutterAccountId: mappedDepositAccount?.rutter_account_id,
        creditExpenseRutterAccountId: mappedCreditExpenseAccount?.rutter_account_id,
        creditPaymentRutterAccountId: mappedCreditPaymentAccount?.rutter_account_id,
      } as AccountMapping;
    },
  });
