import { MenuItem, TextField } from '@mui/material';
import Show from '../../../../app/components/Show';
import { RutterAccount, RutterPlatform } from '../../rutter/rutter.types';
import { useGetRutterAccounts } from '../../rutter/data/getRutterAccounts';

interface RutterAccountSelectorProps {
  selectedAccountId?: string;
  platform: RutterPlatform;
  onChange: (account: RutterAccount) => void;
  className?: string;
  label?: string;
  textFieldError?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
  size?: 'small' | 'medium';
  allowedAccountCategories?: string[];
}

const RutterAccountSelector = (props: RutterAccountSelectorProps) => {
  const {
    className,
    onChange,
    allowedAccountCategories,
    selectedAccountId,
    label,
    platform,
    textFieldError,
    helperText,
  } = props;

  const { data, isLoading, isError } = useGetRutterAccounts(platform);
  const accounts = data?.accounts ?? [];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const accountId = event.target.value;
    const account = accounts.find(account => `${account.id}` === accountId);
    if (account) {
      onChange(account);
    }
  };

  return (
    <div className={className}>
      <TextField
        select
        disabled={props.disabled}
        size={props.size}
        value={selectedAccountId ?? ''}
        onChange={handleChange}
        label={label ?? 'Select an option'}
        className="w-full"
        error={textFieldError}
        helperText={helperText}
      >
        <Show when={isLoading}>
          <p className="text-center">Loading...</p>
        </Show>
        <Show when={isError}>
          <p className="text-center">Could not load data</p>
        </Show>
        {accounts
          ?.filter(account =>
            allowedAccountCategories ? allowedAccountCategories.includes(account.category) : true,
          )
          .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
          .map(account => (
            <MenuItem
              className="w-full max-w-2xl bg-white"
              key={account.id}
              value={`${account.id}`}
            >
              {account.name}
            </MenuItem>
          ))}
      </TextField>
    </div>
  );
};

export default RutterAccountSelector;
