import CustomDialog from '../../../../app/components/CustomDialog';
import { useEffect, useState } from 'react';
import { Button, InputAdornment, TextField } from '@mui/material';
import { SellOutlined } from '@mui/icons-material';
import TagGroupSelector from '../TagGroupSelector/TagGroupSelector';
import { useCreateTag } from '../../data/createTag';
import LoadingButton from '../../../../app/components/LoadingButton';
import SnackbarNotification from '../../../../app/components/SnackbarNotification';
import Show from '../../../../app/components/Show';
import SuccessDialog from '../../../../app/components/SuccessDialog';
import './style.scss';
import { useNavigate } from 'react-router';

interface CreateTagModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateTagModal = (props: CreateTagModalProps) => {
  const navigate = useNavigate();

  const { isOpen, onClose } = props;

  const [selectedTagGroupId, setSelectedTagGroupId] = useState<number>();
  const [tagName, setTagName] = useState('');
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

  const { mutate: postTag, isSuccess, isPending, error, isError, reset } = useCreateTag();

  const createTag = () => {
    postTag({ name: tagName?.trim(), tag_group_id: selectedTagGroupId! });
  };

  const closeModal = () => {
    setTagName('');
    setSelectedTagGroupId(undefined);
    onClose();
    reset();
  };

  const closeSuccessDialog = () => {
    setIsSuccessDialogOpen(false);
    closeModal();
  };

  const navigateToTransactions = () => {
    navigate('/dashboard/transactions');
  };

  useEffect(() => {
    if (isSuccess) {
      setIsSuccessDialogOpen(true);
    }
  }, [isSuccess]);

  return (
    <>
      <CustomDialog title="Create New Tag" open={isOpen && !isSuccess} onClose={closeModal}>
        <TextField
          label="Tag Name"
          variant="outlined"
          className="w-full mt-1"
          value={tagName}
          onChange={event => setTagName(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SellOutlined />
              </InputAdornment>
            ),
          }}
          error={isError}
        />
        <TagGroupSelector
          className="mt-1"
          onChange={selectedId => setSelectedTagGroupId(selectedId)}
          value={selectedTagGroupId ? `${selectedTagGroupId}` : undefined}
          textFieldError={isError}
        />
        <Show when={isError}>
          <p className="text-sm font-semibold text-[red] mt-[5px]">{error as unknown as string}</p>
        </Show>
        <div className="flex items-center justify-end mt-1">
          <Button color="inherit" className="cancel-btn mr-1" onClick={closeModal}>
            Cancel
          </Button>
          <LoadingButton
            loading={isPending}
            disabled={!tagName || !selectedTagGroupId}
            onClick={createTag}
          >
            Create Tag
          </LoadingButton>
        </div>
      </CustomDialog>

      <Show when={isError}>
        <SnackbarNotification type={'error'} message={error as unknown as string} />
      </Show>
      <Show when={isSuccess}>
        <SuccessDialog
          open={isSuccessDialogOpen}
          onClose={closeSuccessDialog}
          title="Your tag has been created!"
        >
          <div>
            <p>Your tag {tagName} has been created and you can now start tagging transactions.</p>
            <div className="flex items-center justify-between mt-1">
              <Button
                color="inherit"
                className="cancel-btn mr-1 w-1/2"
                onClick={closeSuccessDialog}
              >
                Not now
              </Button>
              <Button onClick={navigateToTransactions} className="w-[12rem]">
                Start tagging
              </Button>
            </div>
          </div>
        </SuccessDialog>
      </Show>
    </>
  );
};

export default CreateTagModal;
