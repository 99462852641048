import { Popover } from '@mui/material';
import { EntityTag } from '../../types';
import EntityTagsWidget from '../EntityTagsWidget';

interface EntityTagsPopupProps {
  isOpen: boolean;
  anchorEl?: HTMLElement;
  onClose: () => void;
  entityTags: EntityTag[];
  onAddTagButtonClick: (event?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onUpdate: () => void;
}

const EntityTagsPopup = (props: EntityTagsPopupProps) => {
  const { isOpen, anchorEl, onClose, entityTags, onAddTagButtonClick, onUpdate } = props;

  return (
    <>
      <Popover
        open={isOpen}
        onClose={onClose}
        anchorEl={anchorEl}
        onClick={e => e.stopPropagation()}
      >
        <EntityTagsWidget
          entityTags={entityTags}
          onAddTagButtonClick={onAddTagButtonClick}
          onUpdate={onUpdate}
        />
      </Popover>
    </>
  );
};

export default EntityTagsPopup;
