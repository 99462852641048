import React from 'react';
import { Typography, useTheme } from '@mui/material';

interface UserInfoProps {
  userName?: string;
  companyName?: string;
  userNameStyle?: React.CSSProperties;
  companyNameStyle?: React.CSSProperties;
}

const UserInfo: React.FC<UserInfoProps> = ({
  userName,
  companyName,
  userNameStyle,
  companyNameStyle,
}) => {
  const theme = useTheme();

  const defaultUserNameStyle: React.CSSProperties = {
    color: theme.palette.common.white,
    fontSize: '14.4px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
  };

  const defaultCompanyNameStyle: React.CSSProperties = {
    color: '#9D9D9D',
    fontSize: '12.8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
  };

  return (
    <div style={{ flexGrow: 1, overflow: 'hidden' }}>
      <Typography variant="h6" style={{ ...defaultUserNameStyle, ...userNameStyle }}>
        {userName}
      </Typography>
      <Typography variant="body2" style={{ ...defaultCompanyNameStyle, ...companyNameStyle }}>
        {companyName}
      </Typography>
    </div>
  );
};

export default UserInfo;
