export enum IdentityVerificationStatus {
  ACTIVE = 'active',
  FAILED = 'failed',
  PENDING_REVIEW = 'pending_review',
  EXPIRED = 'expired',
  SUCCESS = 'success',
  CANCELED = 'canceled',
}

export enum UnitApplicationStatus {
  APPROVED = 'Approved',
  DENIED = 'Denied',
  CANCELED = 'Canceled',
  CREATED = 'Created',
  NOT_STARTED = 'NotStarted',
  PENDING_REVIEW = 'PendingReview',
  AWAITING_DOCUMENTS = 'AwaitingDocuments',
}
