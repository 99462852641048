import { Drawer } from '@mui/material';
import { memo, ReactNode, useEffect, useState } from 'react';
import { CloseIcon } from '../Icon/fontawesome';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface DrawerWrapperProps {
  children: ReactNode;
  open: boolean;
  anchor?: Anchor;
  onClose(): void;
  onOpen(): void;
  drawerClasses?: string;
}

const DrawerWrapper = memo(function DrawerWrapper(props: DrawerWrapperProps) {
  const { children, open = false, anchor = 'right', onClose, drawerClasses = 'px-2' } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDrawer = (open: boolean) => {
    return (event: KeyboardEvent) => {
      if (event?.type === 'keydown' && event?.key === 'Tab') {
        return;
      }
      onClose();
      setIsOpen(open);
    };
  };

  useEffect(() => {
    setIsOpen(open);

    if (open) {
      props.onOpen();
    }
  }, [open]);

  return (
    <>
      <Drawer disableScrollLock={true} anchor={anchor} open={isOpen} onClose={toggleDrawer(false)}>
        <CloseIcon
          data-cy="transaction-detail-close-icon"
          className="close absolute pointer right-4 top-4"
          onClick={() => onClose()}
        />
        <section className={drawerClasses} datatype="section-wrapper">
          {children}
        </section>
      </Drawer>
    </>
  );
});

export default DrawerWrapper;
