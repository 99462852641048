import Spinner from 'app/components/Spinner';
import { removeUnderscore, standardError } from 'helpers/base';
import { MyUser, ScreenProps } from 'interfaces/baseInterfaces';
import { useEffect, useReducer, memo, useRef, MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import depositService, { GetCashFlowResponse } from 'services/deposit.service';
import { depositPageInitialState, depositPageReducer, DepositPageActionType } from './reducer';
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputLabel from '@mui/material/InputLabel';
import './style.scss';
import { Chart, CategoryScale, LinearScale, LineElement, PointElement } from 'chart.js';
import WelcomeHeader from 'app/components/WelcomeHeader';
import TimePeriodSelect from 'app/components/CustomSelect';
import ActionMenuButton from 'app/components/ActionMenuButton';
import BalanceInfo from 'app/components/BalanceInfo';
import LineChart from 'app/components/LineChart';
import InfoCard from 'app/components/InfoCard';
import TransactionsTable from 'app/components/TransactionTable';
import { formatCentsAsDollars, formatDollarNumber } from 'helpers/formattings';
import { ApiError } from 'services/base.service';
import { DepositAccount } from 'interfaces/account';
import dayjs from 'dayjs';
import AchAccountDetailsModal from '../../../../features/depositAccount/components/AchAccountDetailsDialog';
import AchFundingDialog from '../../../../features/depositAccount/components/AchFundingDialog';
import AddFundsDialog from '../../../../features/depositAccount/components/AddFundsDialog';
import AccountInfoDialog from '../../../../features/depositAccount/components/AccountInfoDialog';
import { CompanyRole } from 'interfaces/company';
import {
  ArrowDownIcon,
  ArrowUpRightIcon,
  CameraIcon,
  CashStackIcon,
  Check2CircleIcon,
  CloseIcon,
  DollarSignIcon,
  ExclamationCircleIcon,
  GiftIcon,
  LightBulb2Icon,
  PercentageIcon,
  TextResizeIcon,
} from 'app/components/Icon/fontawesome';
import AchDebitFundingDialog from '../../../../features/depositAccount/components/AchDebitFundingDialog';
import AchDebitConfirmationFundingDialog from '../../../../features/depositAccount/components/AchDebitFundingDialog/Confirmation';
import AchDebitSuccessFundingDialog from '../../../../features/depositAccount/components/AchDebitFundingDialog/Success';
import { Transaction } from '../../../../features/transactions/types';
import { useGetDepositAccount } from '../../../../features/depositAccount/data/getDepositAccount';
import Show from '../../../components/Show';
import posthog from 'posthog-js';
import { useGetDepositAccountLimits } from 'features/depositAccount/data/getDepositAccountLimits';

Chart.register(CategoryScale, LinearScale, LineElement, PointElement);

interface DepositPageProps extends ScreenProps {
  currentUser: MyUser;
}

const DepositPage = memo(function (props: DepositPageProps) {
  const { currentUser } = props;
  const [state, dispatch] = useReducer(depositPageReducer, depositPageInitialState);
  const open = Boolean(state.anchorAction);
  const videoRef = useRef<HTMLVideoElement>(null);
  const photoRef = useRef<HTMLCanvasElement>(null);
  const theme = useTheme();
  const matchesLandscape = useMediaQuery('(orientation:landscape)');
  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const {
    data: depositAccount,
    isLoading: isFetchingAccount,
    isError: isFetchAccountError,
    isSuccess: isFetchAccountSuccess,
  } = useGetDepositAccount();

  const {
    data: depositAccountLimits,
    isLoading: isFetchingLimits,
    isError: isDepositAccountLimitError,
  } = useGetDepositAccountLimits();

  const isLoading = state.loading || isFetchingAccount || isFetchingLimits;

  const transactions = state?.latestTransactions?.map((transaction: Transaction) => ({
    ...transaction,
    id: String(transaction.id),
    amount: transaction.amount,
    category: transaction.category,
  }));

  useEffect(() => {
    if (isFetchAccountError) {
      standardError(isFetchAccountError);
    }
  }, [isFetchAccountError]);

  const loadLatestTransactions = async () => {
    const res = await depositService.getLatestTransactions();
    if (res?.error) {
      standardError(res.error);
      return;
    }
    if (res) {
      dispatch({ type: DepositPageActionType.LatestTransactions, payload: res.data });
    }
  };

  const fetchTotalInterestPayments = async () => {
    const res = await depositService.getTotalInterestPayment();
    if (res?.error) {
      standardError(res.error);
      return;
    }
    if (res) {
      dispatch({
        type: DepositPageActionType.TotalInterestPayments,
        payload: res.total_interest_payments,
      });
    }
  };

  const loadMoneyInAndOut = async () => {
    const res = await depositService.getMoneyMovementTransactions(state.timePeriod);
    if (res?.error) {
      standardError(res.error);
      return;
    }
    if (res) {
      dispatch({ type: DepositPageActionType.MoneyIn, payload: res.money_in });
      dispatch({ type: DepositPageActionType.MoneyOut, payload: res.money_out });
    }
  };

  const loadPendingTransactions = async () => {
    const res = await depositService.getPendingTransactions();
    if (res?.error) {
      standardError(res.error);
      return;
    }
    if (res) {
      dispatch({ type: DepositPageActionType.PendingDeposits, payload: res.pending_deposits });
    }
  };

  const loadTotalRewards = async () => {
    const res = await depositService.getTotalRewards();
    if (res?.error) {
      standardError(res.error);
      return;
    }
    if (res) {
      dispatch({ type: DepositPageActionType.TotalRewards, payload: res.total_rewards });
    }
  };

  const loadCashFlow = async () => {
    const res = await depositService.getCashFlow(state.timePeriod);
    const { error } = res as ApiError;
    if (error) {
      standardError(error);
      return;
    }
    const { cash_flow } = res as GetCashFlowResponse;
    if (res) {
      dispatch({ type: DepositPageActionType.CashFlow, payload: cash_flow.reverse() });
    }
  };

  const getVideo = () => {
    const video: MediaTrackConstraints = {
      facingMode: 'environment',
      aspectRatio: window.innerWidth / window.innerHeight,
    };

    if (matchesLandscape) {
      video.width = window.innerWidth;
    } else {
      video.height = window.innerHeight;
    }

    navigator.mediaDevices
      .getUserMedia({
        video: video,
      })
      .then(stream => {
        const video = videoRef.current;
        if (video) {
          video.srcObject = stream;
          return video.play();
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  const takePhoto = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    dispatch({ type: DepositPageActionType.NewCheckDepositSidePhotoFilled, payload: true });
    if (videoRef) {
      const video = videoRef.current;
      const photo = photoRef.current;
      if (photo && video) {
        photo.width = width;
        photo.height = height;

        const ctx = photo.getContext('2d');
        if (ctx) {
          ctx.drawImage(video, 0, 0, width, height);
        }
      }
    }
  };

  const submitNewCheckDeposit = async () => {
    try {
      dispatch({ type: DepositPageActionType.NewCheckDepositSubmitting, payload: true });
      const amount = Number(state.newCheckDepositAmount) * 100;
      await depositService.createCheckDeposit(
        amount,
        state.newCheckDepositDescription,
        state.newCheckDepositSidePhotoFront,
        state.newCheckDepositSidePhotoBack,
      );
      dispatch({ type: DepositPageActionType.NewCheckDepositFormPopupOpen, payload: false });
      dispatch({ type: DepositPageActionType.NewCheckDepositSubmittedPopupOpen, payload: true });
      dispatch({ type: DepositPageActionType.NewCheckDepositSubmitting, payload: false });
    } catch (e) {
      dispatch({ type: DepositPageActionType.NewCheckDepositSubmitting, payload: false });
      standardError(e);
    }
  };

  const handleActionClick = (event: MouseEvent<HTMLButtonElement>) => {
    posthog.capture('Banking Page - Actions Click');
    dispatch({ type: DepositPageActionType.AnchorAction, payload: event.currentTarget });
  };

  const handleActionClose = () => {
    dispatch({ type: DepositPageActionType.AnchorAction, payload: null });
  };

  const handleActionAddsFunds = () => {
    posthog.capture('Banking Page - Actions - Add Funds Click');
    dispatch({ type: DepositPageActionType.AddFundsPopupOpen, payload: true });
    handleActionClose();
  };

  const handleShowAccountDetails = () => {
    posthog.capture('Banking Page - Actions - View Account Details Click');
    dispatch({ type: DepositPageActionType.AccountDetailsPopupOpen, payload: true });
    handleActionClose();
  };

  const handleViewAllTransactions = () => {
    navigate('/dashboard/transactions');
  };

  useEffect(() => {
    dispatch({
      type: DepositPageActionType.IsAdministrative,
      payload:
        currentUser?.team_role == CompanyRole.ACCOUNT_OWNER ||
        currentUser?.team_role === CompanyRole.SUPER_ADMIN ||
        currentUser?.team_role == CompanyRole.ADMIN,
    });
  }, [currentUser]);

  const fetchData = async () => {
    if (!state.isAdministrative) return;
    await loadCashFlow();
    await fetchTotalInterestPayments();
    await loadMoneyInAndOut();
    await loadPendingTransactions();
    await loadTotalRewards();
    await loadLatestTransactions();
  };

  useEffect(() => {
    if (state.newCheckDepositSidePhoto.length > 0) {
      getVideo();
    } else {
      const video = videoRef.current;
      if (video && !video.paused) {
        video.pause();
        const stream = video.srcObject as MediaStream;
        if (stream) {
          stream.getTracks().forEach(function (track) {
            track.stop();
          });
        }
      }
    }
  }, [videoRef, state.newCheckDepositSidePhoto]);

  useEffect(() => {
    dispatch({ type: DepositPageActionType.Loading, payload: true });
    fetchData()
      .then(() => {
        dispatch({ type: DepositPageActionType.Loading, payload: false });
      })
      .catch(error => {
        standardError(error);
      });
  }, [state.isAdministrative]);

  useEffect(() => {
    void loadCashFlow();
    void loadMoneyInAndOut();
  }, [state.timePeriod]);

  useEffect(() => {
    posthog.capture('Banking Page - Viewed');
  }, []);

  return (
    <>
      <Show when={isLoading}>
        <Spinner />
      </Show>
      <Show when={!isLoading && isFetchAccountSuccess}>
        <div className="deposits-page">
          <Helmet>
            <title>Toolbox - Banking</title>
          </Helmet>
          <Box
            display="flex"
            flexDirection={matchesMobile ? 'column' : 'row'}
            padding="32px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              marginBottom={matchesMobile ? 2 : 0}
              alignSelf="flex-start"
              width={matchesMobile ? '100%' : 'auto'}
            >
              <WelcomeHeader firstName={currentUser.first_name} style={{ color: '#1D1D1D' }} />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              width={matchesMobile ? '100%' : 'auto'}
              justifyContent={matchesMobile ? 'flex-start' : 'space-between'}
            >
              <Box
                marginRight={1}
                flexGrow={matchesMobile ? 1 : 0}
                width={matchesMobile ? 'auto' : 'initial'}
              >
                <TimePeriodSelect
                  value={state.timePeriod}
                  onChange={value => {
                    posthog.capture('Banking Page - Quick Filter Click');
                    dispatch({
                      type: DepositPageActionType.TimePeriod,
                      payload: value.target.value,
                    });
                  }}
                  options={[
                    { value: 'last_30_days', label: 'Last 30 Days' },
                    { value: 'last_60_days', label: 'Last 60 Days' },
                    { value: 'last_90_days', label: 'Last 90 Days' },
                  ]}
                />
              </Box>
              <Box marginLeft={matchesMobile ? 1 : 0}>
                <ActionMenuButton
                  open={open}
                  anchorEl={state.anchorAction}
                  handleActionClick={handleActionClick}
                  handleActionClose={handleActionClose}
                  handleActionAddsFunds={handleActionAddsFunds}
                  handleShowAccountDetails={handleShowAccountDetails}
                />
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection={matchesMobile ? 'column' : 'row'}
            padding="2px 32px 32px 32px"
            margin="32px 0 0 0"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              marginBottom={matchesMobile ? 2 : 0}
              width={matchesMobile ? '100%' : 'auto'}
              flex={matchesMobile ? 'none' : '1 0 auto'}
            >
              <BalanceInfo
                availableBalance={formatCentsAsDollars(depositAccount?.available ?? 0)}
                accountInfoTrigger={handleShowAccountDetails}
                style={{
                  width: matchesMobile ? '100%' : '362px',
                  marginRight: '16px',
                  borderRadius: '2px',
                  background: '#063021',
                  height: 185,
                }}
              />
            </Box>
            <Box width={matchesMobile ? '100%' : 'auto'} flex={matchesMobile ? 'none' : '2 0 auto'}>
              <LineChart
                title="Cash Balance"
                subtitle={removeUnderscore(state.timePeriod)}
                data={{
                  labels: state.cashFlow.map(item => dayjs(item.date).format('MMM DD')),
                  datasets: [
                    {
                      data: state.cashFlow.map(item => item.available / 100),
                      fill: true,
                      backgroundColor: '#36A0FB',
                      borderColor: '#36A0FB',
                      borderWidth: 1,
                      cubicInterpolationMode: 'monotone',
                      tension: 0.4,
                    },
                  ],
                }}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection={matchesMobile ? 'column' : 'row'}
            sx={{
              overflowX: 'visible',
              mt: 1,
              padding: '0 32px',
              width: '100%',
            }}
          >
            <InfoCard
              className="w-33-33-percent px-quarter"
              title="Money In / Out"
              subtitle={removeUnderscore(state.timePeriod)}
              firstIcon={
                <ArrowDownIcon
                  style={{ width: 16, height: 16, color: '#3A6959', cursor: 'pointer' }}
                />
              }
              secondIcon={
                <ArrowUpRightIcon
                  style={{ width: 16, height: 16, color: '#3A6959', cursor: 'pointer' }}
                />
              }
              firstLabel="MONEY IN"
              firstValue={formatCentsAsDollars(state.moneyIn)}
              secondLabel="MONEY OUT"
              secondValue={formatCentsAsDollars(state.moneyOut)}
              linkText="VIEW TRANSACTIONS"
              sx={{
                width: matchesMobile ? '100%' : 'auto',
                marginBottom: matchesMobile ? 2 : 0,
                marginLeft: 0,
              }}
            />

            <InfoCard
              className="w-33-33-percent pr-half"
              title="Pending Transactions"
              firstIcon={
                <ArrowDownIcon
                  style={{ width: 16, height: 16, color: '#3A6959', cursor: 'pointer' }}
                />
              }
              secondIcon={
                <ArrowUpRightIcon
                  style={{ width: 16, height: 16, color: '#3A6959', cursor: 'pointer' }}
                />
              }
              firstLabel="PENDING DEPOSITS"
              firstValue={formatCentsAsDollars(state.pendingDeposits)}
              secondLabel="PENDING WITHDRAWALS"
              secondValue="$0.00"
              linkText="VIEW TRANSACTIONS"
              style={{ marginLeft: matchesMobile ? 0 : 16 }}
              sx={{
                width: matchesMobile ? '100%' : 'auto',
                marginBottom: matchesMobile ? 2 : 0,
                marginLeft: matchesMobile ? 0 : 2,
              }}
            />

            <InfoCard
              className="w-33-33-percent pl-half"
              title="Yield & Rewards"
              firstIcon={
                <PercentageIcon
                  style={{ width: 16, height: 16, color: '#3A6959', cursor: 'pointer' }}
                />
              }
              secondIcon={
                <GiftIcon style={{ width: 16, height: 16, color: '#3A6959', cursor: 'pointer' }} />
              }
              firstLabel="INTEREST EARNED"
              firstValue={formatCentsAsDollars(state.totalInterestPayments)}
              secondLabel="TOTAL POINTS"
              secondValue={String(state.totalRewards)}
              linkText="VIEW REWARDS"
              sx={{
                width: matchesMobile ? '100%' : 'auto',
                marginLeft: matchesMobile ? 0 : 2,
              }}
            />
          </Box>

          <div
            className="flex-row mt-2"
            style={{
              padding: '32px 32px 0 32px',
            }}
          >
            <div className="flex-grow-1">
              <h2 style={{ fontSize: 20, fontWeight: 'semi-bold' }}>Recent Activity</h2>
            </div>
            <div className="flex-grow-1 text-right">
              <Button
                data-cy="viewAllTransactions2"
                color="secondary"
                variant="outlined"
                onClick={() => handleViewAllTransactions()}
              >
                View All Transactions
              </Button>
            </div>
          </div>

          <TransactionsTable
            transactions={transactions}
            loadTransactions={loadLatestTransactions}
            dispatcher={dispatch}
          />
          <AddFundsDialog isOpen={state.addFundsPopupOpen} updateState={dispatch} />
          <AchFundingDialog
            isOpen={state.achFundingPopupOpen}
            updateState={dispatch}
            account={depositAccount!}
          />
          <AchDebitFundingDialog
            isOpen={state.achDebitFundingPopupOpen}
            amount={state.newACHDepositAmount}
            updateState={dispatch}
            externalAccounts={state.externalAccounts}
            account={depositAccount!}
            depositAccountLimits={depositAccountLimits}
          />
          <AchDebitConfirmationFundingDialog
            isOpen={state.achDebitConfirmationFundingPopupOpen}
            amount={state.newACHDepositAmount}
            selectedExternalAccount={state.selectedExternalAccount!}
            updateState={dispatch}
            account={depositAccount!}
            creating={state.achDebitCreatingFunding}
          />
          <AchDebitSuccessFundingDialog
            isOpen={state.achDebitSuccessFundingPopupOpen}
            amount={state.newACHDepositAmount}
            updateState={dispatch}
          />
          <AchAccountDetailsModal
            isOpen={state.achAccountDetailsOpen}
            updateState={dispatch}
            account={depositAccount!}
          />
          <Dialog
            open={state.newCheckDepositPopupOpen}
            onClose={() =>
              dispatch({ type: DepositPageActionType.NewCheckDepositPopupOpen, payload: false })
            }
            fullScreen={true}
          >
            <IconButton
              onClick={() =>
                dispatch({ type: DepositPageActionType.NewCheckDepositPopupOpen, payload: false })
              }
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
              }}
            >
              <CloseIcon fontSize="20px" />
            </IconButton>
            <DialogContent>
              <div className="flex-column h-full">
                <div className="pt-1 flex-grow">
                  <div className="flex-row">
                    <div className="flex-grow-3">
                      <h2 className="mb-half">New Check Deposit</h2>
                      <p>Appears in 1-7 business days</p>
                    </div>
                    <div className="flex-grow flex items-center">
                      <CashStackIcon
                        fontSize="large"
                        style={{ width: 20, height: 20 }}
                        className="rounded-circle bg-gray-10 px-half py-half"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex-grow-8">
                  <FormControl variant="standard" fullWidth={true}>
                    <InputLabel>SET AN AMOUNT</InputLabel>
                    <Input
                      type="number"
                      inputProps={{ style: { fontSize: 50 }, min: 0.01, step: '0.01' }}
                      value={state.newCheckDepositAmount}
                      onChange={e =>
                        dispatch({
                          type: DepositPageActionType.NewCheckDepositAmount,
                          payload: e.target.value,
                        })
                      }
                      startAdornment={
                        <InputAdornment position="start">
                          <DollarSignIcon fontSize="small" color="primary" />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Alert icon={false} severity="warning" className="mt-half">
                    <span className="font-bold">Note:</span> New Check Deposits have limits of{' '}
                    {formatDollarNumber(depositAccountLimits?.checkDeposit?.limits?.daily ?? 0)}/day
                    and{' '}
                    {formatDollarNumber(depositAccountLimits?.checkDeposit?.limits?.monthly ?? 0)}
                    /month
                  </Alert>
                  <FormControl variant="standard" fullWidth={true}>
                    <Input
                      className="mt-4"
                      placeholder="Description"
                      inputProps={{ style: { fontSize: 20 } }}
                      value={state.newCheckDepositDescription}
                      onChange={e =>
                        dispatch({
                          type: DepositPageActionType.NewCheckDepositDescription,
                          payload: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </div>

                <div className="flex flex-grow items-end">
                  <Button
                    fullWidth={true}
                    disabled={
                      state.newCheckDepositAmount.length === 0 ||
                      state.newCheckDepositDescription.length === 0
                    }
                    onClick={() => {
                      dispatch({
                        type: DepositPageActionType.NewCheckDepositPopupOpen,
                        payload: false,
                      });
                      dispatch({
                        type: DepositPageActionType.NewCheckDepositGuidePopupOpen,
                        payload: true,
                      });
                    }}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            open={state.newCheckDepositGuidePopupOpen}
            fullScreen={true}
            PaperProps={{
              className: 'bg-gray-90',
            }}
          >
            <DialogContent>
              <div className="flex-column h-full text-white">
                <h2 className="text-center flex-grow-1">Check Deposit Guide</h2>
                <div className="flex-grow-3 text-center">
                  <p className="mb-half">
                    <IconButton color="primary">
                      <LightBulb2Icon style={{ width: 24, height: 24 }} />
                    </IconButton>
                  </p>
                  <p>Place the check on a dark surface, in a well lit area</p>
                </div>
                <div className="flex-grow-3 text-center">
                  <p className="mb-half">
                    <IconButton color="primary">
                      <TextResizeIcon style={{ width: 22, height: 22 }} />
                    </IconButton>
                  </p>
                  <p>Center the check within the photo borders</p>
                </div>
                <div className="flex-grow-3 text-center">
                  <p className="mb-half">
                    <IconButton color="primary">
                      <CameraIcon style={{ height: 22, width: 22 }} />
                    </IconButton>
                  </p>
                  <p>Hold still, we'll capture the photo for you</p>
                </div>
                <div className="flex-grow-1 flex items-end justify-center">
                  <Button
                    variant="outlined"
                    color="secondary"
                    className="border-white text-white"
                    onClick={() => {
                      dispatch({
                        type: DepositPageActionType.NewCheckDepositGuidePopupOpen,
                        payload: false,
                      });
                      dispatch({
                        type: DepositPageActionType.NewCheckDepositPopupOpen,
                        payload: true,
                      });
                    }}
                  >
                    Go Back
                  </Button>
                  <Button
                    className="ml-1"
                    onClick={() => {
                      dispatch({
                        type: DepositPageActionType.NewCheckDepositGuidePopupOpen,
                        payload: false,
                      });
                      dispatch({
                        type: DepositPageActionType.NewCheckDepositFormPopupOpen,
                        payload: true,
                      });
                    }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog open={state.newCheckDepositFormPopupOpen} fullScreen={true}>
            <IconButton
              onClick={() =>
                dispatch({
                  type: DepositPageActionType.NewCheckDepositFormPopupOpen,
                  payload: false,
                })
              }
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
              }}
            >
              <CloseIcon fontSize="20px" />
            </IconButton>
            <DialogContent>
              <div className="h-full flex-column">
                <div className="pt-1 flex-grow">
                  <div className="flex-row">
                    <div className="flex-grow-3">
                      <h2 className="mb-half">New Check Deposit</h2>
                      <p>Appears in 1-7 business days</p>
                    </div>
                    <div className="flex-grow flex items-center">
                      <CashStackIcon
                        fontSize="large"
                        style={{ width: 20, height: 20 }}
                        className="rounded-circle bg-gray-10 px-half py-half"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex-grow-8">
                  <p className="variant-all-caps mb-half">REVIEW DEPOSIT</p>
                  <div className="flex-row font-sm">
                    <div className="flex-grow-1 text-gray-20">Amount</div>
                    <div className="flex-grow-1 text-right">${state.newCheckDepositAmount}</div>
                  </div>
                  <div className="flex-row font-sm mb-1">
                    <div className="flex-grow-1 text-gray-20">Date</div>
                    <div className="flex-grow-1 text-right">{new Date().toDateString()}</div>
                  </div>
                  <p className="variant-all-caps mb-half">IMAGES</p>
                  <div className="flex-row font-sm text-gray-20">
                    <div className="flex-grow-1">Front</div>
                    <div className="flex-grow-1">Back</div>
                  </div>
                  <div className="flex-row">
                    <div className="flex-grow-1 items-center flex h-5 justify-center">
                      {state.newCheckDepositSidePhotoFront.length === 0 && (
                        <IconButton
                          color="primary"
                          onClick={() => {
                            dispatch({
                              type: DepositPageActionType.NewCheckDepositFormPopupOpen,
                              payload: false,
                            });
                            dispatch({
                              type: DepositPageActionType.NewCheckDepositSidePhotoFilled,
                              payload: false,
                            });
                            dispatch({
                              type: DepositPageActionType.NewCheckDepositSidePhoto,
                              payload: 'front',
                            });
                          }}
                        >
                          <CameraIcon />
                        </IconButton>
                      )}

                      {state.newCheckDepositSidePhotoFront.length > 0 && (
                        <img
                          className="h-full"
                          src={state.newCheckDepositSidePhotoFront}
                          alt="Front"
                        />
                      )}
                    </div>
                    <div className="flex-grow-1 items-center flex h-5 justify-center">
                      {state.newCheckDepositSidePhotoBack.length === 0 && (
                        <IconButton
                          color="primary"
                          onClick={() => {
                            dispatch({
                              type: DepositPageActionType.NewCheckDepositFormPopupOpen,
                              payload: false,
                            });
                            dispatch({
                              type: DepositPageActionType.NewCheckDepositSidePhotoFilled,
                              payload: false,
                            });
                            dispatch({
                              type: DepositPageActionType.NewCheckDepositSidePhoto,
                              payload: 'back',
                            });
                          }}
                        >
                          <CameraIcon />
                        </IconButton>
                      )}

                      {state.newCheckDepositSidePhotoBack.length > 0 && (
                        <img
                          className="h-full"
                          src={state.newCheckDepositSidePhotoBack}
                          alt="Back"
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex-grow flex-column-reverse">
                  <Button
                    fullWidth={true}
                    className="mb-1"
                    disabled={
                      state.newCheckDepositSidePhotoFront.length === 0 ||
                      state.newCheckDepositSidePhotoBack.length === 0 ||
                      state.newCheckDepositSubmitting
                    }
                    onClick={submitNewCheckDeposit}
                  >
                    {state.newCheckDepositSubmitting
                      ? 'Submitting Check Deposit'
                      : 'Submit Check Deposit'}
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog open={state.newCheckDepositSidePhoto.length > 0} fullScreen={true}>
            <DialogContent sx={{ padding: 0 }}>
              <div className="h-full overflow-hidden">
                <div className={state.newCheckDepositSidePhotoFilled ? 'none' : ''}>
                  <video ref={videoRef} className={matchesLandscape ? 'w-full' : 'h-full'}></video>
                  <div
                    className="absolute h-full w-full bottom-0"
                    style={{ border: '50px solid rgb(0,0,0,0.3)' }}
                    onClick={takePhoto}
                  >
                    <div
                      className="relative h-2 w-2"
                      style={{
                        borderLeft: '5px solid rgba(255 255 255)',
                        borderTop: '5px solid rgba(255 255 255)',
                      }}
                    ></div>
                    <div
                      className="h-2 w-2"
                      style={{
                        position: 'absolute',
                        borderRight: '5px solid rgba(255 255 255)',
                        borderTop: '5px solid rgba(255 255 255)',
                        top: 0,
                        right: 0,
                      }}
                    ></div>
                    <div
                      className="h-2 w-2"
                      style={{
                        position: 'absolute',
                        borderLeft: '5px solid rgba(255 255 255)',
                        borderBottom: '5px solid rgba(255 255 255)',
                        bottom: 0,
                        left: 0,
                      }}
                    ></div>
                    <div
                      className="h-2 w-2"
                      style={{
                        position: 'absolute',
                        borderRight: '5px solid rgba(255 255 255)',
                        borderBottom: '5px solid rgba(255 255 255)',
                        bottom: 0,
                        right: 0,
                      }}
                    ></div>
                  </div>
                </div>
                <div className={state.newCheckDepositSidePhotoFilled ? 'h-full' : 'none'}>
                  <canvas ref={photoRef} />
                  <div className="absolute bottom-0 text-center w-full">
                    <Button
                      className="mb-1"
                      color="info"
                      onClick={() => {
                        dispatch({
                          type: DepositPageActionType.NewCheckDepositSidePhotoFilled,
                          payload: false,
                        });
                      }}
                    >
                      Take another
                    </Button>
                    <Button
                      className="ml-1 mb-1"
                      onClick={() => {
                        if (!photoRef || !photoRef.current) {
                          return;
                        }
                        if (state.newCheckDepositSidePhoto === 'front') {
                          dispatch({
                            type: DepositPageActionType.NewCheckDepositSidePhotoFront,
                            payload: photoRef.current.toDataURL('image/jpeg'),
                          });
                        } else {
                          dispatch({
                            type: DepositPageActionType.NewCheckDepositSidePhotoBack,
                            payload: photoRef.current.toDataURL('image/jpeg'),
                          });
                        }
                        dispatch({
                          type: DepositPageActionType.NewCheckDepositSidePhoto,
                          payload: '',
                        });
                        dispatch({
                          type: DepositPageActionType.NewCheckDepositFormPopupOpen,
                          payload: true,
                        });
                        dispatch({
                          type: DepositPageActionType.NewCheckDepositSidePhotoFilled,
                          payload: false,
                        });
                      }}
                    >
                      Use photo
                    </Button>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog open={state.newCheckDepositSubmittedPopupOpen} fullScreen={true}>
            <IconButton
              onClick={() =>
                dispatch({
                  type: DepositPageActionType.NewCheckDepositSubmittedPopupOpen,
                  payload: false,
                })
              }
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
              }}
            >
              <CloseIcon fontSize="20px" />
            </IconButton>
            <DialogContent>
              <div className="h-full flex-column">
                <div className="flex-grow-6">
                  <div className="text-center mb-2 mt-2">
                    <p>
                      <Check2CircleIcon
                        style={{ fontSize: 30, marginBottom: 2, color: '#6FCF97' }}
                      />
                    </p>
                    <h3 className="mb-half">Check deposit submitted</h3>
                    <p className="text-gray-70">
                      The amount should arrive within 1 to 7 business days.
                    </p>
                  </div>
                  <Paper className="text-center bg-gray-3" variant="outlined">
                    <div className="py-1 px-1">
                      <h2 className="font-3xl">$ {state.newCheckDepositAmount} </h2>
                      <p className="text-gray-70">Check to Spending Account</p>
                    </div>

                    <div className="flex-row align-middle bg-warning-light">
                      <div className="py-half px-half">
                        <ExclamationCircleIcon fontSize="12px" />
                      </div>
                      <div className="font-xs text-left py-half pr-half self-center">
                        Make sure to keep your check for 14 days or until it clears
                      </div>
                    </div>
                  </Paper>
                </div>
                <div className="flex-grow-1 flex-column-reverse">
                  <Button
                    className="mt-1"
                    onClick={() => {
                      dispatch({
                        type: DepositPageActionType.NewCheckDepositSubmittedPopupOpen,
                        payload: false,
                      });
                      dispatch({ type: DepositPageActionType.NewCheckDepositAmount, payload: '' });
                      dispatch({
                        type: DepositPageActionType.NewCheckDepositDescription,
                        payload: '',
                      });
                      dispatch({
                        type: DepositPageActionType.NewCheckDepositSidePhotoFront,
                        payload: '',
                      });
                      dispatch({
                        type: DepositPageActionType.NewCheckDepositSidePhotoBack,
                        payload: '',
                      });
                      dispatch({
                        type: DepositPageActionType.NewCheckDepositPopupOpen,
                        payload: true,
                      });
                    }}
                  >
                    Deposit Another Check
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog open={state.accountDetailsPopupOpen} fullScreen={false}>
            <IconButton
              onClick={() =>
                dispatch({ type: DepositPageActionType.AccountDetailsPopupOpen, payload: false })
              }
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
              }}
            >
              <CloseIcon fontSize="20px" />
            </IconButton>
            <DialogContent>
              <div className="h-full flex-column">
                <p className="mt-2">
                  Account number:{' '}
                  <span className="sensitive">{depositAccount?.account_number}</span>
                </p>
                <p>
                  Routing number:{' '}
                  <span className="sensitive">{depositAccount?.routing_number}</span>
                </p>
              </div>
            </DialogContent>
          </Dialog>

          <AccountInfoDialog
            isOpen={state.accountDetailsPopupOpen}
            account={depositAccount!}
            onAccountInfoDialogClose={() =>
              dispatch({
                type: DepositPageActionType.AccountDetailsPopupOpen,
                payload: false,
              })
            }
          />
        </div>
      </Show>
    </>
  );
});

export default DepositPage;
