import { useGetCsvStatement } from '../../../data/getCsvStatement';
import { useEffect, useState } from 'react';
import Show from '../../../../../app/components/Show';
import { CircularProgress } from '@mui/material';
import { GenericFontAwesomeIcon } from '../../../../../app/components/Icon/fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useGetPdfStatement } from '../../../data/getPdfStatement';

interface PdfDownloadButtonProps {
  statementId: string;
  statementPeriod: string;
}

const PdfDownloadButton = (props: PdfDownloadButtonProps) => {
  const { statementPeriod } = props;
  const [statementId, setStatementId] = useState<string>();
  const { data: pdfData, isLoading } = useGetPdfStatement(statementId);

  const triggerDownload = (pdfData: Blob) => {
    const data = new Blob([pdfData], { type: 'application/pdf' });
    const csvURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `Toolbox Statement ${statementPeriod}.pdf`);
    tempLink.click();
  };

  const downloadStatement = () => {
    setStatementId(props.statementId);
  };

  useEffect(() => {
    if (pdfData) {
      triggerDownload(pdfData);
      setStatementId(undefined);
    }
  }, [pdfData]);

  return (
    <span className="w-[15px] h-[15px]">
      <Show when={isLoading}>
        <CircularProgress size={15} />
      </Show>
      <Show when={!isLoading}>
        <GenericFontAwesomeIcon
          icon={faDownload}
          className="cursor-pointer"
          onClick={downloadStatement}
        />
      </Show>
    </span>
  );
};

export default PdfDownloadButton;
